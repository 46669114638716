import { IM, IMLayout, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { TicketArticle } from '../../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import { cardTextStyle } from '../../../components/CardText'
import WarrantyLoader from '../../../components/WarrantyLoader'
import { ThemeColorExpanded } from '../../../types'
import ticketUtils from '../../../utils/TicketUtils'

interface Props {
  article: TicketArticle
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const TicketArticleWarrantyCard = memo(function TicketArticleWarrantyCard({ spacing, article, style }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const expired = useMemo(
    () =>
      !!article.serialnumberCustomerWarrantyStart &&
      !!article.serialnumberCustomerWarrantyEnd &&
      ticketUtils.getWarrantyStatus(article.serialnumberCustomerWarrantyStart, article.serialnumberCustomerWarrantyEnd).expired,
    [article]
  )

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('WARRANTY')} />} spacing={spacing} style={[styles.container, style]}>
      {article.serialnumberCustomerWarrantyStart && article.serialnumberCustomerWarrantyEnd ? (
        <IM.View style={styles.contentContainer}>
          <IM.View style={IMLayout.flex.row} spacing="bottom">
            <IM.Text style={cardTextStyle.title}>{`${i18n.t('STATUS')}: `}</IM.Text>
            <IM.Text style={[cardTextStyle.title, { color: expired ? theme.article.status.red : theme.article.status.green }]}>
              {`${expired ? i18n.t('EXPIRED') : i18n.t('ON_WARRANTY')}`.toLowerCase()}
            </IM.Text>
          </IM.View>
          {article.serialnumberCustomerWarranty && (
            <IM.View spacing="bottom">
              <IM.Text>{article.serialnumberCustomerWarranty}</IM.Text>
            </IM.View>
          )}
          <WarrantyLoader
            startDate={article.serialnumberCustomerWarrantyStart}
            endDate={article.serialnumberCustomerWarrantyEnd}
            showDates
            spacing={article.serialnumberCustomerWarranty ? 'none' : 'top'}
          />
        </IM.View>
      ) : (
        <IM.Text secondary>{i18n.t('NO_WARRANTY_FOUND')}</IM.Text>
      )}
    </IM.Card>
  )
})

export default TicketArticleWarrantyCard

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  contentContainer: { marginHorizontal: 2 },
})
