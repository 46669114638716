import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useCallback, useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { Customer, IsoVatnumber } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'

export type Props = {
  customer: Customer
  selected?: boolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  onPress?: (isBlocked: boolean) => void
}

const CustomerDetailCard = memo(function CustomerDetailCard({ customer, spacing, selected, style, onPress }: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const street = useMemo(() => customerUtils.formatAddress(customer, 'street'), [customer])
  const location = useMemo(() => customerUtils.formatAddress(customer, 'location'), [customer])

  const getVat = useCallback(
    (iso: IsoVatnumber) => {
      return customerUtils.getVat(iso, i18n)
    },
    [i18n]
  )

  const blocked = customerUtils.isBlocked(customer)
  const inactive = customer.inactive
  const opacity = inactive ? appUtils.getOpacity(colorScheme) : 1

  return (
    <IM.Card
      head={<CardLeftTitle color={selected ? theme.general.info : theme.card.accent.active} />}
      onPress={() => onPress?.(blocked)}
      spacing={spacing}
      style={[style, { opacity }]}
      disabled={inactive}>
      <IM.View spacing="bottom">
        <TextWithIcon
          icon={blocked ? ['fas', 'user-slash'] : ['fal', 'building']}
          iconColor={blocked ? theme.general.error : undefined}
          secondary={customer.inactive}
          style={[IMLayout.flex.f1, { fontWeight: IMStyle.typography.fontWeightBold, fontSize: IMStyle.typography.fontSizeRegular }]}>
          {`${customer.description} (${customer.number})`}
        </TextWithIcon>
      </IM.View>
      <IM.View>
        {customer.isoVatnumber && getVat(customer.isoVatnumber) && <IM.Text secondary={customer.inactive}>{getVat(customer.isoVatnumber)}</IM.Text>}
        {!!street && <IM.Text secondary={customer.inactive}>{street}</IM.Text>}
        {!!location && <IM.Text secondary={customer.inactive}>{location}</IM.Text>}
      </IM.View>
    </IM.Card>
  )
})

export default CustomerDetailCard
