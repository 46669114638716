import { IM, IMLayout, useDimensions, useEvent } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { Animated, Platform, ScrollView, StyleSheet, useWindowDimensions } from 'react-native'

import api from '../../apis/apiCalls'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { ScreenHeaderShadows } from '../../components/screen/constants/constants'
import { REFRESH_CONTACT_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_CUSTOMER } from '../../constants/Keys'
import CustomerDetailsLargeDeviceView from './CustomerDetailsLargeDeviceView'
import CustomerDetailsSmallDeviceView from './CustomerDetailsSmallDeviceView'

interface Props {
  customerId: string
  buttonAnimationValue: Animated.Value
}

const CustomerDetailsView = ({ customerId, buttonAnimationValue }: Props) => {
  const { isSmallDevice } = useDimensions()
  const { width } = useWindowDimensions()
  const { item: customer, loadItem: loadCustomer, loading } = useControlledLoader(api.getCustomer, { id: REQUEST_CUSTOMER })

  useEvent({ key: REFRESH_CONTACT_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [customerId])

  const refresh = () => loadCustomer({ id: customerId })

  return (
    <ScrollView
      canCancelContentTouches={false}
      keyboardShouldPersistTaps="never"
      contentContainerStyle={[IMLayout.flex.f1, ScreenHeaderShadows]}
      horizontal
      style={styles.container}>
      {loading === false && customer && customer.length !== 0 ? (
        <>
          {isSmallDevice ? (
            <CustomerDetailsSmallDeviceView customer={customer[0]} buttonAnimationValue={buttonAnimationValue} />
          ) : (
            <CustomerDetailsLargeDeviceView customer={customer[0]} buttonAnimationValue={buttonAnimationValue} />
          )}
        </>
      ) : (
        <IM.SkeletonContainer style={IMLayout.flex.f1}>
          <IM.Rect x="20" y="20" rx="5" ry="5" width={'40%'} height={20} />
          <IM.Rect x={width - width * 0.4 - 20} y="20" rx="5" ry="5" width={width * 0.4} height={20} />
          <IM.Rect x="20" y="60" rx="5" ry="5" width={'40%'} height={20} />
          <IM.Rect x={width - width * 0.4 - 20} y="60" rx="5" ry="5" width={width * 0.4} height={20} />
          <IM.Rect x="0" y="100" rx="20" ry="20" width={'100%'} height={'100%'} />
        </IM.SkeletonContainer>
      )}
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  // @ts-ignore missing overflowX on RN
  container: {
    ...Platform.select({
      web: {
        overflowX: 'hidden',
      },
    }),
  },
})

export default CustomerDetailsView
