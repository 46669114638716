import { IM, IMStyle } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { StyleSheet } from 'react-native'

import Text from './Text'

interface Props {
  text: string
  width: number
}

export default function VerticalText({ text, width }: Props) {
  const [textDim, setTextDim] = useState(0)

  return (
    <IM.View style={[styles.container, { width }]} onLayout={e => setTextDim(e.nativeEvent.layout.height)}>
      <IM.View
        style={[
          styles.containerRotated,
          {
            width: textDim,
            height: width,
          },
        ]}>
        <Text style={[styles.text, { width: textDim }]} numberOfLines={1}>
          {text}
        </Text>
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    minHeight: 60,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerRotated: {
    alignItems: 'center',
    justifyContent: 'center',
    transform: [{ rotate: '270deg' }],
  },
  text: {
    color: IMStyle.palette.white,
    fontSize: IMStyle.typography.fontSizeRegular,
    textAlign: 'center',
    // @ts-ignore web-only style
    textAlignVertical: 'middle',
  },
})
