import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'
import Text from './Text'

interface Props {
  value: string
  size?: 'normal' | 'small'
  style?: StyleProp<ViewStyle>
  color?: string
  textColor?: string
}

export default function Chip({ value, size = 'normal', color, textColor, style }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <IM.View style={[styles.chip, { backgroundColor: color ?? theme.chip.background, paddingVertical: size === 'normal' ? 4 : 2 }, style]}>
      <Text
        style={[
          styles.chipText,
          {
            fontSize: size === 'normal' ? IMStyle.typography.fontSizeSmall : IMStyle.typography.fontSizeSmall - 2,
            color: textColor ?? theme.chip.text,
          },
        ]}
        numberOfLines={1}>
        {value}
      </Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  chip: {
    paddingHorizontal: 16,
    minWidth: 80,
    maxWidth: 160,
    borderRadius: 20,
    alignSelf: 'flex-start',
  },
  chipText: { textAlign: 'center' },
})
