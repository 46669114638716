import { useEvent, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import BooleanInput from '../../components/input/BooleanInput'
import DateInput, { convertDateToInputFormat } from '../../components/input/DateInput'
import { TextInputProps } from '../../components/input/TextInput'
import TimeInput from '../../components/input/TimeInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import { REFRESH_ACTIVITY_VIEW_EVENT_KEY } from '../../constants/EmitterKeys'
import useForm from '../../hooks/useForm'
import { ActivityUnitUp, EditOrCreateViewProps, EditOrCreateViewRef, TimeFormat, UnitUp, UploadStatus } from '../../types'
import TimeUtils from '../../utils/TimeUtils'

const defaultUnitUp: UnitUp = {
  unitUp: false,
  date: new Date(TimeUtils.resetTimeOfISOString(new Date().toISOString())).toISOString(),
}

type Props = { activityId: string; initialValue: ActivityUnitUp | undefined } & Omit<EditOrCreateViewProps, 'disabled'>

const ActivityUnitUpView = ({ activityId, initialValue, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { request: edit, loading: loadingEdit } = useRequest(api.editActivity)
  const { emit } = useEvent({ key: REFRESH_ACTIVITY_VIEW_EVENT_KEY })

  const initialData = useRef<UnitUp>(
    initialValue
      ? {
          unitUp: initialValue.unitUp,
          date: initialValue.dateTime ? new Date(TimeUtils.resetTimeOfISOString(initialValue.dateTime)).toISOString() : defaultUnitUp.date,
          time: initialValue.dateTime ? TimeUtils.isoStringToSeconds(initialValue.dateTime) : undefined,
        }
      : defaultUnitUp
  )

  const { error } = useForm()
  const { i18n, language } = useLanguage()

  const [state, setState] = useState(initialData.current)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'
    let equal = error

    if (!error) {
      equal = isEqual(initialData.current, state)

      if (equal === false && state?.time === undefined) {
        updateUploadStatus('mandatoryMissing')
        return
      }
    }

    if (!equal) {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state, error])

  useEffect(() => {
    if (loadingEdit === 'catched') {
      updateUploadStatus('waiting')

      return
    }

    const loadingDone = loadingEdit === false

    if (waitingUpload !== 'done' && loadingDone) {
      emit()
      updateUploadStatus('done')
    }
  }, [loadingEdit])

  const isEqual = (a: UnitUp, b: UnitUp) => {
    let toRet = true

    toRet = a.date === b.date && a.time === b.time && a.unitUp === b.unitUp

    return toRet
  }

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = () => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    edit({
      id: activityId,
      unitUp: state.unitUp,
      unitUpDateTime: TimeUtils.resetTimeOfISOString(state.date ? new Date(state.date).toISOString() : new Date().toISOString(), state.time),
    } as Activity)
  }

  const handleChangeText = (newVal: Partial<UnitUp>) => {
    if (waitingUpload === 'uploading') return

    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const dateValue = useMemo(
    () => (initialData.current.date ? convertDateToInputFormat(new Date(initialData.current.date), language) : undefined),
    [initialData, language]
  )

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
  }

  return (
    <ScrollViewForm>
      <BooleanInput
        title={i18n.t('UNIT_UP')}
        value={state.unitUp}
        onValueChange={() => handleChangeText({ unitUp: !state.unitUp })}
        {...commonProps}
      />
      <DateInput
        title={i18n.t('DATE') + ' *'}
        value={dateValue}
        onChangeDate={date => handleChangeText({ date: date ? date.toISOString() : undefined })}
        {...commonProps}
      />
      <TimeInput
        value={state.time ? TimeUtils.secondsToTime(state.time, TimeFormat.TIME) : undefined}
        title={i18n.t('TIME') + ' *'}
        onChangeTime={val => handleChangeText({ time: val })}
        {...commonProps}
      />
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityUnitUpView)
