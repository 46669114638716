import React, { createContext, ReactElement, useContext, useEffect, useState } from 'react'
import { LatLng } from 'react-native-maps'

import GeoLocationUtils from '../utils/GeoLocationUtils'

interface GeoLocationContextType {
  location: LatLng | null
  updateLocation: () => Promise<LatLng | null>
}

export const GeoLocationContext = createContext<GeoLocationContextType | undefined>(undefined)

export const GeoLocationProvider = ({ children, requestPermissionOnMount }: { children: ReactElement; requestPermissionOnMount?: boolean }) => {
  const [location, setLocation] = useState<LatLng | null>(null)

  useEffect(() => {
    if (!requestPermissionOnMount) return
    updateLocation().catch(console.error)
  }, [])

  async function updateLocation() {
    const updatedLocation = await GeoLocationUtils.getDevicePosition()

    if (updatedLocation) setLocation(updatedLocation)
    return updatedLocation
  }

  return <GeoLocationContext.Provider value={{ location, updateLocation }}>{children}</GeoLocationContext.Provider>
}

export function useDevicePosition() {
  const geoLocationContext = useContext(GeoLocationContext)

  useEffect(() => {
    geoLocationContext?.updateLocation().catch(console.error)
  }, [])

  return geoLocationContext?.location
}
