import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import EditOrCreateHeader from '../../components/screen/headers/EditOrCreateHeader'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { ActivityStackScreenProps } from '../../navigation/types'
import { ActivityPurchaseOrderParams, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import ActivityPurchaseOrderCreateView from '../../views/activity/ActivityPurchaseOrderCreateView'

const ActivityPurchaseOrderScreen = (
  { route, controller }: Pick<ActivityStackScreenProps<'ActivityPurchaseOrder'>, 'route'> & ModalScreenProps<ActivityPurchaseOrderParams>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const editOrCreateViewRef = createRef<EditOrCreateViewRef>()

  const { i18n } = useLanguage()
  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={handleGoBack}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        onRightIconPress={() => {
          Keyboard.dismiss()
          editOrCreateViewRef.current?.handleUpload()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}>
        <EditOrCreateHeader
          title={i18n.t('CREATE_PURCHASE_ORDER')}
          icon={['fal', 'pen-field']}
          iconSize={20}
          showButton={false}
          onPress={() => {
            return
          }}
        />
      </Screen.Header>
      <Screen.Content>
        <FormProvider>
          <ActivityPurchaseOrderCreateView ref={editOrCreateViewRef} ticketId={route.params.ticketId} onUploadStatus={setStatus} />
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(ActivityPurchaseOrderScreen)
