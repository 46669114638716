import { useTheme } from '@infominds/react-native-components'
import React, { createContext, memo, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { ThemeColorExpanded } from '../../../../types'
import { BaseTextInputProps } from '../BaseTextInput'

interface BaseTextInputContextProps {
  focus: boolean
  loading: boolean
  editable: boolean
  borderColor: string
  backgroundColor: string
  onClickEffect: (focusEff: boolean) => void
}

const BaseTextInputContext = createContext<BaseTextInputContextProps | undefined>(undefined)

export interface BaseTextInputProviderProps {
  error?: boolean | { value: boolean; message: string }
  loading?: boolean
  disableFocus?: boolean
  onBlur?: () => void
  onFocus?: () => void
}
// TODO either make this generic or rename it to AccessDataCardProvider

export const BaseTextInputProvider = memo(function BaseTextInputProvider({
  children,
  editable = true,
  error = false,
  loading = false,
  disableFocus = false,
  onBlur,
  onFocus,
}: PropsWithChildren<BaseTextInputProviderProps & Pick<BaseTextInputProps, 'editable'>>) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const [focus, setFocus] = useState(false)

  useEffect(() => {
    setFocus(false)
  }, [])

  useEffect(() => {
    focus === false && onBlur?.()
    focus && onFocus?.()
  }, [focus])

  useEffect(() => {
    editable === false && onClickEffect(false)
  }, [editable])

  const onClickEffect = useCallback(
    (focusEff: boolean) => {
      if (focusEff === false) setFocus(false)

      disableFocus === false && editable && loading === false && setFocus(focusEff)
    },
    [disableFocus, editable, loading]
  )

  const hasError = typeof error === 'boolean' ? error : error.value
  const borderColor = hasError
    ? theme.inputBox.border.error
    : focus
      ? theme.general.info
      : editable
        ? theme.inputBox.border.active
        : theme.inputBox.border.disabled

  const backgroundColor = editable ? theme.inputBox.background.active : theme.inputBox.background.disabled

  const props = useMemo<BaseTextInputContextProps>(
    () => ({
      focus,
      loading,
      editable,
      borderColor,
      backgroundColor,
      onClickEffect,
    }),
    [loading, editable, focus, borderColor, backgroundColor]
  )

  return <BaseTextInputContext.Provider value={props}>{children}</BaseTextInputContext.Provider>
})

export default BaseTextInputContext
