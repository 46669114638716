import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { OptionsProvider } from '@infominds/react-native-camera'
import { useDimensions, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { useAuthentication, useInAppUpdates } from '@infominds/react-native-license'
import { AssetProvider } from '@infominds/react-native-media'
import { createNativeStackNavigator, NativeStackNavigationOptions } from '@react-navigation/native-stack'
import React, { memo, useEffect, useMemo } from 'react'
import { Platform, StatusBar } from 'react-native'
import { useSetRecoilState } from 'recoil'

import AppContent from '../components/Infominds/AppContent'
import DrawerNavigator from '../components/Infominds/DrawerNavigator'
import TabNavigator from '../components/Infominds/TabNavigator'
import CONSTANTS from '../constants/Constants'
import { FilterProvider } from '../contexts/FilterContext'
import { MenuProvider } from '../contexts/MenuContext'
import { QualityCheckFilterProvider } from '../contexts/QualityCheckFilterContext'
import { QualityFilterProvider } from '../contexts/QualityFilterProvider'
import { SparePartsFilterProvider } from '../contexts/SparePartsFilterContext'
import { VaultProvider } from '../contexts/VaultContext'
import useIsOnline from '../dataProvider/hooks/useIsOnline'
import useUserSettings from '../hooks/useUserSettings'
import ErrorModal from '../modals/ErrorModal'
import AccessDataEditOrCreateScreen from '../screens/accessData/AccessDataEditOrCreateScreen'
import AccessDataFilterScreen from '../screens/accessData/AccessDataFilterScreen'
import AccessDataScreen from '../screens/accessData/AccessDataScreen'
import ActivityAllocationScreen from '../screens/activity/ActivityAllocationScreen'
import ActivityArticleCounterScreen from '../screens/activity/ActivityArticleCounterScreen'
import ActivityCloseScreen from '../screens/activity/ActivityCloseScreen'
import ActivityPlanningTimesEditOrCreateScreen from '../screens/activity/ActivityPlanningTimesEditOrCreateScreen'
import ActivityPurchaseOrderScreen from '../screens/activity/ActivityPurchaseOrderScreen'
import ActivityScreen from '../screens/activity/ActivityScreen'
import ActivitySendReportScreen from '../screens/activity/ActivitySendReportScreen'
import ActivitySparePartsScreen from '../screens/activity/ActivitySparePartsScreen'
import ActivitySparePartsShoppingCartScreen from '../screens/activity/ActivitySparePartsShoppingCartScreen'
import ActivityTimesEditOrCreateScreen from '../screens/activity/ActivityTimesEditOrCreateScreen'
import ActivityUnitUpScreen from '../screens/activity/ActivityUnitUpScreen'
import EditAddFieldsScreen from '../screens/common/EditAddFieldsScreen'
import EditClassificationScreen from '../screens/common/EditClassificationScreen'
import EditStateScreen from '../screens/common/EditStateScreen'
import FilterScreen from '../screens/common/FilterScreen'
import NoteEditOrCreateScreen from '../screens/common/NoteEditOrCreateScreen'
import QualityCheckFilterScreen from '../screens/common/QualityCheckFilterScreen'
import QualityCheckScreen from '../screens/common/QualityCheckScreen'
import QualityFilterScreen from '../screens/common/QualityFilterScreen'
import SparePartsFilterScreen from '../screens/common/SparePartsFilterScreen'
import CustomerDetailContactEditOrCreateScreen from '../screens/customer/CustomerDetailContactEditOrCreateScreen'
import CustomerDetailDestinationEditOrCreateScreen from '../screens/customer/CustomerDetailDestinationEditOrCreateScreen'
import CustomerDetailsScreen from '../screens/customer/CustomerDetailScreen'
import HistoryScreen from '../screens/history/HistoryScreen'
import InfoboxAssetInfoScreen from '../screens/infobox/InfoboxAssetInfoScreen'
import InfoboxAssetScreen from '../screens/infobox/InfoboxAssetScreen'
import InfoboxCameraScreen from '../screens/infobox/InfoboxCameraScreen'
import InfoboxCameraSettingsScreen from '../screens/infobox/InfoboxCameraSettingsScreen'
import InfoboxFolderScreen from '../screens/infobox/InfoboxFolderScreen'
import InfoboxMediaScreen from '../screens/infobox/InfoboxMediaScreen'
import LoginScreen from '../screens/login/LoginScreen'
import EditNavigationScreen from '../screens/more/EditNavigationScreen'
import EmptyMoreScreen from '../screens/more/EmptyMoreScreen'
import MoreScreen from '../screens/more/MoreScreen'
import NotFoundScreen from '../screens/notFound/NotFoundScreen'
import CustomersListScreen from '../screens/passwords/CustomersListScreen'
import PlanningScreen from '../screens/planning/PlanningScreen'
import QualityScreen from '../screens/quality/QualityScreen'
import ScannerCameraScreen from '../screens/scanner/ScannerCameraScreen'
import SettingsScreen from '../screens/settings/SettingsScreen'
import SparePartsScreen from '../screens/spareParts/SparePartsScreen'
import SynchronizationScreen from '../screens/synchronization/SynchronizationScreen'
import TicketActivityCreateScreen from '../screens/tickets/TicketActivityCreateScreen'
import TicketAddSerialNumberScreen from '../screens/tickets/TicketAddSerialNumberScreen'
import TicketArticleCounterScreen from '../screens/tickets/TicketArticleCounterScreen'
import TicketArticleScreen from '../screens/tickets/TicketArticleScreen'
import TicketCreationScreen from '../screens/tickets/TicketCreationScreen'
import TicketDetailScreen from '../screens/tickets/TicketDetailScreen'
import TicketEditPriorityScreen from '../screens/tickets/TicketEditPriorityScreen'
import TicketReportScreen from '../screens/tickets/TicketReportScreen'
import TicketSerialNumberCreationScreen from '../screens/tickets/TicketSerialNumberCreationScreen'
import TicketsScreen from '../screens/tickets/TicketsScreen'
import navigationUtils from '../utils/navigationUtils'
import { sparePartsFilterEnableAtom, sparePartsScreenFilterEnableAtom } from '../utils/stateManager'
import NavigationContainer from './NavigationContainer'
import {
  AccessDataStackParamList,
  ActivityStackParamList,
  BottomTabParamList,
  CommonStackParamList,
  CustomerStackParamList,
  HistoryStackParamList,
  InfoboxStackParamList,
  PasswordsStackParamList,
  PlanningStackParamList,
  QualityStackParamList,
  RootStackParamList,
  SettingsStackParamList,
  SparePartsStackParamList,
  SynchronizationStackParamList,
  TabNavigationScreen,
  TicketStackParamList,
} from './types'

const screenOptions: NativeStackNavigationOptions = {
  headerShown: false,
  animation: Platform.OS === 'ios' ? 'default' : 'fade',
}

const Stack = createNativeStackNavigator<RootStackParamList>()

interface Props {
  isLoggedIn: boolean
  basePath: string
}

function Navigator({ isLoggedIn, basePath }: Props) {
  const { i18n, language } = useLanguage()
  const controller = useModalController()
  const { theme, colorScheme } = useTheme()
  const { isSmallDevice } = useDimensions()
  const { logout } = useAuthentication()
  const { userSettings, menuItems, loading, loadSettings } = useUserSettings()

  useInAppUpdates(true, language)

  useEffect(() => {
    if (userSettings === undefined) return

    userSettings.employeeId === undefined && controller.show()
    userSettings.employeeId !== undefined && controller.isShown && controller.close()
  }, [userSettings])

  let bottomTabs: TabNavigationScreen<keyof BottomTabParamList>[] = [
    {
      id: 0,
      name: 'TicketsStack',
      component: TicketStackNavigator,
      icon: ['fal', 'ticket'],
      title: i18n.t('TAB_TICKETS'),
      iconSize: 20,
    },
    {
      id: 1,
      name: 'PasswordsStack',
      component: PasswordsStackNavigator,
      icon: ['fal', 'lock'],
      title: i18n.t('TAB_PASSWORDS'),
      iconSize: 19,
    },
    { id: 2, name: 'SettingsStack', component: SettingsStackNavigator, icon: ['fal', 'gear'], title: i18n.t('TAB_SETTINGS'), iconSize: 20 },
    {
      id: 3,
      name: 'HistoryStack',
      component: HistoryStackNavigator,
      icon: ['fal', 'clock-rotate-left'],
      title: i18n.t('TAB_HISTORY'),
      iconSize: 19,
    },
    {
      id: 4,
      isFixed: true,
      name: 'MoreEmpty',
      component: EmptyMoreScreen,
      icon: ['fal', 'bars'],
      title: i18n.t('TAB_MORE'),
      iconSize: 19,
      listeners: ({ navigation }) => {
        return {
          tabPress: event => {
            event.preventDefault()
            navigation.navigate('More')
          },
        }
      },
    },
    {
      id: 6,
      name: 'PlanningStack',
      component: PlanningStackNavigator,
      icon: ['fal', 'id-badge'],
      title: i18n.t('TAB_PLANNING'),
      iconSize: 19,
    },
    {
      id: 7,
      name: 'SparePartsStack',
      component: SparePartsStackStackNavigator,
      icon: ['fal', 'arrows-repeat'],
      title: i18n.t('TAB_SPAREPARTS'),
      iconSize: 18,
    },
    {
      id: 8,
      name: 'QualityStack',
      component: QualityStackNavigator,
      icon: ['fal', 'badge-check'],
      title: i18n.t('TAB_QUALITY'),
      iconSize: 20,
    },
  ]

  Platform.OS !== 'web' &&
    bottomTabs.push({
      id: 100,
      name: 'SynchronizationStack',
      component: SynchronizationStackNavigator,
      icon: ['fal', 'cloud-arrow-down'],
      title: i18n.t('TAB_SYNCHRONIZATION'),
      iconSize: 21,
    })

  const elements = isSmallDevice ? CONSTANTS.maxNavBarElements : CONSTANTS.maxTabletNavBarElements

  bottomTabs = useMemo(() => {
    return navigationUtils.sortTabs(bottomTabs, elements, menuItems)
  }, [elements, menuItems])

  return (
    <AssetProvider>
      <OptionsProvider>
        <MenuProvider bottomTabs={bottomTabs} maxDynamicElements={elements}>
          <StatusBar backgroundColor="transparent" barStyle={colorScheme === 'dark' ? 'light-content' : 'dark-content'} translucent />
          <AppContent>
            <BottomSheetModalProvider>
              <NavigationContainer basePath={basePath}>
                <Stack.Navigator
                  screenOptions={{
                    ...screenOptions,
                    navigationBarColor: theme.tabNavigator.background,
                    gestureEnabled: Platform.OS !== 'web', // This is to enable the navigation event listener "beforeRemove" on web
                  }}>
                  {!isLoggedIn ? (
                    <Stack.Screen name="Login" component={LoginScreen} />
                  ) : (
                    <Stack.Group>
                      <Stack.Screen name="BottomTab">{() => (Platform.OS === 'web' ? <DrawerNavigator /> : <TabNavigator />)}</Stack.Screen>
                      {/* Alternative: https://github.com/th3rdwave/react-navigation-bottom-sheet */}
                      <Stack.Screen name="More" component={MoreScreen} options={{ presentation: 'containedTransparentModal', animation: 'fade' }} />
                      <Stack.Screen
                        name="EditNavigation"
                        component={EditNavigationScreen}
                        options={{ presentation: 'containedTransparentModal', animation: 'fade_from_bottom' }}
                      />
                      <Stack.Screen name="NotFound" component={NotFoundScreen} />
                      <Stack.Screen
                        name="Scanner"
                        component={ScannerCameraScreen}
                        options={{
                          animation: 'slide_from_bottom',
                          navigationBarColor: '#000000',
                        }}
                      />
                    </Stack.Group>
                  )}
                </Stack.Navigator>
              </NavigationContainer>
            </BottomSheetModalProvider>
          </AppContent>
        </MenuProvider>
        <ErrorModal
          controller={controller}
          description={i18n.t('COLLABORATOR_NOT_ASSIGNED')}
          onHardwareBackPress={() => {
            return
          }}
          buttons={[
            {
              title: i18n.t('CHECK'),
              loading,
              onPress: loadSettings,
            },
            {
              title: i18n.t('LOGOUT'),
              color: theme.error,
              onPress: () => {
                logout().catch(err => console.error('Logout error', err))
                controller.close()
              },
            },
          ]}
        />
      </OptionsProvider>
    </AssetProvider>
  )
}

export default memo(Navigator)

const TicketsStack = createNativeStackNavigator<TicketStackParamList>()
function TicketStackNavigator() {
  return (
    <FilterProvider disableOrderByTown storageKeyUniqueId="TicketsStack">
      <TicketsStack.Navigator screenOptions={screenOptions}>
        <TicketsStack.Screen name="Tickets" component={TicketsScreen} />
        <TicketsStack.Screen name="TicketDetail" component={TicketDetailScreen} />
        <TicketsStack.Screen name="TicketArticle" component={TicketArticleScreen} />
        <TicketsStack.Screen name="TicketCreation" component={TicketCreationScreen} />
        <TicketsStack.Screen name="TicketSerialNumberCreation" component={TicketSerialNumberCreationScreen} />
        <TicketsStack.Screen name="TicketCustomerStack" component={CustomerStackNavigator} />
        <TicketsStack.Screen name="TicketAccessDataStack" component={AccessDataStackNavigator} />
        <TicketsStack.Screen name="TicketInfoboxStack" component={InfoboxStackNavigator} />
        <TicketsStack.Screen name="TicketReport" component={TicketReportScreen} />
        <TicketsStack.Screen name="TicketArticleCounter" component={TicketArticleCounterScreen} />
        <TicketsStack.Screen name="TicketAddSerialNumber" component={TicketAddSerialNumberScreen} />
        <TicketsStack.Screen name="TicketEditPriority" component={TicketEditPriorityScreen} />
        <TicketsStack.Screen name="TicketActivityCreation" component={TicketActivityCreateScreen} />
        <TicketsStack.Screen name="TicketCommonStack" component={CommonStackNavigator} />
        <TicketsStack.Screen name="TicketActivityStack" component={ActivityStackNavigator} />
        <TicketsStack.Screen name="TicketDetailPastTicket" component={TicketDetailScreen} />
      </TicketsStack.Navigator>
    </FilterProvider>
  )
}

const CommonStack = createNativeStackNavigator<CommonStackParamList>()
function CommonStackNavigator() {
  return (
    <QualityCheckFilterProvider>
      <CommonStack.Navigator screenOptions={screenOptions}>
        <CommonStack.Screen name="EditAddFields" component={EditAddFieldsScreen} />
        <CommonStack.Screen name="EditClassification" component={EditClassificationScreen} />
        <CommonStack.Screen name="NoteEditOrCreate" component={NoteEditOrCreateScreen} />
        <CommonStack.Screen name="Filter" component={FilterScreen} />
        <CommonStack.Screen name="SparePartsFilter" component={SparePartsFilterScreen} />
        <CommonStack.Screen name="QualityFilter" component={QualityFilterScreen} />
        <CommonStack.Screen name="QualityCheck" component={QualityCheckScreen} />
        <CommonStack.Screen name="QualityCheckFilters" component={QualityCheckFilterScreen} />
        <CommonStack.Screen name="EditState" component={EditStateScreen} />
      </CommonStack.Navigator>
    </QualityCheckFilterProvider>
  )
}

const ActivityStack = createNativeStackNavigator<ActivityStackParamList>()
function ActivityStackNavigator() {
  const enableFilter = useSetRecoilState(sparePartsFilterEnableAtom)
  const isOnline = useIsOnline()

  return (
    <SparePartsFilterProvider
      storageKeyUniqueId="ActivityStack"
      onEnableFilter={enableFilter}
      disableCompatibilityFilter={!isOnline}
      disableStockFilter={!isOnline}
      disableDepositFilter={!isOnline}>
      <ActivityStack.Navigator screenOptions={screenOptions}>
        <ActivityStack.Screen name="Activity" component={ActivityScreen} />
        <ActivityStack.Screen name="ActivityPast" component={ActivityScreen} />
        <ActivityStack.Screen name="ActivityHistory" component={ActivityScreen} />
        <ActivityStack.Screen name="ActivityPlanning" component={ActivityScreen} />
        <ActivityStack.Screen name="ActivityUnitUp" component={ActivityUnitUpScreen} />
        <ActivityStack.Screen name="ActivityAllocation" component={ActivityAllocationScreen} />
        <ActivityStack.Screen name="ActivityEditOrCreateTimes" component={ActivityTimesEditOrCreateScreen} />
        <ActivityStack.Screen name="ActivityEditOrCreatePlanningTimes" component={ActivityPlanningTimesEditOrCreateScreen} />
        <ActivityStack.Screen name="ActivityClose" component={ActivityCloseScreen} />
        <ActivityStack.Screen name="ActivitySendReport" component={ActivitySendReportScreen} />
        <ActivityStack.Screen name="ActivityInfoboxStack" component={InfoboxStackNavigator} />
        <ActivityStack.Screen name="ActivityCommonStack" component={CommonStackNavigator} />
        <ActivityStack.Screen name="ActivitySpareParts" component={ActivitySparePartsScreen} />
        <ActivityStack.Screen name="ActivitySparePartsShoppingCart" component={ActivitySparePartsShoppingCartScreen} />
        <ActivityStack.Screen name="ActivityPurchaseOrder" component={ActivityPurchaseOrderScreen} />
        <ActivityStack.Screen name="ActivityArticleCounter" component={ActivityArticleCounterScreen} />
      </ActivityStack.Navigator>
    </SparePartsFilterProvider>
  )
}

const CustomerStack = createNativeStackNavigator<CustomerStackParamList>()
function CustomerStackNavigator() {
  return (
    <CustomerStack.Navigator screenOptions={screenOptions}>
      <CustomerStack.Screen name="CustomerDetail" component={CustomerDetailsScreen} />
      <CustomerStack.Screen name="CustomerDetailContactEditOrCreate" component={CustomerDetailContactEditOrCreateScreen} />
      <CustomerStack.Screen name="CustomerDetailDestinationEditOrCreate" component={CustomerDetailDestinationEditOrCreateScreen} />
      <CustomerStack.Screen name="CustomerDetailInfoboxStack" component={InfoboxStackNavigator} />
    </CustomerStack.Navigator>
  )
}

const PasswordsStack = createNativeStackNavigator<PasswordsStackParamList>()
export function PasswordsStackNavigator() {
  return (
    <VaultProvider>
      <PasswordsStack.Navigator screenOptions={screenOptions}>
        <PasswordsStack.Screen name="PasswordCustomersList" component={CustomersListScreen} />
        <PasswordsStack.Screen name="PasswordAccessDataStack" component={AccessDataStackNavigator} />
      </PasswordsStack.Navigator>
    </VaultProvider>
  )
}

const AccessDataStack = createNativeStackNavigator<AccessDataStackParamList>()
export function AccessDataStackNavigator() {
  return (
    <VaultProvider>
      <AccessDataStack.Navigator screenOptions={screenOptions}>
        <AccessDataStack.Screen name="AccessData" component={AccessDataScreen} />
        <AccessDataStack.Screen name="AccessDataEditOrCreation" component={AccessDataEditOrCreateScreen} />
        <AccessDataStack.Screen name="AccessDataFilter" component={AccessDataFilterScreen} />
        <AccessDataStack.Screen name="AccessDataCustomerStack" component={CustomerStackNavigator} />
      </AccessDataStack.Navigator>
    </VaultProvider>
  )
}

const InfoboxStack = createNativeStackNavigator<InfoboxStackParamList>()
function InfoboxStackNavigator() {
  return (
    <InfoboxStack.Navigator screenOptions={screenOptions}>
      <InfoboxStack.Screen name="InfoboxFolders" component={InfoboxFolderScreen} />
      <InfoboxStack.Screen name="InfoboxMedia" component={InfoboxMediaScreen} />
      <InfoboxStack.Screen
        name="InfoboxNoBottomTabCameraSettings"
        component={InfoboxCameraSettingsScreen}
        options={{
          animation: 'slide_from_bottom',
          presentation: 'formSheet',
          navigationBarColor: '#000000',
        }}
      />
      <InfoboxStack.Screen
        name="InfoboxNoBottomTabCamera"
        component={InfoboxCameraScreen}
        options={{
          presentation: 'fullScreenModal',
          navigationBarColor: '#000000',
        }}
      />
      <InfoboxStack.Screen
        name="InfoboxNoBottomTabAsset"
        component={InfoboxAssetScreen}
        options={{
          animation: 'slide_from_bottom',
          presentation: 'fullScreenModal',
          navigationBarColor: '#000000',
        }}
      />
      <InfoboxStack.Screen
        name="InfoboxNoBottomTabAssetInfo"
        component={InfoboxAssetInfoScreen}
        options={{
          animation: 'slide_from_bottom',
          presentation: 'fullScreenModal',
          navigationBarColor: '#000000',
        }}
      />
    </InfoboxStack.Navigator>
  )
}

const HistoryStack = createNativeStackNavigator<HistoryStackParamList>()
function HistoryStackNavigator() {
  return (
    <FilterProvider disableOrderByTown disableOrderByPlan disableOrderByPriority storageKeyUniqueId="HistoryStack">
      <HistoryStack.Navigator screenOptions={screenOptions}>
        <HistoryStack.Screen name="History" component={HistoryScreen} />
        <HistoryStack.Screen name="HistoryActivityStack" component={ActivityStackNavigator} />
        <HistoryStack.Screen name="HistoryCommonStack" component={CommonStackNavigator} />
      </HistoryStack.Navigator>
    </FilterProvider>
  )
}

const PlanningStack = createNativeStackNavigator<PlanningStackParamList>()
function PlanningStackNavigator() {
  return (
    <FilterProvider disableOrderByPriority storageKeyUniqueId="PlanningStack">
      <PlanningStack.Navigator screenOptions={screenOptions}>
        <PlanningStack.Screen name="Planning" component={PlanningScreen} />
        <PlanningStack.Screen name="PlanningActivityStack" component={ActivityStackNavigator} />
        <PlanningStack.Screen name="PlanningCommonStack" component={CommonStackNavigator} />
      </PlanningStack.Navigator>
    </FilterProvider>
  )
}

const SparePartsStack = createNativeStackNavigator<SparePartsStackParamList>()
function SparePartsStackStackNavigator() {
  const enableFilter = useSetRecoilState(sparePartsScreenFilterEnableAtom)

  return (
    <SparePartsFilterProvider storageKeyUniqueId="SparePartsStack" disableCompatibilityFilter disableStockFilter onEnableFilter={enableFilter}>
      <SparePartsStack.Navigator screenOptions={screenOptions}>
        <SparePartsStack.Screen name="SpareParts" component={SparePartsScreen} />
        <SparePartsStack.Screen name="SparePartsCommonStack" component={CommonStackNavigator} />
      </SparePartsStack.Navigator>
    </SparePartsFilterProvider>
  )
}

const QualityStack = createNativeStackNavigator<QualityStackParamList>()
function QualityStackNavigator() {
  return (
    <QualityFilterProvider storageKeyUniqueId="QualityStack">
      <QualityStack.Navigator screenOptions={screenOptions}>
        <QualityStack.Screen name="Quality" component={QualityScreen} />
        <QualityStack.Screen name="QualityCommonStack" component={CommonStackNavigator} />
      </QualityStack.Navigator>
    </QualityFilterProvider>
  )
}

const SettingsStack = createNativeStackNavigator<SettingsStackParamList>()
function SettingsStackNavigator() {
  return (
    <SettingsStack.Navigator screenOptions={screenOptions}>
      <SettingsStack.Screen name="Settings" component={SettingsScreen} />
    </SettingsStack.Navigator>
  )
}

const SynchronizationStack = createNativeStackNavigator<SynchronizationStackParamList>()
function SynchronizationStackNavigator() {
  return (
    <SynchronizationStack.Navigator screenOptions={{ ...screenOptions, gestureEnabled: false }}>
      <SynchronizationStack.Screen name="Synchronization" component={SynchronizationScreen} />
    </SynchronizationStack.Navigator>
  )
}
