import { IM, useAlert, useEvent, useLanguage, Utils } from '@infominds/react-native-components'
import isEqual from 'lodash/isEqual'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { ActivityTime } from '../../apis/types/apiResponseTypes'
import DeleteButton from '../../components/DeleteButton'
import useRequest from '../../components/Infominds/hooks/useRequest'
import DateInput, { convertDateToInputFormat } from '../../components/input/DateInput'
import { TextInputProps } from '../../components/input/TextInput'
import TimeInput from '../../components/input/TimeInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import EmployeeSelector from '../../components/selectors/EmployeeSelector'
import InvoiceTypeSelector from '../../components/selectors/InvoiceTypeSelector'
import Text from '../../components/Text'
import { EDIT_ACTIVITY_TIME_EVENT_KEY } from '../../constants/EmitterKeys'
import useForm from '../../hooks/useForm'
import { EditOrCreateViewProps, EditOrCreateViewRef, TimeFormat, UploadStatus } from '../../types'
import TimeUtils from '../../utils/TimeUtils'

type Edit = {
  mode: 'edit'
  time: ActivityTime
  activityId?: never
}

type Creation = {
  mode: 'creation'
  time?: never
  activityId: string
}

type Props = (Edit | Creation) & EditOrCreateViewProps

const ActivityTimeEditOrCreateView = ({ mode, time, disabled, activityId, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { request: create, loading: loadingCreate } = useRequest(api.createActivityTime)
  const { request: edit, loading: loadingEdit } = useRequest(api.editActivityTime)
  const { request: deleteTime, loading: deleting } = useRequest(api.deleteActivityTime)

  const initialData = useRef(time)
  const equalData = useRef(false)

  const alert = useAlert()
  const { error } = useForm()
  const { i18n, language } = useLanguage()

  const [state, setState] = useState(time)
  const [defaultValue, setDefaultValue] = useState<Partial<Pick<ActivityTime, 'employeeId' | 'invoicingTypeId'>>>({})
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')
  const [deleteRequested, setDeleteRequested] = useState(false)

  const { emit } = useEvent({ key: EDIT_ACTIVITY_TIME_EVENT_KEY })

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'
    let equal = error

    if (!error) {
      equalData.current = isEqual(initialData.current, state)

      if (mode === 'edit') {
        equal = equalData.current
      } else {
        equal = state === undefined || JSON.stringify(state) === '{}'
      }

      if (equal === false && (state?.from === undefined || state.until === undefined)) {
        updateUploadStatus('mandatoryMissing')
        return
      }
    }

    if (!equal) {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state, error])

  useEffect(() => {
    if (mode === 'edit' ? loadingEdit === 'catched' || deleting === 'catched' : loadingCreate === 'catched') {
      updateUploadStatus('waiting')

      return
    }

    const loadingDone = mode === 'edit' ? loadingEdit === false && deleting === false : loadingCreate === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      emit()
    }
  }, [loadingCreate, loadingEdit, deleting, deleteRequested])

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const handleDelete = () => {
    alert.alert(
      i18n.t('DELETE_TIME'),
      Utils.stringValueReplacer(
        i18n.t('DELETE_TIME_DESCRIPTION'),
        TimeUtils.secondsToTime(initialData.current?.from ?? 0),
        TimeUtils.secondsToTime(initialData.current?.until ?? 0),
        TimeUtils.format(initialData.current?.date ?? new Date().toISOString(), language)
      ),
      [
        {
          text: i18n.t('DELETE'),
          onPress: () => {
            setDeleteRequested(true)
            updateUploadStatus('uploading')
            // @ts-ignore improve data provider ts
            deleteTime({ id: initialData.current.id, period: initialData.current.period })
          },
          style: 'destructive',
        },
        {
          text: i18n.t('CANCEL'),
          onPress: () => {
            return
          },
          style: 'cancel',
        },
      ]
    )
  }

  const upload = () => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    if (mode === 'edit') {
      edit(state)
    } else {
      create({
        ...state,
        employeeId: state.employeeId ?? defaultValue.employeeId,
        invoicingTypeId: state.invoicingTypeId ?? defaultValue.invoicingTypeId,
        activityId,
        date: state.date ?? new Date().toISOString(),
      })
    }
  }

  const handleChangeText = (newVal: Partial<ActivityTime>) => {
    if (waitingUpload === 'uploading') return

    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const dateValue = useMemo(
    () =>
      state?.date
        ? convertDateToInputFormat(new Date(state.date), language)
        : convertDateToInputFormat(new Date(TimeUtils.resetTimeOfISOString(new Date().toISOString())), language),
    [state, language]
  )

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: !disabled,
  }

  return (
    <ScrollViewForm>
      <DateInput
        title={i18n.t('DATE')}
        value={dateValue}
        onChangeDate={date =>
          // Some weird issue in the comparison when we set {dateofbirth: undefined} in an object which does not have that key
          !(state?.date === undefined && date === undefined) && handleChangeText({ date: date ? date.toISOString() : undefined })
        }
        maximumDate={new Date()}
        {...commonProps}
        editable={mode === 'edit' ? false : commonProps.editable}
      />
      <EmployeeSelector
        id={state?.employeeId}
        onChange={val => handleChangeText({ employeeId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValue(prev => ({ ...prev, employeeId: id }))}
        {...commonProps}
        type="time"
        editable={mode === 'edit' ? false : commonProps.editable}
      />
      <InvoiceTypeSelector
        type="ActivityManualRecording"
        id={state?.invoicingTypeId}
        onChange={val => handleChangeText({ invoicingTypeId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValue(prev => ({ ...prev, invoicingTypeId: id }))}
        {...commonProps}
        editable={mode === 'edit' ? false : commonProps.editable}
      />
      <TimeInput
        title={i18n.t('START') + ' *'}
        value={state ? TimeUtils.secondsToTime(state.from, TimeFormat.TIME) : undefined}
        onChangeTime={val => handleChangeText({ from: val })}
        {...commonProps}
      />
      <TimeInput
        title={i18n.t('END') + ' *'}
        value={state && state.until ? TimeUtils.secondsToTime(state.until, TimeFormat.TIME) : undefined}
        onChangeTime={val => handleChangeText({ until: val })}
        {...commonProps}
      />
      <Text secondary>
        {`${i18n.t('DURATION')}: `}
        {state && state.until && state.from
          ? state?.until - state?.from > 0
            ? TimeUtils.secondsToTime(state?.until - state?.from, TimeFormat.TIME_WITH_DIMENSIONS)
            : TimeUtils.secondsToTime(24 * 3600 - state?.from + state.until, TimeFormat.TIME_WITH_DIMENSIONS)
          : '-'}
      </Text>
      {mode === 'edit' && !disabled && (
        <IM.View {...commonProps}>
          <DeleteButton title={i18n.t('DELETE_TIME')} onPress={handleDelete} />
        </IM.View>
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityTimeEditOrCreateView)
