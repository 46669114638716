import { ApiError } from '../types'

export const ExceptionUtils = {
  exceptionIsResponse: (exception: unknown): exception is Response => {
    return !!(exception as Response).status
  },
  exceptionIsError: (exception: unknown): exception is Error => {
    return !!(exception as Error).message
  },
  exceptionIsApiError: (exception: unknown): exception is ApiError => {
    return !!(exception as ApiError).Message
  },
  exceptionToString(exception: unknown) {
    if (this.exceptionIsApiError(exception)) return exception.Message
    if (this.exceptionIsError(exception)) return exception.message
    if (this.exceptionIsResponse(exception)) return exception.statusText
    return (exception as string)?.toString()
  },
}
