import { IM, ViewProps } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'

import Text from './Text'

interface Props extends ViewProps {
  header: string
  text?: string
  children?: ReactNode
}

export default function TextWithHeader({ header, text, style, children, ...props }: Props) {
  return (
    <IM.View style={style} {...props}>
      <Text primary>{header}</Text>
      {text && <Text>{text}</Text>}
      {children && children}
    </IM.View>
  )
}
