import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'

import api from '../../apis/apiCalls'
import { StateResponse } from '../../apis/types/apiResponseTypes'
import { REQUEST_ACTIVITY_STATE } from '../../constants/Keys'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import SelectInput from './selectInput/SelectInput'
import { TicketStateRender } from './TicketStateSelector'

interface Props {
  id?: string
  editable?: boolean
  spacing?: SpacingProps
  mandatory?: boolean
  onChange: (value: StateResponse | undefined) => void
  onDefaultFound: (id: string) => void
}

export default function ActivityStateSelector({ id, mandatory, onChange, onDefaultFound, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const [selectedId, setSelectedId] = useState(id)
  const { item: data, loadItem, loading } = useControlledLoader(api.getActivityState, { id: REQUEST_ACTIVITY_STATE })

  useEffect(() => {
    loadItem({})
  }, [])

  useEffect(() => {
    if (id) return

    if (loading === false && data !== undefined) {
      const defaultFound = data.filter(el => el.isDefault)

      if (defaultFound && defaultFound.length === 1) {
        setSelectedId(defaultFound[0].id)
        onDefaultFound(defaultFound[0].id)
      }
    }
  }, [id, loading, data])

  const handleOnChange = (newValue: StateResponse | undefined) => {
    setSelectedId(newValue?.id)
    onChange(newValue)
  }

  return (
    <SelectInput
      data={appUtils.filter(data ?? [], search, ['description', 'code', { searchStringCompositor: ticketUtils.composeActivityString }])}
      value={data?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={() => loadItem({})}
      onSearchChange={setSearch}
      title={i18n.t('ACTIVITY_STATUS') + (mandatory ? ' *' : '')}
      screenTitle={i18n.t('ACTIVITY_STATUSES')}
      noDataMessage={i18n.t('NO_ACTIVITY_STATUS_FOUND')}
      renderItem={(elem, onPress) => TicketStateRender(elem, selectedId, onPress)}
      onChange={handleOnChange}
      renderSelectedString={ticketUtils.composeActivityString}
      deferredTimeout={0}
      disableLoadAfterMount
      disableReset
      {...props}
    />
  )
}
