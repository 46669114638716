import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import Button from './Button'
import Separator from './Infominds/Separator'

interface Props {
  disableSeparator?: boolean
  disabled?: boolean
  title?: string
  color?: string
  onPress: () => void
  style?: StyleProp<ViewStyle>
}

export default function CardButton({ disabled, title, disableSeparator, color = IMStyle.palette.tint, style, onPress }: Props) {
  const { i18n } = useLanguage()

  return (
    <>
      {!disableSeparator && <Separator spacing="bottom" />}
      <IM.View style={[styles.buttonContainer, style]}>
        <Button title={title ?? i18n.t('ADD')} onPress={onPress} color={color} disabled={disabled} />
      </IM.View>
    </>
  )
}

const styles = StyleSheet.create({
  buttonContainer: {
    alignItems: 'flex-end',
    marginTop: 4,
    marginLeft: 2,
  },
})
