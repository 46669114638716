import { useContext } from 'react'

import QualityFilterContext from '../contexts/QualityFilterProvider'

export default function useQualityFilter() {
  const context = useContext(QualityFilterContext)

  if (context === undefined) {
    throw new Error('useQualityFilter() must be called inside QualityFilterProvider')
  }

  return context
}
