import { useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo } from 'react'
import { TextStyle } from 'react-native'

import { TextInputRef } from '../../types'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type PrinceInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
  }

const PriceInput = memo(
  forwardRef(function PriceInput(
    { title, titleFontWeight, details, editable, error, loading, disableFocus, ...textInputProps }: PrinceInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    const { theme } = useTheme()

    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput ref={ref} {...textInputProps} type="decimal">
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} />}

          <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
            <PressableIcon icon={['fal', 'euro']} size={19} disabled disabledColor={theme.textDetail} />
          </BaseTextInput.RightIcon>
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default PriceInput
