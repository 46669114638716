const APP_NAME = 'RadixPlusService.'

export const STORAGE_KEY_CALENDAR_VIEW = APP_NAME + 'CalendarView'
export const STORAGE_KEY_CUSTOMER_DETAILS_TAB = APP_NAME + 'CustomerDetailsTab'
export const STORAGE_KEY_DRAWER_OPEN = APP_NAME + 'DrawerOpen'
export const STORAGE_KEY_FILTER_CONTEXT_FILTER = APP_NAME + 'FilterContextFilterStorage'
export const STORAGE_KEY_FILTER_CONTEXT_GROUP = APP_NAME + 'FilterContextGroupStorage'
export const STORAGE_KEY_FILTER_CONTEXT_ORDER = APP_NAME + 'FilterContextOrderStorage'
export const STORAGE_KEY_FILTER_CONTEXT_QUALITY_FILTER = APP_NAME + 'FilterContextFilterQualityStorage'
export const STORAGE_KEY_FILTER_CONTEXT_QUALITY_GROUP = APP_NAME + 'FilterContextGroupQualityStorage'
export const STORAGE_KEY_FILTER_CONTEXT_QUALITY_ORDER = APP_NAME + 'FilterContextOrderQualityStorage'
export const STORAGE_KEY_FILTER_CONTEXT_QUALITY_CHECK_GROUP = APP_NAME + 'FilterContextQualityCheckGroupStorage'
export const STORAGE_KEY_FILTER_CONTEXT_QUALITY_CHECK_ORDER = APP_NAME + 'FilterContextQualityCheckOrderStorage'
export const STORAGE_KEY_FILTER_CONTEXT_SPARE_PARTS_FILTER = APP_NAME + 'FilterContextSparePartsFilterStorage'
export const STORAGE_KEY_FILTER_CONTEXT_SPARE_PARTS_ORDER = APP_NAME + 'FilterContextSparePartsOrderStorage'
export const STORAGE_KEY_INFOBOX_SELECTED_FOLDER = APP_NAME + 'InfoboxSelectedFolder'
export const STORAGE_KEY_LAST_USED_CUSTOMERS = APP_NAME + 'LastUsedCustomers'
export const STORAGE_KEY_MEDIA_SORTING_METHOD = APP_NAME + 'mediaSortingMethod'
export const STORAGE_KEY_MENU_ORDER = APP_NAME + 'MenuPreference'
export const STORAGE_KEY_NAVIGATION_STATE = APP_NAME + 'NavigationState'
export const STORAGE_KEY_SAVE_MEDIA_ON_STORAGE = APP_NAME + 'saveMediaOnStorage'
export const STORAGE_KEY_SN_CUSTOMER_FILTER = APP_NAME + 'serialNumberCustomerFilterStorage'
export const STORAGE_KEY_TICKET_LIST_SPLIT_VIEW_ENABLED = APP_NAME + 'ticketListSplitViewEnabledStorage'
export const STORAGE_KEY_TICKET_VIEW = APP_NAME + 'TicketView'
export const STORAGE_KEY_DATA_PROVIDER_STATE = 'DataProviderStorage'

export const REQUEST_ACCESS_DATA = 'AccessDataRequest'
export const REQUEST_ACCESS_DATA_GROUPS = 'AccessDataGroupsRequest'
export const REQUEST_ACCESS_DATA_LEVEL = 'AccessDataLevelRequest'
export const REQUEST_ACTIVITY = 'ActivityRequest'
export const REQUEST_ACTIVITY_EDIT = 'ActivityEditRequest'
export const REQUEST_ACTIVITY_EMAIL = 'ActivityEmailRequest'
export const REQUEST_ACTIVITY_OPEN_TIMES = 'ActivityOpenTimesRequest'
export const REQUEST_ACTIVITY_PLANNING_TIME = 'ActivityPlanningTimeRequest'
export const REQUEST_ACTIVITY_PURCHASE_ORDER = 'ActivityPurchaseOrderRequest'
export const REQUEST_ACTIVITY_SPARE_PARTS = 'ActivitySparePartsRequest'
export const REQUEST_ACTIVITY_SPARE_PART_PRICE = 'ActivitySparePartPriceTimeRequest'
export const REQUEST_ACTIVITY_STATE = 'ActivityStateRequest'
export const REQUEST_ACTIVITY_TIME = 'ActivityTimeRequest'
export const REQUEST_ADD_TICKET_ARTICLE = 'AddTicketArticleRequest'
export const REQUEST_ADDITIONAL_FIELDS = 'AdditionalFieldsRequest'
export const REQUEST_ADDITIONAL_FIELDS_DEFINITION_VALUES = 'AdditionalFieldsDefinitionValuesRequest'
export const REQUEST_ADDITIONAL_FIELDS_DEFINITIONS = 'AdditionalFieldsDefinitionsRequest'
export const REQUEST_ARTICLE = 'ArticleRequest'
export const REQUEST_ARTICLE_INFOBOX_FILE = 'InfoboxArticleFileRequest'
export const REQUEST_ARTICLE_INFOBOX_FILES = 'InfoboxArticleFilesRequest'
export const REQUEST_CLASSIFICATION = 'ClassificationRequest'
export const REQUEST_CLASSIFICATION_DEFINITION_VALUES = 'ClassificationDefinitionValuesRequest'
export const REQUEST_COUNTRY = 'CountryRequest'
export const REQUEST_CUSTOMER = 'CustomerRequest'
export const REQUEST_CUSTOMER_CONTACTS = 'CustomerContactsRequest'
export const REQUEST_CUSTOMER_DESTINATIONS = 'CustomerDestinationsRequest'
export const REQUEST_DELETE_TICKET_ARTICLE = 'DeleteTicketArticleRequest'
export const REQUEST_EDIT_PASSWORD = 'EditPasswordRequest'
export const REQUEST_GENERATE_PASSWORD = 'GeneratePasswordRequest'
export const REQUEST_GENERATE_PASSWORD_DEFAULTS = 'GeneratePasswordDefaultsRequest'
export const REQUEST_EDIT_TICKET_ARTICLE = 'EditTicketArticleRequest'
export const REQUEST_EMAIL_TYPE = 'EmailTypeRequest'
export const REQUEST_EMPLOYEE = 'EmployeeRequest'
export const REQUEST_FAX_TYPE = 'FaxTypeRequest'
export const REQUEST_INFOBOX_FILES = 'InfoboxFilesRequest'
export const REQUEST_INFOBOX_FILES_ASSET_VIEW = 'InfoboxFilesAssetViewRequest'
export const REQUEST_INFOBOX_FOLDER = 'InfoboxFolderRequest'
export const REQUEST_INVOICE_TYPE = 'InvoiceTypeRequest'
export const REQUEST_LANGUAGE = 'LanguageRequest'
export const REQUEST_MENU_ITEMS = 'MenuItemsRequest'
export const REQUEST_NOTE_GROUP = 'NoteGroupRequest'
export const REQUEST_OTP = 'OtpRequest'
export const REQUEST_PASSWORD = 'PasswordRequest'
export const REQUEST_PHONE_TYPE = 'PhoneTypeRequest'
export const REQUEST_POST_ADD_FIELDS = 'PostAdditionalFieldRequest'
export const REQUEST_POST_CLASSIFICATION = 'PostClassificationRequest'
export const REQUEST_POST_DESTINATION_ID_CARD = 'PostDestinationIdCardRequest'
export const REQUEST_POST_REPORT = 'PostReportRequest'
export const REQUEST_POST_TICKET_DETAIL = 'PostTicketDetailRequest'
export const REQUEST_QUALITY = 'QualityRequest'
export const REQUEST_QUALITY_LIST = 'QualityListRequest'
export const REQUEST_REGISTRY = 'RegistryRequest'
export const REQUEST_REPORTS = 'ReportsRequest'
export const REQUEST_SERIAL_NUMBER = 'SerialNumberRequest'
export const REQUEST_SERIAL_NUMBER_ACCESSORIES = 'SerialNumberAccessoriesRequest'
export const REQUEST_SERIAL_NUMBER_COUNTERS = 'SerialNumberCountersRequest'
export const REQUEST_SERIAL_NUMBER_NUMBER = 'SerialNumberNumberRequest'
export const REQUEST_SERIAL_NUMBER_TICKETS = 'SerialNumberTicketsRequest'
export const REQUEST_SPARE_PART_DEPOTS = 'SparePartDepotsRequest'
export const REQUEST_SM_NOTES = 'SMNotesRequest'
export const REQUEST_MESSAGES = 'MessagesRequest'
export const REQUEST_TEAM = 'TeamRequest'
export const REQUEST_TICKET = 'TicketRequest'
export const REQUEST_TICKET_ACTIVITY = 'TicketActivityRequest'
export const REQUEST_TICKET_ACTIVITY_TYPES = 'TicketActivityTypesRequest'
export const REQUEST_TICKET_ARTICLE = 'TicketArticleRequest'
export const REQUEST_TICKET_ARTICLE_SPARE_PARTS = 'TicketArticleSparePartsRequest'
export const REQUEST_TICKET_CLASSIFICATION_DEFINITIONS = 'TicketClassificationDefinitionsRequest'
export const REQUEST_TICKET_NOTES = 'TicketNotesRequest'
export const REQUEST_TICKET_SERIAL_NUMBER = 'TicketSerialNumberRequest'
export const REQUEST_TICKET_STATE = 'TicketStateRequest'
export const REQUEST_TICKET_PRIORITY = 'TicketPriorityRequest'
export const REQUEST_TRAVEL_TIME = 'TravelTimeRequest'
export const REQUEST_USER_SETTINGS = 'UseSettingsRequest'
