import { useDimensions, useOrientation } from '@infominds/react-native-components'
import { Platform } from 'react-native'

export type LayoutType = 'small' | 'medium' | 'large'

export type ForceLayoutType = false | LayoutType

export default function useLayout(
  detectMediumDevice?: boolean,
  forceLayout?: ForceLayoutType
): {
  isSmallDevice: boolean
  isMediumDevice: boolean
  isLargeDevice: boolean
  isLandscape: boolean
  isPortrait: boolean
  isDesktop: boolean
} {
  const { isSmallDevice } = useDimensions()
  const { portrait, landscape } = useOrientation()

  if (forceLayout) {
    return {
      isSmallDevice: forceLayout === 'small',
      isMediumDevice: forceLayout === 'medium',
      isLargeDevice: forceLayout === 'large',
      isLandscape: landscape,
      isPortrait: portrait,
      isDesktop: false, // TODO check
    }
  }

  const isMedium = !isSmallDevice && (!detectMediumDevice || portrait)
  const isLarge = !isSmallDevice && (!detectMediumDevice || landscape)

  return {
    isSmallDevice,
    isMediumDevice: isMedium,
    isLargeDevice: isLarge,
    isLandscape: landscape,
    isPortrait: portrait,
    isDesktop: Platform.OS === 'web' && (isMedium || isLarge),
  }
}
