import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import prettyBytes from 'pretty-bytes'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'
import DeviceInfo from 'react-native-device-info'

import ErrorComponent from '../../components/Error'
import DataBar from '../../components/Infominds/DataBar'
import Text from '../../components/Text'
import { ThemeColorExpanded } from '../../types'

type Storage = {
  total: string
  totalBytes: number
  used: string
  usedBytes: number
  free: string
  freeBytes: number
}

type AdditionalStorageInfo = {
  name: string
  totalBytes: number
  color: string
}

interface Props {
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
  additionalStorageInfo?: (false | AdditionalStorageInfo)[]
}

export default function StorageSpaceCard({ spacing, style, additionalStorageInfo }: Props) {
  const { language, i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [storageGB, setStorageGB] = useState<Storage | undefined>(undefined)
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const promises: Promise<number>[] = [DeviceInfo.getTotalDiskCapacity(), DeviceInfo.getFreeDiskStorage()]

    Promise.allSettled(promises)
      .then(results => {
        if (results.length === 2) {
          if (results[0].status === 'fulfilled' && results[1].status === 'fulfilled') {
            setError(false)

            const used = results[0].value - results[1].value

            setStorageGB({
              total: prettyBytes(results[0].value, { locale: language }),
              totalBytes: results[0].value,
              used: prettyBytes(used, { locale: language }),
              usedBytes: used,
              free: prettyBytes(results[1].value, { locale: language }),
              freeBytes: results[1].value,
            })
          } else {
            throw new Error(`Promise 1 ${results[0].status} - Promise 2 ${results[1].status}`)
          }
        } else {
          throw new Error('storage promise length !== 2')
        }
      })
      .catch(err => {
        console.error('getFreeDiskStorage fails', err)
        setError(true)
      })
  }, [])

  const dataBarElements = useMemo(() => {
    const additionalInfoTotalBytes = Utils.sum(additionalStorageInfo?.map(q => (q ? q.totalBytes : 0)) ?? [], q => q)
    const usedSpaceWithoutAdditionalInfo = Math.max((storageGB?.usedBytes ?? 0) - additionalInfoTotalBytes, 0)
    const elements: AdditionalStorageInfo[] = []
    elements.push({ totalBytes: usedSpaceWithoutAdditionalInfo, color: theme.randomColors[0], name: i18n.t('OTHER_DATA') })

    additionalStorageInfo?.forEach(element => {
      if (element && element.totalBytes > 0) elements.push(element)
    })
    return elements
  }, [storageGB?.usedBytes, additionalStorageInfo])

  return (
    <IM.Card spacing={spacing} noContentSpacing style={style}>
      <IM.View style={styles.container}>
        {error && <ErrorComponent />}
        {storageGB && (
          <>
            <IM.View style={styles.headerContainer}>
              <IM.View style={styles.headerUsedPercent}>
                <Text style={{ fontSize: IMStyle.typography.fontSizeRegular }}>
                  {Math.round((storageGB.usedBytes / storageGB.totalBytes) * 100)}%
                </Text>
                <Text style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }}> {i18n.t('USED_LOWER_CASE')}</Text>
              </IM.View>
              <Text style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }} secondary>
                {storageGB.used} / {storageGB.total}
              </Text>
            </IM.View>
            <DataBar
              elements={dataBarElements}
              totalValue={storageGB.totalBytes}
              elementDataProvider={element => ({ value: element.totalBytes, color: element.color })}
              backgroundColor={theme.loader.background}
            />

            {dataBarElements.map((element, index) =>
              element ? <InfoText key={element.name} index={index} bytes={element.totalBytes} color={element.color} text={element.name} /> : <></>
            )}
          </>
        )}
      </IM.View>
    </IM.Card>
  )
}

function InfoText({ text, color, bytes, index }: { text: string; color?: string; bytes: number; index: number }) {
  const { language } = useLanguage()
  const bytesText = useMemo(() => prettyBytes(bytes, { locale: language }), [bytes, language])

  return (
    <IM.View style={styles.description} spacing={index === 0 ? 'top' : 'none'}>
      <IM.View style={[IMLayout.flex.row, styles.center]}>
        {!!color && <IM.View style={[{ backgroundColor: color }, styles.indicator]} />}
        <Text>{text}</Text>
      </IM.View>
      <Text>{bytesText}</Text>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
  },
  container: {
    margin: 10,
    justifyContent: 'center',
  },
  description: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    marginBottom: 1,
  },
  headerUsedPercent: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  indicator: {
    borderRadius: 50,
    width: 12,
    aspectRatio: 1,
    marginRight: 10,
  },
})
