import DataProviderHelper from '../../dataProvider/utils/DataProviderHelper'
import OfflineProvider from '../../dataProvider/utils/OfflineProvider'
import { MenuItems } from '../../types'
import {
  GetActivityRequest,
  GetActivitySparePartsRequest,
  GetActivityTimeRequest,
  GetAdditionalFieldsDefinitionRequest,
  GetAdditionalFieldsDefinitionValueRequest,
  GetAdditionalFieldsRequestTickerOrActivity,
  GetArticleSparePartsRequest,
  GetClassificationDefinitionValueRequest,
  GetContactDataRequest,
  GetCustomersRequest,
  GetDestinationRequest,
  GetEmployeeRequest,
  GetInfoboxFilesRequest,
  GetInfoboxFolderWithCountRequest,
  GetNoteGroupRequest,
  GetQualityRequest,
  GetSerialNumberCounterRequest,
  GetSMNoteRequest,
  GetTicketActivityRequest,
  GetTicketArticleRequest,
  GetTicketClassificationRequest,
  GetTicketListRequest,
  GetTicketStateRequest,
  GetTravelTimeRequest,
  InfoboxFile,
} from '../types/apiRequestTypes'
import {
  Activity,
  ActivitySparePart,
  ActivityTime,
  AdditionalFieldDefinition,
  AdditionalFieldDefinitionValue,
  AdditionalFieldResponse,
  ArticleSparePart,
  ClassificationDefinitionValue,
  Contact,
  Country,
  Customer,
  Destination,
  EmailType,
  Employee,
  FaxType,
  FolderWithCount,
  InvoiceType,
  Language,
  NoteGroup,
  PhoneType,
  Quality,
  QualityList,
  SerialNumberCounter,
  SMNote,
  SparePartDepot,
  StateResponse,
  Ticket,
  TicketActivity,
  TicketArticle,
  TicketClassificationRequest,
  TicketPriorityResponse,
  TravelTime,
  UserSettings,
} from '../types/apiResponseTypes'
import { ActivityAddFieldsUpdateEffect } from './customFunctions/ActivityAddFieldsDataProviderFunctions'
import { ActivitySparePartsCustomUpdateEffect } from './customFunctions/ActivitySparePartsDataProviderFunctions'
import { ActivityTimeCustomUpdateEffect } from './customFunctions/ActivityTimeDataProviderFunctions'
import {
  InfoboxFileUpdateEffect,
  InfoboxGetFileModifier,
  InfoboxGetFilesModifier,
  InfoboxGetFoldersWithCountModifier,
} from './customFunctions/InfoboxFileDataProviderFunctions'
import { QualityCustomUpdateEffect } from './customFunctions/QualityDataProviderFunctions'
import { SMNoteCustomUpdateEffect } from './customFunctions/SMNoteDataProviderFunctions'
import { ActivityCustomGetModifier, ActivityCustomUpdateEffect } from './customFunctions/TicketActivityDataProviderFunctions'
import { TicketClassificationCustomUpdateEffect } from './customFunctions/TicketClassificationDataProviderFunctions'
import { TicketCustomUpdateEffect } from './customFunctions/TicketDataProviderFunctions'

export const OfflineConfig = {
  userSettings: OfflineProvider<UserSettings>({ singleObject: true }),
  menuItems: OfflineProvider<MenuItems>({ singleObject: true }),
  phoneTypes: OfflineProvider<PhoneType>({ id: ['type'] }),
  emailTypes: OfflineProvider<EmailType>({ id: ['type'] }),
  faxTypes: OfflineProvider<FaxType>({ id: ['type'] }),
  invoiceTypes: OfflineProvider<InvoiceType>({}),
  employee: OfflineProvider<Employee, GetEmployeeRequest>({}),
  languages: OfflineProvider<Language>({}),
  countries: OfflineProvider<Country>({}),
  // customer
  customer: OfflineProvider<Customer, GetCustomersRequest>({
    get_queries: [DataProviderHelper.query.matchLike('searchText', ['description', 'email', 'number'])],
    get_modifiers: [DataProviderHelper.modify.sort(['description']), DataProviderHelper.modify.skipTake()],
  }),
  customerDestinations: OfflineProvider<Destination, GetDestinationRequest>({
    get_queries: [DataProviderHelper.query.matchLike('searchtext', ['description', 'email'])],
    get_modifiers: [DataProviderHelper.modify.skipTake(), DataProviderHelper.modify.sort(['inactive', 'description'])],
  }),
  customerContacts: OfflineProvider<Contact, GetContactDataRequest>({
    get_queries: [
      DataProviderHelper.query.matchLike('searchtext', ['description', 'email', 'position']),
      DataProviderHelper.query.compareDate('createdateFrom', 'createdate', 'gte'),
    ],
    get_modifiers: [DataProviderHelper.modify.skipTake(), DataProviderHelper.modify.sort(['inactive', 'description'])],
  }),
  // ticket
  ticket: OfflineProvider<Ticket, GetTicketListRequest>({
    get_queries: [DataProviderHelper.query.ignore(['state'])],
    get_modifiers: [DataProviderHelper.modify.sort(['documentDate', 'code'])],
    customUpdateEffect: TicketCustomUpdateEffect,
  }),
  ticketState: OfflineProvider<StateResponse, GetTicketStateRequest>({}),
  ticketArticle: OfflineProvider<TicketArticle, GetTicketArticleRequest>({}),
  ticketClassification: OfflineProvider<TicketClassificationRequest, GetTicketClassificationRequest>({
    id: ['fkId', 'type'],
    syncOptions: { syncType: ['common_classificationsOfTicket'] },
    get_queries: [DataProviderHelper.query.matchArray('fkIds', 'fkId')],
    customUpdateEffect: TicketClassificationCustomUpdateEffect,
  }),
  ticketPriority: OfflineProvider<TicketPriorityResponse, GetTicketStateRequest>({}),
  classificationDefinitionValues: OfflineProvider<ClassificationDefinitionValue, GetClassificationDefinitionValueRequest>({}),
  additionalfieldsOfTickets: OfflineProvider<AdditionalFieldResponse, GetAdditionalFieldsRequestTickerOrActivity>({
    id: ['fkId'],
    get_queries: [DataProviderHelper.query.matchArray('fkIds', 'fkId')],
    customUpdateEffect: ActivityAddFieldsUpdateEffect,
  }),
  additionalfieldsOfActivities: OfflineProvider<AdditionalFieldResponse, GetAdditionalFieldsRequestTickerOrActivity>({
    id: ['fkId'],
    get_queries: [DataProviderHelper.query.matchArray('fkIds', 'fkId')],
    customUpdateEffect: ActivityAddFieldsUpdateEffect,
  }),
  addFieldsDefinitions: OfflineProvider<AdditionalFieldDefinition, GetAdditionalFieldsDefinitionRequest>({
    get_queries: [DataProviderHelper.query.ignore(['onlyForApp'])],
  }),
  addFieldsDefinitionValues: OfflineProvider<AdditionalFieldDefinitionValue, GetAdditionalFieldsDefinitionValueRequest>({}),
  activity: OfflineProvider<Activity, GetActivityRequest>({
    customUpdateEffect: ActivityCustomUpdateEffect,
    get_queries: [DataProviderHelper.query.ignore(['state'])],
    get_modifiers: [DataProviderHelper.modify.sort('id')],
    syncOptions: { syncType: ['activity', 'ticket_activity'] },
    customGetModifier: ActivityCustomGetModifier,
  }),
  ticketActivity: OfflineProvider<TicketActivity, GetTicketActivityRequest>({
    localResource: 'activity',
    get_modifiers: [DataProviderHelper.modify.sort('code')],
  }),
  activityTime: OfflineProvider<ActivityTime, GetActivityTimeRequest>({
    customUpdateEffect: ActivityTimeCustomUpdateEffect,
    id: ['id', 'period'],
    syncOptions: {
      mergePendingRequests: true,
    },
    get_modifiers: [DataProviderHelper.modify.sort('date')],
  }),
  activitySpareParts: OfflineProvider<ActivitySparePart, GetActivitySparePartsRequest>({
    customUpdateEffect: ActivitySparePartsCustomUpdateEffect,
  }),
  sparePartDepot: OfflineProvider<SparePartDepot>({}),
  serialNumberCounter: OfflineProvider<SerialNumberCounter, GetSerialNumberCounterRequest>({}),
  noteGroup: OfflineProvider<NoteGroup, GetNoteGroupRequest>({}),
  smNote: OfflineProvider<SMNote, GetSMNoteRequest>({
    get_queries: [DataProviderHelper.query.match('fkId', 'fkId')],
    get_modifiers: [DataProviderHelper.modify.sort('date')],
    customUpdateEffect: SMNoteCustomUpdateEffect,
  }),
  articleSpareParts: OfflineProvider<ArticleSparePart, GetArticleSparePartsRequest>({
    autoQuery: false,
    get_queries: [
      DataProviderHelper.query.matchLike('searchText', ['articleCode', 'articleDescription', 'articleSearchtext', 'serialnumber']),
      DataProviderHelper.query.match('depositId'),
      DataProviderHelper.query.ignore(['serialnumberIdParent', 'inStock']),
      DataProviderHelper.query.match('ticketId', 'ticketId'),
      DataProviderHelper.query.compareNumber('inStock', 'stockQuantity', 'gt', 0),
    ],
    get_modifiers: [DataProviderHelper.modify.skipTake(), DataProviderHelper.modify.sort(['articleSearchtext', 'serialnumber'])],
  }),
  quality: OfflineProvider<Quality, GetQualityRequest>({
    id: ['id', 'activityId', 'serialnumberId'],
    customUpdateEffect: QualityCustomUpdateEffect,
    syncOptions: { mergePendingRequests: true },
  }),
  qualityList: OfflineProvider<QualityList>({}),
  infoboxFiles: OfflineProvider<InfoboxFile, GetInfoboxFilesRequest>({
    syncOptions: {
      clearOnSync: true,
    },
    get_queries: [DataProviderHelper.query.match('id', 'fkId')],
    saveDataOnDevice: ['file'],
    customGetModifier: InfoboxGetFilesModifier,
  }),
  infoboxFile: OfflineProvider<InfoboxFile, GetInfoboxFilesRequest>({
    localResource: 'common/infoboxfiles',
    saveDataOnDevice: ['file'],
    customUpdateEffect: InfoboxFileUpdateEffect,
    syncOptions: {
      clearOnSync: true,
    },
    customGetModifier: InfoboxGetFileModifier,
  }),
  infoBoxFolderWithFileCount: OfflineProvider<FolderWithCount, GetInfoboxFolderWithCountRequest>({
    syncOptions: {
      syncType: ['common/infoboxfolder'],
    },
    autoQuery: false,
    get_modifiers: [DataProviderHelper.modify.sort('order')],
    customGetModifier: InfoboxGetFoldersWithCountModifier,
  }),
  travelTime: OfflineProvider<TravelTime, GetTravelTimeRequest>({
    customUpdateEffect: (data, props) => {
      // set the openTime bit
      return props.defaultEffect({ ...data, openTime: data.until === undefined || data.until === null })
    },
    // filter all times that are not open
    get_filter: data => !!data.openTime,
  }),
}
