import { useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import { AssetInfo, AssetInfoView, AssetOrigin } from '@infominds/react-native-media'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'

import api from '../../apis/apiCalls'
import { INFOBOX_NEW_INFO_EVENT_KEY, REFRESH_INFOBOX_EVENT_KEY } from '../../constants/EmitterKeys'
import { InfoboxStackParamList } from '../../navigation/types'
import { InfoboxInfoEvent, InfoboxType } from '../../types'

interface Props {
  id: string
  infoboxTyp: InfoboxType
  origin: AssetOrigin | undefined
}

const InfoboxAssetInfoView = ({ id, infoboxTyp, origin }: Props) => {
  const { alert } = useAlert()
  const { i18n } = useLanguage()

  const navigation = useNavigation<NavigationProp<InfoboxStackParamList>>()
  const [loading, setLoading] = useState<boolean | undefined>(undefined)

  const { emit } = useEvent({ key: REFRESH_INFOBOX_EVENT_KEY })
  const { emit: emitInfo } = useEvent<InfoboxInfoEvent>({ key: INFOBOX_NEW_INFO_EVENT_KEY })

  useEffect(() => {
    if (loading === undefined) return

    if (!loading) {
      emit()
      navigation.goBack()
    }
  }, [loading])

  const handleClose = (pending: boolean) => {
    if (pending) {
      alert(i18n.t('UNSAVED_CHANGES_TITLE'), i18n.t('DISCARD_UNSAVED_CHANGES'), [
        {
          text: i18n.t('DISCARD'),
          onPress: navigation.goBack,
          style: 'destructive',
        },
        {
          text: i18n.t('CANCEL'),
          onPress: () => {
            return
          },
          style: 'cancel',
        },
      ])
    } else {
      navigation.goBack()
    }
  }

  const handleUpload = (result: AssetInfo) => {
    if (origin === 'backend') {
      setLoading(true)

      api
        .updateInfoboxFile({ id, infoboxTyp, filename: result.name, note: result.note })
        .catch(console.error)
        .finally(() => setLoading(false))
    } else {
      emitInfo({ id, note: result.note, infoboxTyp, filename: result.name })
      navigation.goBack()
    }
  }

  return <AssetInfoView id={id} onClose={handleClose} onUpload={handleUpload} />
}

export default InfoboxAssetInfoView
