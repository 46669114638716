import { CalendarViewMode } from '@howljs/calendar-kit'
import { AssetDisplayMethod } from '@infominds/react-native-media'
import { NavigationContainerRefWithCurrent } from '@react-navigation/native'
import { atom, atomFamily } from 'recoil'

import { Customer, InvoiceType, TicketArticle } from '../apis/types/apiResponseTypes'
import { ScreenViewType } from '../components/screen/types'
import {
  STORAGE_KEY_CALENDAR_VIEW,
  STORAGE_KEY_CUSTOMER_DETAILS_TAB,
  STORAGE_KEY_INFOBOX_SELECTED_FOLDER,
  STORAGE_KEY_LAST_USED_CUSTOMERS,
  STORAGE_KEY_MEDIA_SORTING_METHOD,
  STORAGE_KEY_SAVE_MEDIA_ON_STORAGE,
  STORAGE_KEY_SN_CUSTOMER_FILTER,
  STORAGE_KEY_TICKET_LIST_SPLIT_VIEW_ENABLED,
  STORAGE_KEY_TICKET_VIEW,
} from '../constants/Keys'
import { PartDto, StockQuantityType, TabType } from '../types'
import appUtils from './appUtils'

export const lastUsedCustomersAtom = atomFamily<Customer[], string>({
  key: 'lastUsedCustomersAtom',
  default: [],
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_LAST_USED_CUSTOMERS)],
})

export const selectedCustomerDetailTabAtom = atomFamily<TabType, string>({
  key: 'selectedCustomerDetailTab',
  default: 'contacts',
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_CUSTOMER_DETAILS_TAB)],
})

export const mediaSortingMethodAtom = atomFamily<AssetDisplayMethod, string>({
  key: 'mediaSortingMethodInfoboxAtom',
  default: 'twice',
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_MEDIA_SORTING_METHOD)],
})

export const saveMediaOnStorageAtom = atomFamily<boolean, string>({
  key: 'saveMediaOnStorageAtom',
  default: false,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_SAVE_MEDIA_ON_STORAGE)],
})

export const serialNumberCustomerFilterAtom = atomFamily<boolean, string>({
  key: 'serialNumberCustomerFilterAtom',
  default: true,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_SN_CUSTOMER_FILTER)],
})

export const ticketListSplitViewEnabledAtom = atomFamily<boolean, string>({
  key: 'ticketListSplitViewEnabledAtom',
  default: false,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_TICKET_LIST_SPLIT_VIEW_ENABLED)],
})

export const selectedFolderIdAtom = atomFamily<string | undefined, string>({
  key: 'selectedFolderIdAtom',
  default: undefined,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_INFOBOX_SELECTED_FOLDER)],
})

export const calendarViewAtom = atomFamily<CalendarViewMode, string>({
  key: 'calendarViewAtom',
  default: 'week',
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_CALENDAR_VIEW)],
})

export const ticketViewAtom = atomFamily<ScreenViewType | undefined, string>({
  key: 'ticketViewAtom',
  default: ScreenViewType.LIST,
  effects: param => [appUtils.localStorageEffect(param + STORAGE_KEY_TICKET_VIEW)],
})

export const shoppingCartAtom = atom<PartDto[] | undefined>({
  key: 'shoppingCartAtom',
  default: undefined,
})

export const shoppingCartRemovalAtom = atom<PartDto[] | undefined>({
  key: 'shoppingCartRemovalAtom',
  default: undefined,
})

export const invoiceTypeAtom = atom<InvoiceType[] | undefined>({
  key: 'invoiceTypeAtom',
  default: undefined,
})

export const stockQuantityAtom = atom<StockQuantityType[]>({
  key: 'stockQuantityAtom',
  default: [],
})

export const ticketArticleAtom = atom<TicketArticle[] | undefined>({
  key: 'ticketArticleAtom',
  default: undefined,
})

export const infoboxAssetToSyncAtom = atom({
  key: 'infoboxAssetToSyncAtom',
  default: 0,
})

export const accessDataSelectedIdAtom = atom<string | undefined>({
  key: 'accessDataSelectedIdAtom',
  default: undefined,
})

export const accessDataFilterEnableAtom = atom({
  key: 'accessDataFilterEnableAtom',
  default: false,
})

export const ticketFilterEnableAtom = atom({
  key: 'ticketFilterEnableAtom',
  default: false,
})

export const sparePartsFilterEnableAtom = atom({
  key: 'sparePartsFilterEnableAtom',
  default: false,
})

export const activityHistoryFilterEnableAtom = atom({
  key: 'activityHistoryFilterEnableAtom',
  default: false,
})

export const sparePartsScreenFilterEnableAtom = atom({
  key: 'sparePartsScreenFilterEnableAtom',
  default: false,
})

export const activityPlanningFilterEnableAtom = atom({
  key: 'activityPlanningFilterEnableAtom',
  default: false,
})

export const qualityFilterEnableAtom = atom({
  key: 'qualityFilterEnableAtom',
  default: false,
})

export const landingPageUrlAtom = atom<string | undefined>({
  key: 'landingPageUrlAtom',
  default: undefined,
})

export const revalidateSettingsAtom = atom({
  key: 'revalidateSettingsAtom',
  default: false,
})

export const apiVersionAtom = atom({
  key: 'apiVersionAtom',
  default: '',
})

export const navigationRefAtom = atom<NavigationContainerRefWithCurrent<ReactNavigation.RootParamList> | undefined>({
  key: 'navigationRefAtom',
  default: undefined,
})
