import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useState } from 'react'
import { Platform, SectionListRenderItemInfo } from 'react-native'
import { useRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { SerialNumber } from '../../apis/types/apiResponseTypes'
import SerialNumberCard from '../../cards/common/SerialnumberCard'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_TICKET_SERIAL_NUMBER } from '../../constants/Keys'
import { ListSection, ThemeColorExpanded } from '../../types'
import { serialNumberCustomerFilterAtom } from '../../utils/stateManager'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import PressableIcon from '../Infominds/PressableIcon'
import MultiSelectionItem from '../MultiSelectionItem'
import MultiSelectInput from './selectInput/MultiSelectInput'

interface Props {
  customerId: string
  values: SerialNumber[]
  shippingAddressId: string | undefined
  multiSelect?: boolean
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: SerialNumber) => void
}

export default function SerialNumberMultiSelector({ customerId, shippingAddressId, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { sessionKey } = useAuthentication()

  const [search, setSearch] = useState('')
  const [shippingAddressFilter, setShippingAddressFilter] = useRecoilState(serialNumberCustomerFilterAtom(sessionKey))
  const {
    item: serialNumbers,
    loadItem: loadItems,
    allDataLoaded,
    loadMore,
    loading,
  } = useInfiniteLoader(api.getSerialNumber, { chuckSize: CONSTANTS.defaultChuckSize, id: REQUEST_TICKET_SERIAL_NUMBER })

  useEffect(() => {
    refresh()
  }, [search, shippingAddressFilter, shippingAddressId, customerId])

  const refresh = () =>
    loadItems({
      customerId: customerId,
      searchText: search,
      devicesOnly: true,
      shippingAddressId: shippingAddressFilter ? shippingAddressId : undefined,
    })

  const render = ({ item }: SectionListRenderItemInfo<SerialNumber, ListSection<SerialNumber>>, onPress?: () => void) => {
    const isSelected = props.values.find(el => el.id === item.id)
    const isLast = allDataLoaded && serialNumbers.length > 0 && item.id === serialNumbers[serialNumbers.length - 1].id

    return (
      <IM.View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ marginBottom: isLast ? 3 * IMLayout.verticalMargin : 0 }}>
        <SerialNumberCard item={item} onPress={onPress} selected={isSelected === undefined ? false : true} spacing={['horizontal', 'top']} />
      </IM.View>
    )
  }

  return (
    <MultiSelectInput
      data={serialNumbers}
      loading={loading}
      allDataLoaded={allDataLoaded}
      title={i18n.t('SERIAL_NUMBER')}
      screenTitle={i18n.t('SERIAL_NUMBERS')}
      noDataMessage={i18n.t('NO_SN_FOUND')}
      onLoadMore={loadMore}
      refresh={refresh}
      onSearchChange={setSearch}
      focusSearch={Platform.OS === 'android' ? false : true} // TODO: the space to dismiss keyboard is pretty limited
      extraIcon={
        shippingAddressId !== undefined && (
          <PressableIcon
            icon={['fal', 'location-dot']}
            color={shippingAddressFilter ? theme.general.info : IMStyle.palette.white}
            onPress={() => setShippingAddressFilter(prev => !prev)}
            size={19}
          />
        )
      }
      onChange={onChange}
      renderItem={render}
      renderMultiSelectItem={item => <MultiSelectionItem item={item} onRemove={onChange} value={item.article.searchtext} />}
      disableLoadAfterMount
      {...props}
    />
  )
}
