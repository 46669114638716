import { Storage, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import {
  STORAGE_KEY_FILTER_CONTEXT_QUALITY_FILTER,
  STORAGE_KEY_FILTER_CONTEXT_QUALITY_GROUP,
  STORAGE_KEY_FILTER_CONTEXT_QUALITY_ORDER,
} from '../constants/Keys'
import { Filter, FilterElement, FilterStorageType, Group, Order, StorageType } from '../types'
import ticketUtils from '../utils/TicketUtils'

export enum QualityGroupType {
  Customer = 'GroupCustomerKey',
  Ticket = 'TicketKey',
}

export enum QualityOrderType {
  SerialNumberDescending = 'SerialNumberDescendingKey',
  SerialNumberAscending = 'SerialNumberAscendingKey',
  SortKeyDescending = 'SortKeyDescendingKey',
  SortKeyAscending = 'SortKeyAscendingKey',
}

export enum QualityFilterType {
  SerialNumber = 'FilterSerialNumberKey',
}

interface ContextProps {
  filters: Filter<QualityFilterType>[]
  groups: Group<QualityGroupType>[]
  orders: Order<QualityOrderType>[]
  setFilters: (filters: Filter<QualityFilterType>[]) => void
  setGroups: (groups: Group<QualityGroupType>[]) => void
  setOrders: (orders: Order<QualityOrderType>[]) => void
  initFilters: (serial: FilterElement[]) => void
  changeFilterStatus: (id: QualityGroupType | QualityOrderType | QualityFilterType, filterElemId?: string) => void
}

const QualityFilterContext = createContext<ContextProps | undefined>(undefined)

interface ProviderProps extends PropsWithChildren {
  storageKeyUniqueId: string
}

export const QualityFilterProvider = ({ storageKeyUniqueId, children }: ProviderProps) => {
  const { i18n, language } = useLanguage()

  const snOrders: Order<QualityOrderType>[] = [
    {
      active: false,
      data: {
        id: QualityOrderType.SerialNumberDescending,
        name: ticketUtils.getNameByType(QualityOrderType.SerialNumberDescending, i18n),
        icon: ['fal', 'arrow-up-1-9'],
      },
    },
    {
      active: false,
      data: {
        id: QualityOrderType.SerialNumberAscending,
        name: ticketUtils.getNameByType(QualityOrderType.SerialNumberAscending, i18n),
        icon: ['fal', 'arrow-down-1-9'],
      },
    },
  ]
  const keyOrders: Order<QualityOrderType>[] = [
    {
      active: false,
      data: {
        id: QualityOrderType.SortKeyDescending,
        name: ticketUtils.getNameByType(QualityOrderType.SortKeyDescending, i18n),
        icon: ['fal', 'arrow-up-1-9'],
      },
    },
    {
      active: false,
      data: {
        id: QualityOrderType.SortKeyAscending,
        name: ticketUtils.getNameByType(QualityOrderType.SortKeyAscending, i18n),
        icon: ['fal', 'arrow-down-1-9'],
      },
    },
  ]

  const [groups, setGroups] = useState<Group<QualityGroupType>[]>(
    [
      { active: false, data: { id: QualityGroupType.Customer, name: ticketUtils.getNameByType(QualityGroupType.Customer, i18n) } },
      { active: false, data: { id: QualityGroupType.Ticket, name: ticketUtils.getNameByType(QualityGroupType.Ticket, i18n) } },
    ].sort((a, b) => a.data.name.localeCompare(b.data.name))
  )
  const [orders, setOrders] = useState<Order<QualityOrderType>[]>([...snOrders, ...keyOrders].sort((a, b) => a.data.name.localeCompare(b.data.name)))
  const [filters, setFilters] = useState<Filter<QualityFilterType>[]>([])

  const groupsStorage = Storage<StorageType[]>(STORAGE_KEY_FILTER_CONTEXT_QUALITY_GROUP + storageKeyUniqueId)
  const ordersStorage = Storage<StorageType[]>(STORAGE_KEY_FILTER_CONTEXT_QUALITY_ORDER + storageKeyUniqueId)
  const filtersStorage = Storage<FilterStorageType<QualityFilterType>[]>(STORAGE_KEY_FILTER_CONTEXT_QUALITY_FILTER + storageKeyUniqueId)

  useEffect(() => {
    groupsStorage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const clone = cloneDeep(groups).map(elem => {
            const found = loaded.find(ld => ld.id === elem.data.id)

            if (found) {
              return { ...elem, active: found.value }
            } else {
              return elem
            }
          })

          setGroups(clone)
        }
      })
      .catch(err => console.error('Failed loading ticket group by', err))

    ordersStorage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const clone = cloneDeep(orders).map(elem => {
            const found = loaded.find(ld => ld.id === elem.data.id)

            if (found) {
              return { ...elem, active: found.value }
            } else {
              return elem
            }
          })

          setOrders(clone)
        }
      })
      .catch(err => console.error('Failed loading ticket sort by', err))
  }, [])

  useEffect(() => {
    setGroups(prv =>
      prv.map(el => {
        return { ...el, data: { ...el.data, name: ticketUtils.getNameByType(el.data.id, i18n) } }
      })
    )
    setOrders(prv =>
      prv.map(el => {
        return { ...el, data: { ...el.data, name: ticketUtils.getNameByType(el.data.id, i18n) } }
      })
    )
  }, [language])

  const initFilters = useCallback((serialNumber: FilterElement[]) => {
    filtersStorage
      .load()
      .then(loaded => {
        const serialNumberToUpdate: FilterElement[] = []

        if (loaded !== null) {
          const loadedFilters: Filter<QualityFilterType>[] = []
          loaded.forEach(el => {
            const elements: FilterElement[] = []
            el.data.forEach(data => {
              elements.push({ id: data.id, active: data.value })
            })

            loadedFilters.push({ id: el.id, name: ticketUtils.getNameByType(el.id, i18n), elements })
          })

          loadedFilters.forEach(loadedFilter => {
            if (loadedFilter.id === QualityFilterType.SerialNumber) {
              serialNumberToUpdate.push(...ticketUtils.mergeFilters(loadedFilter, serialNumber))
            }
          })
        }

        setFilters([
          {
            id: QualityFilterType.SerialNumber,
            name: ticketUtils.getNameByType(QualityFilterType.SerialNumber, i18n),
            elements:
              serialNumberToUpdate.length === 0
                ? ticketUtils.removeDuplicateFilters(serialNumber).sort((a, b) => a.id.localeCompare(b.id))
                : serialNumberToUpdate,
          },
        ])
      })
      .catch(err => console.error('Failed loading ticket filter', err))
  }, [])

  const changeFilterStatus = useCallback(
    (id: QualityGroupType | QualityOrderType | QualityFilterType, filterElemId?: string) => {
      if (Object.values(QualityGroupType).includes(id as QualityGroupType)) {
        const clone = cloneDeep(groups)

        clone.forEach(filter => {
          if (filter.data.id === id) {
            filter.active = !filter.active
          }
        })

        handleGroupsUpdate(clone)
      } else if (Object.values(QualityOrderType).includes(id as QualityOrderType)) {
        const clone = cloneDeep(orders)

        clone.forEach(filter => {
          if (filter.data.id === id) {
            filter.active = !filter.active
          }
        })

        handleOrderUpdate(clone)
      } else {
        const clone = cloneDeep(filters)

        clone.forEach(filter => {
          if (filter.id === id) {
            filter.elements.forEach(el => {
              if (el.id === filterElemId) {
                el.active = !el.active
              }
            })
          }
        })

        handleFilterUpdate(clone)
      }
    },
    [filters, orders, groups]
  )

  const handleGroupsUpdate = (newGroups: Group<QualityGroupType>[]) => {
    const toSave: StorageType[] = []
    newGroups.forEach(order => toSave.push({ id: order.data.id, value: order.active }))
    groupsStorage.save(toSave).catch(err => console.error('Failed saving ticket group by', err))
    setGroups(newGroups)
  }

  const handleOrderUpdate = (newOrders: Order<QualityOrderType>[]) => {
    const toSave: StorageType[] = []
    newOrders.forEach(order => toSave.push({ id: order.data.id, value: order.active }))
    ordersStorage.save(toSave).catch(err => console.error('Failed saving ticket sort by', err))
    setOrders(newOrders)
  }

  const handleFilterUpdate = (newFilters: Filter<QualityFilterType>[]) => {
    const toSave: FilterStorageType<QualityFilterType>[] = []
    newFilters.forEach(filter => {
      const data: StorageType[] = []

      filter.elements.forEach(el => {
        data.push({ id: el.id, value: el.active })
      })

      toSave.push({ id: filter.id, data })
    })
    filtersStorage.save(toSave).catch(err => console.error('Failed saving ticket filter', err))
    setFilters(newFilters)
  }

  const props = useMemo<ContextProps>(
    () => ({
      filters,
      groups,
      orders,
      setFilters: handleFilterUpdate,
      setGroups: handleGroupsUpdate,
      setOrders: handleOrderUpdate,
      changeFilterStatus,
      initFilters,
    }),
    [filters, groups, orders]
  )

  return <QualityFilterContext.Provider value={props}>{children}</QualityFilterContext.Provider>
}

export default QualityFilterContext
