import { SpacingProps, useDimensions, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'

import { translation } from '../../assets/languages/i18next'
import BottomSheetModal from '../../modals/BottomSheetModal'
import { Gender } from '../../types'
import SelectTextInput from '../input/SelectTextInput'
import RadioPressable from '../RadioPressable'

interface Props {
  value: Gender | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Gender) => void
}

const handleGender = (value: Gender | undefined) => {
  return value === Gender.None ? undefined : value
}

export default function GenderSelector({ value, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const [open, setOpen] = useState(false)
  const [gender, setGender] = useState<Gender | undefined>(handleGender(value))

  const translateGender = (genderToTranslate: Gender) => {
    switch (genderToTranslate) {
      case Gender.Female:
        return i18n.t('FEMALE')
      case Gender.Male:
        return i18n.t('MALE')
      case Gender.Neuter:
        return i18n.t('NEUTER')
      default:
        return i18n.t('NO_SELECTION')
    }
  }

  const onGenderPress = (newGender: Gender) => {
    setGender(handleGender(newGender))
    onChange(newGender ?? Gender.None)
    setOpen(false)
  }

  return (
    <>
      <SelectTextInput
        title={i18n.t('GENDER')}
        value={gender ? i18n.t(gender.toUpperCase() as keyof typeof translation.en) : undefined}
        placeholder={i18n.t('NO_SELECTION')}
        onPress={() => setOpen(true)}
        onReset={() => onGenderPress(Gender.None)}
        showNoDataMessage={false}
        {...props}
      />

      {open && (
        <BottomSheetModal disableCentering disableBottomOffset={!isSmallDevice} onDismiss={() => setOpen(false)} scrollEnabled={false}>
          {Object.values(Gender).map(elem => {
            return (
              <RadioPressable
                key={elem}
                onPress={() => onGenderPress(elem)}
                selected={gender === undefined ? elem === Gender.None : elem === gender}
                text={translateGender(elem)}
              />
            )
          })}
        </BottomSheetModal>
      )}
    </>
  )
}
