import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleSheet } from 'react-native'

import useIsOnline from '../../../dataProvider/hooks/useIsOnline'
import DataManagementIconGroup, { DataManagementIconGroupProps } from '../DataManagementIconGroup'
import HeaderWithPressable from './HeaderWithPressable'
import SyncIcon from './SyncIcon'

interface Props {
  title: string
  description?: string
  icon?: IconProp
  iconSize?: number
  iconColor?: string
  onPress?: () => void
  extraIcon?: ReactNode
  sync?: boolean
  enabledOffline?: boolean
}

export default function HeaderWithIcons({
  title,
  icon,
  iconSize,
  iconColor,
  description,
  onPress,
  sync = true,
  disabled,
  enabledOffline,
  ...props
}: Props & DataManagementIconGroupProps) {
  const isOnline = useIsOnline()

  return (
    <IM.View style={styles.container}>
      <HeaderWithPressable title={title} icon={icon} iconSize={iconSize} iconColor={iconColor} description={description} onPress={onPress} />
      <DataManagementIconGroup disabled={disabled || (!enabledOffline && !isOnline)} {...props} />
      {sync && <SyncIcon />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
})
