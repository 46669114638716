import { IM, useDimensions } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { Platform } from 'react-native'

import { themeExpansion, themeWebExpansion } from '../InitStructures'

type Props = {
  children: ReactNode
}

export default function ThemeContextWrapper({ children }: Props) {
  const { isSmallDevice } = useDimensions()

  return <IM.ThemeProvider theme={!isSmallDevice && Platform.OS === 'web' ? themeWebExpansion : themeExpansion}>{children}</IM.ThemeProvider>
}
