import { IM, IMLayout, TextProps } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import useLayout, { ForceLayoutType } from '../hooks/useLayout'
import Text from './Text'

interface Props {
  title?: string
  value: string | number | JSX.Element
  style?: StyleProp<ViewStyle>
  disableSpacing?: boolean
  forceSpacingTable?: boolean
  forceShowTitle?: boolean
  forceLayout?: ForceLayoutType
}

const CellText = memo(function CellText({
  title,
  value,
  style,
  disableSpacing,
  forceShowTitle = false,
  forceSpacingTable = false,
  forceLayout,
  ...others
}: Props & Pick<TextProps, 'numberOfLines' | 'secondary'>) {
  const { isSmallDevice } = useLayout(false, forceLayout)

  return (
    <IM.View style={[styles.container, style]} spacing={!isSmallDevice && forceSpacingTable === false ? 'none' : disableSpacing ? 'none' : 'top'}>
      {(forceShowTitle || isSmallDevice) && (
        <IM.View style={[IMLayout.flex.f1, styles.margin]}>
          <Text secondary>{title}</Text>
        </IM.View>
      )}
      <IM.View style={[IMLayout.flex.f1, !isSmallDevice && styles.margin]}>
        {typeof value === 'string' || typeof value === 'number' ? <Text {...others}>{value}</Text> : value}
      </IM.View>
    </IM.View>
  )
})

export default CellText

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  margin: { marginRight: 20 },
})
