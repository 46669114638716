import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useEffect, useRef } from 'react'

export default function usePopState(unsavedChanges: boolean, title: string, description: string, onGoBack: () => void) {
  const { alert } = useAlert()
  const { i18n } = useLanguage()

  const hasPushedState = useRef(false)

  useEffect(() => {
    const callback = () => {
      if (!unsavedChanges) {
        // onGoBack()
        return
      }

      alert(title, description, [
        {
          text: i18n.t('DISCARD'),
          style: 'destructive',
          onPress: () => {
            onGoBack()
            window.history.go(-1)
            hasPushedState.current = false
          },
        },
        {
          text: i18n.t('CANCEL'),
          style: 'cancel',
          onPress: () => window.history.pushState(null, document.title, window.location.href),
        },
      ])
    }

    if (!unsavedChanges && hasPushedState.current === true) {
      hasPushedState.current = false
      window.history.go(-1)
    }

    if (unsavedChanges && hasPushedState.current === false) {
      hasPushedState.current = true
      window.history.pushState(null, document.title, window.location.href)
    }

    window.addEventListener('popstate', callback)
    return () => window.removeEventListener('popstate', callback)
  }, [unsavedChanges])
}
