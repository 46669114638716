import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import CONSTANTS from '../../../constants/Constants'
import useLayout from '../../../hooks/useLayout'
import VerticalText from '../../VerticalText'

export type CardLeftTitleProps = {
  color?: string
  text?: string
}

const CardLeftTitle = memo(function CardLeftTitle({ color, text }: CardLeftTitleProps) {
  const { theme } = useTheme()
  const { isSmallDevice } = useLayout()

  return (
    <IM.View style={[text ? styles.containerWithText : styles.containerNoText, { backgroundColor: color ?? theme.card.headBackground }]}>
      {!!text && <VerticalText text={text} width={isSmallDevice ? 25 : 32} />}
    </IM.View>
  )
})

export default CardLeftTitle

const styles = StyleSheet.create({
  containerNoText: {
    width: CONSTANTS.cardLeftWidth,
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
  containerWithText: {
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
    paddingVertical: 8,
  },
})
