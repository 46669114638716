import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import HeaderWithSync from '../../components/screen/headers/HeaderWithSync'
import { Screen } from '../../components/screen/Screen'
import SynchronizationView from '../../views/synchronization/SynchronizationView'

export default function SynchronizationScreen() {
  const { i18n } = useLanguage()

  return (
    <Screen>
      <Screen.Header>
        <HeaderWithSync title={i18n.t('TAB_SYNCHRONIZATION')} showSyncInfo />
      </Screen.Header>
      <Screen.Content>
        <SynchronizationView />
      </Screen.Content>
    </Screen>
  )
}
