import { useContext } from 'react'

import { QualityCheckContext } from '../contexts/QualityCheckContext'

export default function useQualityCheck() {
  const context = useContext(QualityCheckContext)

  if (context === undefined) {
    throw new Error('useQualityCheck() must be called inside QualityCheckProvider')
  }

  return context
}
