import { useContext } from 'react'

import { SignatureContext } from '../contexts/SignatureContext'

export default function useSignature() {
  const context = useContext(SignatureContext)

  if (context === undefined) {
    throw new Error('useSignature() must be called inside SignatureProvider')
  }

  return context
}
