import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import CONSTANTS from '../../../constants/Constants'
import useUserSettings from '../../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../../types'
import PressableIcon from '../../Infominds/PressableIcon'
import DataManagementIconGroup from '../DataManagementIconGroup'
import SearchDetailHeaderContainer from './containers/SearchDetailHeaderContainer'
import DefaultHeader from './default/DefaultHeader'

interface Props {
  title: string
  hideDestinationFilter: boolean
  destinationFilterEnabled?: boolean
  onAdd: () => void
  onDestinationFilterPress: () => void
}

export default function SerialNumberSelectionHeader({
  title,
  hideDestinationFilter,
  destinationFilterEnabled,
  onAdd,
  onDestinationFilterPress,
}: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { userSettings } = useUserSettings()

  return (
    <IM.View style={styles.container}>
      <DefaultHeader title={title} />
      <DataManagementIconGroup
        extraIcon={
          <>
            {userSettings?.allowCreationOfNewSerialnumbers && (
              <PressableIcon icon={['fal', 'plus']} color={IMStyle.palette.white} onPress={onAdd} size={20} style={styles.icon} />
            )}
            {!hideDestinationFilter && (
              <PressableIcon
                icon={['fal', 'location-dot']}
                color={destinationFilterEnabled ? theme.general.info : IMStyle.palette.white}
                onPress={onDestinationFilterPress}
                size={19}
                style={styles.icon}
              />
            )}
          </>
        }
        disableFilter
        tabletSearchComponent={
          <SearchDetailHeaderContainer iconPosition="right" enableQR={false} deferredTimeout={CONSTANTS.searchDeferredTimeout} />
        }
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  icon: {
    marginRight: 2,
  },
})
