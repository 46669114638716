import { IM, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { Modal, ScrollView, StyleSheet } from 'react-native'

import { getApi } from '../apis/apiCalls'
import Pressable from '../components/Infominds/Pressable'
import PasswordInput from '../components/input/PasswordInput'
import KeyboardAware from '../components/KeyboardAware'
import LoadingIcon from '../components/LoadingIcon'
import { useDataProvider } from '../dataProvider/hooks/useDataProvider'
import useVault from '../hooks/useVault'
import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'
import { utils } from '../utils/utils'

// TODO RT: managed back handler!

interface Props {
  controller: ModalController
  onAccept?: (id: string) => void
  onHardwareBackPress: () => void
}

export default function VaultModal({ controller, onHardwareBackPress, onAccept }: Props) {
  const { i18n } = useLanguage()
  const { setSessionId } = useVault()
  const { client } = useDataProvider()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const checkPassword = () => {
    setError(false)
    setLoading(true)

    const id = utils.generateUuid()

    getApi(client)
      .getVaultAccess({ SessionId: id, Masterpassword: password })
      .then(response => {
        if (response) {
          setSessionId(id)
          onAccept?.(id)
          controller.close()
          setError(false)
          setPassword('')
        }
      })
      .catch(err => {
        console.error('Failed validating vault password:', err)
        setError(true)
      })
      .finally(() => setLoading(false))
  }

  const onForceClose = () => {
    onHardwareBackPress()
    controller.close()
  }

  return (
    <Modal statusBarTranslucent visible={controller.isShown} onRequestClose={onForceClose} transparent>
      <IM.View style={[styles.centeredView, { backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100 * 1.2}` }]}>
        <KeyboardAware style={styles.avoidingView}>
          <IM.View style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme) }]}>
            <ScrollView canCancelContentTouches={false}>
              <IM.View spacing="bottom">
                <IM.Text style={styles.title}>{i18n.t('VAULT_MANAGEMENT')}</IM.Text>
              </IM.View>
              <IM.View spacing="bottom">
                <IM.Text>{i18n.t('VAULT_INSERT_PASSWORD')}</IM.Text>
              </IM.View>
              <PasswordInput
                placeholder={i18n.t('MASTER_PASSWORD')}
                value={password}
                onChangeText={setPassword}
                spacing="bottom"
                loading={loading}
                error={error}
                disableFocus
                focusOnMount
                onSubmitEditing={checkPassword}
              />
              <IM.View style={styles.buttonsContainer}>
                <Pressable spacing="right" onPress={onForceClose}>
                  <IM.Text style={[styles.text, { color: loading ? theme.textDetail : theme.text }]}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
                </Pressable>
                <IM.View style={styles.okContainer}>
                  {loading ? (
                    <LoadingIcon />
                  ) : (
                    <Pressable style={styles.okButton} onPress={checkPassword} disabled={loading}>
                      <IM.Text style={[styles.text, { color: loading ? theme.textDetail : theme.text }]}>{i18n.t('OK').toUpperCase()}</IM.Text>
                    </Pressable>
                  )}
                </IM.View>
              </IM.View>
            </ScrollView>
          </IM.View>
        </KeyboardAware>
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    height: 170,
    backgroundColor: 'red',
  },
  modalView: {
    borderRadius: 20,
    paddingHorizontal: 30,
    paddingTop: 28,
    paddingBottom: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '90%',
    maxWidth: 400,
  },
  okButton: {
    paddingHorizontal: 14,
  },
  okContainer: {
    width: 48,
    height: 34,
    justifyContent: 'center',
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  avoidingView: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})
