import { Storage } from '@infominds/react-native-components'
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useMemo, useState } from 'react'

import { STORAGE_KEY_DRAWER_OPEN } from '../../constants/Keys'

export interface DrawerContextProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

const DrawerContext = createContext<DrawerContextProps | undefined>(undefined)

export const DrawerProvider = ({ children }: PropsWithChildren) => {
  const [open, setOpen] = useState(false)
  const storage = Storage<boolean>(STORAGE_KEY_DRAWER_OPEN)

  useEffect(() => {
    storage
      .load()
      .then(res => {
        if (!res) return

        setOpen(res)
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    storage.save(open).catch(console.error)
  }, [open])

  const props = useMemo<DrawerContextProps>(() => ({ open, setOpen }), [open])

  return <DrawerContext.Provider value={props}>{children}</DrawerContext.Provider>
}

export default DrawerContext
