import { IM, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Modal, Platform, ScrollView, StyleSheet } from 'react-native'

import Pressable from '../components/Infominds/Pressable'
import KeyboardAware from '../components/KeyboardAware'
import { LoadingType, ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'

type Button = {
  title: string
  color?: string
  loading?: LoadingType
  onPress: () => void
}

interface Props {
  controller: ModalController
  description: string
  buttons: Button[]
  onHardwareBackPress: () => void
}

export default function ErrorModal({ controller, description, buttons, onHardwareBackPress }: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const onForceClose = () => {
    onHardwareBackPress()
    controller.close()
  }

  return (
    <Modal statusBarTranslucent visible={controller.isShown} onRequestClose={onForceClose} transparent>
      <IM.View style={[styles.centeredView, { backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100 * 1.2}` }]}>
        <KeyboardAware style={styles.avoidingView}>
          <IM.View style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme), borderColor: theme.error }]}>
            <ScrollView canCancelContentTouches={false}>
              <IM.View spacing="bottom">
                <IM.Text style={[styles.title, { color: theme.error }]}>{i18n.t('ERROR')}</IM.Text>
              </IM.View>
              <IM.View>
                <IM.Text>{description}</IM.Text>
              </IM.View>
              <IM.View style={styles.buttonsContainer} spacing="top">
                {buttons.map((button, index) => (
                  <Pressable style={styles.button} onPress={button.onPress} key={index}>
                    {button.loading === false || button.loading === undefined ? (
                      <IM.Text style={[styles.text, { color: button.color ?? theme.text }]}>{button.title.toUpperCase()}</IM.Text>
                    ) : (
                      // eslint-disable-next-line react-native/no-inline-styles
                      <IM.LoadingSpinner isVisible size="small" style={{ paddingTop: Platform.OS === 'ios' ? 2 : 0 }} />
                    )}
                  </Pressable>
                ))}
              </IM.View>
            </ScrollView>
          </IM.View>
        </KeyboardAware>
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    borderRadius: 20,
    paddingHorizontal: 30,
    paddingTop: 28,
    paddingBottom: 20,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '90%',
    maxWidth: 400,
    borderWidth: 1,
  },
  button: {
    paddingHorizontal: 14,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: 38,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  avoidingView: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})
