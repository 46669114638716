import { IM, IMStyle, ModalController, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Platform, ScrollView, StyleSheet } from 'react-native'

import api from '../apis/apiCalls'
import { Customer } from '../apis/types/apiResponseTypes'
import useRequest from '../components/Infominds/hooks/useRequest'
import Pressable from '../components/Infominds/Pressable'
import TextInput from '../components/input/TextInput'
import KeyboardAware from '../components/KeyboardAware'
import { REFRESH_CONTACT_EVENT_KEY } from '../constants/EmitterKeys'
import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'

interface Props {
  controller: ModalController
  customer: Customer
}

export default function EditOpeningHoursModal({ controller, customer }: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const initial = useRef(customer.openingHours)

  const [save, setSave] = useState(false)
  const [text, setText] = useState(customer.openingHours)

  const { request: editCustomer, loading } = useRequest(api.editCustomer)

  const { emit } = useEvent({ key: REFRESH_CONTACT_EVENT_KEY })

  useEffect(() => {
    if (save && loading === false) {
      emit()
      controller.close()
    }
  }, [loading, save])

  const disabled = initial.current === text

  return (
    <Modal
      statusBarTranslucent
      visible={controller.isShown}
      onRequestClose={controller.close}
      hardwareAccelerated={Platform.OS !== 'web'}
      transparent>
      <IM.View style={[styles.centeredView, { backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100 * 1.2}` }]}>
        <KeyboardAware style={styles.avoidingView}>
          <IM.View style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme), borderColor: theme.error }]}>
            <ScrollView canCancelContentTouches={false}>
              <IM.View spacing="bottom">
                <IM.Text style={styles.title}>{i18n.t('EDIT_OPENING_HOURS')}</IM.Text>
              </IM.View>
              <TextInput value={text ?? ''} onChangeText={val => setText(val === '' ? undefined : val)} multiline />
              <IM.View style={styles.buttonsContainer} spacing="top">
                <Pressable style={styles.button} onPress={controller.close}>
                  <IM.Text style={styles.text}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
                </Pressable>
                <Pressable
                  style={styles.button}
                  disabled={disabled}
                  onPress={() => {
                    setSave(true)
                    editCustomer({ ...customer, openingHours: text })
                  }}>
                  {loading === false ? (
                    <IM.Text secondary={disabled} style={[styles.text, { color: disabled ? theme.textDetail : theme.general.info }]}>
                      {i18n.t('SAVE').toUpperCase()}
                    </IM.Text>
                  ) : (
                    // eslint-disable-next-line react-native/no-inline-styles
                    <IM.LoadingSpinner isVisible size="small" style={{ paddingTop: Platform.OS === 'ios' ? 2 : 0 }} />
                  )}
                </Pressable>
              </IM.View>
            </ScrollView>
          </IM.View>
        </KeyboardAware>
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    borderRadius: 20,
    paddingHorizontal: 30,
    paddingTop: 28,
    paddingBottom: 20,
    width: '90%',
    maxWidth: 400,
  },
  button: {
    paddingHorizontal: 14,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: 38,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  avoidingView: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})
