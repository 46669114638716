import { useEvent } from '@infominds/react-native-components'
import React, { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import {
  ACTIVITY_CLOSE_ACT_ID_EVENT_KEY,
  ACTIVITY_CLOSE_NEXT_ACT_EVENT_KEY,
  ACTIVITY_CLOSE_REPORT_EVENT_KEY,
  ACTIVITY_CLOSE_SIGNATURE_EVENT_KEY,
} from '../constants/EmitterKeys'
import { CloseActivityEvent, CloseType, ClosingDto } from '../types'

interface ContextProps {
  closeType: CloseType | undefined
  setCloseType: React.Dispatch<React.SetStateAction<CloseType | undefined>>
  enableDone: boolean
  pending: boolean
  dto: ClosingDto | undefined
}

export const CloseActivityContext = createContext<ContextProps | undefined>(undefined)

export const CloseActivityProvider = ({ children }: PropsWithChildren) => {
  const [signatureReady, setSignatureReady] = useState(true)
  const [signaturePending, setSignaturePending] = useState(false)
  const [reportReady, setReportReady] = useState(true)
  const [reportPending, setReportPending] = useState(false)
  const [nextActReady, setNextActReady] = useState(true)
  const [nextActPending, setNextActPending] = useState(false)
  const [idsPending, setIdsPending] = useState(false)
  const [enableDone, setEnableDone] = useState(false)
  const [pending, setPending] = useState(false)
  const [closeType, setCloseType] = useState<CloseType | undefined>(undefined)
  const [dto, setDto] = useState<ClosingDto | undefined>(undefined)

  useEvent<CloseActivityEvent<Pick<ClosingDto, 'signatureImageData' | 'signatureName' | 'signaturePenColor'>>>(
    { key: ACTIVITY_CLOSE_SIGNATURE_EVENT_KEY },
    ({ done, pending: pendingEv, dto: inDto }) => {
      setSignatureReady(done)
      setSignaturePending(pendingEv)
      setDto(prev => ({
        ...prev,
        signatureName: inDto.signatureName,
        signatureImageData: inDto.signatureImageData,
        signaturePenColor: inDto.signaturePenColor,
      }))
    }
  )

  useEvent<CloseActivityEvent<Pick<ClosingDto, 'reportEmails'>>>(
    { key: ACTIVITY_CLOSE_REPORT_EVENT_KEY },
    ({ done, pending: pendingEv, dto: inDto }) => {
      setReportReady(done)
      setReportPending(pendingEv)
      setDto(prev => ({ ...prev, reportEmails: inDto.reportEmails }))
    }
  )

  useEvent<CloseActivityEvent<Pick<ClosingDto, 'nextActivityEmployeeId' | 'nextActivityTechnicalDescription'>>>(
    { key: ACTIVITY_CLOSE_NEXT_ACT_EVENT_KEY },
    ({ done, pending: pendingEv, dto: inDto }) => {
      setNextActReady(done)
      setNextActPending(pendingEv)
      setDto(prev => ({
        ...prev,
        nextActivityEmployeeId: inDto.nextActivityEmployeeId,
        nextActivityTechnicalDescription: inDto.nextActivityTechnicalDescription,
      }))
    }
  )

  useEvent<CloseActivityEvent<Pick<ClosingDto, 'activityIds'>>>(
    { key: ACTIVITY_CLOSE_ACT_ID_EVENT_KEY },
    ({ done: _done, pending: pendingEv, dto: inDto }) => {
      setIdsPending(pendingEv)
      setDto(prev => ({
        ...prev,
        activityIds: inDto.activityIds,
      }))
    }
  )

  useEffect(() => {
    if (signatureReady && reportReady && closeType !== undefined && (closeType === CloseType.withNextActivity ? nextActReady : true)) {
      return setEnableDone(true)
    }

    setEnableDone(false)
  }, [signatureReady, reportReady, closeType, nextActReady])

  useEffect(() => {
    if (signaturePending || reportPending || nextActPending || idsPending) return setPending(true)
    setPending(false)
  }, [signaturePending, reportPending, nextActPending, idsPending])

  const contextValue: ContextProps = useMemo(
    () => ({
      closeType,
      setCloseType,
      enableDone,
      pending,
      dto,
    }),
    [closeType, enableDone, pending, dto]
  )

  return <CloseActivityContext.Provider value={contextValue}>{children}</CloseActivityContext.Provider>
}
