import { IM, IMLayout, IMStyle, useAlert, useDimensions, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import Color from 'color'
import React, { createRef, useEffect } from 'react'
import { Animated, Platform, StyleSheet, useWindowDimensions } from 'react-native'
import { useRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { Customer } from '../../apis/types/apiResponseTypes'
import ContactInformation from '../../components/ContactInformation'
import InfoboxButton from '../../components/InfoboxButton'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import Map from '../../components/map/Map'
import { SCREEN_CONSTANTS, ScreenCardsHeaderShadows } from '../../components/screen/constants/constants'
import useScreen from '../../components/screen/hooks/useScreen'
import TabSelector from '../../components/TabSelector'
import { ADD_CONTACT_DESTINATION_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_INFOBOX_FILES } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useUserSettings from '../../hooks/useUserSettings'
import { CustomerStackParamList } from '../../navigation/types'
import { ContactViewRef, DestinationViewRef, ThemeColorExpanded } from '../../types'
import { selectedCustomerDetailTabAtom } from '../../utils/stateManager'
import { utils } from '../../utils/utils'
import ContactsView from './ContactsView'
import DestinationsView from './DestinationsView'

interface Props {
  customer: Customer
  buttonAnimationValue: Animated.Value
}

export type TabType = 'contacts' | 'destinations' | 'infobox'

const CustomerDetailsSmallDeviceView = ({ customer, buttonAnimationValue }: Props) => {
  const {
    item: files,
    loadItem: loadFiles,
    loading: loadingFiles,
  } = useControlledLoader(api.getInfoboxFiles, {
    id: REQUEST_INFOBOX_FILES,
  })

  const contactViewRef = createRef<ContactViewRef>()
  const destinationViewRef = createRef<DestinationViewRef>()

  const alert = useAlert()
  const { detail } = useScreen()
  const { i18n } = useLanguage()
  const { isOnline } = useDataProvider()
  const { height } = useWindowDimensions()
  const { isSmallDevice } = useDimensions()
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { sessionKey } = useAuthentication()
  const navigation = useNavigation<NavigationProp<CustomerStackParamList>>()

  const [tab, setTab] = useRecoilState(selectedCustomerDetailTabAtom(sessionKey))

  const mapShown = detail.open === false && customer.mapLat !== 0 && customer.mapLong !== 0
  const top = mapShown ? undefined : 0
  const fixedHeight = Math.floor(height * 0.65)
  const mapHeight = mapShown ? fixedHeight : '100%'

  useEvent({ key: REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadFiles({ infoboxTyp: 'Customer', id: customer.id })

  return (
    <>
      <IM.View style={IMLayout.flex.f1}>
        {customer.mapLat !== 0 && customer.mapLong !== 0 && (
          <Map
            items={[customer]}
            mapMarkerRenderItem={MapMarker}
            mapPadding={{ bottom: fixedHeight, top: 0, left: 0, right: 0 }}
            hideMyLocationButton
            hideCenterButton
            disableMapTouch
            delta={0.002}
            onMapPress={() => utils.openMaps(customer.mapLat, customer.mapLong, alert, i18n.t('OPEN_MAP_LINK_ERROR'))}
            style={Platform.OS === 'web' && { marginBottom: fixedHeight }}
          />
        )}
        <IM.View style={[styles.screen, { height: mapHeight, top: top }, Platform.OS !== 'web' && ScreenCardsHeaderShadows]}>
          <IM.View style={[styles.screenBorder, IMLayout.shadow, IMLayout.flex.f1, { backgroundColor: theme.tabNavigator.background }]}>
            <ContactInformation customer={customer} />
            <IM.View
              style={[
                IMLayout.flex.f1,
                styles.screenBorder,
                {
                  backgroundColor: theme.background,
                },
                ScreenCardsHeaderShadows,
              ]}>
              <IM.View spacing="horizontal">
                <TabSelector selectionColor={Color(theme.header.main.background).toString()} style={styles.tabHeight}>
                  <TabSelector.Item
                    icon={['fal', 'address-book']}
                    iconSize={18}
                    text={i18n.t('CONTACTS')}
                    selected={tab === 'contacts'}
                    onPress={() => setTab('contacts')}
                  />
                  <TabSelector.Item
                    icon={['fal', 'location-dot']}
                    iconSize={18}
                    text={i18n.t('DESTINATIONS')}
                    selected={tab === 'destinations'}
                    onPress={() => setTab('destinations')}
                    disableDivider
                  />
                </TabSelector>
              </IM.View>
              <ContactsView
                ref={contactViewRef}
                customerAddressId={customer.addressId}
                hideSectionTitle
                show={tab === 'contacts'}
                onRefresh={refresh}
              />
              <DestinationsView
                ref={destinationViewRef}
                customerId={customer.id}
                hideSectionTitle
                show={tab === 'destinations'}
                onRefresh={refresh}
              />
            </IM.View>
          </IM.View>
        </IM.View>
      </IM.View>
      {isOnline && (
        <AnimatedButton
          id={ADD_CONTACT_DESTINATION_BUTTON_ID}
          value={buttonAnimationValue}
          icon={['fal', 'plus']}
          disabled={!userSettings?.allowContactCreation}
          onPress={() => {
            if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
              tab === 'contacts'
                ? navigation.navigate('CustomerDetailContactEditOrCreate', { addressId: customer.addressId })
                : navigation.navigate('CustomerDetailDestinationEditOrCreate', { customerId: customer.id })
            } else {
              tab === 'contacts' ? contactViewRef.current?.openModal() : destinationViewRef.current?.openModal()
            }
          }}
        />
      )}
      <InfoboxButton
        id={ADD_CONTACT_DESTINATION_BUTTON_ID}
        value={buttonAnimationValue}
        loading={loadingFiles}
        disabled={!userSettings?.allowShippingAddressCreation}
        onPress={() =>
          files &&
          navigation.navigate('CustomerDetailInfoboxStack', {
            screen: 'InfoboxFolders',
            params: { id: customer.id, type: 'Customer', subTitle: customer.description },
          })
        }
        bottom={isOnline ? CONSTANTS.secondButtonBottom : undefined}
        text={files?.length.toString() ?? 'NaN'}
      />
    </>
  )
}

function MapMarker() {
  return <IM.Icon icon={['fas', 'location-dot']} color={IMStyle.palette.red} size={30} />
}

const styles = StyleSheet.create({
  openingHours: { padding: 0 },
  openingHoursContainer: { flexDirection: 'row', alignItems: 'center' },
  openingHoursIcon: { paddingLeft: 12, paddingRight: 10 },
  screen: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  screenBorder: {
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
  view: {
    justifyContent: 'center',
  },
  tabHeight: { height: 36 },
})

export default CustomerDetailsSmallDeviceView
