import { useEvent } from '@infominds/react-native-components'
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { ACTIVITY_CLOSE_ACT_ID_EVENT_KEY } from '../constants/EmitterKeys'
import { ActivityToClose, CloseActivityEvent, ClosingDto } from '../types'

interface ContextProps {
  activities: ActivityToClose[]
  getActivity: (id: string) => ActivityToClose | undefined
  setActivity: (id: string) => void
}

export const CloseActivityIdContext = createContext<ContextProps | undefined>(undefined)

interface ProviderProps {
  baseActivityId: string
}

export const CloseActivityIdProvider = ({ children, baseActivityId }: PropsWithChildren & ProviderProps) => {
  const [activityIds, setActivityIds] = useState<ActivityToClose[]>([{ id: baseActivityId, mandatory: true }])

  const { emit } = useEvent<CloseActivityEvent>({ key: ACTIVITY_CLOSE_ACT_ID_EVENT_KEY })

  useEffect(() => {
    const event: CloseActivityEvent<Pick<ClosingDto, 'activityIds'>> = {
      done: true,
      pending: baseActivityId ? activityIds.length !== 1 : activityIds.length !== 0,
      dto: {
        activityIds,
      },
    }

    emit(event)
  }, [activityIds])

  const getActivity = useCallback(
    (id: string) => {
      return activityIds.find(el => el.id === id)
    },
    [activityIds]
  )

  const setActivity = useCallback(
    (id: string) => {
      if (getActivity(id) === undefined) {
        setActivityIds(prev => [...prev, { id, mandatory: false }])
      } else {
        setActivityIds(prev => prev.filter(el => el.id !== id))
      }
    },
    [getActivity]
  )

  const contextValue: ContextProps = useMemo(
    () => ({
      activities: activityIds,
      getActivity,
      setActivity,
    }),
    [activityIds]
  )

  return <CloseActivityIdContext.Provider value={contextValue}>{children}</CloseActivityIdContext.Provider>
}
