import { useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Platform } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import useLayout from '../../hooks/useLayout'
import useSparePartsFilter from '../../hooks/useSparePartsFilter'
import { CommonStackParamList, CommonStackScreenProps } from '../../navigation/types'
import { ModalScreenProps, ModalScreenRef } from '../../types'
import SparePartsFilterView, { SparePartsFilterViewRef } from '../../views/common/SparePartsFilterView'

const SparePartsFilterScreen = (
  { controller }: Omit<CommonStackScreenProps<'SparePartsFilter'>, 'navigation'> & ModalScreenProps<CommonStackParamList['SparePartsFilter']>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: () => handleGoBack(),
  }))

  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { isSmallDevice } = useLayout()
  const { filters, orders, setFilters, setOrders } = useSparePartsFilter()

  const viewRef = createRef<SparePartsFilterViewRef>()

  const handleGoBack = () => {
    if (viewRef.current) {
      setFilters(viewRef.current.getState().filters)
      setOrders(viewRef.current.getState().orders)
    }

    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      navigation.goBack()
    } else {
      controller?.close()
    }
  }

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack} isModal={isModal} title={i18n.t('FILTERS')} />
      <Screen.Content>
        <SparePartsFilterView ref={viewRef} filters={filters} orders={orders} />
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(SparePartsFilterScreen)
