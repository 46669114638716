import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { TicketRegistry } from '../../apis/types/apiResponseTypes'
import { REQUEST_REGISTRY } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: string
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: TicketRegistry | undefined) => void
  onDefaultFound: (id: string) => void
}

export default function RegistrySelector({ id, onChange, onDefaultFound, ...props }: Props) {
  const { i18n } = useLanguage()

  const [selectedId, setSelectedId] = useState(id)
  const [search, setSearch] = useState('')

  const { item: data, loadItem, loading } = useControlledLoader(api.getTicketRegistry, { id: REQUEST_REGISTRY })

  useEffect(() => {
    loadItem({})
  }, [])

  useEffect(() => {
    if (id) return

    if (loading === false && data && data.length === 1) {
      setSelectedId(data[0].id)
      onDefaultFound(data[0].id)
    }
  }, [id, data, loading])

  const render = ({ item }: SectionListRenderItemInfo<TicketRegistry, ListSection<TicketRegistry>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.id === selectedId} text={`${item.code} - ${item.description}`} />
  }

  const handleOnChange = (newValue: TicketRegistry | undefined) => {
    setSelectedId(newValue?.id)
    onChange(newValue)
  }

  return (
    <SelectInput
      data={appUtils.filter(data ? data : [], search, ['code', 'description'])}
      value={data?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={() => loadItem({})}
      title={i18n.t('REGISTRY') + ' *'}
      screenTitle={i18n.t('REGISTRIES')}
      noDataMessage={i18n.t('NO_REGISTRY_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => `${item.code} - ${item.description}`}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableReset
      disableLoadAfterMount
      {...props}
    />
  )
}
