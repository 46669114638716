import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { ListSection, QualityCharacteristicDetails } from '../../types'
import appUtils from '../../utils/appUtils'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  data: QualityCharacteristicDetails[]
  screenTitle: string
  id?: string
  editable?: boolean
  spacing?: SpacingProps
  alignRight?: boolean
  onChange: (value: QualityCharacteristicDetails | undefined) => void
}

export default function QualityCheckSelector({ id, alignRight, data, editable, screenTitle, onChange }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')

  const getDescription = (item: QualityCharacteristicDetails) => `${item.description} (${item.code})`

  const handleOnChange = (newValue: QualityCharacteristicDetails | undefined) => {
    onChange(newValue)
  }

  const render = (
    { item }: SectionListRenderItemInfo<QualityCharacteristicDetails, ListSection<QualityCharacteristicDetails>>,
    onPress?: () => void
  ) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.id === id} text={getDescription(item)} />
  }

  return (
    <SelectInput
      data={appUtils.filter(data, search, ['code', 'description'])}
      value={data.find(elem => elem.id === id)}
      onSearchChange={setSearch}
      screenTitle={screenTitle}
      noDataMessage={i18n.t('NO_ELEMENT_FOUND')}
      editable={editable}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={getDescription}
      deferredTimeout={0}
      disableLoadAfterMount
      loading={false}
      enableBackdropOpacity
      fastInputAlignRight={alignRight}
    />
  )
}
