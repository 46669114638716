import { useEvent, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import api, { getApi } from '../../apis/apiCalls'
import { PutAddFieldsRequest } from '../../apis/types/apiRequestTypes'
import { AdditionalFieldResponse } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import AdditionalFieldDefinitionInput from '../../components/input/AdditionalFieldDefinitionInput'
import NoEntry from '../../components/NoEntry'
import ScrollViewForm from '../../components/ScrollViewForm'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import { AddFieldValue, AdditionalField, AdditionalFieldType, EditOrCreateViewProps, EditOrCreateViewRef, UploadStatus } from '../../types'
import appUtils from '../../utils/appUtils'
import { numberUtils } from '../../utils/numberUtils'

type Props = EditOrCreateViewProps & {
  id: string
  type: AdditionalFieldType
  addFields: AdditionalField[]
}

const EditAddFieldsView = ({ id, type, addFields, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const initialData = useRef(addFields)

  const { i18n, language } = useLanguage()
  const { client } = useDataProvider()
  const [state, setState] = useState(addFields)
  const [fieldError, setFieldError] = useState<boolean[]>(Array(addFields.length).fill(false))
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  function editClassification(body: PutAddFieldsRequest, abortController?: AbortController | undefined) {
    if (type === 'Ticket') return api.editTicketAddFields(body as Partial<AdditionalFieldResponse>, abortController)
    if (type === 'Activity') return api.editActivityAddFields(body as Partial<AdditionalFieldResponse>, abortController)
    return getApi(client).editAddFields(body, abortController)
  }

  const { request: edit, loading: loadingEdit } = useRequest(editClassification)
  const { emit } = useEvent({
    key: appUtils.addFieldChangeEvent(type),
  })

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'

    if (!fieldError.every(el => !el)) {
      uploadStatus = 'mandatoryMissing'
    } else {
      if (!isEqual(initialData.current, state)) {
        uploadStatus = 'waiting'
      }
    }

    updateUploadStatus(uploadStatus)
  }, [state, fieldError])

  useEffect(() => {
    if (loadingEdit === 'catched') {
      updateUploadStatus('waiting')
      return
    }

    const loadingDone = loadingEdit === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      emit()
    }
  }, [loadingEdit])

  const isEqual = (a: AdditionalField[], b: AdditionalField[]) => {
    let toRet = true

    a.forEach(aEl => {
      b.forEach(bEl => {
        if (aEl.definitionId === bEl.definitionId) {
          if (appUtils.processAdditionFieldDtoValue(aEl, language) !== appUtils.processAdditionFieldDtoValue(bEl, language)) {
            toRet = false
          }
        }
      })
    })

    return toRet
  }

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const prepareForUpload = (obj: AdditionalField[]) => {
    const clone = cloneDeep(obj)

    const toRet: PutAddFieldsRequest = {
      fkId: id,
      additionalfields: [],
    }

    clone.forEach(el => {
      return toRet.additionalfields.push({
        id: el.id,
        definitionId: el.id === undefined ? el.definitionId : undefined,
        value:
          el.definitionFeldType === 'Numeric'
            ? numberUtils.convertLocaleStringToIsoString(appUtils.processAdditionFieldDtoValue(el, language), language)
            : appUtils.processAdditionFieldDtoValue(el, language),
      })
    })

    console.log('🚀 ~ prepareForUpload ~ toRet:', JSON.stringify(toRet, undefined, 2))
    return toRet
  }

  const upload = () => {
    updateUploadStatus('uploading')

    edit(prepareForUpload(state))
  }

  const handleChange = (val: AddFieldValue) => {
    if (waitingUpload === 'uploading') return

    setState(prev => {
      const toRet = prev.map(elm => {
        if (elm.definitionId === val.definitionId) {
          return { ...elm, ...appUtils.createAdditionFieldValue(elm.definitionFeldType, val.value, language) }
        } else {
          return elm
        }
      })

      return toRet
    })
  }

  return (
    <ScrollViewForm>
      {addFields.length === 0 && <NoEntry description={i18n.t('NO_ADDITIONAL_FIELD')} />}
      {addFields.map((el, index) => (
        <AdditionalFieldDefinitionInput
          key={el.definitionId}
          definition={{
            code: el.definitionCode,
            description: el.definitionDescription,
            fieldType: el.definitionFeldType,
            isMandatory: el.definitionIsMandatory,
            type: type,
            id: el.definitionId,
            fieldDecimal: el.definitionFieldDecimal,
            fieldLength: el.definitionFieldLength,
          }}
          value={appUtils.processAdditionFieldDtoValue(el, language)}
          spacing="vertical"
          onChange={handleChange}
          onError={value => {
            const clone = cloneDeep(fieldError)
            if (clone[index] !== value) {
              clone[index] = value
              setFieldError(clone)
            }
          }}
        />
      ))}
    </ScrollViewForm>
  )
}

export default forwardRef(EditAddFieldsView)
