import React, { createRef } from 'react'

import { TravelTime } from '../apis/types/apiResponseTypes'
import useTravelTimeManager from '../hooks/useTravelTimeManager'
import useUserSettings from '../hooks/useUserSettings'
import { TimeButtonRef } from '../types'
import TimeButton, { TimeButtonProps } from './TimeButton'

const TravelTimeButton = ({ ...props }: Omit<TimeButtonProps, 'onStart' | 'onStop' | 'loading'>) => {
  const { userSettings } = useUserSettings()
  const timeButtonRef = createRef<TimeButtonRef>()

  const { start, stop, type, time, activityTimes } = useTravelTimeManager(timeButtonRef)

  const timeLoading = !(type === 'stopped' || type === 'started' || type === 'init')

  if (userSettings?.isEmployeeTravelTimeActive === false) return <></>

  return (
    <TimeButton
      ref={timeButtonRef}
      loading={timeLoading ? 'reloading' : false}
      disabled={false}
      time={!timeLoading && type === 'started' ? (time as TravelTime) : undefined}
      onStart={start}
      onStop={stop}
      badgeIcon={activityTimes && activityTimes.length !== 0 ? ['far', 'stopwatch'] : undefined}
      {...props}
    />
  )
}

export default TravelTimeButton
