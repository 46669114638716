import { IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import KeyboardAware from '../../components/KeyboardAware'
import ScrollViewData from '../../components/ScrollViewData'
import { TICKET_CLOSE_ACTIVITY_ID } from '../../constants/ButtonIds'
import { NextActivityProvider } from '../../contexts/NextActivityContext'
import { ReportProvider } from '../../contexts/ReportContext'
import { SignatureContext, SignatureProvider } from '../../contexts/SignatureContext'
import useUserSettings from '../../hooks/useUserSettings'
import TicketCloseActivityStep1View from '../ticket/closeActivitySteps/TicketCloseActivityStep1View'

export interface CloseActivityViewProps {
  activityId: string
  customerId: string
  shippingAddressId?: string
  addressId: string
}

interface Props {
  initialContactName?: string
  initialReportEmail?: string
}

export default function ActivityCloseView({ initialContactName, initialReportEmail, ...others }: CloseActivityViewProps & Props) {
  const { userSettings } = useUserSettings()

  if (userSettings === undefined) return <></>

  return (
    <KeyboardAware>
      <ReportProvider initial={initialReportEmail}>
        <SignatureProvider contact={initialContactName ? { id: initialContactName } : undefined}>
          <NextActivityProvider initialEmployeeId={userSettings.employeeId}>
            <SignatureContext.Consumer>
              {signatureContext => (
                <ScrollViewData
                  buttonId={TICKET_CLOSE_ACTIVITY_ID}
                  scrollEnabled={signatureContext?.scrollEnabled}
                  contentContainerStyle={styles.container}
                  overScrollMode="never"
                  keyboardShouldPersistTaps="handled"
                  disableHideKeyboardOnScroll
                  bounces={false}>
                  <TicketCloseActivityStep1View {...others} />
                </ScrollViewData>
              )}
            </SignatureContext.Consumer>
          </NextActivityProvider>
        </SignatureProvider>
      </ReportProvider>
    </KeyboardAware>
  )
}
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    padding: 0,
    paddingHorizontal: 2 * IMLayout.horizontalMargin,
  },
})
