import { IM, IMLayout, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo, useEffect, useRef, useState } from 'react'
import { ScrollView, StyleSheet, TextStyle } from 'react-native'

import CONSTANTS from '../../../constants/Constants'
import ScreenSelectModal, { ScreenSelectModalProps } from '../../../modals/ScreenSelectModal'
import { FiniteLoadList, InfiniteLoadList } from '../../../types'
import BaseTextInputTitle from '../../input/baseTextInput/BaseTextInputTitle'
import SelectTextInput from '../../input/SelectTextInput'
import SkeletonText from '../../skeleton/SkeletonText'
import Tag from '../../Tag'
import Text from '../../Text'

type Props<T> = (FiniteLoadList | InfiniteLoadList) &
  ScreenSelectModalProps<T> & {
    value?: T
    title?: string
    editable?: boolean
    spacing?: SpacingProps
    disableReset?: boolean
    error?: boolean
    titleFontWeight?: TextStyle['fontWeight']
    disableFastInput?: boolean
    fastInputEntries?: number
    fastInputAlignRight?: boolean
    disableFastInputScrollView?: boolean
    showNoneButton?: boolean
    renderSelectedString: (item: T) => string
  }

const MARGIN_SHADOW = 4

function SelectInput<T extends { id: string }>({
  value,
  title,
  spacing,
  error,
  titleFontWeight,
  editable = true,
  disableReset = false,
  disableFastInput = false,
  disableFastInputScrollView,
  data,
  fastInputEntries,
  showNoneButton = false,
  loading,
  enableBackdropOpacity = false,
  fastInputAlignRight = false,
  noDataMessage,
  ...other
}: Props<T>) {
  const { onChange, renderSelectedString, onSearchChange } = other

  const { i18n } = useLanguage()
  const [width, setWidth] = useState(0)
  const [contentWidth, setContentWidth] = useState(0)
  const [isVisible, setIsVisible] = useState(false)
  const [fastInputDisabled, setFastInputDisabled] = useState<boolean | undefined>(undefined)
  const isMandatory = data.find(el => el.id === CONSTANTS.noSelectionId) === undefined
  const flexWrap = disableFastInputScrollView ? 'wrap' : 'nowrap'
  const searching = useRef(false)

  const handlePress = (el: T) => {
    !isMandatory && value?.id === el.id ? (showNoneButton ? undefined : onChange(undefined)) : onChange(el)
  }

  useEffect(() => {
    if (!searching.current && loading === false) {
      setFastInputDisabled(disableFastInput || data.length > (fastInputEntries ?? CONSTANTS.minDataLengthToHaveInputBox))
    }
  }, [loading, data])

  const handleSearchChange = (val: string) => {
    searching.current = val !== ''
    onSearchChange?.(val)
  }

  const showNoDataMessage = data.length === 0 && loading === false

  return (
    <>
      {fastInputDisabled ? (
        <>
          <SelectTextInput
            title={title}
            titleFontWeight={titleFontWeight}
            value={value ? renderSelectedString(value) : ''}
            placeholder={loading === false || loading === 'init' ? i18n.t('NO_SELECTION') : i18n.t('LOADING_PLACEHOLDER')}
            onPress={() => setIsVisible(true)}
            onReset={() => onChange(undefined)}
            disableReset={disableReset}
            editable={loading === false ? editable : false}
            spacing={spacing}
            error={error}
            showNoDataMessage={showNoDataMessage}
            noDataMessage={noDataMessage}
          />
          <ScreenSelectModal
            data={data}
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            loading={loading}
            enableBackdropOpacity={enableBackdropOpacity}
            noDataMessage={noDataMessage}
            {...other}
            onSearchChange={handleSearchChange}
          />
        </>
      ) : (
        <IM.View
          spacing={spacing}
          style={[fastInputAlignRight && styles.alignRight, { marginBottom: -MARGIN_SHADOW, marginLeft: -MARGIN_SHADOW }]}
          onLayout={ev => setWidth(ev.nativeEvent.layout.width)}>
          {title && (
            <IM.View style={{ marginLeft: MARGIN_SHADOW }}>
              <BaseTextInputTitle title={title} fontWeight={titleFontWeight} />
              {showNoDataMessage && <Text secondary>{noDataMessage}</Text>}
            </IM.View>
          )}
          {fastInputDisabled !== undefined ? (
            <ScrollView
              horizontal={!disableFastInputScrollView}
              scrollEnabled={disableFastInputScrollView ? false : contentWidth >= width}
              contentContainerStyle={[IMLayout.flex.row, { paddingBottom: MARGIN_SHADOW, paddingLeft: MARGIN_SHADOW, flexWrap }]}
              onContentSizeChange={w => !disableFastInputScrollView && setContentWidth(w)}>
              {data.map(el => {
                if (!showNoneButton && el.id === CONSTANTS.noSelectionId) return <IM.View key={el.id} />
                if (!editable && el.id !== value?.id) return <IM.View key={el.id} />

                return (
                  <Tag
                    key={el.id}
                    name={other.renderSelectedString(el)}
                    active={!editable ? false : value?.id === el.id}
                    id={el.id}
                    onPress={() => handlePress(el)}
                    style={styles.tag}
                    disabled={!editable}
                  />
                )
              })}
            </ScrollView>
          ) : (
            <SkeletonText width="100%" height={30} style={[styles.loading, { marginLeft: MARGIN_SHADOW }]} />
          )}
        </IM.View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  loading: { paddingVertical: 4, paddingBottom: 13 },
  tag: { marginVertical: 4, marginHorizontal: 3 },
  alignRight: { alignItems: 'flex-end' },
})

export default memo(SelectInput) as typeof SelectInput
