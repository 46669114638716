import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { REQUEST_CUSTOMER_CONTACTS } from '../../constants/Keys'
import { ActivityEmail } from '../../types'
import EmployeeBadge from '../EmployeeBadge'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import Pressable from '../Infominds/Pressable'
import Text from '../Text'
import BaseInputSelector, { BaseInputSelectorProps } from './baseInputSelector/BaseInputSelector'

interface Props {
  activityId: string
}

export default function EmailInputSelector({ activityId, ...others }: Omit<BaseInputSelectorProps<ActivityEmail>, 'type' | 'onTestInput'> & Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState<string | undefined>('')

  const { item: emails, loadItem: loadEmails, loading: loadingEmails } = useControlledLoader(api.getActivityEmail, { id: REQUEST_CUSTOMER_CONTACTS })

  useEffect(() => {
    loadEmails({ activityId })
  }, [activityId])

  const renderItem = (item: ActivityEmail, isFirst: boolean, onPressCallback: (id: string, value: string) => void) => {
    return (
      <Pressable
        key={item.email}
        style={[IMLayout.flex.row, styles.pressable]}
        spacing={isFirst ? 'none' : 'none'}
        onPress={() => onPressCallback(item.email, item.email)}>
        <EmployeeBadge showName={false} id={item.employeeId} name={item.description} />
        <IM.View spacing="horizontal" style={IMLayout.flex.f1}>
          <Text numberOfLines={1}>{item.description}</Text>
          <Text numberOfLines={1}>{item.email}</Text>
          {item.employeeFunction && (
            <Text secondary numberOfLines={1}>
              {item.employeeFunction}
            </Text>
          )}
        </IM.View>
      </Pressable>
    )
  }

  const onTestInput = (text: string) => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w\w+)+$/

    return reg.test(text)
  }

  return (
    <BaseInputSelector
      {...others}
      title={i18n.t('EMAIL') + (others.editable ? ' *' : '')}
      type="email"
      onTestInput={onTestInput}
      searchLoading={loadingEmails}
      searchResult={
        search
          ? emails?.emails.filter(el => el.email.toLowerCase().includes(search) || el.description.toLowerCase().includes(search))
          : emails?.emails
            ? emails?.emails
            : []
      }
      onSearchStart={setSearch}
      renderSearchItem={renderItem}
    />
  )
}

const styles = StyleSheet.create({
  pressable: { alignItems: 'center', justifyContent: 'flex-start' },
})
