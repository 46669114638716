import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { DimensionValue, StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'

interface Props {
  width: DimensionValue
  color?: string
}

export default function LoaderBar({ color, width }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View
      style={[
        styles.loader,
        {
          backgroundColor: theme.loader.background,
          borderColor: theme.loader.border,
        },
      ]}>
      <IM.View
        style={[
          styles.loader,
          styles.innerLoader,
          {
            width: width,
            backgroundColor: color ?? theme.general.info,
          },
        ]}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  loader: {
    flex: 1,
    height: 10,
    borderRadius: IMLayout.borderRadius,
    borderWidth: 1,
  },
  innerLoader: {
    borderWidth: 0,
  },
})
