import { useFocusEffect } from '@react-navigation/native'
import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'

import { utils } from '../utils/utils'

interface Props {
  key: string | string[] | undefined
  cb: (key: string) => void
}

const ids: string[] = []

export default function useKeyPress({ key, cb }: Props, deps?: DependencyList) {
  const id = useRef(utils.generateUuid())
  const [focused, setFocused] = useState(false)

  useEffect(() => {
    ids.push(id.current)

    return () => {
      ids.pop()
    }
  }, [])

  useFocusEffect(
    useCallback(() => {
      setFocused(true)

      return () => {
        setFocused(false)
      }
    }, [deps])
  )

  useEffect(() => {
    if (!focused || key === undefined) return

    window.addEventListener('keydown', keyPress)

    return () => {
      key !== undefined && window.removeEventListener('keydown', keyPress)
    }
  }, [key, deps, focused])

  const keyPress = useCallback(
    (e: KeyboardEvent) => {
      const lastId = ids[ids.length - 1]

      if (lastId === id.current && Array.isArray(key) ? key.find(el => e.key === el) !== undefined : e.key === key) {
        cb(e.key)
      }
    },
    [key]
  )

  return false
}
