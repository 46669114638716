import { IM, SpacingProps, useAlert, useDimensions, useLanguage } from '@infominds/react-native-components'
import React, { memo, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import SignatureInput from '../../components/input/SignatureInput'
import ContactInputSelector from '../../components/inputSelector/ContactInputSelector'
import useSignature from '../../hooks/useSignature'
import useUserSettings from '../../hooks/useUserSettings'

type Props = {
  spacing?: SpacingProps
  initialContactName?: string
  addressId: string
  handleChangeContact: (val: string | undefined) => void
  handleChangeSignature: (val: string | undefined, signaturePenColor?: string) => void
}

const ActivitySignatureCard = memo(function ActivitySignatureCard({
  spacing,
  initialContactName,
  addressId,
  handleChangeContact,
  handleChangeSignature,
}: Props) {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { signature } = useSignature()
  const { isSmallDevice } = useDimensions()
  const { userSettings } = useUserSettings()

  const [width, setWidth] = useState<number | undefined>(undefined)

  return (
    <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('SIGNATURE')} />}>
      <IM.View onLayout={e => setWidth(e.nativeEvent.layout.width)}>
        <>
          <ContactInputSelector
            onDataEntered={val => handleChangeContact(val.length === 0 ? undefined : val[0])}
            value={initialContactName}
            mandatory={userSettings?.mandatoryInputContactOnClosingTheActivityWithSignature ? signature !== undefined : false}
            addressId={addressId}
          />
          <IM.View style={styles.container}>
            <SignatureInput
              title={i18n.t('SIGNATURE')}
              onOk={(newSignature, signaturePenColor) => {
                const split = newSignature.split('base64,')

                if (split.length === 2) {
                  handleChangeSignature(split[1], signaturePenColor)
                } else {
                  alert(i18n.t('ERROR'), `Signature split error. Length: ${split.length}`)
                }
              }}
              onCancel={() => handleChangeSignature(undefined)}
              width={width}
              height={isSmallDevice ? (width ?? 0) * (Platform.OS === 'ios' ? 0.6 : 1.5) : (width ?? 0) * 0.3}
              spacing="top"
            />
          </IM.View>
        </>
      </IM.View>
    </IM.Card>
  )
})

export default ActivitySignatureCard

const styles = StyleSheet.create({
  container: {
    zIndex: -1,
  },
})
