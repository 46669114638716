import { AppEnv } from './AppEnv'

const prodDemo = {
  DEMO_USERNAME: 'Radix-Demo',
  DEMO_PASSWORD: '123',
  DEMO_LICENSE_KEY: 'APP-RxPlusSM-Demo-0JO',
}

const devDemo = {
  DEMO_USERNAME: 'IPAD_SU',
  DEMO_PASSWORD: 'demo',
  DEMO_LICENSE_KEY: 'APP-RxPlusService-Dev',
}

const devDemoVault = {
  DEMO_USERNAME: 'ADMINLUKASM',
  DEMO_PASSWORD: '123',
  DEMO_LICENSE_KEY: 'APP-RX+SM_001',
}

export default __DEV__ ? (AppEnv.VAULT_DEMO ? devDemoVault : devDemo) : prodDemo
