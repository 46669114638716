import { useEvent, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Platform } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { ACCESS_DATA_FILTER_CHANGE_EVENT_KEY } from '../../constants/EmitterKeys'
import useLayout from '../../hooks/useLayout'
import { AccessDataStackParamList, AccessDataStackScreenProps } from '../../navigation/types'
import { AccessDataFilterEmitter, ModalScreenProps, ModalScreenRef } from '../../types'
import AccessDataFilterView, { AccessDataFilterViewRef } from '../../views/accessData/AccessDataFilterView'

const AccessDataFilterScreen = (
  {
    route,
    controller,
  }: Omit<AccessDataStackScreenProps<'AccessDataFilter'>, 'navigation'> & ModalScreenProps<AccessDataStackParamList['AccessDataFilter']>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: () => handleGoBack(),
  }))

  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { isSmallDevice } = useLayout()
  const emitter = useEvent<AccessDataFilterEmitter>({ key: ACCESS_DATA_FILTER_CHANGE_EVENT_KEY })

  const accessDataFilterRef = createRef<AccessDataFilterViewRef>()

  const handleGoBack = () => {
    accessDataFilterRef.current &&
      emitter.emit({ filters: accessDataFilterRef.current.getState().filters, permissions: accessDataFilterRef.current.getState().permissions })

    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      navigation.goBack()
    } else {
      controller?.close()
    }
  }

  const isModal = controller !== undefined

  return (
    <Screen transparent={isModal}>
      <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack} isModal={isModal} title={i18n.t('FILTERS')} />
      <Screen.Content>
        <AccessDataFilterView ref={accessDataFilterRef} filters={route.params.filters} permissions={route.params.permissions} />
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(AccessDataFilterScreen)
