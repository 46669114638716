import { useEffect, useState } from 'react'

import api from '../apis/apiCalls'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'
import { REQUEST_ADDITIONAL_FIELDS, REQUEST_CLASSIFICATION, REQUEST_QUALITY, REQUEST_SERIAL_NUMBER_COUNTERS } from '../constants/Keys'
import { AdditionalField, AdditionalFieldType } from '../types'
import qualityUtils from '../utils/QualityUtils'
import ticketUtils from '../utils/TicketUtils'
import useUserSettings from './useUserSettings'

export type AdditionalFieldValidationType = AdditionalFieldType | 'TicketClassifications' | 'Quality' | 'Counters'

type Type = 'init' | 'checking' | 'done' | 'failed'

type AdditionalFieldValidation = { result: boolean; type: AdditionalFieldValidationType }

export default function useValidateFields(activityId: string, documentId: string, ticketId: string) {
  const { userSettings } = useUserSettings()

  const [type, setType] = useState<Type>('init')
  const [result, setResult] = useState<AdditionalFieldValidation[]>([])

  const {
    loadItem: loadActivityItem,
    item: activityItem,
    loading,
  } = useControlledLoader(api.getActivityAddFields, { id: REQUEST_ADDITIONAL_FIELDS + 'Activity' })
  const {
    loadItem: loadTicketItem,
    item: ticketItem,
    loading: loadingTicket,
  } = useControlledLoader(api.getTicketAddFields, { id: REQUEST_ADDITIONAL_FIELDS + 'Ticket' })
  const {
    loadItem: loadClassifications,
    item: classifications,
    loading: loadingClassifications,
  } = useControlledLoader(api.getTicketClassification, { id: REQUEST_CLASSIFICATION })
  const {
    item: quality,
    loadItem: loadQuality,
    loading: loadingQuality,
  } = useControlledLoader(api.getQuality, {
    id: REQUEST_QUALITY,
  })
  const {
    item: counters,
    loadItem: loadCounter,
    loading: loadingCounters,
  } = useControlledLoader(api.getSerialNumberCounter, { id: REQUEST_SERIAL_NUMBER_COUNTERS })

  useEffect(() => {
    if (
      loading === 'catched' ||
      loading === 'aborted' ||
      loadingTicket === 'catched' ||
      loadingTicket === 'aborted' ||
      loadingClassifications === 'catched' ||
      loadingClassifications === 'aborted' ||
      loadingQuality === 'catched' ||
      loadingQuality === 'aborted' ||
      loadingCounters === 'catched' ||
      loadingCounters === 'aborted'
    ) {
      setType('failed')
      setResult([])
      return
    }

    if (
      loading === false &&
      loadingTicket === false &&
      loadingClassifications === false &&
      loadingQuality === false &&
      loadingCounters === false &&
      !!activityItem &&
      !!ticketItem &&
      !!classifications &&
      !!quality &&
      !!counters
    ) {
      const results: AdditionalFieldValidation[] = []

      if (activityItem.length && activityItem[0].additionalfields) {
        results.push({ result: validate(activityItem[0].additionalfields), type: 'Activity' })
      }

      if (ticketItem.length && ticketItem[0].additionalfields) {
        results.push({ result: validate(ticketItem[0].additionalfields), type: 'Ticket' })
      }

      if (classifications.classifications) {
        const mandatoryPresence: boolean[] = []
        classifications.classifications.forEach(el => {
          if (el.definitionIsMandatory) {
            mandatoryPresence.push(el.classificationId !== undefined)
          }
        })

        results.push({
          result: mandatoryPresence.length === 0 || mandatoryPresence.every(el => el === true) ? true : false,
          type: 'TicketClassifications',
        })
      }

      results.push({
        result: qualityUtils.closingQualityCheck(quality),
        type: 'Quality',
      })

      results.push({
        result: ticketUtils.closingCountersCheck(counters, userSettings) ?? true,
        type: 'Counters',
      })

      setResult(results)
      setType('done')
    }
  }, [loading, loadingTicket, loadingClassifications, loadingQuality, loadingCounters, activityItem, ticketItem, classifications, quality, counters])

  const validate = (additionalfields: AdditionalField[]) => {
    const mandatoryPresence: boolean[] = []

    additionalfields.forEach(field => {
      if (field.definitionIsMandatory) {
        mandatoryPresence.push(
          field.logicValue !== undefined || field.dateValue !== undefined || field.numberValue !== undefined || field.stringValue !== undefined
        )
      }
    })

    return mandatoryPresence.length === 0 || mandatoryPresence.every(el => el === true) ? true : false
  }

  const startValidation = () => {
    setType('checking')
    loadActivityItem({ fkIds: [activityId] })
    loadTicketItem({ fkIds: [documentId] })
    loadClassifications({ fkIds: [ticketId] })
    loadQuality({ activityId })
    loadCounter({ ticketId })
  }

  return { validate: startValidation, type, result }
}
