import { IM, IMStyle, SpacingProps, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useEffect, useState } from 'react'
import { Keyboard, StyleSheet } from 'react-native'

import { Email, EmailType } from '../../apis/types/apiResponseTypes'
import BottomSheetModal from '../../modals/BottomSheetModal'
import { FastAction, LoadingType, NoFastAction, ThemeColorExpanded } from '../../types'
import Pressable from '../Infominds/Pressable'
import PressableIcon from '../Infominds/PressableIcon'
import RadioPressable from '../RadioPressable'
import SkeletonText from '../skeleton/SkeletonText'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'
import TextInput from './TextInput'

type Props = (FastAction | NoFastAction) &
  Omit<BaseTextInputProps, 'type' | 'placeholder' | 'disableBorder' | 'onChangeText' | 'onChange'> &
  BaseTextInputProviderProps & {
    index: number
    initialValue: Email
    spacing?: SpacingProps
    title?: string
    types?: EmailType[]
    typesLoading?: LoadingType
    typesDisabled?: string[]
    onRemoveLastNumber?: (index: number) => void
    onActionPress?: (index: number) => void
    onChange?: (index: number, value: Email) => void
  }

const EmailInput = memo(function EmailInput({
  index,
  initialValue,
  spacing,
  editable,
  error,
  loading,
  disableFocus,
  title,
  disableFastAction,
  types,
  typesLoading,
  typesDisabled,
  onRemoveLastNumber,
  onActionPress,
  onChange,
  ...textInputProps
}: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { theme } = useTheme<ThemeColorExpanded>()

  const [open, setOpen] = useState(false)
  const [type, setType] = useState(initialValue.type)
  const [address, setAddress] = useState(initialValue.address)
  const [description, setDescription] = useState(initialValue.description)
  const enableDescription = editable || (!editable && description !== '')
  const typeDescription = types?.find(elem => elem.type === initialValue.type)?.description

  useEffect(() => {
    onChange?.(index, { type, address: address ?? '', description: description ?? '' })
  }, [address, description, type])

  return (
    <IM.View spacing={spacing}>
      <BaseTextInputProvider editable={typesLoading === false && editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput
          {...textInputProps}
          placeholder={i18n.t('EMAIL')}
          value={address}
          disableBorderRadius={enableDescription ? 'bottom' : undefined}
          type="email"
          onChangeText={val => setAddress(val)}>
          {title && <BaseTextInput.Title title={title} />}
          {onActionPress === undefined && (
            <BaseTextInput.RightIcon disableBorder="bottom">
              {typesLoading === false ? (
                <>
                  <Pressable
                    onPress={() => {
                      Keyboard.dismiss()
                      setOpen(true)
                    }}>
                    <IM.Text secondary>{typeDescription ?? i18n.t('MISSING_DESCRIPTION')}</IM.Text>
                  </Pressable>
                  {address === '' && (
                    <PressableIcon icon={['fas', 'circle-minus']} size={20} color={IMStyle.palette.red} onPress={() => onRemoveLastNumber?.(index)} />
                  )}
                </>
              ) : (
                <BaseTextInputSpinningIcon loading={true} />
              )}
            </BaseTextInput.RightIcon>
          )}
          {address !== '' && onActionPress !== undefined && (
            <BaseTextInput.RightIcon disableBorder="bottom">
              {typesLoading === false ? (
                <IM.Text secondary style={styles.typeTest}>
                  {typeDescription ?? i18n.t('MISSING_DESCRIPTION')}
                </IM.Text>
              ) : (
                <SkeletonText />
              )}
              <PressableIcon
                icon={['fas', 'envelope']}
                size={20}
                color={theme.general.info}
                disabled={disableFastAction}
                onPress={() => onActionPress?.(index)}
              />
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>

      {enableDescription && (
        <TextInput
          placeholder={i18n.t('DESCRIPTION')}
          value={description ?? ''}
          disableBorderRadius="top"
          onChangeText={val => setDescription(val)}
          editable={typesLoading === false && editable}
        />
      )}

      {open && types && (
        <BottomSheetModal disableCentering disableBottomOffset={!isSmallDevice} onDismiss={() => setOpen(false)}>
          {types.map(elem => {
            return (
              <RadioPressable
                key={elem.type}
                onPress={() => {
                  setType(elem.type)
                  setOpen(false)
                }}
                selected={elem.type === type}
                disabled={typesDisabled?.find(disabled => disabled === elem.type) !== undefined && elem.type !== type}
                text={elem.description}
              />
            )
          })}
        </BottomSheetModal>
      )}
    </IM.View>
  )
})

export default EmailInput

const styles = StyleSheet.create({
  typeTest: {
    paddingRight: 6,
  },
})
