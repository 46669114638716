import { IM, IMLayout, SpacingProps, useDimensions, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { getApi } from '../../apis/apiCalls'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import CardButton from '../../components/CardButton'
import CellText from '../../components/CellText'
import DynamicView from '../../components/Infominds/DynamicView'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../components/Infominds/Pressable'
import Separator from '../../components/Infominds/Separator'
import SkeletonText from '../../components/skeleton/SkeletonText'
import TextTable from '../../components/TextTable'
import { EDIT_ACTIVITY_PURCHASE_ORDER_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ACTIVITY_PURCHASE_ORDER } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import ActivityPurchaseOrderModal from '../../modals/activity/ActivityPurchaseOrderModal'
import { ActivityStackParamList } from '../../navigation/types'
import { ActivityPurchaseOrderParams, ThemeColorExpanded } from '../../types'
import TimeUtils from '../../utils/TimeUtils'

type Props = {
  ticketId: string
  spacing?: SpacingProps
  onlyRead?: boolean
}

const firstColumn = IMLayout.flex.f1
const secondColumn = IMLayout.flex.f2
const thirdColumn = IMLayout.flex.f2
const fourthColumn = IMLayout.flex.f1
const fifthColumn = IMLayout.flex.f1
const sixthColumn = IMLayout.flex.f2

const ActivityOrderCard = memo(function ActivityOrderCard({ ticketId, spacing, onlyRead }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { client } = useDataProvider()
  const { theme } = useTheme<ThemeColorExpanded>()
  const controller = useModalController<ActivityPurchaseOrderParams>()
  const navigation = useNavigation<NavigationProp<ActivityStackParamList>>()
  const { item: order, loadItem: getOrder, loading } = useControlledLoader(getApi(client).getOrder, { id: REQUEST_ACTIVITY_PURCHASE_ORDER })

  useEvent({ key: EDIT_ACTIVITY_PURCHASE_ORDER_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => getOrder({ ticketId })

  const onPress = () =>
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('ActivityPurchaseOrder', {
          ticketId,
        })
      : controller.show({ ticketId })

  return (
    <>
      <IM.Card
        spacing={spacing}
        head={<CardLeftTitle text={i18n.t('PURCHASE_ORDER')} />}
        style={styles.container}
        noContentSpacing
        buttons={{
          buttons:
            isSmallDevice || onlyRead || order?.length !== 0
              ? undefined
              : [
                  {
                    icon: ['fal', 'plus'],
                    onPress: onPress,
                    backGroundColor: theme.chip.installation,
                  },
                ],
        }}>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <IM.View style={{ margin: isSmallDevice || order?.length === 0 ? 6 : 10 }}>
          {loading === false && order && order.length !== 0 ? (
            <>
              {!isSmallDevice && (
                <IM.View>
                  <DynamicView style={[IMLayout.flex.f1, styles.headerContainer]}>
                    <CellText style={firstColumn} value={i18n.t('PURCHASE_ORDER_CODE')} secondary numberOfLines={1} />
                    <CellText style={secondColumn} value={i18n.t('ARTICLE')} secondary numberOfLines={1} />
                    <CellText style={thirdColumn} value={i18n.t('ARTICLE_CODE')} secondary numberOfLines={1} />
                    <CellText style={fourthColumn} value={i18n.t('QUANTITY')} secondary numberOfLines={1} />
                    <CellText style={fifthColumn} value={i18n.t('DATE')} secondary numberOfLines={1} />
                    <CellText style={sixthColumn} value={i18n.t('NOTE')} secondary numberOfLines={1} />
                  </DynamicView>
                  <Separator spacing="none" />
                </IM.View>
              )}
              <>
                {order.map((ord, index) => (
                  <IM.View key={index}>
                    <Pressable disabled>
                      <DynamicView style={IMLayout.flex.f1} flexLimitSmallDevice={1}>
                        <CellText title={i18n.t('PURCHASE_ORDER_CODE')} value={<TextTable text={ord.code} />} disableSpacing style={firstColumn} />
                        <CellText title={i18n.t('ARTICLE')} value={<TextTable text={ord.articleSearchtext} />} style={secondColumn} />
                        <CellText title={i18n.t('ARTICLE_CODE')} value={<TextTable text={ord.articleCode} />} style={thirdColumn} />
                        <CellText
                          title={i18n.t('QUANTITY')}
                          value={
                            <TextTable
                              text={(ord.quantity ? ord.quantity.toString() : '') + (ord.measurementUnit ? ` ${ord.measurementUnit}` : '')}
                            />
                          }
                          style={fourthColumn}
                        />
                        <CellText title={i18n.t('DATE')} value={<TextTable text={TimeUtils.format(ord.date, language)} />} style={fifthColumn} />
                        <CellText title={i18n.t('NOTE')} value={<TextTable text={ord.note ?? '-'} />} style={sixthColumn} />
                      </DynamicView>
                    </Pressable>
                    {index !== order.length - 1 && <Separator spacing="none" />}
                  </IM.View>
                ))}
              </>
            </>
          ) : (
            <>
              {loading !== false ? (
                <IM.View spacing={isSmallDevice ? 'horizontal' : 'none'}>
                  {isSmallDevice ? (
                    <>
                      <SkeletonText width="80%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="vertical" />
                    </>
                  ) : (
                    <>
                      <SkeletonText width="100%" spacing="top" />
                      <Separator spacing="bottom" />
                      <SkeletonText width="100%" spacing="bottom" />
                    </>
                  )}
                </IM.View>
              ) : (
                // eslint-disable-next-line react-native/no-inline-styles
                <IM.View style={{ margin: 6 }}>
                  <IM.Text secondary>{i18n.t('NO_PURCHASE_ORDER_FOUND')}</IM.Text>
                </IM.View>
              )}
            </>
          )}
          {isSmallDevice && !onlyRead && order?.length === 0 && (
            <CardButton title={i18n.t('ORDER')} onPress={onPress} style={styles.button} disableSeparator={true} disabled={loading !== false} />
          )}
        </IM.View>
      </IM.Card>
      <ActivityPurchaseOrderModal controller={controller} />
    </>
  )
})

export default ActivityOrderCard

const styles = StyleSheet.create({
  button: { margin: 6, marginTop: 3 },
  buttonContainer: { justifyContent: 'flex-end' },
  container: {
    flexGrow: 1,
  },
  headerContainer: { paddingHorizontal: 8, marginBottom: 2 },
  iconContainer: { justifyContent: 'flex-end', alignItems: 'center' },
  justify: { justifyContent: 'center' },
})
