import { DataProviderCustomUpdateEffect } from '../../../dataProvider/types'
import { ActivityTime, Employee, InvoiceType, UserSettings } from '../../types/apiResponseTypes'

export const ActivityTimeCustomUpdateEffect: DataProviderCustomUpdateEffect<ActivityTime> = async (data, props) => {
  if (props.requestParams.type === 'POST') {
    // check if employee data is given otherwise compile it
    if (!data.employee || !data.employeeId) {
      try {
        if (data.employeeId) {
          const employee = (await props.dataStore.Get<Employee>('employee', { id: data.employeeId })).shift()
          if (employee) {
            data.employee = [employee.firstName, employee.lastName].filter(q => !!q).join(' ')
          }
        } else {
          const userSettings = (await props.dataStore.Get<UserSettings>('userSettings'))?.shift()
          if (userSettings) {
            data.employee = data.employee || [userSettings.employeeFirstname, userSettings.employeeLastname].filter(q => !!q).join(' ')
            data.employeeId = data.employeeId || userSettings.employeeId || ''
          }
        }
      } catch (_e) {
        /* empty */
      }
    }
    // check if invoice info is given otherwise compile it
    if (!data.invoicingType || !data.invoicingTypeId) {
      try {
        const invoiceType = (
          await props.dataStore.Get<InvoiceType>('common/invoicetypes', data.invoicingTypeId ? { id: data.invoicingTypeId } : { isDefault: true })
        ).shift()
        if (invoiceType) {
          data.invoicingTypeId = invoiceType.id
          data.invoicingType = `${invoiceType.code} - ${invoiceType.description ?? ''}`
        }
      } catch (_e) {
        /* empty */
      }
    }

    data.openTime = data.openTime ?? !data.until
  }
  if (props.requestParams.type === 'PATCH' || props.requestParams.type === 'PUT') {
    data.openTime = !data.until
  }
  return props.defaultEffect(data)
}
