import { useEvent, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import api from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ActivityStateSelector from '../../components/selectors/ActivityStateSelector'
import ActivityTypeSelector from '../../components/selectors/ActivityTypeSelector'
import EmployeeSelector from '../../components/selectors/EmployeeSelector'
import InvoiceTypeSelector from '../../components/selectors/InvoiceTypeSelector'
import { REFRESH_TICKET_ACTIVITY_EVENT_KEY } from '../../constants/EmitterKeys'
import useForm from '../../hooks/useForm'
import { EditOrCreateViewProps, EditOrCreateViewRef, UploadStatus } from '../../types'

type Props = EditOrCreateViewProps & { ticketId: string }

const TicketActivityCreateView = ({ ticketId, disabled, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { request: create, loading: loadingCreate } = useRequest(api.createActivity)

  const { emit } = useEvent({ key: REFRESH_TICKET_ACTIVITY_EVENT_KEY })

  const { error } = useForm()
  const { i18n } = useLanguage()

  const [state, setState] = useState<Activity>()
  const [defaultValues, setDefaultValues] = useState<Partial<Pick<Activity, 'employeeId' | 'activityTypeId' | 'invoiceTypeId' | 'stateId'>>>({})
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  useEffect(() => {
    if (state === undefined) {
      if (
        defaultValues.employeeId !== undefined &&
        defaultValues.activityTypeId !== undefined &&
        defaultValues.invoiceTypeId !== undefined &&
        defaultValues.stateId !== undefined
      ) {
        return updateUploadStatus('waitingWithoutPending')
      } else {
        return updateUploadStatus('done')
      }
    }

    let uploadStatus: UploadStatus = 'done'
    const equal = error

    if (!equal) {
      if (
        (state?.stateId === undefined && defaultValues.stateId === undefined) ||
        (state?.invoiceTypeId === undefined && defaultValues.invoiceTypeId === undefined) ||
        (state?.employeeId === undefined && defaultValues.employeeId === undefined) ||
        (state?.activityTypeId === undefined && defaultValues.activityTypeId === undefined)
      ) {
        uploadStatus = 'mandatoryMissing'
      } else {
        uploadStatus = 'waiting'
      }
    }

    updateUploadStatus(uploadStatus)
  }, [state, defaultValues, error])

  useEffect(() => {
    if (loadingCreate === 'catched') {
      updateUploadStatus('waiting')

      return
    }

    const loadingDone = loadingCreate === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      emit()
    }
  }, [loadingCreate])

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = () => {
    updateUploadStatus('uploading')

    create({
      ...(state ?? {}),
      employeeId: state?.employeeId ?? defaultValues.employeeId ?? '',
      activityTypeId: state?.activityTypeId ?? defaultValues.activityTypeId ?? '',
      invoiceTypeId: state?.invoiceTypeId ?? defaultValues.invoiceTypeId ?? '',
      stateId: state?.stateId ?? defaultValues.stateId ?? '',
      ticketId: ticketId,
    } as Activity)
  }

  const handleChangeText = (newVal: Partial<Activity>) => {
    if (waitingUpload === 'uploading') return
    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: !disabled,
  }

  return (
    <ScrollViewForm>
      <ActivityTypeSelector
        onChange={val => handleChangeText({ activityTypeId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValues(prev => ({ ...prev, activityTypeId: id }))}
        mandatory
        {...commonProps}
      />
      <EmployeeSelector
        onChange={val => handleChangeText({ employeeId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValues(prev => ({ ...prev, employeeId: id }))}
        mandatory
        {...commonProps}
      />
      <InvoiceTypeSelector
        type="Activity"
        onChange={val => handleChangeText({ invoiceTypeId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValues(prev => ({ ...prev, invoiceTypeId: id }))}
        mandatory
        {...commonProps}
      />
      <ActivityStateSelector
        onChange={val => handleChangeText({ stateId: val === undefined ? undefined : val.id })}
        onDefaultFound={id => setDefaultValues(prev => ({ ...prev, stateId: id }))}
        mandatory
        {...commonProps}
      />
      <TextInput
        title={i18n.t('TECHNICAL_DESCRIPTION')}
        value={state?.technicalDescription}
        multiline
        fixMultilineHeight
        onChangeText={text => handleChangeText({ technicalDescription: text === '' ? undefined : text })}
        {...commonProps}
      />
      <TextInput
        title={i18n.t('CUSTOMER_DESCRIPTION')}
        value={state?.customerDescription}
        multiline
        fixMultilineHeight
        onChangeText={text => handleChangeText({ customerDescription: text === '' ? undefined : text })}
        {...commonProps}
      />
    </ScrollViewForm>
  )
}

export default forwardRef(TicketActivityCreateView)
