import { useAlert, useEvent, useLanguage, Utils } from '@infominds/react-native-components'
import { useCallback, useEffect, useRef, useState } from 'react'

import { ActivityTime, TravelTime } from '../apis/types/apiResponseTypes'
import {
  EDIT_ACTIVITY_TIME_EVENT_KEY,
  REFRESH_OTHER_TIME_MANAGER_EVENT_KEY,
  REFRESH_TICKET_ACTIVITY_EVENT_KEY,
  REFRESH_TICKET_LIST_TIMES_EVENT_KEY,
  REFRESH_TRAVEL_TIME_BUTTON_EVENT_KEY,
} from '../constants/EmitterKeys'
import { ActivityTimeState, TimeButtonRef } from '../types'
import TimeUtils from '../utils/TimeUtils'
import useActivityTimeApi from './useActivityTimeApi'
import useTravelTimeApi from './useTravelTimeApi'
import useUserSettings from './useUserSettings'

export default function useTimeManager(activityId: string, askForStop = false, buttonRef?: React.RefObject<TimeButtonRef>, forceRefresh = false) {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { userSettings } = useUserSettings()
  const [type, setType] = useState<ActivityTimeState>('init')
  const manualStart = useRef(false)

  const { state, times, load, create, edit } = useActivityTimeApi()
  const { state: stateTravel, time: travelTime, load: loadTravel, edit: editTrvTimes } = useTravelTimeApi()

  const { emit: emitRefreshTimeCard } = useEvent<string | undefined>({ key: EDIT_ACTIVITY_TIME_EVENT_KEY })
  const { emit: emitStart } = useEvent({ key: REFRESH_TICKET_ACTIVITY_EVENT_KEY })
  const { emit: emitRefreshTravel } = useEvent({ key: REFRESH_TRAVEL_TIME_BUTTON_EVENT_KEY })
  const { emit: refresh } = useEvent<string>({ key: REFRESH_OTHER_TIME_MANAGER_EVENT_KEY }, id => id === activityId && check())
  const { emit } = useEvent({ key: REFRESH_TICKET_LIST_TIMES_EVENT_KEY })

  useEffect(() => {
    check()
  }, [])

  useEffect(() => {
    if (!(state === 'loaded' && stateTravel === 'loaded')) return

    const time = times?.at(0)

    switch (type) {
      case 'init': {
        break
      }
      case 'checking': {
        if (time === undefined) {
          setType('init')
        } else {
          const isOpenOnAnotherActivity = time.activityId !== activityId
          isOpenOnAnotherActivity ? setType('init') : setType('starting')
        }

        break
      }
      case 'checking-start': {
        if (time === undefined) {
          if (travelTime === null || travelTime === undefined || travelTime.openTime === false) {
            start()
          } else {
            alert(i18n.t('TRAVEL_TIME_RECORDING'), i18n.t('TRAVEL_TIME_RECORDING_DESCRIPTION'), [
              {
                text: i18n.t('NO'),
                style: 'destructive',
                onPress: () => setType('init'),
              },
              {
                text: i18n.t('YES'),
                style: 'default',
                onPress: () => stopTravelTime(travelTime),
              },
            ])
          }
        } else {
          const isOpenOnAnotherActivity = time.activityId !== activityId

          if (isOpenOnAnotherActivity) {
            if (askForStop) {
              alert(i18n.t('TIME_RECORDING'), Utils.stringValueReplacer(i18n.t('TIME_RECORDING_DESCRIPTION'), time.activity), [
                {
                  text: i18n.t('NO'),
                  style: 'destructive',
                  onPress: () => setType('init'),
                },
                {
                  text: i18n.t('YES'),
                  style: 'default',
                  onPress: () => stop(time, 'stopping-other-activity'),
                },
              ])
            } else {
              stop(time, 'stopping-other-activity')
            }
          } else {
            setType('starting')
          }
        }

        break
      }
      case 'checking-stop': {
        if (time === undefined) {
          setType('stopped')
        } else {
          stop(time)
        }

        return
      }
      case 'starting': {
        if (time?.openTime) {
          setType('started')
        } else {
          setType('init')
        }
        break
      }
      case 'started': {
        if (manualStart.current) {
          emitRefreshTimeCard(activityId)
          manualStart.current = false
        }
        emitStart()
        emitRefreshTravel()
        emit()
        break
      }
      case 'stopping': {
        if (time === undefined || time.openTime === false) {
          setType('stopped')
        } else {
          setType('started')
        }
        break
      }
      case 'stopping-other-activity': {
        if (time === undefined || time.openTime === false) {
          start()
        } else {
          setType('init')
        }
        break
      }
      case 'stopping-travel-time': {
        if (travelTime?.openTime) {
          setType('init')
        } else {
          start()
        }
        break
      }
      case 'stopped': {
        emitRefreshTimeCard(activityId)
        emitRefreshTravel()
        emit()
        forceRefresh && refresh(activityId)
        break
      }
      case 'catched': {
        if (time !== undefined && time.openTime) {
          buttonRef?.current?.reset('started')
          setType('started')
        } else {
          buttonRef?.current?.reset('stopped')
          setType('stopped')
        }
        break
      }
    }
  }, [state, stateTravel, type, times, travelTime])

  useEffect(() => {
    if (state === 'failed-posting' || state === 'failed-patching' || stateTravel === 'failed-posting' || stateTravel === 'failed-patching') {
      setType('catched')
    }
  }, [state, stateTravel])

  const check = useCallback((newType: ActivityTimeState = 'checking') => {
    setType(newType)
    load()
    loadTravel()
  }, [])

  const start = useCallback(() => {
    if (userSettings && userSettings.employeeId) {
      create({
        date: TimeUtils.resetTimeOfISOString(new Date().toISOString()),
        employeeId: userSettings.employeeId,
        from: TimeUtils.getCurrentSeconds(),
        activityId,
      } as Omit<ActivityTime, 'id'>)
    }

    manualStart.current = true
    setType('starting')
  }, [userSettings])

  const stop = useCallback((currentTime: ActivityTime, newType: ActivityTimeState = 'stopping') => {
    edit({ ...currentTime, until: TimeUtils.getCurrentSeconds() })
    setType(newType)
  }, [])

  const stopTravelTime = useCallback((currentTime: TravelTime) => {
    editTrvTimes({ ...currentTime, dateUntil: TimeUtils.resetTimeOfISOString(new Date().toISOString()), until: TimeUtils.getCurrentSeconds() })
    setType('stopping-travel-time')
  }, [])

  return { start: () => check('checking-start'), stop: () => check('checking-stop'), type, times, travelTime }
}
