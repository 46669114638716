import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Article } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_ARTICLE } from '../../constants/Keys'
import { ListSection, SparePartCardRef } from '../../types'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  code?: string
  editable?: boolean
  spacing?: SpacingProps
  isMandatory?: boolean
  enableBackdropOpacity?: boolean
  requestExtraId?: number
  onChange: (value: Article | undefined) => void
}

const ArticleSelector = (
  { code, isMandatory, enableBackdropOpacity, requestExtraId, onChange, ...props }: Props,
  ref: ForwardedRef<SparePartCardRef>
) => {
  useImperativeHandle(ref, () => ({
    reset: () => setSelectedCode(noSelectionEntry.code),
  }))

  const { i18n } = useLanguage()

  const [search, setSearch] = useState('')
  const [selectedCode, setSelectedCode] = useState(code ?? CONSTANTS.noSelectionId)

  const {
    item: data,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
  } = useInfiniteLoader(api.getArticle, {
    chuckSize: CONSTANTS.defaultChuckSize,
    id: REQUEST_ARTICLE + (requestExtraId ? requestExtraId.toString() : ''),
  })

  const noSelectionEntry: Article = {
    id: CONSTANTS.noSelectionId,
    code: CONSTANTS.noSelectionId,
    searchtext: i18n.t('NO_SELECTION'),
  }

  useEffect(() => {
    refresh(search)
  }, [search])

  const refresh = (text?: string) => loadItem({ searchtext: text, readPrice: false })

  const render = ({ item }: SectionListRenderItemInfo<Article, ListSection<Article>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.code === selectedCode} text={getText(item)} />
  }

  const handleOnChange = (newValue: Article | undefined) => {
    setSelectedCode(newValue?.code ?? CONSTANTS.noSelectionId)

    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  const getText = useCallback((item: Article) => (item.id === noSelectionEntry.id ? item.searchtext : `${item.searchtext} (${item.code})`), [])

  return (
    <SelectInput
      data={search !== '' ? data : data ? [noSelectionEntry, ...data] : [noSelectionEntry]}
      value={selectedCode === CONSTANTS.noSelectionId ? undefined : data?.find(elem => elem.code === selectedCode)}
      loading={loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={i18n.t('ARTICLE') + (isMandatory ? ' *' : '')}
      screenTitle={i18n.t('ARTICLES')}
      noDataMessage={i18n.t('NO_ARTICLE_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => getText(item)}
      allDataLoaded={allDataLoaded}
      onLoadMore={loadMore}
      disableLoadAfterMount
      enableBackdropOpacity={enableBackdropOpacity}
      {...props}
    />
  )
}

export default forwardRef(ArticleSelector)
