import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import ActivityPlanningTimesEditOrCreateScreen from '../../screens/activity/ActivityPlanningTimesEditOrCreateScreen'
import { ActivityEditOrCreatePlanningTimesParams, ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ActivityEditOrCreatePlanningTimesParams>
}

export default function ActivityEditOrCreatePlanningTimeModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      {controller.data && (
        <ActivityPlanningTimesEditOrCreateScreen
          ref={ref}
          controller={controller}
          route={{
            key: '',
            name: 'ActivityEditOrCreatePlanningTimes',
            params: controller.data,
          }}
        />
      )}
    </BaseServiceModal>
  )
}
