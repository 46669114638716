import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react'
import { StyleSheet } from 'react-native'

import Offline from '../../components/Offline'
import Tag from '../../components/Tag'
import TagAccess from '../../components/TagAccess'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import { AccessDataFilterEmitter } from '../../types'

export interface AccessDataFilterViewRef {
  getState: () => AccessDataFilterEmitter
}

const AccessDataFilterView = (props: AccessDataFilterEmitter, ref: ForwardedRef<AccessDataFilterViewRef>) => {
  useImperativeHandle(ref, () => ({
    getState: () => state,
  }))

  const { i18n } = useLanguage()
  const { isOnline } = useDataProvider()
  const [state, setState] = useState<AccessDataFilterEmitter>(props)

  return (
    <IM.View spacing="all" style={styles.container}>
      {isOnline === true ? (
        <>
          <IM.View spacing="vertical">
            <IM.Text style={styles.title}>{i18n.t('ACCESS_GROUP')}</IM.Text>
            {state.filters.length === 0 && <IM.Text secondary>{i18n.t('NO_ACCESS_GROUP_FOUND')}</IM.Text>}
            <IM.View style={styles.filterContainer}>
              {state.filters.map(filter => {
                return (
                  <Tag
                    key={filter.data.id}
                    id={filter.data.id}
                    active={filter.active}
                    name={filter.data.name}
                    style={styles.tag}
                    onPress={id =>
                      setState(prev => {
                        const clone = cloneDeep(prev)

                        clone.filters.forEach(elem => {
                          if (elem.data.id === id) elem.active = !elem.active
                        })

                        return clone
                      })
                    }
                  />
                )
              })}
            </IM.View>
          </IM.View>
          {state.permissions && (
            <IM.View spacing="vertical">
              <IM.Text style={styles.title}>{i18n.t('ACCESS_PERMISSION')}</IM.Text>
              <IM.View style={styles.filterContainer}>
                {state.permissions.map(permission => {
                  return (
                    <TagAccess
                      key={permission.data}
                      element={permission}
                      style={styles.tag}
                      size={20}
                      onPress={id =>
                        setState(prev => {
                          let clickOnAlreadySelected = false
                          const clone = cloneDeep(prev)

                          clone.permissions?.forEach(elem => {
                            if (elem.data === id && elem.active) {
                              clickOnAlreadySelected = true
                            }

                            elem.active = false
                          })

                          if (!clickOnAlreadySelected) {
                            clone.permissions?.forEach(elem => {
                              if (elem.data === id) elem.active = !elem.active
                            })
                          }

                          return clone
                        })
                      }
                    />
                  )
                })}
              </IM.View>
            </IM.View>
          )}
        </>
      ) : (
        <IM.View style={styles.center} spacing="all">
          <Offline />
        </IM.View>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: IMLayout.horizontalMargin,
  },
  title: {
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  filterContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  tag: { marginVertical: 10 },
  center: {
    alignItems: 'center',
  },
})

export default forwardRef(AccessDataFilterView)
