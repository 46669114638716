import { useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../types'

export default function InitMeta() {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <>
      <meta name="theme-color" content={theme.header.main.background} />
      {/* <meta name="background_color" content={theme.background} /> */}
    </>
  )
}
