import { IM, IMLayout, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { Platform, SectionListRenderItemInfo, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Report } from '../../apis/types/apiResponseTypes'
import Button from '../../components/Button'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import Pressable from '../../components/Infominds/Pressable'
import RadioButton from '../../components/RadioButton'
import SectionList from '../../components/SectionList'
import SkeletonText from '../../components/skeleton/SkeletonText'
import Text from '../../components/Text'
import { REQUEST_POST_REPORT, REQUEST_REPORTS } from '../../constants/Keys'
import { ListSection, ThemeColorExpanded } from '../../types'

interface Props {
  ticketId: string
  onCompleted: () => void
}

export default function TicketReportView({ ticketId, onCompleted }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  const { loadItem: loadReports, item: reports, loading } = useControlledLoader(api.getReports, { id: REQUEST_REPORTS })
  const { request, loading: loadingSend } = useRequest(api.sendTicketReport, { id: REQUEST_POST_REPORT })

  const [pressed, setPressed] = useState(false)
  const [selectedId, setSelectedId] = useState<undefined | string>(undefined)

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    loadingSend === false && pressed && onCompleted()
  }, [loadingSend])

  const refresh = () => loadReports({})

  const send = () => selectedId && request({ reportId: selectedId, ticketId })

  const renderItem = ({ item }: SectionListRenderItemInfo<Report, ListSection<Report>>) => (
    <Pressable style={[IMLayout.flex.row, styles.pressable]} onPress={() => setSelectedId(item.id)}>
      <RadioButton selected={item.id === selectedId} />
      <Text>{item.description}</Text>
    </Pressable>
  )

  return (
    <>
      <SectionList
        loading={loading}
        skeletonElements={15}
        noDataMessage={i18n.t('NO_REPORTS_FOUND')}
        sections={[{ data: reports ?? [] }]}
        renderItem={renderItem}
        onRefresh={refresh}
        skeletonComponent={<SkeletonText width="100%" spacing="bottom" />}
        contentContainerStyle={styles.listContainer}
      />
      <IM.View style={{ margin: 2 * IMLayout.horizontalMargin }}>
        <Button
          title={i18n.t('SEND_REPORT')}
          onPress={() => {
            setPressed(true)
            send()
          }}
          disabled={selectedId === undefined}
          color={theme.general.info}
          loading={!(loadingSend === false)}
        />
      </IM.View>
    </>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    marginHorizontal: IMLayout.horizontalMargin,
    marginVertical: IMLayout.horizontalMargin,
    ...Platform.select({
      native: {
        height: '100%',
      },
    }),
  },
  pressable: {
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
})
