import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { DimensionValue, Platform, Pressable, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import Divider from './Divider'
import TextWithIcon from './Infominds/TextWithIcon'
import { SCREEN_CONSTANTS } from './screen/constants/constants'

interface Props {
  selectionColor: string
  height?: DimensionValue
  width?: DimensionValue
  style?: StyleProp<ViewStyle>
}

export default function TabSelector({ children, selectionColor, height = 48, width, style }: PropsWithChildren<Props>) {
  return (
    <IM.View style={Platform.OS === 'ios' && IMLayout.shadow}>
      <IM.View
        style={[
          styles.container,
          { height, width },
          Platform.OS !== 'ios' && IMLayout.shadow,
          {
            backgroundColor: selectionColor,
          },
          style,
        ]}>
        {children}
      </IM.View>
    </IM.View>
  )
}

type TabItem = {
  icon: IconProp
  iconSize?: number
  text: string
  textColor?: string
  selected: boolean
  selectedTextColor?: string
  backgroundColor?: string
  dividerColor?: string
  disableDivider?: boolean
  disabled?: boolean
  onPress: () => void
}

function TabSelectorItem({
  text,
  disabled,
  selected,
  textColor,
  selectedTextColor = IMStyle.palette.white,
  onPress,
  backgroundColor,
  disableDivider,
  dividerColor,
  ...other
}: TabItem) {
  const { theme } = useTheme()
  const backColor = selected ? 'transparent' : backgroundColor ?? theme.tabNavigator.background

  return (
    <Pressable onPress={onPress} disabled={disabled} style={[IMLayout.flex.f1, styles.itemContainer]}>
      <TextWithIcon
        {...other}
        numberOfLines={1}
        disabled={disabled}
        color={selected ? selectedTextColor : textColor}
        viewStyle={[styles.text, styles.itemText, { backgroundColor: backColor }]}>
        {text}
      </TextWithIcon>
      {!disableDivider && <Divider color={dividerColor ?? theme.textPlaceholder} height="100%" style={styles.divider} />}
    </Pressable>
  )
}

TabSelector.Item = TabSelectorItem

const styles = StyleSheet.create({
  container: {
    borderRadius: SCREEN_CONSTANTS.headerRadius,
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginVertical: 10,
    marginHorizontal: 8,
    overflow: 'hidden',
  },
  text: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemContainer: {
    flexDirection: 'row',
  },
  itemText: {
    height: '100%',
  },
  divider: {
    marginHorizontal: 0,
  },
})
