import { IM, IMLayout, useDimensions, useTheme } from '@infominds/react-native-components'
import React from 'react'

import useKeyPress from '../../hooks/useKeyPress'
import { ThemeColorExpanded } from '../../types'
import PressableIcon from '../Infominds/PressableIcon'
import useScreen from './hooks/useScreen'
import useSearch from './hooks/useSearch'
import { DetailHeaderType } from './types'

export type SearchIconProps = {
  disabled?: boolean
}

export default function SearchIcon({ disabled }: SearchIconProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { search } = useSearch()
  const { isSmallDevice } = useDimensions()
  const { detail, setDetailOpen } = useScreen()

  const searchOpen = detail.type === DetailHeaderType.SEARCH && detail.open

  useKeyPress(
    {
      key: ['F2'],
      cb: () => {
        isSmallDevice && setDetailOpen(DetailHeaderType.SEARCH)
      },
    },
    [isSmallDevice, setDetailOpen]
  )

  return (
    <IM.View
      style={
        detail.open &&
        searchOpen && {
          backgroundColor: theme.header.detail.background,
          borderRadius: IMLayout.iconRadius,
        }
      }>
      <PressableIcon
        icon={['fal', 'magnifying-glass']}
        size={17}
        color={search !== '' ? theme.general.info : theme.header.main.text.primary}
        onPress={() => setDetailOpen(DetailHeaderType.SEARCH)}
        hitSlop={{ top: 10, bottom: 8 }}
        disabled={disabled}
      />
    </IM.View>
  )
}
