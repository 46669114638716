import React, { ForwardedRef, forwardRef, memo } from 'react'
import { TextStyle } from 'react-native'

import { TextInputRef } from '../../types'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

export type TextInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
  }

const TextInput = memo(
  forwardRef(function TextInput(
    { title, titleFontWeight, details, editable, error, loading, disableFocus, ...textInputProps }: TextInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput ref={ref} {...textInputProps}>
          {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} />}
          {loading && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              {loading && <BaseTextInputSpinningIcon loading={loading} />}
            </BaseTextInput.RightIcon>
          )}
          {typeof error !== 'boolean' && error?.value && <BaseTextInput.Error message={error.message} />}
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default TextInput
