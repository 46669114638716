import dayjs from 'dayjs'
import { i18n } from 'i18next'

import { Customer, IsoVatnumber } from '../apis/types/apiResponseTypes'

const customerUtils = {
  formatAddress: <
    T extends {
      town?: string
      province?: string
      postalCode?: string
      country?: string
      street?: string
      streetnumber?: string
    },
  >(
    customer: T | undefined | null,
    type?: 'street' | 'location'
  ) => {
    if (!customer) return ''
    let toRet = ''

    if (customer.town !== undefined && (!type || type === 'location')) {
      toRet += customer.town
      if (customer.province !== undefined) toRet += ` (${customer.province})`
    }

    if (customer.postalCode !== undefined && (!type || type === 'location')) {
      if (toRet !== '') toRet += ' - '
      toRet += `${customer.postalCode}`
    }

    if (customer.country !== undefined && (!type || type === 'location')) {
      if (toRet !== '') toRet += ' - '
      toRet += `${customer.country}`
    }

    if (customer.street !== undefined && (!type || type === 'street')) {
      if (toRet !== '') toRet += ' - '
      toRet += `${customer.street}`

      if (customer.streetnumber !== undefined) {
        toRet += `, ${customer.streetnumber}`
      }
    }

    return toRet
  },
  getVat: (iso: IsoVatnumber, translator: i18n) => {
    let toRet: string | undefined = translator.t('VAT') + ': '

    if (iso.isocode && iso.vatNumber) {
      toRet += `${iso.isocode}-${iso.vatNumber}`
    } else if (iso.isocode === undefined && iso.vatNumber) {
      toRet += iso.vatNumber
    } else if (iso.isocode && iso.vatNumber === undefined) {
      toRet += iso.isocode
    } else {
      toRet = undefined
    }

    return toRet
  },
  isBlocked: (customer: Customer | undefined) => {
    if (customer === undefined || !customer.lockedSince || customer.lockedSince === '') return false

    return dayjs().isAfter(customer.lockedSince)
  },
}

export default customerUtils
