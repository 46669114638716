import { useContext } from 'react'

import MapContext, { MapContextProps } from '../context/MapContext'

export default function useMap<T>() {
  const context = useContext(MapContext)

  if (context === undefined) {
    throw new Error('useMap() must be called inside MapProvider')
  }

  return context as unknown as MapContextProps<T>
}
