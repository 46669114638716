import { useContext } from 'react'

import QualityCheckFilterContext from '../contexts/QualityCheckFilterContext'

export default function useQualityCheckFilter() {
  const context = useContext(QualityCheckFilterContext)

  if (context === undefined) {
    throw new Error('useQualityCheckFilter() must be called inside QualityCheckFilterProvider')
  }

  return context
}
