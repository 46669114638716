import { IM, IMLayout, IMStyle, useEvent, useLanguage, useModalController, useTheme, Utils } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import Color from 'color'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../apis/apiCalls'
import { TicketActivity } from '../apis/types/apiResponseTypes'
import ActivityTicketInfoCard from '../cards/activity/ActivityTicketInfoCard'
import ActivityTimeCard from '../cards/activity/ActivityTimeCard'
import { EDIT_ARTICLE_COUNTERS_EVENT_KEY } from '../constants/EmitterKeys'
import { REQUEST_SERIAL_NUMBER_COUNTERS } from '../constants/Keys'
import useLayout from '../hooks/useLayout'
import useUserSettings from '../hooks/useUserSettings'
import ActivityArticleCounterModal from '../modals/activity/ActivityArticleCounterModal'
import { ActivityStackParamList } from '../navigation/types'
import { ActivityArticleCounterParams, ThemeColorExpanded } from '../types'
import ticketUtils from '../utils/TicketUtils'
import { utils } from '../utils/utils'
import ActivityCloseSwitch from './ActivityCloseSwitch'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import Pressable from './Infominds/Pressable'
import PressableTextIcon from './Infominds/PressableTextIcon'
import TextWithIcon from './Infominds/TextWithIcon'
import SkeletonText from './skeleton/SkeletonText'
import Text from './Text'

interface Props {
  activity: TicketActivity
  multipleClosing: boolean
}

const TicketActivityClose = memo(function TicketActivityClose({ activity, multipleClosing }: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout()
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation<NavigationProp<ActivityStackParamList>>()
  const counterController = useModalController<ActivityArticleCounterParams>()

  const [shown, setShown] = useState(!multipleClosing)
  const [error, setError] = useState(false)
  const uuid = useRef(utils.generateUuid())

  useEvent<string | undefined>({ key: EDIT_ARTICLE_COUNTERS_EVENT_KEY }, id => uuid.current === id && refresh())

  const {
    item: counters,
    loadItem: loadCounter,
    loading: loadingCounters,
  } = useControlledLoader(api.getSerialNumberCounter, { id: REQUEST_SERIAL_NUMBER_COUNTERS + activity.id })

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadCounter({ ticketId: activity.ticketId })

  const onPress = () => {
    if (counters === undefined) return

    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('ActivityArticleCounter', {
          counters: counters,
          emitId: uuid.current,
        })
      : counterController.show({ counters: counters, emitId: uuid.current })
  }

  const closingCheck = useMemo(() => ticketUtils.closingCountersCheck(counters, userSettings), [counters, userSettings])

  return (
    <>
      <Pressable style={styles.container} disabled={!multipleClosing} onPress={() => setShown(prev => !prev)}>
        <IM.View style={[IMLayout.flex.row, styles.titleContainer]}>
          <Text style={{ fontWeight: IMStyle.typography.fontWeightMedium, fontSize: IMStyle.typography.fontSizeRegular }}>{activity.code}</Text>
          {multipleClosing && (
            <IM.View style={[IMLayout.flex.row, styles.iconContainer]}>
              <ActivityCloseSwitch activityId={activity.id} ticketId={activity.ticketId} documentId={activity.ticketDocumentId} onError={setError} />
              <IM.Icon icon={['fal', shown ? 'chevron-up' : 'chevron-down']} style={{ marginLeft: 3 * IMLayout.horizontalMargin }} size={18} />
            </IM.View>
          )}
        </IM.View>
        {error && (
          <TextWithIcon icon={['fas', 'circle-exclamation']} color={theme.general.error} style={[IMLayout.flex.f1, styles.error]}>
            {Utils.stringValueReplacer(i18n.t('ACTIVITY_MULTIPLE_CLOSING_MANDATORY_FIELD_MISSING'), activity.code)}
          </TextWithIcon>
        )}
        {shown && (
          <IM.View>
            <ActivityTicketInfoCard ticketId={activity.ticketId} spacing="top" />
            <ActivityTimeCard activity={activity} onlyRead spacing="top" type="Closing" />
            {loadingCounters === false ? (
              <PressableTextIcon
                icon={closingCheck === false ? ['fal', 'times'] : ['fal', 'check']}
                color={closingCheck === false ? theme.general.error : theme.general.info}
                iconSize={16}
                alignIcon="right"
                containerStyle={{
                  backgroundColor: Color(closingCheck === false ? theme.general.error : theme.general.info)
                    .fade(0.9)
                    .toString(),
                  borderRadius: IMLayout.borderRadius,
                }}
                style={IMLayout.flex.f1}
                onPress={onPress}
                spacingType="margin"
                spacing="top"
                disabled={counters === undefined || counters.length === 0}>
                {i18n.t('COUNTERS').toUpperCase()}
              </PressableTextIcon>
            ) : (
              <SkeletonText spacing="top" width="100%" height={30} />
            )}
          </IM.View>
        )}
      </Pressable>
      <ActivityArticleCounterModal controller={counterController} />
    </>
  )
})

export default TicketActivityClose

const styles = StyleSheet.create({
  iconContainer: {
    alignItems: 'center',
  },
  container: { marginHorizontal: -6 },
  error: { marginTop: 4 },
  titleContainer: {
    justifyContent: 'space-between',
  },
})
