import { Profile, useAuthentication } from '@infominds/react-native-license'
import { useEffect, useState } from 'react'

import api from '../apis/apiCalls'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'
import { REQUEST_EMPLOYEE } from '../constants/Keys'
import useUserSettings from './useUserSettings'

export default function useUserInfo() {
  const { userSettings } = useUserSettings()
  const { loginType, profile: getProfile } = useAuthentication()

  const { item, loadItem, loading: infomindsLoading } = useControlledLoader(api.getEmployee, { id: REQUEST_EMPLOYEE })

  const [profile, setProfile] = useState<Profile | undefined>(undefined)
  const [aadLoading, setAadLoading] = useState(true)

  const isAad = loginType === 'AAD'
  const name = profile ? profile.givenName : item?.at(0)?.firstName
  const surname = profile ? profile.surname : item?.at(0)?.lastName
  const loading = isAad ? aadLoading : infomindsLoading

  useEffect(() => {
    if (userSettings) {
      if (isAad) {
        getProfile?.()
          .then(val => {
            setProfile(val)
            setAadLoading(false)
          })
          .catch(console.error)
      } else {
        loadItem({ id: userSettings.employeeId })
      }
    }
  }, [userSettings, isAad])

  return { name, surname, fullName: name && surname ? `${name} ${surname}` : undefined, picture: profile?.picture, loading }
}
