import { IM, IMStyle, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import Clipboard from '@react-native-clipboard/clipboard'
import React, { useEffect, useState } from 'react'
import { Platform, Pressable, StyleSheet } from 'react-native'

import { AccessData } from '../apis/types/apiResponseTypes'
import { accessDataCardStyle } from '../cards/accessData/AccessDataCard'
import { ThemeColorExpanded } from '../types'
import PressableIcon from './Infominds/PressableIcon'
import Password from './Password'

interface Props {
  open: boolean
  levelAccessGranted: boolean
  groupAccessGranted: boolean
  data: AccessData
  color: string
}

export default function AccessCredentials({ open, levelAccessGranted, groupAccessGranted, data, color }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const [copied, setCopied] = useState(false)

  const disableClipboard = data.user === undefined
  const accessGranted = levelAccessGranted && groupAccessGranted
  const userCopyDisabled = disableClipboard || !accessGranted
  const userCopyPlaceholder = copied && Platform.OS !== 'android'

  useEffect(() => {
    copied && setTimeout(() => setCopied(false), 2000)
  }, [copied])

  const copyToClipboard = () => {
    setCopied(true)
    data.user && Clipboard.setString(data.user)
  }

  return (
    <>
      {(!levelAccessGranted || !groupAccessGranted) && (
        <IM.View spacing="bottom">
          <IM.Text style={{ color: color, fontSize: IMStyle.typography.fontSizeSmall - 2 }}>
            {!groupAccessGranted ? i18n.t('GROUP_ACCESS_ERROR') : Utils.stringValueReplacer(i18n.t('LEVEL_ACCESS_ERROR'), data.accesslevel)}
          </IM.Text>
        </IM.View>
      )}
      <IM.View style={styles.container}>
        <Pressable onPress={copyToClipboard} style={styles.userTextContainer} hitSlop={{ right: -5 }}>
          <IM.Text style={accessDataCardStyle.title}>{i18n.t('USER')}</IM.Text>
          <IM.Text secondary={data.user === undefined || userCopyPlaceholder}>
            {userCopyPlaceholder ? i18n.t('COPIED_TO_CLIPBOARD') : data.user ?? i18n.t('NO_USER_AVAILABLE')}
          </IM.Text>
        </Pressable>
        {copied ? (
          <PressableIcon icon={['fal', 'check']} size={18} hitSlop={4} color={theme.general.info} opacityColor={theme.card.background} />
        ) : (
          <PressableIcon icon={['fal', 'copy']} size={16} hitSlop={4} disabled={userCopyDisabled} onPress={copyToClipboard} />
        )}
      </IM.View>
      <IM.View style={styles.container} spacing="top">
        <Password open={open} data={data} accessGranted={accessGranted} />
      </IM.View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  userTextContainer: {
    flex: 1,
  },
})
