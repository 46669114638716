import { ParamListBase } from '@react-navigation/native'
import { useContext } from 'react'

import { IMasterDetailContext, MasterDetailContext, MasterDetailProp } from '../contexts/MasterDetailContext'

export default function useMasterDetail<T extends MasterDetailProp<ParamListBase>>(): IMasterDetailContext<T> {
  const context = useContext(MasterDetailContext)

  if (!context) {
    throw new Error('useMasterDetail can only be called inside MasterDetailProvider')
  }

  return {
    ...context,
    detailParams: context.detailParams as unknown as T,
  }
}
