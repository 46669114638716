import { IMLayout } from '@infominds/react-native-components'
import React from 'react'

import EmployeeBadge from './EmployeeBadge'

interface Props {
  employeeId: string
  name?: string
}

export default function EmployeeBadgeTable({ employeeId, name = '' }: Props) {
  return <EmployeeBadge id={employeeId} name={name} showName style={IMLayout.flex.f1} />
}
