import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { InvoiceType } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_INVOICE_TYPE } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: string
  data?: InvoiceType[]
  editable?: boolean
  spacing?: SpacingProps
  enableBackdropOpacity?: boolean
  type: 'SpareParts' | 'Activity' | 'ActivityManualRecording'
  mandatory?: boolean
  onChange: ((value: InvoiceType | undefined) => void) | undefined
  onDefaultFound?: ((id: string) => void) | undefined
}

export default function InvoiceTypeSelector({ id, mandatory, data, type, enableBackdropOpacity, onChange, onDefaultFound, ...props }: Props) {
  const { i18n } = useLanguage()

  const [selectedId, setSelectedId] = useState(id)
  const [search, setSearch] = useState('')

  const noSelectionEntry: InvoiceType = {
    id: CONSTANTS.noSelectionId,
    isDefault: false,
    isForManualTimeRecording: false,
    code: i18n.t('NO_SELECTION'),
  }

  const { item: invoiceData, loadItem, loading } = useControlledLoader(api.getInvoiceType, { id: REQUEST_INVOICE_TYPE })

  useEffect(() => {
    !data && loadItem()
  }, [data])

  useEffect(() => {
    if (id || type === 'ActivityManualRecording') return
    if (loading === false && selectedId === undefined && invoiceData && invoiceData.length !== 0) {
      let selId: string | undefined

      const defaultFound = invoiceData?.find(el => el.isDefault)

      if (selId === undefined && defaultFound) selId = defaultFound.id
      if (selId === undefined) selId = invoiceData.at(0)?.id
      setSelectedId(selId)
      selId && onDefaultFound?.(selId)
    }
  }, [id, type, loading, selectedId, invoiceData])

  const render = ({ item }: SectionListRenderItemInfo<InvoiceType, ListSection<InvoiceType>>, onPress?: () => void) => {
    return (
      <RadioPressable
        onPress={() => onPress?.()}
        selected={item.id === CONSTANTS.noSelectionId && selectedId === undefined ? true : item.id === selectedId}
        text={renderString(item)}
      />
    )
  }

  const renderString = (item: InvoiceType) => {
    return item.description ? `${item.code} - ${item.description}` : item.code
  }

  const handleOnChange = (newValue: InvoiceType | undefined) => {
    if (newValue?.id === CONSTANTS.noSelectionId) {
      setSelectedId(undefined)
      onChange?.(undefined)
    } else {
      setSelectedId(newValue?.id)
      onChange?.(newValue)
    }
  }

  let types = data ?? (type === 'ActivityManualRecording' ? invoiceData?.filter(el => el.isForManualTimeRecording === true) : invoiceData)
  if (types && type === 'SpareParts') {
    types = [noSelectionEntry, ...types]
  }

  return (
    <SelectInput
      data={appUtils.filter(types ? types : [], search, ['code', 'description'])}
      value={types?.find(elem => elem.id === selectedId)}
      loading={data ? false : loading}
      refresh={() => (data ? loadItem() : undefined)}
      title={i18n.t('INVOICE_TYPE') + (mandatory ? ' *' : '')}
      screenTitle={i18n.t('INVOICE_TYPES')}
      noDataMessage={i18n.t('NO_INVOICE_TYPE_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={renderString}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableReset={type !== 'SpareParts'}
      disableLoadAfterMount
      enableBackdropOpacity={enableBackdropOpacity}
      {...props}
    />
  )
}
