let controllers: { abortController: AbortController; id: string; hash: string }[] = []

export const abortUtils = {
  get(controllerId: string, hash?: string) {
    return controllers.find(controller => controller.id === controllerId && controller.hash === hash)
  },
  add(controllerId: string, componentHash: string) {
    const newAbortController = new AbortController()
    controllers.push({ abortController: newAbortController, id: controllerId, hash: componentHash })
    return newAbortController
  },
  remove(removeId: string) {
    controllers = controllers.filter(controller => controller.id !== removeId)
  },
  abort(abortId: string, hash?: string) {
    const found = this.get(abortId, hash)

    if (found) {
      found.abortController.abort()
      this.remove(abortId)
    }
  },
}
