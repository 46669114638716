import { IM, IMLayout, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { TicketPriorityResponse } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_TICKET_PRIORITY } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

const POINT_DIMENSION = 22

const styles = StyleSheet.create({
  pointContainer: {
    width: 'auto',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  point: { height: POINT_DIMENSION, aspectRatio: 1, borderRadius: 5, marginRight: 2 * IMLayout.horizontalMargin },
})

interface Props {
  id?: string
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: TicketPriorityResponse | undefined) => void
}

export default function TicketPrioritySelector({ id, onChange, ...props }: Props) {
  const { i18n } = useLanguage()

  const [selectedId, setSelectedId] = useState(id ?? CONSTANTS.noSelectionId)
  const [search, setSearch] = useState('')

  const { item: items, loadItem, loading } = useControlledLoader(api.getTicketPriority, { id: REQUEST_TICKET_PRIORITY })
  const noSelectionEntry: TicketPriorityResponse = {
    id: CONSTANTS.noSelectionId,
    code: '',
    description: i18n.t('NO_SELECTION'),
    color: 'transparent',
  }

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadItem({})

  const handleOnChange = (newValue: TicketPriorityResponse | undefined) => {
    setSelectedId(newValue?.id ?? CONSTANTS.noSelectionId)

    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  const data = items ? [noSelectionEntry, ...items] : []

  return (
    <SelectInput
      data={appUtils.filter(data ?? [], search, ['description', 'code', { searchStringCompositor: ticketUtils.composePriorityString }])}
      value={items?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={refresh}
      title={i18n.t('PRIORITY')}
      screenTitle={i18n.t('PRIORITIES')}
      noDataMessage={i18n.t('NO_PRIORITY_FOUND')}
      renderItem={(item, onPress) => RenderPriorityElement(item, selectedId, onPress)}
      onChange={handleOnChange}
      renderSelectedString={ticketUtils.composePriorityString}
      deferredTimeout={0}
      onSearchChange={setSearch}
      disableLoadAfterMount
      {...props}
    />
  )
}

export const RenderPriorityElement = (
  { item }: SectionListRenderItemInfo<TicketPriorityResponse, ListSection<TicketPriorityResponse>>,
  selectedId: string | undefined,
  onPress?: () => void
) => {
  return (
    <IM.View style={[IMLayout.flex.row, IMLayout.flex.f1]}>
      <RadioPressable
        onPress={() => onPress?.()}
        selected={item.id === selectedId}
        text={ticketUtils.composePriorityString(item)}
        containerStyle={IMLayout.flex.f1}
      />
      <IM.View style={styles.pointContainer}>
        <IM.View style={[{ backgroundColor: item.color }, styles.point]} />
      </IM.View>
    </IM.View>
  )
}
