import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import InfomindsAnalytics from '@infominds/react-native-analytics'
import { IM, IMLayout, useDimensions } from '@infominds/react-native-components'
import { useStorageMigration } from '@infominds/react-native-license'
import React, { useEffect } from 'react'
import { LogBox, Platform, useWindowDimensions } from 'react-native'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import Orientation from 'react-native-orientation-locker'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'
import { ToastProvider } from 'react-native-toast-notifications'
import { RecoilEnv, RecoilRoot } from 'recoil'

import WebConfig from './app.config'
import { languageResources } from './assets/languages/LanguageResources'
import AuthenticationWrapper from './components/AuthenticationWrapper'
import InitMeta from './components/InitMeta'
import ThemeContextWrapper from './components/ThemeContextWrapper'
import { AppEnv } from './constants/AppEnv'
import CONSTANTS from './constants/Constants'
import { firebaseConfig } from './constants/FirebaseConfigWeb'
import { AnimationProvider } from './contexts/AnimationContext'
import { GeoLocationProvider } from './contexts/GeoLocationContext'
import { UserSettingsProvider } from './contexts/UserSettingsContext'
import InitStructures from './InitStructures'
import Navigator from './navigation/Navigator'

import './dataProvider/InitDataProvider'

RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = !__DEV__
AppEnv.VAULT_DEMO = false

export default function App() {
  const { isSmallDevice } = useDimensions()
  const { fontScale } = useWindowDimensions()
  const migrated = useStorageMigration({ isExpensesApp: false })

  useEffect(() => {
    InfomindsAnalytics.init(firebaseConfig)

    if (Platform.OS !== 'web') {
      LogBox.ignoreAllLogs()
    }
  }, [])

  useEffect(() => {
    isSmallDevice && Orientation.lockToPortrait()
  }, [isSmallDevice])

  if (migrated === undefined) return <></>
  if (migrated === false) console.error('🛑 Migration failed!')

  return (
    <AnimationProvider>
      <SafeAreaProvider key={fontScale} initialMetrics={initialWindowMetrics}>
        <RecoilRoot>
          <GestureHandlerRootView style={IMLayout.flex.f1}>
            <IM.LanguageProvider resources={languageResources}>
              <IM.LanguageContext.Consumer>
                {languageContext => {
                  if (!languageContext?.initOk) return <IM.LoadingSpinner isVisible />

                  return (
                    <ThemeContextWrapper>
                      <IM.ThemeContext.Consumer>
                        {themeContext => (
                          <IM.AlertProvider>
                            <AuthenticationWrapper>
                              {({ authReady, isLoggedIn }) => (
                                <UserSettingsProvider>
                                  {({ userSettingsReady }) => (
                                    <>
                                      <InitMeta />
                                      <InitStructures />
                                      <BottomSheetModalProvider>
                                        <ToastProvider
                                          placement="bottom"
                                          duration={CONSTANTS.toastVisibilityTime}
                                          offsetBottom={IMLayout.verticalMargin * (Platform.OS === 'ios' ? 4 : 2)}
                                          offset={20}
                                          swipeEnabled={true}>
                                          <GeoLocationProvider>
                                            {authReady && (isLoggedIn === false || userSettingsReady) ? (
                                              <Navigator
                                                isLoggedIn={isLoggedIn}
                                                basePath={WebConfig.BASE_PATH.includes('/') ? WebConfig.BASE_PATH : '/'}
                                              />
                                            ) : (
                                              // todo: lento nel web al refresh perchè ogni volta chiama ios api.
                                              <IM.View style={[IMLayout.flex.f1, { backgroundColor: themeContext?.theme.background }]}>
                                                {Platform.OS !== 'web' && <IM.LoadingSpinner isVisible />}
                                              </IM.View>
                                            )}
                                          </GeoLocationProvider>
                                        </ToastProvider>
                                      </BottomSheetModalProvider>
                                    </>
                                  )}
                                </UserSettingsProvider>
                              )}
                            </AuthenticationWrapper>
                          </IM.AlertProvider>
                        )}
                      </IM.ThemeContext.Consumer>
                    </ThemeContextWrapper>
                  )
                }}
              </IM.LanguageContext.Consumer>
            </IM.LanguageProvider>
          </GestureHandlerRootView>
        </RecoilRoot>
      </SafeAreaProvider>
    </AnimationProvider>
  )
}
