import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React, { Children, memo, ReactElement, ReactNode, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'
import LinearGradient from 'react-native-linear-gradient'
import { SafeAreaView } from 'react-native-safe-area-context'

import useLayout from '../../hooks/useLayout'
import { ThemeColorExpanded } from '../../types'
import { SCREEN_CONSTANTS } from './constants/constants'
import ContentContainer from './containers/ContentContainer'
import DetailHeaderContainer from './containers/DetailHeaderContainer'
import HeaderContainer from './containers/HeaderContainer'
import { ScreenProvider } from './contexts/ScreenContext'

type Props = {
  children?: ReactNode
  forceDetailOpen?: boolean
  transparent?: boolean
}

const Screen = memo(function Screen({ children, forceDetailOpen = false, transparent = false }: Props) {
  const { colorScheme, theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice, isLargeDevice } = useLayout(true)
  const darken = colorScheme === 'dark' ? 0.2 : 0

  const components = useMemo(() => {
    const childArray = Children.toArray(children)
    const detailChild = childArray.find(q => (q as ReactElement).type === DetailHeaderContainer)

    return {
      header: childArray.find(q => (q as ReactElement).type === HeaderContainer),
      detail: detailChild,
      content: childArray.find(q => (q as ReactElement).type === ContentContainer),
    }
  }, [children])

  const renderChild = () => (
    <SafeAreaView style={IMLayout.flex.f1}>
      <ScreenProvider forceDetailOpen={forceDetailOpen} detailHeight={isSmallDevice ? SCREEN_CONSTANTS.headerDetailMinBarHeight : 0}>
        {components.header}
        {isSmallDevice && components.detail}
        {components.content}
      </ScreenProvider>
    </SafeAreaView>
  )

  return (
    <>
      {transparent ? (
        renderChild()
      ) : (
        <LinearGradient
          useAngle
          colors={[Color('#25D07C').darken(darken).toString(), Color('#0FCEAD').darken(darken).toString()]}
          angle={90}
          style={IMLayout.flex.f1}>
          {Platform.OS === 'web' ? (
            <IM.View
              style={[IMLayout.flex.f1, isLargeDevice && styles.container, { backgroundColor: theme.header.main.background }, IMStyle.layout.shadow]}>
              {renderChild()}
            </IM.View>
          ) : (
            renderChild()
          )}
        </LinearGradient>
      )}
    </>
  )
})

const ScreenNamespace = Object.assign(Screen, {
  Header: HeaderContainer,
  DetailHeader: DetailHeaderContainer,
  Content: ContentContainer,
})

export { ScreenNamespace as Screen }

const styles = StyleSheet.create({
  container: {
    paddingRight: 10,
  },
})
