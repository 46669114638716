import { useContext } from 'react'

import SparePartsFilterContext from '../contexts/SparePartsFilterContext'

export default function useSparePartsFilter() {
  const context = useContext(SparePartsFilterContext)

  if (context === undefined) {
    throw new Error('useSparePartsFilter() must be called inside SparePartsFilterProvider')
  }

  return context
}
