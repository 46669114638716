import { IM, IMStyle, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'

type Props = {
  description: string
  tecDescription: string | undefined
  customerDescription: string | undefined
  spacing?: SpacingProps
}

const TicketDetailDescriptionCard = memo(function TicketDetailDescriptionCard({ description, tecDescription, customerDescription, spacing }: Props) {
  const { i18n } = useLanguage()

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('DESCRIPTION')} />} spacing={spacing}>
      <IM.View spacing={tecDescription ? 'bottom' : 'none'}>
        <IM.Text>{description}</IM.Text>
      </IM.View>
      {!!tecDescription && (
        <>
          <IM.Text style={styles.title}>{i18n.t('TECHNICAL_DESCRIPTION')}</IM.Text>
          <IM.Text>{tecDescription}</IM.Text>
        </>
      )}
      {!!customerDescription && (
        <IM.View spacing="top">
          <IM.Text style={styles.title}>{i18n.t('CUSTOMER_DESCRIPTION')}</IM.Text>
          <IM.Text>{customerDescription}</IM.Text>
        </IM.View>
      )}
    </IM.Card>
  )
})

export default TicketDetailDescriptionCard

const styles = StyleSheet.create({
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
    fontWeight: IMStyle.typography.fontWeightBold,
  },
})
