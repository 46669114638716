import { IM, IMLayout, IMStyle, useAlert, useDimensions, useEvent, useLanguage, useOrientation, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import Color from 'color'
import React, { useRef, useState } from 'react'
import { Keyboard, Platform, TextInput } from 'react-native'

import CONSTANTS from '../../../../constants/Constants'
import { BARCODE_EVENT_KEY } from '../../../../constants/EmitterKeys'
import { RootStackParamList } from '../../../../navigation/types'
import { BarcodeEvent } from '../../../../screens/scanner/ScannerCameraScreen'
import { ThemeColorExpanded } from '../../../../types'
import { camera } from '../../../../utils/camera'
import { utils } from '../../../../utils/utils'
import useFocus from '../../hooks/useFocus'
import useSearch from '../../hooks/useSearch'

export interface DefaultSearchDetailHeaderProps {
  enableQR?: boolean
  focus?: boolean
  disableAutofocus?: boolean
  deferredTimeout?: number
}

export default function DefaultSearchDetailHeader({
  deferredTimeout,
  focus,
  enableQR = false,
  disableAutofocus = false,
}: DefaultSearchDetailHeaderProps) {
  const id = useRef(utils.generateUuid())
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { search, setSearch } = useSearch()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation<NavigationProp<RootStackParamList>>()
  const { isSmallDevice } = useDimensions()
  const { landscape } = useOrientation()

  const searchBoxRef = useRef<TextInput | null>(null)

  const [key, setKey] = useState('')

  useEvent<BarcodeEvent>({ key: BARCODE_EVENT_KEY }, ({ barcode, id: callbackId }) => {
    callbackId === id.current && handleBarCodeScanned(barcode)
  })

  useFocus({ ref: searchBoxRef, focus: focus, disable: disableAutofocus })

  function handleBarCodeScanned(barcode: string) {
    Keyboard.dismiss()
    barcode && setSearch(barcode, true)
    setKey(utils.generateUuid())
  }

  function onQrCodeIconPress() {
    camera.requestCameraPermission(alert, i18n, () => navigation.navigate('Scanner', { id: id.current }))
  }

  return (
    <>
      <IM.SearchBox
        key={key}
        innerRef={searchBoxRef}
        initialValue={search}
        placeholder={i18n.t('SEARCH_PLACEHOLDER')}
        onEnterEnd={setSearch}
        backgroundColor={!isSmallDevice ? theme.header.detail.background : colorScheme === 'light' ? undefined : theme.inputBox.background.active}
        textColor={!isSmallDevice ? theme.header.main.text.primary : undefined}
        placeholderTextColor={colorScheme === 'dark' ? theme.textPlaceholder : Color(IMStyle.palette.white).darken(0.26).toString()}
        containerStyle={
          isSmallDevice
            ? IMLayout.flex.f1
            : {
                width: landscape ? CONSTANTS.landscapeTabletMaxSearchBar : CONSTANTS.portraitTabletMaxSearchBar,
              }
        }
        inputMode="search"
        icon={['fal', 'qrcode']}
        onIconPress={onQrCodeIconPress}
        hideIcon={Platform.OS === 'android' || Platform.OS === 'ios' ? !enableQR : true}
        deferredTimeout={deferredTimeout}
        hideBorder
        autoCapitalize="none"
        keyboardAppearance={colorScheme}
      />
    </>
  )
}
