import { CardProps, useDidUpdate } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { memo, useRef, useState } from 'react'
import { LayoutAnimation } from 'react-native'
import { SetterOrUpdater, useRecoilState } from 'recoil'

import { InvoiceType, TicketArticle } from '../../apis/types/apiResponseTypes'
import { arrayUtils } from '../../components/Infominds/utils/array'
import { PartDto, SparePartDirection } from '../../types'
import { shoppingCartAtom, shoppingCartRemovalAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'
import SparePartsCard from './SparePartsCard'

const layoutAnimConfig = {
  duration: 350,
  update: {
    duration: 200,
    type: LayoutAnimation.Types.easeIn,
    property: LayoutAnimation.Properties.opacity,
  },
  delete: {
    duration: 150,
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
  },
}

interface Props extends CardProps {
  activityId: string
  dto: Omit<PartDto, 'stockQuantity'>
  ticketArticles: TicketArticle[]
  invoiceTypes: InvoiceType[]
  direction: SparePartDirection
}

const ArticleSparePartShoppingCartCard = memo(function ArticleSparePartShoppingCartCard({
  activityId,
  dto,
  invoiceTypes,
  ticketArticles,
  direction,
  ...others
}: Props) {
  const [shown, setShown] = useState(false)
  const [state, setState] = useState<PartDto>(dto)
  const [defaultInvoiceTypeId, setDefaultInvoiceTypeId] = useState<string>()
  const [parts, setParts] = useRecoilState(shoppingCartAtom)
  const [removalParts, setRemovalParts] = useRecoilState(shoppingCartRemovalAtom)

  const id = useRef<NodeJS.Timeout>()

  useDidUpdate(() => {
    clearTimeout(id.current)
    id.current = setTimeout(() => {
      handleChange()
    }, 500)
  }, [state])

  const handleChange = () => {
    handleEditSparePart(state, direction === 'Installation' ? parts : removalParts, direction === 'Installation' ? setParts : setRemovalParts)
    LayoutAnimation.configureNext(layoutAnimConfig)
  }

  const handleEditSparePart = (newPart: PartDto, partsState: PartDto[] | undefined, setPartsState: SetterOrUpdater<PartDto[] | undefined>) => {
    if (!partsState) return

    const condition = (item: PartDto) => {
      return ticketUtils.getPartIdentifierShoppingCart(item) === ticketUtils.getPartIdentifierShoppingCart(newPart)
    }

    const toUpdate: PartDto = newPart
    const clone = cloneDeep(partsState)

    const foundIndex = clone.findIndex(condition)
    const found = clone.filter(condition)
    const othersParts = clone.filter(item => !condition(item))

    if (found.length > 1) {
      console.error('Found multiple shopping cart spare parts!')
    }

    toUpdate.status = ticketUtils.getSparePartStatus(toUpdate)
    setPartsState(
      arrayUtils
        .insert(othersParts, { ...toUpdate, invoicingTypeId: toUpdate.invoicingTypeId ?? defaultInvoiceTypeId }, foundIndex)
        .filter(el => !(el.prevStatus === 'new' && el.status === 'deleted'))
        .sort(
          (a, b) =>
            a.articleSearchtext?.localeCompare(b.articleSearchtext) ||
            (a.serialnumber && b.serialnumber ? a.serialnumber.localeCompare(b.serialnumber) : 0)
        )
    )
  }

  return (
    <SparePartsCard
      activityId={activityId}
      shown={shown}
      dto={state}
      articles={ticketArticles}
      invoices={invoiceTypes}
      onChangeDto={setState}
      onChangeShown={setShown}
      type="shoppingCart"
      changesPending={state.status !== 'init'}
      direction={direction}
      setDefaultInvoiceTypeId={setDefaultInvoiceTypeId}
      {...others}
    />
  )
})

export default ArticleSparePartShoppingCartCard
