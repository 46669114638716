import { IM, useTheme } from '@infominds/react-native-components'
import React, { Children, ReactElement, ReactNode, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../types'
import CardBasic from '../Infominds/CardBasic'
import CardContentContainer from './containers/CardContentContainer'
import CardHeaderContainer from './containers/CardHeaderContainer'
import CardLeftContainer from './containers/CardLeftContainer'

type Props = {
  children?: ReactNode
  direction?: 'vertical' | 'horizontal'
  selected?: boolean
  onPress?: () => void
}

export default function Card({ children, selected, onPress, direction = 'vertical' }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const flexDir = direction === 'horizontal' ? 'row' : 'column'
  const backgroundColor = selected ? theme.item.selected : 'transparent'

  const components = useMemo(() => {
    const childArray = Children.toArray(children)
    return {
      left: childArray.find(q => (q as ReactElement).type === CardLeftContainer),
      other: childArray.filter(q => (q as ReactElement).type === CardHeaderContainer || (q as ReactElement).type === CardContentContainer),
    }
  }, [children])

  return (
    <CardBasic onPress={onPress}>
      <IM.View style={styles.container}>
        {components.left}
        <IM.View
          style={[
            styles.elements,
            {
              flexDirection: flexDir,
              backgroundColor: backgroundColor,
            },
          ]}
          spacing="all">
          {components.other}
        </IM.View>
      </IM.View>
    </CardBasic>
  )
}

Card.Header = CardHeaderContainer
Card.Content = CardContentContainer
Card.Left = CardLeftContainer

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row' },
  elements: { flex: 1 },
})
