import { IM, IMLayout, useEvent } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, ScrollView, ScrollViewProps } from 'react-native'

import CONSTANTS from '../constants/Constants'
import { SECTION_LIST_CLOSE_TO_END_EVENT_KEY } from '../constants/EmitterKeys'
import { InfiniteLoadingType } from '../types'
import appUtils from '../utils/appUtils'
import { AnimatedButtonHideEvent } from './Infominds/AnimatedButton'
import RefreshControl from './Infominds/RefreshControl'
import SkeletonText from './skeleton/SkeletonText'

interface Props {
  loading?: InfiniteLoadingType
  buttonId?: string
  disableHideKeyboardOnScroll?: boolean
  refresh?: () => void
  onScroll?: ((event: NativeSyntheticEvent<NativeScrollEvent>) => void) | undefined
}

export default function ScrollViewData({
  children,
  loading = false,
  buttonId,
  disableHideKeyboardOnScroll,
  refresh,
  onScroll,
  ...others
}: Props & PropsWithChildren<ScrollViewProps>) {
  const { emit } = useEvent<AnimatedButtonHideEvent>({ key: SECTION_LIST_CLOSE_TO_END_EVENT_KEY })

  const handleScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
    !disableHideKeyboardOnScroll && Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()

    onScroll?.(e)

    if (!buttonId) return

    if (appUtils.closeToEndDetector(e, 50, true)) {
      emit({ hide: true, id: buttonId })
    } else {
      emit({ hide: false, id: buttonId })
    }
  }

  return (
    <ScrollView
      onScroll={handleScroll}
      scrollEventThrottle={200}
      scrollEnabled={loading !== 'reloading'}
      refreshControl={refresh ? <RefreshControl refreshing={false} onRefresh={refresh} /> : undefined}
      style={[IMLayout.flex.f1, others.style]}
      {...others}
      contentContainerStyle={[{ padding: 2 * IMLayout.horizontalMargin }, others.contentContainerStyle]}>
      {loading === false ? (
        children
      ) : (
        <>
          {Array(CONSTANTS.skeletonCards)
            .fill(0)
            .map((_, index) => {
              return (
                <IM.View spacing={index === 0 ? 'bottom' : 'vertical'} key={`ScrollViewData-${index}`}>
                  <SkeletonText width="30%" />
                  <SkeletonText width="60%" spacing="top" />
                  <SkeletonText width="100%" spacing="top" />
                </IM.View>
              )
            })}
        </>
      )}
    </ScrollView>
  )
}
