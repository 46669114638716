import { IM, IMLayout, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import type { Ticket } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import DynamicView from '../../components/Infominds/DynamicView'
import Tag from '../../components/Tag'
import useLayout, { ForceLayoutType } from '../../hooks/useLayout'
import { ThemeColorExpanded } from '../../types'
import { TicketListType, TicketListView } from '../../views/ticket/TicketList'
import DetailInfo from '../common/DetailInfo'
import TicketDescription from './components/TicketDescription'
import TicketIcon from './components/TicketIcon'
import TicketInfo from './components/TicketInfo'
import TicketTitle from './components/TicketTitle'

type Props = {
  ticket: Ticket
  spacing?: SpacingProps
  borderless?: boolean
  limitDescriptionToLines?: number
  forceLayout?: ForceLayoutType
  isSelected?: boolean
  style?: StyleProp<ViewStyle>
  type: TicketListType
  timeRunning?: boolean
  view: TicketListView
  onPress?: () => void
}

const TicketListCard = memo(function TicketListCard({
  ticket,
  spacing,
  onPress,
  borderless,
  style,
  limitDescriptionToLines,
  forceLayout,
  isSelected,
  type,
  view,
  timeRunning = false,
}: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice, isMediumDevice } = useLayout(true, forceLayout)

  const { i18n } = useLanguage()

  return (
    <IM.Card
      head={<CardLeftTitle color={ticket.priorityColorHex} />}
      onPress={onPress}
      borderless={borderless}
      spacing={spacing}
      style={[isSelected && { backgroundColor: theme.item.selected }, { borderRadius: IMLayout.borderRadius }, style]}>
      <DynamicView
        flexLimitSmallDevice={2}
        flexLimitLargeDevice={12}
        separatorVertical="largeDeviceOnly"
        separatorHorizontal="smallDeviceOnly"
        forceLayout={forceLayout}>
        <TicketIcon
          activityType={ticket.activityType}
          priorityColorHex={ticket.priorityColorHex}
          spacing="right"
          forceLayout={forceLayout}
          unReadMessages={ticket.countOfExternalComments}
        />
        {isSmallDevice && (
          <TicketTitle
            style={IMLayout.flex.f1}
            ticket={ticket}
            type={type}
            showIcon
            forceLayout={forceLayout}
            timeRunning={timeRunning}
            view={view}
          />
        )}
        {ticket.hasUnSyncedCompletedActivities && isSmallDevice && (
          <IM.View style={[IMLayout.flex.row]}>
            <Tag name={i18n.t('COMPLETED_PENDING_SYNC')} id={'PendingSyncTag'} active={true} />
          </IM.View>
        )}
        <DynamicView style={IMLayout.flex.f6} separatorVertical="largeDeviceOnly" separatorHorizontal="smallDeviceOnly" forceLayout={forceLayout}>
          {!isSmallDevice && <TicketTitle style={IMLayout.flex.f2} ticket={ticket} type={type} timeRunning={timeRunning} view={view} />}
          <DetailInfo style={IMLayout.flex.f3} object={ticket} />
          <TicketDescription
            style={isMediumDevice ? IMLayout.flex.f12 : IMLayout.flex.f6}
            ticket={ticket}
            spacing={isMediumDevice ? 'top' : 'none'}
            numberOfLines={limitDescriptionToLines}
          />
        </DynamicView>
        {!isSmallDevice && <TicketInfo style={styles.ticketInfo} ticket={ticket} showIcon showEmployee timeRunning={timeRunning} view={view} />}
      </DynamicView>
    </IM.Card>
  )
})

export default TicketListCard

const styles = StyleSheet.create({
  ticketInfo: {
    flex: 0.65,
  },
})
