import { IMLayout, useDimensions } from '@infominds/react-native-components'
import { ListRenderItemInfo } from '@shopify/flash-list'
import React, { Dispatch, SetStateAction } from 'react'

import { ArticleSparePart } from '../../apis/types/apiResponseTypes'
import SparePartsViewCard from '../../cards/spareParts/SparePartsViewCard'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import ticketUtils from '../../utils/TicketUtils'
import { SparePartsDetailsViewParams } from './SparePartsDetailsView'

interface Props {
  info: ListRenderItemInfo<ArticleSparePart | string>
  selected: string
  setSelected: Dispatch<SetStateAction<string>>
  disabled?: boolean
}

const SparePartsViewRenderItem = ({ info, selected, setSelected, disabled }: Props) => {
  const { isSmallDevice } = useDimensions()
  const navigation = useMasterDetail<SparePartsDetailsViewParams>()

  const item = info.item

  if (typeof item === 'string') return <></>

  const handlePress = (identifier: string, articleId: string, graphicId?: string | undefined) => {
    setSelected(identifier)
    navigation.setDetailParams({ articleId, graphicId })
  }

  const isSelected = isSmallDevice ? false : ticketUtils.getPartIdentifier({ ...item, date: new Date().toString() }) === selected

  return (
    <SparePartsViewCard
      selected={isSelected}
      sparePart={item}
      onPress={handlePress}
      style={{
        marginTop: IMLayout.horizontalMargin,
        marginHorizontal: 2 * IMLayout.horizontalMargin,
      }}
      disabled={disabled}
    />
  )
}

export default SparePartsViewRenderItem
