import { useTheme } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { memo, useEffect, useMemo } from 'react'

import { Email, EmailType } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_EMAIL_TYPE } from '../../constants/Keys'
import { FastAction, NoFastAction, ThemeColorExpanded } from '../../types'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import PressableTextIcon from '../Infominds/PressableTextIcon'
import EmailInput from './EmailInput'

type Props = (FastAction | NoFastAction) & {
  values: Email[] | undefined
  title: string
  addValueText: string
  editable?: boolean
  handleChange: (value: Email[] | undefined) => void
  getTypeRequest: (request: void, abortController?: AbortController) => Promise<EmailType[]>
}

const EmailInputGroup = memo(function EmailInputGroup({ values, title, addValueText, editable, getTypeRequest, handleChange, ...props }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { item, loadItem, loading } = useControlledLoader(getTypeRequest, { id: REQUEST_EMAIL_TYPE })

  const typesDisabled = useMemo(() => {
    const toRet: string[] = []
    values?.forEach(value => {
      toRet.push(value.type)
    })

    return toRet
  }, [])

  const maxElemReached = values && values.length >= CONSTANTS.maxPhoneNumbers

  useEffect(() => {
    loadItem()
  }, [])

  return (
    <>
      {values?.map((email, index) => {
        return (
          <EmailInput
            key={index}
            index={index}
            initialValue={email}
            title={index === 0 ? title : undefined}
            spacing="vertical"
            editable={editable}
            types={item}
            typesLoading={loading}
            typesDisabled={typesDisabled}
            onChange={(id, val) => {
              const clone = cloneDeep(values)
              clone[id] = val

              handleChange(clone)
            }}
            onRemoveLastNumber={removeIndex => {
              const clone = [...values]
              clone.splice(removeIndex, 1)

              handleChange(clone.length === 0 ? undefined : clone)
            }}
            {...props}
          />
        )
      })}
      {editable && (
        <PressableTextIcon
          onPress={() => {
            let types = item?.map(elem => {
              return elem.type
            })

            if (types) {
              for (const disabledType of typesDisabled) {
                types = types?.filter(elem => elem !== disabledType)
              }

              if (types.length > 0) {
                handleChange(
                  values === undefined
                    ? [{ type: types[0], description: '', address: '' }]
                    : [...values, { type: types[0], description: '', address: '' }]
                )
              }
            }
          }}
          icon={['fal', 'plus']}
          color={loading !== false || maxElemReached ? theme.textDetail : theme.general.info}
          disabled={loading !== false || maxElemReached}>
          {addValueText}
        </PressableTextIcon>
      )}
    </>
  )
})

export default EmailInputGroup
