import React from 'react'

import { InfoboxStackScreenProps } from '../../navigation/types'
import InfoboxAssetInfoView from '../../views/infobox/InfoboxAssetInfoView'

const InfoboxAssetInfoScreen = ({ route }: InfoboxStackScreenProps<'InfoboxNoBottomTabAssetInfo'>) => {
  return <InfoboxAssetInfoView id={route.params.id} infoboxTyp={route.params.infoboxType} origin={route.params.origin} />
}

export default InfoboxAssetInfoScreen
