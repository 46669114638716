import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { useRecoilValue } from 'recoil'

import { MasterDetailProvider } from '../../components/MasterDetail/contexts/MasterDetailContext'
import MasterDetail from '../../components/MasterDetail/MasterDetails'
import OnlineView from '../../components/offline/OnlineView'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import HeaderWithIcons from '../../components/screen/headers/HeaderWithIcons'
import SparePartsFilterDetailHeader from '../../components/screen/headers/SparePartsFilterDetailHeader'
import { Screen } from '../../components/screen/Screen'
import SparePartsFilterContext from '../../contexts/SparePartsFilterContext'
import { SparePartsStackScreenProps } from '../../navigation/types'
import { sparePartsScreenFilterEnableAtom } from '../../utils/stateManager'
import SparePartsDetailsView from '../../views/spareParts/SparePartsDetailsView'
import SparePartsView from '../../views/spareParts/SparePartsView'

export default function SparePartsScreen({ navigation }: SparePartsStackScreenProps<'SpareParts'>) {
  const { i18n } = useLanguage()
  const enabledFilters = useRecoilValue(sparePartsScreenFilterEnableAtom)

  const handleSDNavigation = () => {
    navigation.navigate('SparePartsCommonStack', { screen: 'SparePartsFilter' })
  }

  return (
    <SearchProvider>
      <SparePartsFilterContext.Consumer>
        {context => {
          let activeFilters = false
          context?.filters.forEach(filter =>
            filter.elements.forEach(el => {
              if (el.active) activeFilters = true
            })
          )
          const activeOrder = context?.orders.filter(el => el.active)

          return (
            <Screen>
              <Screen.Header>
                <HeaderWithIcons
                  title={i18n.t('SPARE_PARTS')}
                  tabletSearchComponent={<SearchDetailHeaderContainer dividers={['left']} iconPosition="right" enableQR />}
                  tabletFilterComponent={
                    <FilterDetailHeaderContainer dividers={['left']}>
                      <SparePartsFilterDetailHeader onSDNavigation={handleSDNavigation} enabled={enabledFilters} />
                    </FilterDetailHeaderContainer>
                  }
                  highlightFilter={activeFilters || activeOrder?.length !== 0}
                  disabled={!enabledFilters}
                />
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <SparePartsFilterDetailHeader onSDNavigation={handleSDNavigation} enabled={enabledFilters} />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <MasterDetailProvider>
                  <MasterDetail flexRatio={0.5}>
                    <MasterDetail.Master>
                      <SparePartsView />
                    </MasterDetail.Master>
                    <MasterDetail.Detail>
                      <OnlineView>
                        <SparePartsDetailsView />
                      </OnlineView>
                    </MasterDetail.Detail>
                  </MasterDetail>
                </MasterDetailProvider>
              </Screen.Content>
            </Screen>
          )
        }}
      </SparePartsFilterContext.Consumer>
    </SearchProvider>
  )
}
