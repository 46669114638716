import { IM, IMLayout, IMStyle, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { TicketArticle } from '../../apis/types/apiResponseTypes'
import CardBasic from '../../components/Infominds/CardBasic'
import TextWithIcon from '../../components/Infominds/TextWithIcon'

interface Props {
  item: TicketArticle
  selected?: boolean
  onPress?: () => void
  spacing?: SpacingProps
}

export default function TicketArticleCard({ item, selected = false, onPress, spacing }: Props) {
  const { theme } = useTheme()

  const articleSerialNumber = () =>
    item.serialnumber && (
      <TextWithIcon
        color={theme.textDetail}
        icon={['fal', 'barcode']}
        style={[IMLayout.flex.f1, { color: theme.textDetail }]}
        // eslint-disable-next-line react-native/no-inline-styles
        viewStyle={{ marginTop: 1 }}>
        {item.serialnumber + (item.serialnumberNumberManufactor ? ` (${item.serialnumberNumberManufactor})` : '')}
      </TextWithIcon>
    )

  const articleLocation = () =>
    item.serialnumberLocation && (
      <TextWithIcon
        color={theme.textDetail}
        icon={['fal', 'location-dot']}
        style={{ color: theme.textDetail }}
        // eslint-disable-next-line react-native/no-inline-styles
        viewStyle={{ marginTop: 1 }}>
        {item.serialnumberLocation}
      </TextWithIcon>
    )

  return (
    <IM.View spacing={spacing}>
      <CardBasic onPress={onPress} selected={selected}>
        <IM.View spacing="all" style={styles.itemContainer}>
          <IM.View style={IMLayout.flex.f1}>
            <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular }}>{item.articleSearchtext}</IM.Text>
            <IM.Text secondary>{item.articleCode}</IM.Text>
            {articleSerialNumber()}
            {articleLocation()}
            <IM.View spacing="top">
              <IM.Text style={{ color: theme.textDetail }} numberOfLines={2}>
                {item.articleDescription}
              </IM.Text>
            </IM.View>
          </IM.View>
        </IM.View>
      </CardBasic>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  itemContainer: { flexDirection: 'row', alignItems: 'center' },
})
