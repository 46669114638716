import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import { ActivityType } from '../../../types'
import ticketUtils from '../../../utils/TicketUtils'
import MapMarkerIcon from './MapMarkerIcon'

interface Props {
  activityType: ActivityType | undefined
  selected: boolean
}

export default function MapMarker({ activityType, selected }: Props) {
  const { i18n } = useLanguage()

  return <MapMarkerIcon icon={ticketUtils.getTicketIconByActivity(activityType)} highlight={selected} highlightText={i18n.t('GOTO_DETAIL')} />
}
