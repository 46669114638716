import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import api from '../apis/apiCalls'
import { REQUEST_ADDITIONAL_FIELDS_DEFINITIONS } from '../constants/Keys'
import { AddFieldValue, AdditionalFieldTextInputRef } from '../types'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import AdditionalFieldDefinitionInput from './input/AdditionalFieldDefinitionInput'
import DefaultBaseTextInputTitle from './input/baseTextInput/DefaultBaseTextInputTitle'
import SkeletonText from './skeleton/SkeletonText'

interface Props {
  onChange: () => void
  onError: (value: boolean) => void
}

const TicketCreationAddFieldGroup = ({ onChange, onError }: Props, ref: ForwardedRef<AdditionalFieldTextInputRef>) => {
  useImperativeHandle(ref, () => ({
    getState: () => state,
    isMandatoryMissing: isMandatoryMissing,
  }))

  const { i18n } = useLanguage()
  const [state, setState] = useState<AddFieldValue[]>([])
  const [fieldError, setFieldError] = useState<boolean[]>([])
  const { item, loadItem: loadAddFields, loading } = useControlledLoader(api.getAddFieldsDefinitions, { id: REQUEST_ADDITIONAL_FIELDS_DEFINITIONS })

  useEffect(() => {
    loadAddFields({ type: 'Ticket', onlyForApp: true })
  }, [])

  useEffect(() => {
    onChange()
  }, [state])

  useEffect(() => {
    if (loading === false && item) {
      setFieldError(Array(item.length).fill(false))
    }
  }, [loading, item])

  useEffect(() => {
    if (fieldError.length !== 0) {
      onError(!fieldError.every(el => !el))
    }
  }, [fieldError])

  const handleChangeText = (newVal: AddFieldValue) => {
    let clone = cloneDeep(state)

    const indx = clone.findIndex(el => el.definitionId === newVal.definitionId)

    if (indx !== -1) {
      if (newVal.value) {
        clone[indx].value = newVal.value
      } else {
        clone = clone.filter(el => el.definitionId !== newVal.definitionId)
      }
    } else {
      newVal.value && clone.push(newVal)
    }

    setState(clone)
  }

  const isMandatoryMissing = () => {
    let toRet = false
    const mandatoryArray: boolean[] = []

    item?.forEach(el => el.isMandatory && mandatoryArray.push(false))

    item?.forEach(el => {
      if (el.isMandatory) {
        if (state.length !== mandatoryArray.length) {
          toRet = true
        }
      }
    })

    return toRet
  }

  return (
    <>
      <IM.View style={{ marginTop: IMLayout.horizontalMargin * 4, marginBottom: IMLayout.horizontalMargin - 2 }}>
        <DefaultBaseTextInputTitle title={i18n.t('ADDITIONAL_FIELDS')} />
      </IM.View>
      {loading === false ? (
        <>
          {item?.length === 0 ? (
            <IM.Text secondary>{i18n.t('NO_ADDITIONAL_FIELD')}</IM.Text>
          ) : (
            <>
              {item?.map((elem, index) => (
                <AdditionalFieldDefinitionInput
                  key={elem.id}
                  definition={elem}
                  onChange={handleChangeText}
                  spacing="bottom"
                  skipValidation
                  onError={value => {
                    const clone = cloneDeep(fieldError)
                    if (clone[index] !== value) {
                      clone[index] = value
                      setFieldError(clone)
                    }
                  }}
                />
              ))}
            </>
          )}
        </>
      ) : (
        <SkeletonText width="100%" />
      )}
    </>
  )
}

export default forwardRef(TicketCreationAddFieldGroup)
