import { IM, useTheme } from '@infominds/react-native-components'
import React, { Children, cloneElement, ReactElement, ReactNode, useMemo } from 'react'
import { StyleSheet } from 'react-native'

import Detail from './containers/Detail'
import Master from './containers/Master'
import useMasterDetail from './hooks/useMasterDetail'

export type MasterDetailProps = {
  children: ReactNode
  flexRatio?: number
}

function MasterDetail({ children, flexRatio }: MasterDetailProps) {
  const { active } = useMasterDetail()
  const { theme, colorScheme } = useTheme()

  const borderColor = colorScheme === 'light' ? theme.input.border : '#424242'

  const { master, detail } = useMemo(() => findMasterAndDetail(), [children])

  function findMasterAndDetail() {
    const childArray = Children.toArray(children)
    return {
      master: childArray.find(q => (q as ReactElement).type === Master) as ReactElement,
      detail: childArray.find(q => (q as ReactElement).type === Detail) as ReactElement,
    }
  }

  const flexMaster = flexRatio ? flexRatio : 0.34
  const flexDetails = flexRatio ? 1 - flexRatio : 0.66

  return (
    <IM.View style={styles.root}>
      {master && cloneElement(master, { flex: active ? flexMaster : 1 })}
      {detail && active && cloneElement(detail, { borderColor: borderColor, flex: active ? flexDetails : 1 })}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  root: { flex: 1, flexDirection: 'row' },
})

MasterDetail.Detail = Detail
MasterDetail.Master = Master

export default MasterDetail
