import { useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'

import { getApi } from '../../../apis/apiCalls'
import { Contact } from '../../../apis/types/apiResponseTypes'
import useRequest from '../../../components/Infominds/hooks/useRequest'
import CONSTANTS from '../../../constants/Constants'
import { REQUEST_EDIT_TICKET_ARTICLE } from '../../../constants/Keys'
import { useDataProvider } from '../../../dataProvider/hooks/useDataProvider'
import { Gender } from '../../../types'
import CustomerContactIdCard, { CustomerContactIdCardProps } from '../../customer/CustomerContactIdCard'

interface Props {
  id: string
  articleId: string
  serialNumberId: string | undefined
  onChangedContactId: (contactId: string | undefined) => void
}

export default function TicketArticleCustomerContactIdCard({
  id,
  articleId,
  serialNumberId,
  onChangedContactId,
  ...others
}: CustomerContactIdCardProps & Props) {
  const contactId = useRef(others.contactId)

  const { i18n } = useLanguage()
  const { client } = useDataProvider()
  const [error, setError] = useState(false)

  const { request, loading: loadingEdit } = useRequest(getApi(client).editTicketArticle, { id: REQUEST_EDIT_TICKET_ARTICLE })

  useEffect(() => {
    loadingEdit === 'catched' && setError(true)
    loadingEdit === false && onChangedContactId(contactId.current)
  }, [loadingEdit])

  const handleChangeContact = (newContact: Contact | undefined) => {
    if (!newContact) return

    contactId.current = newContact.id === noSelectionEntry.id ? undefined : newContact.id

    setError(false)
    !!serialNumberId &&
      request({
        id,
        articleId,
        serialnumberId: serialNumberId,
        contactId: newContact.id === noSelectionEntry.id ? '' : newContact.id,
      })
  }

  const noSelectionEntry: Contact = {
    id: CONSTANTS.noSelectionId,
    addressId: '',
    companyId: '',
    gender: Gender.None,
    inactive: false,
    description: i18n.t('NO_SELECTION'),
  }

  return <CustomerContactIdCard loading={loadingEdit} error={error} noElement={noSelectionEntry} onChange={handleChangeContact} {...others} />
}
