import { useEvent } from '@infominds/react-native-components'
import React, { createContext, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'

import { ACTIVITY_CLOSE_NEXT_ACT_EVENT_KEY } from '../constants/EmitterKeys'
import useCloseActivity from '../hooks/useCloseActivity'
import { CloseActivityEvent, ClosingDto } from '../types'

interface ContextProps {
  value: string | undefined
  setValue: React.Dispatch<React.SetStateAction<string | undefined>>
  employeeId: string | undefined
  setEmployeeId: React.Dispatch<React.SetStateAction<string | undefined>>
  initial: string | undefined
}

export const NextActivityContext = createContext<ContextProps | undefined>(undefined)

interface ProviderProps {
  initialEmployeeId?: string
}

export const NextActivityProvider = ({ children, initialEmployeeId }: PropsWithChildren & ProviderProps) => {
  const initialValue = useRef(initialEmployeeId)
  const [value, setValue] = useState<string | undefined>(undefined)
  const [employeeId, setEmployeeId] = useState(initialEmployeeId)
  const { closeType } = useCloseActivity()

  const { emit } = useEvent<CloseActivityEvent>({ key: ACTIVITY_CLOSE_NEXT_ACT_EVENT_KEY })

  useEffect(() => {
    const event: CloseActivityEvent<Pick<ClosingDto, 'nextActivityEmployeeId' | 'nextActivityTechnicalDescription'>> = {
      done: value !== undefined && value !== '' && employeeId !== undefined,
      pending: closeType === undefined ? false : value !== undefined || value !== '' || employeeId !== initialValue.current,
      dto: {
        nextActivityEmployeeId: employeeId,
        nextActivityTechnicalDescription: value,
      },
    }

    emit(event)
  }, [closeType, value, employeeId])

  const contextValue: ContextProps = useMemo(
    () => ({
      value,
      setValue,
      employeeId,
      setEmployeeId,
      initial: initialValue.current,
    }),
    [employeeId, initialValue, value]
  )

  return <NextActivityContext.Provider value={contextValue}>{children}</NextActivityContext.Provider>
}
