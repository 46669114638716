import { useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { Platform, SectionListRenderItemInfo, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Destination } from '../../apis/types/apiResponseTypes'
import CustomerDestinationCard from '../../cards/customer/CustomerDestinationCard'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import { ADD_CONTACT_DESTINATION_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { EDIT_DESTINATION_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_CUSTOMER_DESTINATIONS } from '../../constants/Keys'
import DestinationEditOrCreateModal from '../../modals/customer/DestinationEditOrCreateModal'
import { CustomerStackParamList } from '../../navigation/types'
import { DestinationEditOrCreateParams, DestinationViewRef, ListSection } from '../../types'

interface Props {
  customerId: string
  show: boolean
  showTitle?: boolean
  skeletonNumber?: number
  closeEndThreshold?: number
  hideSectionTitle?: boolean
  selectedId?: string
  onPress?: (destination: Destination) => void
  onRefresh?: () => void
}

const DestinationsView = (
  { show, customerId, selectedId, showTitle, skeletonNumber, hideSectionTitle, closeEndThreshold, onPress, onRefresh }: Props,
  ref: ForwardedRef<DestinationViewRef>
) => {
  useImperativeHandle(ref, () => ({
    openModal: () => destinationEditOrCreateModalController.show({ customerId }),
  }))

  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<CustomerStackParamList>>()

  const destinationEditOrCreateModalController = useModalController<DestinationEditOrCreateParams>()

  const {
    item: destinations,
    loadItem: loadDestinations,
    loading,
    allDataLoaded,
    loadMore,
  } = useInfiniteLoader(api.getCustomerDestinations, { chuckSize: CONSTANTS.destinationsChuckSize, id: REQUEST_CUSTOMER_DESTINATIONS })
  useEvent({ key: EDIT_DESTINATION_DATA_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [search])

  const refresh = () => {
    onRefresh?.()
    loadDestinations({ customerId: customerId, searchtext: search })
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<Destination, ListSection<Destination>>) => {
    return (
      <CustomerDestinationCard
        destination={item}
        spacing={['horizontal', 'bottom']}
        selected={item.id === selectedId}
        onPress={() => {
          if (onPress) {
            onPress?.(item)
          } else {
            const data = { destination: item, customerId }

            isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
              ? navigation.navigate('CustomerDetailDestinationEditOrCreate', data)
              : destinationEditOrCreateModalController.show(data)
          }
        }}
      />
    )
  }

  const data: ListSection<Destination>[] = useMemo(() => {
    const displayData: ListSection<Destination>[] = []

    if (destinations?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        title: showTitle ? i18n.t('DESTINATIONS') : undefined,
        data: destinations,
      })
    }

    return displayData
  }, [destinations, loading])

  return (
    <>
      {show && (
        <SectionList
          contentContainerStyle={stylesList.list}
          loading={loading}
          noDataIcon={['fal', 'location-dot-slash']}
          loadingSection={i18n.t('DESTINATIONS')}
          noDataSection={i18n.t('DESTINATIONS')}
          noDataMessage={i18n.t('NO_DESTINATION_FOUND')}
          sections={data}
          onRefresh={refresh}
          renderItem={renderItem}
          skeletonElements={skeletonNumber ?? CONSTANTS.accessDataSkeletonCards}
          onLoadMore={loadMore}
          allDataLoaded={allDataLoaded}
          closeEndThreshold={closeEndThreshold ?? 90}
          hideButtonId={ADD_CONTACT_DESTINATION_BUTTON_ID}
          hideSectionTitle={hideSectionTitle}
        />
      )}
      <DestinationEditOrCreateModal controller={destinationEditOrCreateModalController} />
    </>
  )
}

const stylesList = StyleSheet.create({
  list: { paddingTop: 4 },
})

export default forwardRef(DestinationsView)
