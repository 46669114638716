import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, IMStyle, useEvent, useTheme } from '@infominds/react-native-components'
import { createDrawerNavigator, DrawerContentComponentProps, DrawerNavigationOptions } from '@react-navigation/drawer'
import { DrawerActions, useNavigation } from '@react-navigation/native'
import React, { useMemo, useState } from 'react'
import { Platform, Pressable, StyleSheet } from 'react-native'

import { HIDE_NAVIGATION_EVENT_KEY } from '../../constants/EmitterKeys'
import useLayout from '../../hooks/useLayout'
import useMenu from '../../hooks/useMenu'
import useUserInfo from '../../hooks/useUserInfo'
import useUserSettings from '../../hooks/useUserSettings'
import { BottomTabParamList, RootStackParamList } from '../../navigation/types'
import { ThemeColorExpanded } from '../../types'
import navigationUtils from '../../utils/navigationUtils'
import EmployeeBadge from '../EmployeeBadge'
import HoverView from '../HooverView'
import Text from '../Text'
import { DrawerProvider } from './DrawerContext'
import useDrawer from './hooks/useDrawer'
import PressableIcon from './PressableIcon'

const Drawer = createDrawerNavigator()

const SETTINGS = {
  iconSize: 22,
}

interface DrawerNavigatorProps {
  initialRouteName?: keyof BottomTabParamList
  screenOptions?: DrawerNavigationOptions
}

export default function DrawerNavigator<RouteName extends keyof BottomTabParamList>({ initialRouteName, screenOptions }: DrawerNavigatorProps) {
  const { isLargeDevice } = useLayout(true)
  const { menuItems } = useUserSettings()
  const { tabs, maxDynamicElements } = useMenu<RouteName>()
  const { theme } = useTheme<ThemeColorExpanded>()

  const sortedTabs = useMemo(() => {
    const sorted = navigationUtils.sortTabs(tabs, maxDynamicElements, menuItems)
    sorted.push(
      sorted.splice(
        sorted.findIndex(e => e.name === 'SettingsStack'),
        1
      )[0]
    )

    return sorted
  }, [tabs, maxDynamicElements, menuItems])

  if (tabs.length === 0 || menuItems === undefined) {
    if (Platform.OS !== 'web') {
      return (
        <IM.View style={[IMLayout.flex.f1, { backgroundColor: theme.background }]}>
          <IM.LoadingSpinner isVisible />
        </IM.View>
      )
    } else {
      return <></>
    }
  }

  return (
    <DrawerProvider>
      <Drawer.Navigator
        defaultStatus="closed"
        initialRouteName={initialRouteName}
        drawerContent={DrawerContent}
        screenOptions={{
          drawerType: isLargeDevice ? 'permanent' : 'front',
          headerShown: false,
          drawerStyle: {
            width: isLargeDevice ? 'auto' : undefined,
            backgroundColor: theme.drawerNavigator.background,
            borderRightColor: theme.drawerNavigator.background,
          },
          ...screenOptions,
        }}>
        {menuItems &&
          sortedTabs.map(tab => {
            if (tab.name === 'MoreEmpty') {
              return null
            } else {
              return (
                <Drawer.Screen
                  key={`DrawerScreen${tab.name}`}
                  name={tab.name}
                  component={tab.component}
                  options={{
                    ...(tab.options as DrawerContentComponentProps),
                    title: tab.title,
                    drawerIcon: iconProps => DrawerIcon(iconProps, tab.icon),
                  }}
                />
              )
            }
          })}
      </Drawer.Navigator>
    </DrawerProvider>
  )
}

function DrawerContent(props: DrawerContentComponentProps) {
  return <DrawerBar {...props} />
}

function DrawerBar(tabProps: DrawerContentComponentProps) {
  const [hide, setHide] = useState(false)

  const { name, surname, fullName, picture, loading } = useUserInfo()
  const { isLargeDevice } = useLayout(true)
  const { open, setOpen } = useDrawer()
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation()

  useEvent<boolean>({ key: HIDE_NAVIGATION_EVENT_KEY }, value => setHide(value))

  return (
    <IM.View style={[styles.tabBarRoot, IMStyle.layout.shadow, hide && styles.hideDrawerBar]}>
      <IM.View
        style={[styles.main, styles.tabBarContent, { backgroundColor: theme.drawerNavigator.bar }, (open || !isLargeDevice) && styles.tabBarOpen]}>
        <IM.View style={styles.tabContainer}>
          {userSettings && (
            <>
              <IM.View style={[styles.firstElement, styles.tabBarEmployeeBadge]}>
                <EmployeeBadge
                  key={picture}
                  base64={picture}
                  size={38}
                  showName={false}
                  id={userSettings?.employeeId || undefined}
                  name={fullName ?? ''}
                  color={fullName === undefined ? theme.drawerNavigator.bar : undefined}
                />
              </IM.View>

              <IM.View style={IMStyle.layout.flex.f1}>
                {tabProps.state.routes.map((s, index) => {
                  return (
                    <DrawerBarContent
                      key={`DrawerTab${index}`}
                      routeKey={s.key}
                      index={index}
                      tabProps={tabProps}
                      end={s.name === 'SettingsStack'}
                      onlyText={false}
                      open={open}
                    />
                  )
                })}
              </IM.View>

              {isLargeDevice && (
                <PressableIcon
                  icon={['fal', open ? 'angles-left' : 'angles-right']}
                  size={15}
                  color="#ffffff"
                  onPress={() => {
                    const revert = !open
                    setOpen(revert)
                  }}
                />
              )}
            </>
          )}
        </IM.View>
      </IM.View>
      {((isLargeDevice && open) || !isLargeDevice) && (
        <IM.View
          style={[
            styles.drawerOpen,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              paddingBottom: isLargeDevice ? 44 : 8,
              backgroundColor: theme.drawerNavigator.bar,
            },
          ]}>
          <IM.View style={styles.employeeContainer}>
            {userSettings && userSettings.employeeId && (
              <IM.View style={[styles.firstElement, styles.drawerEmployee]}>
                <IM.View style={[IMLayout.flex.row]}>
                  <IM.View style={[styles.employeeName, IMLayout.flex.f1]}>
                    {!loading && (
                      <>
                        <Text style={styles.drawerEmployeeFirst}>{name}</Text>
                        <Text style={styles.drawerEmployeeSecond}>{surname}</Text>
                      </>
                    )}
                  </IM.View>
                  {!isLargeDevice && (
                    <PressableIcon
                      icon={['fal', 'times']}
                      color={IMStyle.palette.white}
                      onPress={() => navigation.dispatch(DrawerActions.closeDrawer())}
                      size={26}
                    />
                  )}
                </IM.View>
              </IM.View>
            )}
          </IM.View>
          {tabProps.state.routes.map((s, index) => {
            return (
              <DrawerBarContent
                key={`DrawerTab${index}`}
                routeKey={s.key}
                index={index}
                tabProps={tabProps}
                end={s.name === 'SettingsStack'}
                onlyText
                open={(isLargeDevice && open) || !isLargeDevice}
              />
            )
          })}
        </IM.View>
      )}
    </IM.View>
  )
}

function DrawerIcon(iconProps: { focused: boolean; color: string; size: number }, icon?: IconProp, customIconSize?: number) {
  if (!icon) return undefined
  return <IM.Icon size={customIconSize ?? iconProps.size} icon={icon} color={iconProps.color} />
}

function DrawerBarContent(props: {
  routeKey: string
  index: number
  tabProps: DrawerContentComponentProps
  end: boolean
  onlyText: boolean
  open: boolean
}) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const { state, descriptors, navigation } = props.tabProps
  const route = state.routes.find(r => r.key === props.routeKey)
  const descriptor = descriptors[props.routeKey]
  const isFocused = state.index === props.index
  const label = descriptor?.options?.title || route?.name || ''

  const { tabsDisabled } = useMenu()

  const onPress = () => {
    const event = navigation.emit({
      type: 'drawerItemPress',
      target: props.routeKey,
      canPreventDefault: true,
    })

    const bottomTabName: keyof RootStackParamList = 'BottomTab'
    if (Platform.OS === 'web') {
      if (route?.state) {
        if (route?.state.stale) {
          navigation.reset({
            index: 0,
            routes: [
              {
                name: bottomTabName,
                params: {
                  screen: route?.name,
                },
              },
            ],
          })
        } else {
          navigation.navigate(bottomTabName, { screen: route?.name, params: { screen: route?.state.routeNames?.at(0) } })
        }
      }
    }

    if (!isFocused && !event.defaultPrevented && route) {
      navigation.navigate(route.name, route.params)
    }
  }

  return (
    <IM.View style={props.end && styles.tabContentRoot}>
      <Pressable key={`DrawerTab${props.routeKey}`} onPress={onPress} style={styles.tab} disabled={tabsDisabled}>
        {isFocused && (
          <>
            {!props.onlyText && (
              <IM.View style={[styles.iconContainer, { backgroundColor: theme.tabNavigator.focused.iconBackground }]}>
                {!!descriptor?.options.drawerIcon &&
                  descriptor?.options.drawerIcon({
                    focused: isFocused,
                    color: theme.drawerNavigator.icon.focused,
                    size: SETTINGS.iconSize,
                  })}
              </IM.View>
            )}
          </>
        )}
        {!isFocused && !props.onlyText ? (
          <HoverView style={styles.iconContainer} disabled={tabsDisabled}>
            {!!descriptor?.options.drawerIcon &&
              descriptor?.options.drawerIcon({
                focused: isFocused,
                color: tabsDisabled ? theme.drawerNavigator.background : theme.drawerNavigator.icon.unFocused ?? '',
                size: SETTINGS.iconSize,
              })}
          </HoverView>
        ) : (
          <>
            {props.open && props.onlyText && (
              // eslint-disable-next-line react-native/no-inline-styles
              <HoverView style={[styles.iconContainer, { alignItems: 'flex-start' }]} disabled={tabsDisabled}>
                <Text
                  style={[
                    styles.text,
                    styles.tabContentText,
                    tabsDisabled && { color: theme.textPlaceholder },
                    isFocused && { color: theme.primary },
                  ]}>
                  {label}
                </Text>
              </HoverView>
            )}
          </>
        )}
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  firstElement: {
    height: 70,
  },
  tabBarEmployeeBadge: {
    alignItems: 'center',
    marginTop: 6,
  },
  tabBarContent: {
    width: 60,
  },
  tabBarOpen: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  tabContentText: {
    color: '#ffffff',
    marginLeft: 15,
  },
  tabContentRoot: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  main: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flex: 1,
    borderRadius: 12,
  },
  iconContainer: {
    height: 45,
    marginBottom: 8,
    aspectRatio: 1,
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabContainer: {
    flexDirection: 'column',
    padding: 8,
    flex: 1,
  },
  tab: {
    justifyContent: 'center',
  },
  tabBarRoot: { flex: 1, margin: 10, flexDirection: 'row', borderRadius: 12 },
  text: {
    alignSelf: 'center',
  },
  androidBorder: { borderTopWidth: 1 },
  drawerOpen: {
    padding: 8,
    width: 200,
    opacity: 0.8,
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
  },
  drawerEmployee: { marginTop: 6 },
  drawerEmployeeFirst: { color: '#ffffff', fontSize: IMStyle.typography.fontSizeRegular },
  drawerEmployeeSecond: { color: '#ffffff', fontSize: IMStyle.typography.fontSizeRegular, fontWeight: IMStyle.typography.fontWeightBold },
  employeeName: {
    marginLeft: 15,
    marginTop: 1,
  },
  employeeContainer: {
    height: 76,
  },
  hideDrawerBar: { display: 'none' },
})
