import { useDimensions } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { createContext, memo, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilState } from 'recoil'

import useIsOnline from '../../../dataProvider/hooks/useIsOnline'
import { ScreenHeight, ScreenStatus } from '../../../types'
import { ticketViewAtom } from '../../../utils/stateManager'
import { SCREEN_CONSTANTS } from '../constants/constants'
import { DetailHeaderType, ScreenViewType } from '../types'

interface ScreenContextProps {
  detail: ScreenStatus
  height: ScreenHeight
  tmpDetailOpen: boolean
  view: ScreenViewType | undefined
  setDetailOpen: (type: DetailHeaderType) => void
  setDetailHeight: (val: number) => void
  setDetailTmpStatus: (val: boolean) => void
  setView: (view: ScreenViewType | undefined) => void
}

interface Props {
  detailHeight: number
  forceDetailOpen: boolean
}

const ScreenContext = createContext<ScreenContextProps | undefined>(undefined)

export const ScreenProvider = memo(function ScreenProvider({ children, forceDetailOpen, detailHeight }: PropsWithChildren<Props>) {
  const { isSmallDevice } = useDimensions()
  const { sessionKey } = useAuthentication()

  const isOnline = useIsOnline()
  const [tmpOpen, setTmpOpen] = useState(false)
  const [view, setView] = useRecoilState(ticketViewAtom(sessionKey))
  const [state, setState] = useState<ScreenStatus>({ open: forceDetailOpen, type: DetailHeaderType.SEARCH })
  const [height, setHeight] = useState<ScreenHeight>({
    detail: detailHeight,
    header: SCREEN_CONSTANTS.headerBarHeight,
  })

  useEffect(() => {
    state.open && !isSmallDevice && setState(prev => ({ ...prev, open: false }))
  }, [isSmallDevice])

  useEffect(() => {
    // if offline change view if necessary, because map view is not available offline
    if (!isOnline && view === ScreenViewType.LOCATION) setView(ScreenViewType.LIST)
  }, [isOnline])

  const handleOpenDetail = useCallback(
    (type: DetailHeaderType) => {
      const newOpenStatus = state.type.toString() === type.toString() ? !state.open : true

      if (tmpOpen === false && newOpenStatus === false) {
        setTmpOpen(true)
      } else {
        setState({
          open: newOpenStatus,
          type,
        })
      }
    },
    [state, tmpOpen]
  )

  const handleTmpStatus = useCallback((status: boolean) => {
    setTmpOpen(status)
  }, [])

  const handleDetailHeight = useCallback((val: number) => {
    setHeight(prev => ({ ...prev, detail: val }))
  }, [])

  const props = useMemo<ScreenContextProps>(
    () => ({
      detail: state,
      height,
      tmpDetailOpen: tmpOpen,
      view,
      setDetailOpen: handleOpenDetail,
      setDetailHeight: handleDetailHeight,
      setDetailTmpStatus: handleTmpStatus,
      setView,
    }),
    [state, height, tmpOpen, view]
  )

  return <ScreenContext.Provider value={props}>{children}</ScreenContext.Provider>
})

export default ScreenContext
