import { IM, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React from 'react'
import { StyleSheet } from 'react-native'

import useIsOnline from '../../../dataProvider/hooks/useIsOnline'
import useSynchronization from '../../../dataProvider/hooks/useSynchronization'
import { ThemeColorExpanded } from '../../../types'
import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'

const SettingsHeader = () => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { logout } = useAuthentication()
  const isOnline = useIsOnline()
  const { alert } = useAlert()
  const { deleteLocalData, enabled } = useSynchronization()

  const handleLogout = () => {
    if (enabled) {
      alert(i18n.t('WARNING'), i18n.t('LOGOUT_WARNING'), [
        {
          text: i18n.t('CANCEL'),
          style: 'default',
        },
        {
          text: i18n.t('LOGOUT'),
          style: 'destructive',
          onPress: () => {
            deleteLocalData()
              .catch(console.error)
              .finally(() => {
                logout().catch(err => console.error('Logout error', err))
              })
          },
        },
      ])
    } else {
      logout().catch(err => console.error('Logout error', err))
    }
  }

  return (
    <IM.View style={styles.container}>
      <IM.Text style={[styles.contentText, styles.title]}>{i18n.t('TAB_SETTINGS')}</IM.Text>
      <Pressable onPress={handleLogout} disabled={!isOnline}>
        {isOnline && (
          <TextWithIcon alignIcon="right" icon={['fal', 'person-to-door']} iconSize={22} color={theme.header.main.error}>
            {i18n.t('LOGOUT')}
          </TextWithIcon>
        )}
        <IM.View style={styles.offlineLogout}>{!isOnline && <IM.Text secondary>{i18n.t('OFFLINE_USER_CHANGE')}</IM.Text>}</IM.View>
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-between' },
  contentText: {
    color: IMStyle.palette.white,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
  },
  offlineLogout: {
    maxWidth: 180,
  },
})

export default SettingsHeader
