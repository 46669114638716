import { Utils } from '@infominds/react-native-components'
import { useState } from 'react'
import { Platform } from 'react-native'

import { dataProviderCore } from '../InitDataProvider'
import { DataProviderStateActions, PendingRequest } from '../types'
import { useDataProvider } from './useDataProvider'

export default function useSynchronization() {
  const { dispatch, dataSyncManager, forcedOfflineMode, isOnline, pendingDataToSync, dataStorage, enabled } = useDataProvider()
  const [initOk, setInitOk] = useState(false)

  async function init(fullSync?: boolean) {
    setInitOk(false)
    // enable DataProvider
    dispatch({
      type: DataProviderStateActions.Enable,
      payload: { enabled: true },
    })
    await dataSyncManager.InitSync(fullSync)
    setInitOk(true)
  }

  function setForcedOfflineMode(value: boolean) {
    dispatch({
      type: DataProviderStateActions.UpdateOnlineState,
      payload: { forcedOfflineMode: value, isOnline: !value },
    })
  }

  function onDownSyncComplete(setOffline?: boolean) {
    dispatch({
      type: DataProviderStateActions.SyncComplete,
      payload: {},
    })
    if (setOffline) {
      setForcedOfflineMode(true)
    }
  }

  function onUpSyncComplete() {
    setForcedOfflineMode(false)
  }

  function processPendingRequests(requestsToProcess?: PendingRequest[]) {
    return dataSyncManager.ProcessPendingRequests(false, requestsToProcess)
  }

  function deletePendingRequest(requestsToDelete: PendingRequest) {
    return dataSyncManager.DeleteFailedPendingRequest(requestsToDelete)
  }

  async function deleteLocalData() {
    if (Platform.OS === 'web') return
    await dataStorage.DeleteAll()
    dispatch({ type: DataProviderStateActions.Enable, payload: { enabled: false } })
  }

  async function getOfflineDataSize() {
    if (Platform.OS === 'web') return 0
    const result = await dataStorage.GetResourceCount(true)
    return Utils.sum(result, q => q.size ?? 0)
  }

  function checkApiConnection() {
    return dataProviderCore.CheckApiAlive()
  }

  return {
    init,
    initOk,
    enabled,
    isOnline,
    setForcedOfflineMode,
    onDownSyncComplete,
    onUpSyncComplete,
    forcedOfflineMode,
    getPendingRequests: (id?: string) => dataSyncManager.GetPendingRequests(id),
    pendingDataToSync,
    processPendingRequests,
    deletePendingRequest,
    dataStorage,
    dataSyncManager,
    deleteLocalData,
    getOfflineDataSize,
    checkApiConnection,
  }
}
