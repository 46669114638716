import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { IM, IMLayout, SpacingProps, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import api from '../../apis/apiCalls'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import DataProviderUtils from '../../dataProvider/utils/DataProviderUtils'
import { ExceptionUtils } from '../../utils/ExceptionUtils'
import { SyncEntry, SyncEntryState } from '../../views/synchronization/DownSynchronizationView'
import CardLeftTitle from '../card/left/CardLeftTitle'
import LoadingIcon from '../LoadingIcon'

export type SyncViewProps = {
  deviceId: string
  entry: SyncEntry
  setState: (state: SyncEntryState) => void
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

export default function Synchronizer({ entry, deviceId, setState, spacing, style }: SyncViewProps) {
  const { theme } = useTheme()
  const { language } = useLanguage()
  const { dataProvider, dataSyncManager, dataStorage } = useDataProvider()

  const [error, setError] = useState('')
  const [blockNumber, setBlockNumber] = useState(Math.max(1, Math.min(...(entry.details?.map(q => q.startWithBlock) ?? 1))))
  const [totalData, setTotalData] = useState<{ count: number; size: number } | null>(null)
  const [totalDataSynced, setTotalDataSynced] = useState(0)

  const data = useRef<object[]>([])

  const totalDataSyncedText = useMemo(
    () =>
      `${Math.min(
        totalDataSynced,
        Utils.sum(entry.details, q => q.dataCount ?? 0)
      )} / ${Utils.sum(entry.details, q => q.dataCount ?? 0)}`,
    [totalDataSynced, entry.details]
  )

  const done = entry.state === 'done'
  const stateIcon = useMemo<{ icon: IconProp; color: string }>(() => {
    if (entry.state === 'done') return { icon: ['fas', 'circle-check'], color: theme.primary }
    if (entry.state === 'error') return { icon: ['fas', 'times-circle'], color: theme.error }
    return { icon: ['fas', 'refresh'], color: theme.info }
  }, [entry.state])

  useEffect(() => {
    if (!entry) {
      setState('done')
      return
    }
    const abortController = new AbortController()
    if (entry.state !== 'busy') return
    setError('')
    sync(abortController).catch(exception => {
      if (!abortController.signal) return
      console.error('Synchronizer sync()', entry.resource, 'Error:', exception)
      setError(ExceptionUtils.exceptionToString(exception))
      setState('error')
    })

    return () => abortController.abort()
  }, [blockNumber, entry.state])

  useEffect(() => {
    if (!done) return
    dataSyncManager
      .GetStorageInfo(entry.resource?.id)
      .then(info => {
        setTotalData(info)
      })
      .catch(console.error)
  }, [done])

  async function sync(abortController: AbortController) {
    const resource = entry.resource
    const ids = (dataProvider.dataStorage.GetResource(resource?.id ?? '').dataIds ?? []) as (keyof object)[]
    if (!resource || !ids) {
      throw new Error(`DataProviderSyncManager SyncData(): No resource was found with the syncType '${entry.details.at(0)?.type ?? 'unknown'}'`)
    }
    if (entry.details.find(q => q.fullSync) && blockNumber === 1) {
      await dataStorage.DeleteAll(resource.id)
    }
    let anyDataSynced = false
    for (const detail of entry.details) {
      if (blockNumber < detail.startWithBlock || blockNumber > detail.blockCount) continue

      const result = await api.getSynchronizationData({ deviceId: deviceId, blockNumber, type: detail.type }, abortController)
      if (!!abortController && !abortController.signal) throw new Error('Aborted')
      if (result?.data) {
        const syncedData = (Array.isArray(result.data) ? result.data : [result.data]) as object[]
        if (!abortController.signal) throw new Error('Aborted')
        if (syncedData?.length) {
          anyDataSynced = true
          for (const sData of syncedData) {
            const foundData = data.current?.find(q => DataProviderUtils.compareObjects(q, sData, ids))
            if (foundData) {
              Object.assign(foundData, sData)
            } else {
              data.current.push(sData)
            }
          }
          console.debug('Synced Block', blockNumber, syncedData.length, detail.type, resource.resource)
        }

        setTotalDataSynced(prev => prev + (syncedData?.length ?? 0))
      }
    }
    if (anyDataSynced) {
      setBlockNumber(prev => prev + 1)
      return
    }

    // end
    await dataStorage.UpdateOrCreate(resource.id, DataProviderUtils.resultToArray<object>(data.current))

    if (blockNumber <= Math.min(...entry.details.map(q => q.blockCount ?? 1))) throw new Error('Not all data was synced')
    setState('done')
    return
  }

  return (
    <IM.View spacing={spacing} style={style}>
      <IM.Card head={<CardLeftTitle />}>
        <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
          <IM.View style={IMLayout.flex.f1}>
            <IM.Text primary>{entry.resource?.id ?? entry.details.at(0)?.type}</IM.Text>
          </IM.View>
          <IM.View style={[IMLayout.flex.row, styles.center, { gap: IMLayout.horizontalMargin }]}>
            {!!totalData?.count && (
              <IM.Text secondary style={styles.sizeText}>
                {DataProviderUtils.convertObjectSizeToString(totalData.size, language)}
              </IM.Text>
            )}
            <IM.Text>{totalDataSyncedText}</IM.Text>
          </IM.View>
          <IM.View style={styles.center}>
            {['error', 'done'].includes(entry.state) ? <IM.Icon icon={stateIcon.icon} color={stateIcon.color} /> : <LoadingIcon />}
          </IM.View>
        </IM.View>
        {entry.state === 'error' && (
          <IM.View style={IMLayout.flex.row}>
            <IM.View style={IMLayout.flex.f1}>
              <IM.Text style={{ color: theme.error }}>{error}</IM.Text>
            </IM.View>
            <IM.View>
              <IM.PressableIcon size={20} icon={['fal', 'refresh']} onPress={() => setState('busy')} />
            </IM.View>
          </IM.View>
        )}
      </IM.Card>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  sizeText: {
    marginLeft: 10,
  },
})
