import React, { memo, useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'

import useCloseActivityId from '../hooks/useCloseActivityId'
import useCloseManager from '../hooks/useCloseManager'
import Switch from './Switch'

interface Props {
  activityId: string
  ticketId: string
  documentId: string
  onError: (hasError: boolean) => void
}

const ActivityCloseSwitch = memo(function ActivityCloseSwitch({ activityId, ticketId, documentId, onError }: Props) {
  const { getActivity, setActivity } = useCloseActivityId()
  const { canBeClosed, check } = useCloseManager(activityId, ticketId, documentId)

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (canBeClosed === 'checking') {
      onError(false)
      setLoading(true)
    } else if (canBeClosed === true) {
      onError(false)
      setActivity(activityId)
      setLoading(false)
    } else if (canBeClosed === false) {
      onError(true)
      setLoading(false)
    }
  }, [canBeClosed])

  const handleChangeValue = () => {
    if (value === undefined) {
      check()
    } else {
      setActivity(activityId)
    }
  }

  const value = getActivity(activityId)

  return (
    <Switch
      value={value !== undefined}
      onValueChange={handleChangeValue}
      loading={loading}
      disabled={value?.mandatory === true}
      loadingStyle={styles.loading}
      style={{ transform: [{ scale: 0.8 }] }}
    />
  )
})

export default ActivityCloseSwitch

const styles = StyleSheet.create({
  loading: {
    marginTop: 1,
    marginBottom: 2,
  },
})
