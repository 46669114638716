import { IM, IMLayout, Language, SpacingProps, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import isArray from 'lodash/isArray'
import React, { Children, ForwardedRef, memo, PropsWithChildren, ReactElement, useImperativeHandle, useMemo, useRef, useState } from 'react'
import { Platform, TextInput as RNTextInput, TextInputProps as RNTextInputProps, StyleProp, StyleSheet, TextStyle } from 'react-native'

import { TEXT_INPUT_BLUR_EVENT_KEY } from '../../../constants/EmitterKeys'
import { Border, TextInputRef, ThemeColorExpanded } from '../../../types'
import { utils } from '../../../utils/utils'
import Pressable from '../../Infominds/Pressable'
import Text from '../../Text'
import BaseTextInputError from './BaseTextInputError'
import BaseTextInputExternalIcon from './BaseTextInputExternalIcon'
import BaseTextInputLeftIcon from './BaseTextInputLeftIcon'
import BaseTextInputRightIcon from './BaseTextInputRightIcon'
import BaseTextInputTitle from './BaseTextInputTitle'
import useBaseTextInput from './hooks/useBaseTextInput'

type ManagedType = 'email' | 'url' | 'number' | 'decimal' | 'phone' | 'time' | 'date' | 'postal-code' | 'town' | 'address' | 'province' | undefined

export type BaseTextInputProps = Omit<RNTextInputProps, 'scrollEnabled'> & {
  type?: ManagedType
  spacing?: SpacingProps
  disableBorderRadius?: Border | Border[]
  onPress?: () => void
  onWidth?: (width: number) => void
  onHeight?: (height: number) => void
  unity?: string
  fixMultilineHeight?: boolean
}

export function handleBorderStyle(borders: Border | Border[], topStyle: StyleProp<TextStyle>, bottomStyle: StyleProp<TextStyle>) {
  const toRet: StyleProp<TextStyle>[] = []

  if (isArray(borders)) {
    borders?.find(elem => elem === 'top') && toRet.push(topStyle)
    borders?.find(elem => elem === 'bottom') && toRet.push(bottomStyle)
  } else {
    switch (borders) {
      case 'bottom': {
        toRet.push(bottomStyle)
        break
      }
      case 'top': {
        toRet.push(topStyle)
        break
      }
    }
  }

  return toRet
}

function getTypeProps(
  type: ManagedType,
  language: Language
): Pick<RNTextInputProps, 'inputMode' | 'keyboardType' | 'secureTextEntry' | 'autoComplete'> {
  switch (type) {
    case 'email': {
      return {
        inputMode: 'email',
        keyboardType: 'email-address',
        autoComplete: 'email',
      }
    }
    case 'url': {
      return { inputMode: 'url', keyboardType: 'url' }
    }
    case 'decimal': {
      return language === 'en'
        ? {
            keyboardType: 'numbers-and-punctuation',
          }
        : { inputMode: 'decimal', keyboardType: 'decimal-pad' }
    }
    case 'number': {
      return {
        inputMode: 'numeric',
        keyboardType: 'numeric',
      }
    }
    case 'phone': {
      return {
        inputMode: 'tel',
        keyboardType: 'phone-pad',
        autoComplete: 'tel',
      }
    }
    case 'date': {
      return {
        inputMode: 'numeric',
        keyboardType: 'numeric',
        autoComplete: 'birthdate-full',
      }
    }
    case 'time': {
      return {
        inputMode: 'numeric',
        keyboardType: 'numeric',
      }
    }
    case 'address': {
      return {
        inputMode: 'text',
        autoComplete: 'postal-address',
      }
    }
    case 'postal-code': {
      return {
        inputMode: 'numeric',
        autoComplete: 'postal-code',
      }
    }
    case 'province': {
      return {
        inputMode: 'text',
      }
    }
    case 'town': {
      return {
        inputMode: 'text',
        autoComplete: 'postal-address-locality',
      }
    }
    case undefined: {
      return {}
    }
  }
}

const BaseTextInput = memo(
  React.forwardRef(
    (
      { children, spacing, type, disableBorderRadius, unity, value, fixMultilineHeight = false, ...props }: PropsWithChildren<BaseTextInputProps>,
      ref: ForwardedRef<TextInputRef>
    ) => {
      const { onPress, onBlur, onWidth, onHeight } = props

      const innerRef = useRef<RNTextInput>(null)

      useImperativeHandle(ref, () => ({
        focus: () => innerRef.current?.focus(),
        blur: () => innerRef.current?.blur(),
      }))

      const uuid = useRef(utils.generateUuid())
      const { i18n, language } = useLanguage()
      const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
      const { backgroundColor, borderColor, loading, editable, onClickEffect } = useBaseTextInput()

      const { emit } = useEvent<string>({ key: TEXT_INPUT_BLUR_EVENT_KEY }, id => id !== uuid.current && innerRef.current?.blur())

      const [scrollHeight, setScrollHeight] = useState<number | undefined>(undefined)

      const components = useMemo(() => {
        const childArray = Children.toArray(children)
        return {
          title: childArray.find(q => (q as ReactElement).type === BaseTextInputTitle),
          rightIcon: childArray.filter(q => (q as ReactElement).type === BaseTextInputRightIcon),
          externalIcon: childArray.filter(q => (q as ReactElement).type === BaseTextInputExternalIcon),
          leftIcon: childArray.filter(q => (q as ReactElement).type === BaseTextInputLeftIcon),
          error: childArray.filter(q => (q as ReactElement).type === BaseTextInputError),
        }
      }, [children])

      return (
        <IM.View spacing={spacing}>
          {components.title}
          <IM.View style={IMLayout.flex.row}>
            <IM.View
              style={[IMLayout.flex.f1, styles.container]}
              onLayout={ev => {
                onWidth?.(ev.nativeEvent.layout.width)
                onHeight?.(ev.nativeEvent.layout.height)
              }}>
              {components.leftIcon.length !== 0 && components.leftIcon}
              <Pressable
                containerStyle={IMLayout.flex.f1}
                style={styles.pressable}
                disabled={!editable}
                onPress={() => {
                  emit(uuid.current)
                  setTimeout(() => {
                    onPress?.()
                  }, 100)
                }}>
                <RNTextInput
                  placeholder={loading ? i18n.t('LOADING_PLACEHOLDER') : undefined}
                  {...props}
                  {...getTypeProps(type, language)}
                  ref={innerRef}
                  value={value}
                  style={[
                    styles.input,
                    Platform.OS === 'android' && styles.inputHorizontal,
                    styles.overflow,
                    components.rightIcon.length !== 0 && components.leftIcon.length !== 0
                      ? undefined
                      : components.rightIcon.length !== 0
                        ? styles.borderInputRightIcon
                        : components.leftIcon.length !== 0
                          ? styles.borderInputLeftIcon
                          : styles.border,
                    disableBorderRadius && handleBorderStyle(disableBorderRadius, styles.disableTopBorder, styles.disableBottomBorder),
                    {
                      height: scrollHeight,
                      borderColor,
                      backgroundColor,
                      color: theme.text,
                    },
                    props.multiline && styles.multilinePaddingTop,
                    props.multiline && Platform.OS === 'ios' && fixMultilineHeight && { height: 26 * 3 },
                    props.multiline && Platform.OS === 'android' && { maxHeight: 26 * 3 },
                    props.style,
                  ]}
                  onFocus={() => onClickEffect(true)}
                  onBlur={e => {
                    onBlur?.(e)
                    onClickEffect(false)
                  }}
                  autoCapitalize="none"
                  editable={onPress !== undefined ? false : loading === false && editable}
                  placeholderTextColor={theme.textPlaceholder}
                  pointerEvents={onPress !== undefined ? 'none' : 'auto'}
                  keyboardAppearance={colorScheme}
                  scrollEnabled={props.multiline && Platform.OS === 'ios' ? true : false}
                  cursorColor={Platform.OS === 'android' ? theme.general.info : undefined}
                  onContentSizeChange={event => Platform.OS === 'web' && props.multiline && setScrollHeight(event.nativeEvent.contentSize.height)}
                />
              </Pressable>
              {unity && (
                <Pressable
                  style={[
                    styles.unity,
                    {
                      backgroundColor,
                      borderColor,
                    },
                  ]}>
                  <Text secondary>{unity}</Text>
                </Pressable>
              )}
              {components.rightIcon.length !== 0 && components.rightIcon}
            </IM.View>
            {components.externalIcon.length !== 0 && components.externalIcon}
          </IM.View>
          {components.error}
        </IM.View>
      )
    }
  )
)

const BaseTextInputNamespace = Object.assign(BaseTextInput, {
  RightIcon: BaseTextInputRightIcon,
  ExternalIcon: BaseTextInputExternalIcon,
  LeftIcon: BaseTextInputLeftIcon,
  Title: BaseTextInputTitle,
  Error: BaseTextInputError,
})

export { BaseTextInputNamespace as BaseTextInput }

const padding = Platform.OS === 'android' ? 7 : 12

const styles = StyleSheet.create({
  border: {
    borderRadius: IMLayout.borderRadius,
  },
  borderInputRightIcon: {
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
  borderInputLeftIcon: {
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
  },
  container: { flexDirection: 'row' },
  disableBottomBorder: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  disableTopBorder: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  input: {
    borderWidth: 1,
    padding: padding,
  },
  inputHorizontal: {
    paddingHorizontal: 12,
  },
  multilinePaddingTop: { paddingTop: padding },
  overflow: { overflow: 'hidden' },
  pressable: { padding: 0 },
  unity: {
    borderRadius: 0,
    flex: 1,
    paddingRight: 10,
    paddingLeft: 0,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    marginLeft: -1,
    borderLeftWidth: 0,
  },
})

export const baseTextInputStyles = StyleSheet.create({
  icon: {
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 4,
  },
  rightIcon: {
    borderLeftWidth: 0,
    borderTopRightRadius: IMLayout.borderRadius,
    borderBottomRightRadius: IMLayout.borderRadius,
    marginLeft: -2,
  },
  leftIcon: {
    borderRightWidth: 0,
    marginRight: -2,
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
})
