import { CardProps, IM, IMLayout, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import Separator from '../../components/Infominds/Separator'
import TextInput from '../../components/input/TextInput'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import DataProviderUtils from '../../dataProvider/utils/DataProviderUtils'

export type DataProviderResourceCardProps = {
  resource: string
  count: number
} & CardProps

export default function DataProviderResourceCard({ resource, count, head, ...cardProps }: DataProviderResourceCardProps) {
  const { language } = useLanguage()
  const { themeUtils } = useTheme()
  const color = useMemo(() => themeUtils.getRandomColorFromTheme(resource), [resource])
  const { dataStorage, dataSyncManager } = useDataProvider()
  const [items, setItems] = useState<object[]>([])
  const [open, setOpen] = useState(false)
  const [searchText, setSearchText] = useState('')
  const itemsToShow = useMemo(() => items.filter(i => JSON.stringify(i).toLowerCase().includes(searchText.toLowerCase())), [items, searchText])
  const [size, setSize] = useState('')

  useEffect(() => {
    dataStorage
      .GetStorageSize(resource)
      .then(s => setSize(DataProviderUtils.convertObjectSizeToString(s, language)))
      .catch(console.error)
  }, [])

  function handlePressed() {
    if (items.length) {
      setOpen(false)
      setItems([])
      return
    }

    dataStorage
      .Get<object>(resource)
      .then(result => {
        result.sort(a => (dataSyncManager.IsOfflineData(a) ? -1 : 1))
        setItems(result)
        setOpen(true)
      })
      .catch(console.error)
  }

  return (
    <IM.Card head={head ?? <CardLeftTitle color={color} />} onPress={handlePressed} {...cardProps}>
      <IM.View style={[IMLayout.flex.row, { gap: IMLayout.horizontalMargin }]}>
        <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row]}>
          <IM.Text primary>{resource}</IM.Text>
        </IM.View>
        <IM.View>
          <IM.Text secondary>{`${count} (${size})`}</IM.Text>
        </IM.View>
      </IM.View>
      {!!open && (
        <IM.View>
          <TextInput value={searchText} onChangeText={setSearchText} spacing={'top'} />
          {itemsToShow.map((item, index) => (
            <ItemCard key={`Item#${index}`} item={item} />
          ))}
        </IM.View>
      )}
    </IM.Card>
  )
}

function ItemCard({ item }: { item: object }) {
  const content = useMemo(() => {
    return Object.keys(item)
      .sort((a, b) => Utils.compareStringsForSort(a, b))
      .map((key: string) => {
        const dataAsString = JSON.stringify(item[key as keyof typeof item], undefined, 2)
        return `• ${key} : ${!dataAsString || dataAsString?.length < 10000 ? dataAsString : '{...}'}`
      })
      .join('\n')
  }, [item])

  if (!content) return <></>

  return (
    <IM.View>
      <IM.View spacing={'all'}>{!!content && <IM.Text>{content}</IM.Text>}</IM.View>
      <Separator />
    </IM.View>
  )
}
