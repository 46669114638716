import { useContext } from 'react'

import VaultContext, { VaultContextProps } from '../contexts/VaultContext'

export default function useVault(): VaultContextProps {
  const context = useContext(VaultContext)

  if (context === undefined) {
    throw new Error('useVault() must be called inside VaultProvider')
  }

  return context
}
