import { useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { memo, useEffect, useState } from 'react'
import { Image, ImageStyle, Platform, StyleProp } from 'react-native'

interface Props {
  uri: string | undefined
  width: number
  height: number
  style?: StyleProp<ImageStyle>
  color?: string
  onError?: (value: boolean) => void
  onDone?: (value: boolean) => void
}

const BlobImage = memo(function BlobImage({ uri, width, height, style, color, onError, onDone }: Props) {
  const { theme } = useTheme()
  const { bearerToken } = useAuthentication()

  const [loading, setLoading] = useState(true)
  const [blobUrl, setBlobUrl] = useState('')

  useEffect(() => {
    if (Platform.OS === 'web' && uri && bearerToken) {
      setLoading(true)
      onError?.(false)

      fetch(uri, {
        headers: {
          Authorization: bearerToken,
        },
      })
        .then(res => {
          if (res.status === 200) {
            res
              .blob()
              .then(blob => setBlobUrl(URL.createObjectURL(blob)))
              .catch(err => {
                console.error('Failed converting blob', err)
                onError?.(true)
              })
              .finally(() => setLoading(false))
          } else {
            throw new Error()
          }
        })
        .catch(() => {
          onError?.(true)
          setLoading(false)
        })
    }
  }, [uri, bearerToken])

  useEffect(() => {
    onDone?.(!loading)
  }, [loading])

  if (!bearerToken) return <></>

  return (
    <>
      {Platform.OS === 'web' ? (
        <Image source={{ uri: blobUrl }} style={[{ width, height }, style, { backgroundColor: color ?? theme.background }]} />
      ) : (
        <Image
          source={{
            uri: uri,
            method: 'GET',
            headers: {
              Authorization: bearerToken,
            },
          }}
          style={[{ width, height }, style, { backgroundColor: color ?? theme.background }]}
          onLoadStart={() => onError?.(false)}
          onLoadEnd={() => setLoading(false)}
          onError={err => {
            // on Android it returns {"error": "unknown image format"}
            if (Platform.OS === 'ios') {
              // code 204 means image is missing
              const code = (err.nativeEvent as { responseCode?: number }).responseCode
              if (code !== 204) {
                console.error((err.nativeEvent as { responseCode?: number }).responseCode)
              }
            }

            onError?.(true)
          }}
        />
      )}
    </>
  )
})

export default BlobImage
