import { useContext } from 'react'

import FormContext, { FormContextProps } from '../contexts/FormContext'

export default function useForm(): FormContextProps {
  const context = useContext(FormContext)

  if (context === undefined) {
    throw new Error('useForm() must be called inside FormProvider')
  }

  return context
}
