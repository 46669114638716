import { useEvent } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import ScrollViewForm from '../../components/ScrollViewForm'
import EmployeeSelector from '../../components/selectors/EmployeeSelector'
import {
  REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY,
  REFRESH_TICKET_ACTIVITY_EVENT_KEY,
  REFRESH_TICKET_LIST_EVENT_KEY,
} from '../../constants/EmitterKeys'
import { EditOrCreateViewProps, EditOrCreateViewRef, UploadStatus } from '../../types'

type Props = { activityId: string; activityEmployeeId?: string | undefined } & Omit<EditOrCreateViewProps, 'disabled'> & {
    origin: 'ticket' | 'planning'
    ticketId?: string
    ticketCode?: string
  }

const ActivityAllocationView = (
  { activityId, activityEmployeeId, origin, ticketCode, ticketId, onUploadStatus }: Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const initialData = useRef(undefined)

  const { request: edit, loading: loadingEdit } = useRequest(api.editActivity)
  const navigation = useNavigation()

  const { emit } = useEvent({ key: REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY })
  const { emit: emitTickets } = useEvent({ key: REFRESH_TICKET_LIST_EVENT_KEY })
  const { emit: emitTicketActivity } = useEvent({ key: REFRESH_TICKET_ACTIVITY_EVENT_KEY })

  const [state, setState] = useState<string | undefined>(initialData.current)
  const [defaultValue, setDefaultValue] = useState<string>()
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  useEffect(() => {
    updateUploadStatus(state === undefined && defaultValue === undefined ? 'done' : getStatus())
  }, [state, defaultValue])

  useEffect(() => {
    if (loadingEdit === 'catched') {
      updateUploadStatus(getStatus())

      return
    }

    const loadingDone = loadingEdit === false

    if (waitingUpload !== 'done' && loadingDone) {
      emit()
      emitTickets()
      emitTicketActivity()
      origin === 'planning'
        ? navigation.navigate('BottomTab', { screen: 'PlanningStack', params: { screen: 'Planning' } })
        : navigation.navigate('BottomTab', {
            screen: 'TicketsStack',
            params: { screen: 'TicketDetail', params: { ticketId: ticketId ?? '', ticketCode: ticketCode ?? '' } },
          })
      updateUploadStatus('done')
    }
  }, [loadingEdit])

  const getStatus = () => {
    return state === initialData.current ? 'waitingWithoutPending' : 'waiting'
  }

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = () => {
    updateUploadStatus('uploading')

    edit({
      id: activityId,
      employeeId: state ?? defaultValue,
      state: 'Allocation',
    } as Activity)
  }

  const handleChangeText = (newVal: string | undefined) => {
    if (waitingUpload === 'uploading') return

    setState(newVal)
  }

  return (
    <ScrollViewForm scrollEnabled={false}>
      <EmployeeSelector
        type="planning"
        id={activityEmployeeId}
        editable={waitingUpload !== 'uploading'}
        spacing="top"
        onChange={val => handleChangeText(val === undefined ? undefined : val.id)}
        onDefaultFound={setDefaultValue}
      />
    </ScrollViewForm>
  )
}

export default forwardRef(ActivityAllocationView)
