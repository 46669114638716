import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'
import { IM, Spacing, useTheme } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren } from 'react'
import { Platform, StyleSheet, useWindowDimensions } from 'react-native'
import DefaultModal from 'react-native-modal'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'

import useLayout from '../hooks/useLayout'
import appUtils from '../utils/appUtils'

interface Props extends PropsWithChildren {
  isVisible?: boolean
  spacing?: Spacing
  backdropOpacity?: number
  isFullWidth?: boolean
  onClose: () => void
}

const TabletModal = memo(function TabletModal({ children, backdropOpacity, isFullWidth, isVisible, onClose, ...props }: Props) {
  const { colorScheme } = useTheme()
  const { isSmallDevice } = useLayout(true)
  const { height, width } = useWindowDimensions()
  const modalWidth = isFullWidth ? '100%' : '50%'

  return (
    <>
      {(!isSmallDevice || (isSmallDevice && Platform.OS === 'web')) && (
        <DefaultModal
          isVisible={isVisible}
          backdropOpacity={backdropOpacity ?? appUtils.getModalOpacity(colorScheme)}
          propagateSwipe
          statusBarTranslucent={true}
          swipeDirection={[]}
          style={[styles.modal, { width: modalWidth }]}
          deviceHeight={height}
          deviceWidth={width}
          animationIn="slideInRight"
          animationOut="slideOutRight"
          hideModalContentWhileAnimating
          onBackButtonPress={onClose}
          onBackdropPress={onClose}
          {...props}>
          <SafeAreaProvider
            initialMetrics={initialWindowMetrics}
            // @ts-ignore ok on web
            style={Platform.select({ web: { colorScheme: colorScheme === 'dark' ? 'dark' : 'light' } })}>
            <IM.AlertProvider>
              <BottomSheetModalProvider>{children}</BottomSheetModalProvider>
            </IM.AlertProvider>
          </SafeAreaProvider>
        </DefaultModal>
      )}
    </>
  )
})

export default TabletModal

const styles = StyleSheet.create({
  modal: {
    alignSelf: 'flex-end',
    height: '100%',
    margin: 0,
    minWidth: 450,
    maxWidth: 550,
  },
})
