import { IMLayout, SpacingProps } from '@infominds/react-native-components'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { SectionListRenderItemInfo, TextStyle } from 'react-native'

import CONSTANTS from '../../../constants/Constants'
import SelectModal from '../../../modals/SelectModal'
import { FiniteLoadList, InfiniteLoadingType, InfiniteLoadList, ListSection } from '../../../types'
import MultiSelectField from '../../input/MultiSelectField'
import { SearchProvider } from '../../screen/contexts/SearchContext'
import SectionList from '../../SectionList'
import SkeletonText from '../../skeleton/SkeletonText'

type Props<T> = (FiniteLoadList | InfiniteLoadList) & {
  values: T[]
  data?: T[]
  title?: string
  screenTitle?: string
  loading: InfiniteLoadingType
  focusSearch?: boolean
  editable?: boolean
  disableLoadAfterMount?: boolean
  spacing?: SpacingProps
  noDataMessage: string
  deferredTimeout?: number | undefined
  titleFontWeight?: TextStyle['fontWeight']
  extraIcon?: ReactNode
  refresh: () => void
  renderItem: (renderItem: SectionListRenderItemInfo<T, ListSection<T>>, onPress?: () => void) => JSX.Element
  renderMultiSelectItem: (item: T) => ReactNode
  onChange: (value: T) => void
  onClose?: () => void
  onSearchChange?: (searchText: string) => void
}

export default function MultiSelectInput<T extends { id: string }>({
  values,
  data,
  loading,
  title = '',
  screenTitle,
  focusSearch,
  spacing,
  noDataMessage,
  deferredTimeout,
  titleFontWeight,
  editable = true,
  disableLoadAfterMount = false,
  extraIcon,
  ...other
}: Props<T>) {
  const { onClose, renderItem, onChange, refresh, onSearchChange, renderMultiSelectItem } = other
  const [isModalBottomVisible, setModalBottomVisible] = useState(false)

  useEffect(() => {
    !disableLoadAfterMount && isModalBottomVisible && refresh()
    !isModalBottomVisible && onClose?.()
  }, [isModalBottomVisible])

  const render = (renderObj: SectionListRenderItemInfo<T, ListSection<T>>) => {
    return renderItem(renderObj, () => {
      onChange(renderObj.item)
      setModalBottomVisible(false)
    })
  }

  const sections: ListSection<T>[] = useMemo(() => {
    const displayData: ListSection<T>[] = []

    if (data?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        data: data,
      })
    }

    return displayData
  }, [data, loading])

  return (
    <>
      <MultiSelectField
        title={title}
        titleFontWeight={titleFontWeight}
        values={values}
        editable={editable}
        spacing={spacing}
        renderItem={item => {
          const found = data?.find(el => el.id === item.id)

          if (found) return renderMultiSelectItem(found)
          return <></>
        }}
        onPress={() => setModalBottomVisible(true)}
      />
      <SearchProvider>
        <SelectModal
          focusSearch={focusSearch}
          isVisible={isModalBottomVisible}
          close={() => setModalBottomVisible(false)}
          title={screenTitle ?? title}
          deferredTimeout={deferredTimeout}
          extraIcon={extraIcon}
          onSearchChange={onSearchChange}>
          <SectionList
            {...other}
            sections={sections}
            loading={loading}
            renderItem={render}
            noDataMessage={noDataMessage}
            skeletonElements={CONSTANTS.skeletonCards}
            skeletonComponent={<SkeletonText height={20} width={250} spacing="all" />}
            onRefresh={refresh}
            contentContainerStyle={{ margin: IMLayout.verticalMargin, paddingBottom: 2 * IMLayout.verticalMargin }}
          />
        </SelectModal>
      </SearchProvider>
    </>
  )
}
