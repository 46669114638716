import {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetScrollView,
  BottomSheetView,
  BottomSheetModal as GorhomBottomSheetModal,
  useBottomSheetDynamicSnapPoints,
} from '@gorhom/bottom-sheet'
import { IMLayout, useDimensions, useTheme } from '@infominds/react-native-components'
import React, { PropsWithChildren, useMemo, useRef } from 'react'
import { LayoutChangeEvent, StyleSheet, useWindowDimensions, View } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import useBackHandlerWrapper from '../hooks/useBackHandlerWrapper'
import { ThemeColorExpanded } from '../types'

interface Props {
  disableCentering?: boolean
  disableBottomOffset?: boolean
  scrollEnabled?: boolean
  onDismiss?: () => void
}

const BottomSheetModal = ({ children, disableCentering, disableBottomOffset, scrollEnabled, onDismiss }: PropsWithChildren<Props>) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useDimensions()
  const { width } = useWindowDimensions()
  const { bottom } = useSafeAreaInsets()
  const { height } = useWindowDimensions()

  const bottomSheetModalRef = useRef<GorhomBottomSheetModal>(null)

  useBackHandlerWrapper(() => {
    bottomSheetModalRef.current?.close()
    return true
  })

  const initialSnapPoints = useMemo(() => ['CONTENT_HEIGHT'], [])

  const { animatedHandleHeight, animatedSnapPoints, animatedContentHeight, handleContentLayout } = useBottomSheetDynamicSnapPoints(initialSnapPoints)

  const renderBackdrop = (props: BottomSheetBackdropProps) => (
    // https://github.com/gorhom/react-native-bottom-sheet/issues/1343#issuecomment-1516805171
    <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} opacity={0.4} />
  )

  const commonProps = {
    onLayout: (ev: LayoutChangeEvent) => {
      ev.nativeEvent.layout.height += disableBottomOffset ? 6 : bottom + 12
      handleContentLayout(ev)
    },
  }

  return (
    <View onLayout={() => bottomSheetModalRef.current?.present()}>
      <GorhomBottomSheetModal
        ref={bottomSheetModalRef}
        // @ts-ignore: working
        snapPoints={animatedSnapPoints}
        handleHeight={animatedHandleHeight}
        contentHeight={animatedContentHeight}
        enableDismissOnClose
        onDismiss={onDismiss}
        backdropComponent={renderBackdrop}
        style={
          !isSmallDevice &&
          !disableCentering && [
            styles.bottomSheetLargeDevice,
            {
              marginLeft: (width - 540) / 2,
            },
          ]
        }
        containerStyle={styles.bottomSheet}
        backgroundStyle={{ backgroundColor: theme.moreSheet.background }}
        handleIndicatorStyle={{ backgroundColor: theme.moreSheet.indicator }}>
        {!scrollEnabled ? (
          <BottomSheetView style={IMLayout.flex.f1} {...commonProps}>
            {children}
          </BottomSheetView>
        ) : (
          <BottomSheetScrollView style={[{ maxHeight: height * 0.4 }, styles.content]} {...commonProps}>
            {children}
          </BottomSheetScrollView>
        )}
      </GorhomBottomSheetModal>
    </View>
  )
}

const styles = StyleSheet.create({
  background: { backgroundColor: '#00000040' },
  bottomSheetLargeDevice: {
    width: 540,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  bottomSheet: {
    position: 'absolute',
    alignContent: 'center',
  },
  content: { padding: 4 },
})

export default BottomSheetModal
