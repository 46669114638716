import React, { createRef, useEffect, useMemo } from 'react'

import useTimeManager from '../hooks/useTimeManager'
import useUserSettings from '../hooks/useUserSettings'
import { TimeButtonRef } from '../types'
import TimeButton, { TimeButtonProps } from './TimeButton'

interface Props {
  activityId: string
  forceStart: boolean
  onForced: () => void
}

const ActivityTimeButton = ({ activityId, forceStart, onForced, ...props }: Omit<TimeButtonProps, 'onStart' | 'onStop'> & Props) => {
  const innerRef = createRef<TimeButtonRef>()

  const { userSettings } = useUserSettings()
  const { start, stop, type, times, travelTime } = useTimeManager(activityId, true, innerRef)

  useEffect(() => {
    if ((userSettings && userSettings.startsTheActivityTimeAsSoonAsTheActivityIsOpened) || forceStart === true) {
      start()
      onForced()
    }
  }, [userSettings])

  const time = times?.at(0)
  const timeLoading = useMemo(() => {
    return !(type === 'stopped' || type === 'started' || type === 'init')
  }, [type])

  return (
    <TimeButton
      ref={innerRef}
      baseIcon="stopwatch"
      size={24}
      loading={timeLoading ? 'reloading' : false}
      disabled={false}
      time={!timeLoading && type === 'started' ? time : undefined}
      onStart={start}
      onStop={stop}
      badgeIcon={travelTime && travelTime.openTime ? ['far', 'truck-clock'] : undefined}
      {...props}
    />
  )
}

export default ActivityTimeButton
