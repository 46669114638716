import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CalendarViewMode } from '@howljs/calendar-kit'

const CalendarUtils = {
  convertToDateTimeString(inDate: string, time: number | undefined, defaultTime: number) {
    const newDate = new Date(inDate)
    // Reset time on incoming date
    newDate.setHours(0)
    newDate.setMinutes(0)
    newDate.setSeconds(0)
    // Add time from midnight
    const baseTime = newDate.getTime() + (time && time !== 0 ? time : defaultTime) * 1000
    newDate.setTime(baseTime)
    return newDate
  },
  getIcon(nextView: CalendarViewMode): IconProp {
    switch (nextView) {
      case 'day':
        return ['fal', 'calendar-day']
      case 'threeDays':
        return ['fal', 'calendar-week']
      case 'workWeek':
        return ['fal', 'calendar-clock']
      case 'week':
        return ['fal', 'calendar']
    }
  },
}

export default CalendarUtils
