import { IM, ViewProps } from '@infominds/react-native-components'
import React from 'react'

import { Ticket } from '../../../apis/types/apiResponseTypes'
import Separator from '../../../components/Infominds/Separator'

type Props = {
  ticket: Ticket
  numberOfLines?: number
  enableSeparator?: boolean
}

export default function TicketDescription({ ticket, numberOfLines, enableSeparator, ...props }: Props & ViewProps) {
  if (!ticket.description || ticket.description === '') return <></>

  return (
    <IM.View {...props}>
      {enableSeparator && <Separator />}
      <IM.Text numberOfLines={numberOfLines}>{ticket.description}</IM.Text>
    </IM.View>
  )
}
