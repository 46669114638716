import React, { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react'

import { utils } from '../utils/utils'

export interface VaultContextProps {
  sessionId: string
  setSessionId: Dispatch<SetStateAction<string>>
}

const VaultContext = createContext<VaultContextProps | undefined>(undefined)

export const VaultProvider = ({ children }: { children: ReactNode }) => {
  const [sessionId, setSessionId] = useState(utils.generateUuid())

  const props = useMemo<VaultContextProps>(() => ({ sessionId, setSessionId }), [sessionId])

  return <VaultContext.Provider value={props}>{children}</VaultContext.Provider>
}

export default VaultContext
