import { CameraSettingsView } from '@infominds/react-native-camera'
import { useLanguage, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React from 'react'

import { InfoboxStackParamList } from '../../navigation/types'

const InfoboxCameraSettingsView = () => {
  const { colorScheme } = useTheme()
  const { language } = useLanguage()
  const navigation = useNavigation<NavigationProp<InfoboxStackParamList>>()

  return <CameraSettingsView colorScheme={colorScheme} languageCode={language} onClose={() => navigation.goBack()} />
}

export default InfoboxCameraSettingsView
