import { ModalController } from '@infominds/react-native-components'
import React from 'react'

import TicketReportScreen from '../../screens/tickets/TicketReportScreen'
import { TicketReportParams } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<TicketReportParams>
}

export default function TicketReportModal({ controller }: Props) {
  return (
    <BaseServiceModal controller={controller}>
      {controller.data && (
        <TicketReportScreen
          controller={controller}
          route={{
            key: '',
            name: 'TicketReport',
            params: controller.data,
          }}
        />
      )}
    </BaseServiceModal>
  )
}
