import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { AccessDataPermission } from '../contexts/AccessDataFilterContext'
import { ThemeColorExpanded } from '../types'
import Pressable from './Infominds/Pressable'

interface Props {
  element: AccessDataPermission
  size?: number
  disabled?: boolean
  isHeader?: boolean
  onPress?: (id: string) => void
  style?: StyleProp<ViewStyle>
}

export default function TagAccess({ element, disabled, style, size, isHeader, onPress }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View
      style={[
        styles.filter,
        styles.groupedFilter,
        { backgroundColor: theme.card.background, borderColor: theme.chip.border },
        !isHeader && element.active && { backgroundColor: theme.chip.active.background, borderColor: theme.chip.active.border },
        IMLayout.shadow,
        style,
      ]}>
      <Pressable onPress={() => onPress?.(element.data)} disabled={disabled} containerStyle={styles.pressableContainer} style={styles.pressable}>
        <IM.Icon
          icon={['fas', element.data === 'granted' ? 'unlock' : 'lock']}
          size={size}
          color={element.data === 'granted' ? theme.access.granted : theme.access.denied}
          style={styles.icon}
        />
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  filter: {
    borderRadius: IMLayout.borderRadius,
    minWidth: 52,
    maxHeight: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  groupedFilter: {
    marginRight: 7,
  },
  text: {
    fontSize: IMStyle.typography.fontSizeSmall - 2,
  },
  pressable: {
    flex: 1,
  },
  pressableContainer: {
    width: '100%',
    height: '100%',
  },
  icon: {
    alignItems: 'center',
    justifyContent: 'center',
  },
})
