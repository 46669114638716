import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import useIsOnline from '../../../dataProvider/hooks/useIsOnline'
import useUserSettings from '../../../hooks/useUserSettings'
import { LoadingType, ThemeColorExpanded } from '../../../types'
import PressableIcon from '../../Infominds/PressableIcon'
import DefaultHeader from './default/DefaultHeader'
import SyncIcon from './SyncIcon'

interface Props {
  title: string
  description?: string
  loading?: LoadingType
  onPress?: () => void
}

export default function SerialNumberHeader({ title, description, loading = false, onPress }: Props) {
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const isOnline = useIsOnline()

  return (
    <IM.View style={styles.container}>
      <DefaultHeader title={title} subtitle={description} />
      {isOnline && (
        <>
          {loading === false ? (
            <PressableIcon
              icon={['fal', 'trash']}
              size={19}
              disabled={!userSettings?.allowAddingAndChangingTicketArticle}
              disabledColor={theme.icon}
              color={theme.general.error}
              onPress={onPress}
              style={styles.icon}
              hitSlop={{ top: 10, bottom: 8 }}
            />
          ) : (
            <IM.View style={styles.loadingIcon}>
              <IM.LoadingSpinner isVisible size="small" />
            </IM.View>
          )}
        </>
      )}
      <SyncIcon />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    height: '100%',
  },
  icon: {
    marginRight: 2,
  },
  loadingIcon: {
    marginRight: 8,
  },
})
