import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import CustomerDetailDestinationEditOrCreateScreen from '../../screens/customer/CustomerDetailDestinationEditOrCreateScreen'
import { DestinationEditOrCreateParams, ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<DestinationEditOrCreateParams>
}

export default function DestinationEditOrCreateModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      {controller.data && (
        <CustomerDetailDestinationEditOrCreateScreen
          ref={ref}
          controller={controller}
          route={{
            key: '',
            name: 'CustomerDetailDestinationEditOrCreate',
            params: { customerId: controller.data.customerId, destination: controller.data.destination },
          }}
        />
      )}
    </BaseServiceModal>
  )
}
