import { IM, Spacing, TextWithIconProps, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React, { memo, useState } from 'react'
import { StyleProp, StyleSheet, TextStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'
import Pressable from './Infominds/Pressable'
import TextWithIcon from './Infominds/TextWithIcon'
import LoadingIcon from './LoadingIcon'

interface Props extends Partial<Pick<TextWithIconProps, 'icon' | 'iconColor' | 'iconSize' | 'alignIcon' | 'numberOfLines'>> {
  title: string
  color?: string
  disabled?: boolean
  fade?: number
  lighten?: number
  darken?: number
  loading?: boolean
  spacing?: Spacing
  textStyle?: StyleProp<TextStyle>
  onPress: () => void
}

const Button = memo(function Button({
  title,
  color,
  disabled,
  loading,
  spacing,
  textStyle,
  fade = 0.9,
  lighten = 0,
  darken = 0,
  onPress,
  ...others
}: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const [width, setWidth] = useState<number | undefined>(undefined)

  const colorManaged = disabled ? theme.button.disabledBackground : color

  return (
    <Pressable
      disabled={disabled}
      style={[styles.pressable, { borderRadius: styles.container.borderRadius }]}
      containerStyle={[
        styles.container,
        {
          backgroundColor: disabled
            ? Color(theme.button.disabledBackground).fade(fade).lighten(lighten).darken(darken).toString()
            : Color(color).fade(fade).lighten(lighten).darken(darken).toString(),
          borderColor: colorManaged,
        },
      ]}
      onPress={loading ? undefined : onPress}
      spacing={spacing}
      spacingType="margin">
      {loading ? (
        <IM.View style={{ width }}>
          <LoadingIcon color={colorManaged} size={17} />
        </IM.View>
      ) : (
        <TextWithIcon {...others} style={[{ color: colorManaged }, styles.text, textStyle]} onLayout={e => setWidth(e.nativeEvent.layout.width)}>
          {title.toUpperCase()}
        </TextWithIcon>
      )}
    </Pressable>
  )
})

export default Button

const styles = StyleSheet.create({
  container: {
    borderRadius: 14,
    borderWidth: 1,
    alignItems: 'center',
  },
  pressable: { minWidth: 80, alignItems: 'center', paddingHorizontal: 8, paddingVertical: 5, width: '100%' },
  text: {
    textAlign: 'center',
  },
})
