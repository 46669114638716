import { useDimensions, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React from 'react'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { TicketStackScreenProps } from '../../navigation/types'
import { ModalScreenProps, TicketReportParams } from '../../types'
import TicketReportView from '../../views/ticket/TicketReportView'

const TicketReportScreen = ({
  route,
  controller,
}: Omit<TicketStackScreenProps<'TicketReport'>, 'navigation'> & ModalScreenProps<TicketReportParams>) => {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { isSmallDevice } = useDimensions()

  const isModal = controller !== undefined

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header goBack={() => (isSmallDevice ? navigation.goBack() : controller?.close())} isModal={isModal} title={i18n.t('SEND_REPORT')} />
        <Screen.Content>
          <TicketReportView ticketId={route.params.ticketId} onCompleted={() => (isSmallDevice ? navigation.goBack() : controller?.close())} />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default TicketReportScreen
