import { IM, IMLayout, IMStyle, useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import Color from 'color'
import React from 'react'
import { Platform, ScrollView, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import { useRecoilValue } from 'recoil'

import { ACCESS_DATA_FILTER_CHANGE_EVENT_KEY } from '../../../constants/EmitterKeys'
import useAccessDataFilter from '../../../hooks/useAccessDataFilter'
import AccessDataFilterModal from '../../../modals/accessData/AccessDataFilterModal'
import { AccessDataStackParamList } from '../../../navigation/types'
import { AccessDataFilterEmitter } from '../../../types'
import { accessDataFilterEnableAtom } from '../../../utils/stateManager'
import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'
import Tag from '../../Tag'
import TagAccess from '../../TagAccess'

interface Props {
  style?: StyleProp<ViewStyle>
}

export default function AccessDataFilterDetailHeader({ style }: Props) {
  const { filters, setFilters, permissions, setPermissions, changeFilterStatus, changePermissionStatus } = useAccessDataFilter()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const enabled = useRecoilValue(accessDataFilterEnableAtom)
  const navigation = useNavigation<NavigationProp<AccessDataStackParamList>>()
  const controller = useModalController<AccessDataStackParamList['AccessDataFilter']>()

  const activeFilters = filters.filter(filter => filter.active)
  const activePermissions = permissions.filter(permission => permission.active)

  useEvent<AccessDataFilterEmitter>({ key: ACCESS_DATA_FILTER_CHANGE_EVENT_KEY }, newData => {
    setFilters(newData.filters)
    setPermissions(newData.permissions)
  })

  return (
    <>
      <IM.View style={[styles.container, isSmallDevice && IMLayout.flex.f1, style]}>
        <ScrollView
          horizontal
          // eslint-disable-next-line react-native/no-inline-styles
          contentContainerStyle={[styles.scrollView, { marginLeft: isSmallDevice ? 4 : 0 }]}
          showsHorizontalScrollIndicator={false}>
          {activeFilters.length === 0 && activePermissions.length === 0 && isSmallDevice && (
            <IM.Text style={{ color: Color(IMStyle.palette.white).darken(0.3).toString() }}>{i18n.t('NO_FILTER_ACTIVE')}</IM.Text>
          )}
          {activeFilters.map(filter => {
            return (
              <Tag
                key={filter.data.id}
                id={filter.data.id}
                active={filter.active}
                name={filter.data.name}
                isHeader
                onPress={() => changeFilterStatus(filter.data.id)}
              />
            )
          })}
          {activePermissions.map(permission => {
            return <TagAccess key={permission.data} element={permission} onPress={() => changePermissionStatus(permission.data)} isHeader />
          })}
        </ScrollView>
        <Pressable
          disabled={!enabled}
          onPress={() =>
            isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
              ? navigation.navigate('AccessDataFilter', { filters, permissions })
              : controller.show({ filters, permissions })
          }
          style={{ marginLeft: IMLayout.horizontalMargin }}
          hitSlop={{ top: 10, bottom: 8 }}>
          <TextWithIcon alignIcon="right" icon={['fal', 'chevron-right']} color={IMStyle.palette.white} secondary={!enabled}>
            {i18n.t('FILTERS')}
          </TextWithIcon>
        </Pressable>
      </IM.View>
      <AccessDataFilterModal controller={controller} />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  scrollView: {
    marginVertical: 4,
    flexDirection: 'row',
    justifyContent: 'center',
  },
})
