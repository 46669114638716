import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import api from '../apis/apiCalls'
import { REQUEST_TICKET_CLASSIFICATION_DEFINITIONS } from '../constants/Keys'
import { ClassificationsTextInputRef, ClassificationValue } from '../types'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import DefaultBaseTextInputTitle from './input/baseTextInput/DefaultBaseTextInputTitle'
import ClassificationSelector from './selectors/ClassificationSelector'
import SkeletonText from './skeleton/SkeletonText'

const TicketCreationClassificationsGroup = ({}, ref: ForwardedRef<ClassificationsTextInputRef>) => {
  useImperativeHandle(ref, () => ({
    getState: () => state,
  }))

  const { i18n } = useLanguage()
  const [state, setState] = useState<ClassificationValue[]>([])
  const { item, loadItem, loading } = useControlledLoader(api.getTicketClassificationDefinition, { id: REQUEST_TICKET_CLASSIFICATION_DEFINITIONS })

  useEffect(() => {
    loadItem({})
  }, [])

  const handleChangeText = (newVal: ClassificationValue) => {
    let clone = cloneDeep(state)

    const indx = clone.findIndex(el => el.classificationDefinitionId === newVal.classificationDefinitionId)

    if (indx !== -1) {
      if (newVal.classificationId) {
        clone[indx].classificationId = newVal.classificationId
      } else {
        clone = clone.filter(el => el.classificationDefinitionId !== newVal.classificationDefinitionId)
      }
    } else {
      clone.push(newVal)
    }

    setState(clone)
  }

  return (
    <>
      <IM.View style={{ marginTop: IMLayout.horizontalMargin * 4, marginBottom: IMLayout.horizontalMargin - 2 }}>
        <DefaultBaseTextInputTitle title={i18n.t('CLASSIFICATIONS')} />
      </IM.View>
      {loading === false ? (
        <>
          {item?.length === 0 ? (
            <IM.Text secondary>{i18n.t('NO_CLASSIFICATION')}</IM.Text>
          ) : (
            <>
              {item?.map(elem => (
                <ClassificationSelector key={elem.id} definitionId={elem.id} title={elem.description} onChange={handleChangeText} spacing="bottom" />
              ))}
            </>
          )}
        </>
      ) : (
        <SkeletonText width="100%" />
      )}
    </>
  )
}

export default forwardRef(TicketCreationClassificationsGroup)
