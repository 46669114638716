import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { Customer } from '../../apis/types/apiResponseTypes'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'

interface Props {
  customer: Customer
  onPress?: () => void
  selected?: boolean
  borderless?: boolean
  spacing?: SpacingProps
}

const CustomerCard = ({ customer, selected, borderless, spacing, onPress }: Props) => {
  const { theme, colorScheme, themeUtils } = useTheme<ThemeColorExpanded>()

  const blocked = customerUtils.isBlocked(customer)
  const opacity = blocked ? appUtils.getOpacity(colorScheme) : 1

  return (
    <IM.View style={{ opacity: opacity }}>
      <IM.Card
        head={{
          backGroundColor: blocked ? theme.pressable.disabled : themeUtils.getRandomColorFromTheme(customer?.number?.toString() ?? ''),
          icon: ['fal', 'building'],
        }}
        defaultContent={{
          texts: [
            {
              text: customer.description ?? '',
              primary: true,
              numberOfLines: 1,
              icon: blocked ? ['fas', 'user-slash'] : undefined,
              iconColor: theme.general.error,
            },
            { text: customer.number ? customer.number.toString() : undefined, secondary: true, textStyle: { color: theme.text }, numberOfLines: 1 },
            { text: customerUtils.formatAddress(customer), secondary: true, numberOfLines: 1 },
          ],
        }}
        onPress={onPress}
        style={selected && [styles.selected, { borderColor: theme.primary }]}
        borderless={borderless}
        spacing={spacing}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  selected: {
    borderRightWidth: 4,
  },
})

export default CustomerCard
