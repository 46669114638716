import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { CommonStackParamList } from '../../navigation/types'
import FilterScreen from '../../screens/common/FilterScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<CommonStackParamList['Filter']>
}

export default function FilterModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <FilterScreen
        ref={ref}
        controller={controller}
        route={{
          key: '',
          name: 'Filter',
        }}
      />
    </BaseServiceModal>
  )
}
