import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Customer } from '../../apis/types/apiResponseTypes'
import CustomerDetailCard from '../../cards/customer/CustomerDetailCard'
import { REQUEST_CUSTOMER } from '../../constants/Keys'
import { ListSection, ThemeColorExpanded } from '../../types'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import TextWithIcon from '../Infominds/TextWithIcon'
import SelectInput from './selectInput/SelectInput'

interface Props {
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Customer | undefined) => void
}

const styles = StyleSheet.create({
  blockedContainer: {
    marginTop: -6,
  },
})

export default function CustomerSelector({ onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const [selected, setSelected] = useState<Customer | undefined>(undefined)
  const [isBlocked, setIsBlocked] = useState(false)

  const { item: data, loadItem, allDataLoaded, loadMore, loading } = useInfiniteLoader(api.getCustomer, { chuckSize: 30, id: REQUEST_CUSTOMER })

  useEffect(() => {
    refresh()
  }, [])

  const refresh = (searchText?: string) => {
    loadItem({ searchText: searchText, userPropertyForFiltering: 'APPSM_KUNIDFILTER' })
  }

  const render = ({ item }: SectionListRenderItemInfo<Customer, ListSection<Customer>>, onPress?: () => void) => {
    const isLast = allDataLoaded && data.length > 0 && item.id === data[data.length - 1].id
    const isFirst = data.length > 0 && item.id === data[0].id

    return (
      <CustomerDetailCard
        customer={item}
        onPress={blocked => {
          setIsBlocked(blocked)
          onPress?.()
        }}
        selected={selected?.id === item.id}
        spacing={['horizontal', isFirst ? 'vertical' : isLast ? 'none' : 'bottom']}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ marginBottom: isLast ? 3 * IMLayout.horizontalMargin : 0 }}
      />
    )
  }

  const handleOnChange = (newValue: Customer | undefined) => {
    setSelected(newValue)
    onChange(newValue)
  }

  return (
    <>
      <SelectInput
        data={data}
        value={selected}
        loading={loading}
        refresh={refresh}
        onSearchChange={refresh}
        title={i18n.t('CUSTOMER') + ' *'}
        screenTitle={i18n.t('CUSTOMERS')}
        noDataMessage={i18n.t('NO_CUSTOMER_FOUND')}
        renderItem={render}
        onChange={handleOnChange}
        renderSelectedString={item => `${item.description} (${item.number})`}
        disableReset
        allDataLoaded={allDataLoaded}
        onLoadMore={loadMore}
        disableLoadAfterMount
        {...props}
      />
      {isBlocked && (
        <IM.View style={styles.blockedContainer}>
          <TextWithIcon
            icon={['fas', 'user-slash']}
            iconSize={12}
            color={theme.general.error}
            style={{ fontWeight: IMStyle.typography.fontWeightMedium, fontSize: IMStyle.typography.fontSizeSmall - 3 }}>
            {i18n.t('CUSTOMER_BLOCKED')}
          </TextWithIcon>
        </IM.View>
      )}
    </>
  )
}
