import { IM, IMLayout, SpacingProps, useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect, useMemo } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import AdditionalFieldElement from '../../components/AdditionalFieldElement'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import Error from '../../components/Error'
import Pressable from '../../components/Infominds/Pressable'
import NavigationIcon from '../../components/NavigationIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import useAddFieldsLoader from '../../hooks/useAddFieldsLoader'
import AddFieldsModal from '../../modals/common/AddFieldsModal'
import { TicketStackParamList } from '../../navigation/types'
import { AdditionalFieldsParams, AdditionalFieldType } from '../../types'
import appUtils from '../../utils/appUtils'

interface Props {
  id: string
  type: AdditionalFieldType
  onlyRead?: boolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const AdditionalFieldsCard = memo(function AdditionalFieldsCard({ spacing, id, type, style, onlyRead = false }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const controller = useModalController<AdditionalFieldsParams>()

  const { loadAddFields, item, loading } = useAddFieldsLoader(type)

  useEvent({ key: appUtils.addFieldChangeEvent(type) }, () => refresh())

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadAddFields([id])

  const hasValue = useMemo(() => {
    let toRet = false

    item &&
      item.length === 1 &&
      item[0].additionalfields.forEach(el => {
        if (appUtils.getAdditionFieldValue(el, language, i18n) !== undefined) {
          toRet = true
        }
      })

    return toRet
  }, [item, language])

  const content = () => {
    let firstValueIndex: number | undefined

    item &&
      item.length === 1 &&
      item[0].additionalfields.forEach((el, index) => {
        if (appUtils.getAdditionFieldValue(el, language, i18n) !== undefined && firstValueIndex === undefined) {
          firstValueIndex = index
        }
      })

    return (
      <IM.View style={[IMLayout.flex.row, styles.align]}>
        <IM.View style={IMLayout.flex.f1}>
          {item && item.length === 1 && item[0].additionalfields.length === 0 ? (
            <IM.Text secondary>{i18n.t('NO_ADDITIONAL_FIELD')}</IM.Text>
          ) : (
            <>
              {hasValue &&
                item &&
                item.length === 1 &&
                item[0].additionalfields.map((elem, index) => (
                  <AdditionalFieldElement key={elem.definitionId} field={elem} index={firstValueIndex === index ? 0 : index} />
                ))}
              {!hasValue && <IM.Text secondary>{i18n.t('NO_ADDITIONAL_FIELD')}</IM.Text>}
            </>
          )}
        </IM.View>
        {!onlyRead && <NavigationIcon enableTableStyle />}
      </IM.View>
    )
  }

  return (
    <>
      <IM.Card head={<CardLeftTitle text={i18n.t('ADDITIONAL_FIELDS')} />} spacing={spacing} style={[styles.container, style]} noContentSpacing>
        <IM.View style={styles.contentContainer}>
          {loading === false ? (
            <>
              {onlyRead ? (
                // eslint-disable-next-line react-native/no-inline-styles
                <IM.View style={{ paddingHorizontal: 6, paddingVertical: 6 }}>{content()}</IM.View>
              ) : (
                <Pressable
                  onPress={() => {
                    if (item && item.length === 1 && item[0].additionalfields) {
                      isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                        ? navigation.navigate('TicketCommonStack', {
                            screen: 'EditAddFields',
                            params: { id: item[0].fkId, type, values: item[0].additionalfields },
                          })
                        : controller.show({ id: item[0].fkId, type, values: item[0].additionalfields })
                    }
                  }}>
                  {content()}
                </Pressable>
              )}
            </>
          ) : (
            <IM.View spacing="horizontal">
              {loading === 'catched' ? (
                <Error />
              ) : (
                <>
                  <SkeletonText width="50%" spacing="top" />
                  <SkeletonText width="80%" spacing="top" />
                  <SkeletonText width="95%" spacing="vertical" />
                </>
              )}
            </IM.View>
          )}
        </IM.View>
      </IM.Card>
      <AddFieldsModal controller={controller} />
    </>
  )
})

export default AdditionalFieldsCard

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  contentContainer: {
    margin: 6,
  },
  align: {
    justifyContent: 'space-between',
  },
})
