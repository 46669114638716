import { IMLayout } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { AvoidSoftInput, AvoidSoftInputView, AvoidSoftInputViewProps } from 'react-native-avoid-softinput'

AvoidSoftInput.setShouldMimicIOSBehavior(true)

export default function KeyboardAware({ children, style, ...props }: PropsWithChildren<AvoidSoftInputViewProps>) {
  return (
    <AvoidSoftInputView {...props} showAnimationDuration={250} style={[IMLayout.flex.f1, style]}>
      {children}
    </AvoidSoftInputView>
  )
}
