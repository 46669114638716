import { useEffect } from 'react'

export const useBeforeUnload = (unsavedChanges: boolean, disabled?: boolean) => {
  useEffect(() => {
    if (disabled || !unsavedChanges) return

    const unloadCallback = (e: Event) => {
      e.preventDefault()
      // @ts-ignore didn't know the type but needed to show browser popup
      e.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [unsavedChanges])
}
