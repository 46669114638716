import { IMLayout, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import Render, { HTMLContentModel, HTMLElementModel } from 'react-native-render-html'

const customHTMLElementModels = {
  font: HTMLElementModel.fromCustomModel({
    tagName: 'font',
    contentModel: HTMLContentModel.block,
  }),
}

type Props = {
  width: number
  source: string
}

function RenderHTML({ width, source }: Props) {
  const { colorScheme } = useTheme()

  return (
    <Render
      key={width}
      contentWidth={width}
      source={{ html: source }}
      // eslint-disable-next-line react-native/no-inline-styles
      baseStyle={{
        backgroundColor: 'white',
        borderRadius: IMLayout.borderRadius,
        padding: colorScheme === 'dark' ? IMLayout.horizontalMargin : 0,
      }}
      customHTMLElementModels={customHTMLElementModels}
    />
  )
}

export default memo(RenderHTML)
