import { IM } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'

import { MasterProvider } from '../contexts/MasterContext'
import useMasterDetail from '../hooks/useMasterDetail'

export interface MasterProps {
  children: ReactNode
  flex?: number
}

const Master = ({ children, flex }: MasterProps) => {
  const { detailParams, setDetailParams } = useMasterDetail()

  return (
    <MasterProvider detail={detailParams} setDetail={setDetailParams}>
      <IM.View style={{ flex }}>{children}</IM.View>
    </MasterProvider>
  )
}

export default Master
