import { IconName } from '@fortawesome/fontawesome-svg-core'
import { IMStyle, useTheme } from '@infominds/react-native-components'
import { intervalToDuration } from 'date-fns'
import dayjs from 'dayjs'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { ThemeColorExpanded, TimeButtonRef, TimeFormat } from '../types'
import TimeUtils from '../utils/TimeUtils'
import AnimatedButton, { AnimatedButtonProps } from './Infominds/AnimatedButton'

export type TimeButtonProps = Omit<AnimatedButtonProps, 'onPress' | 'iconSize' | 'color' | 'icon' | 'color' | 'loadingIcon'> & Props

type Time = { date: string; from: number; until?: number }

interface Props {
  baseIcon?: IconName
  size?: number
  time?: Time
  onStart: () => void
  onStop: () => void
}

const DOUBLE_CLICK_ICON = false
const RESET_TIMEOUT = 2000

const TimeButton = (
  { time, loading, size = 24, baseIcon = 'truck-clock', onStart, onStop, ...props }: TimeButtonProps,
  ref: ForwardedRef<TimeButtonRef>
) => {
  useImperativeHandle(ref, () => ({
    reset(status) {
      setIsActive(status === 'stopped' ? false : true)
    },
  }))

  const { theme } = useTheme<ThemeColorExpanded>()
  const [isActive, setIsActive] = useState(false)
  const [icon, setIcon] = useState<IconName>(time ? 'stop' : baseIcon)
  const [elapsed, setElapsed] = useState<number>(0)

  useEffect(() => {
    time && setIsActive(true)
  }, [time])

  useEffect(() => {
    if (!DOUBLE_CLICK_ICON) return

    const timer = setTimeout(resetIcon, RESET_TIMEOUT)

    return () => {
      clearTimeout(timer)
    }
  }, [icon, isActive])

  useEffect(() => {
    if (time === undefined) return setIsActive(false)

    const duration = calculateDuration(time)

    if (duration.days !== undefined && duration.days > 0) {
      return stop()
    }

    updateElapsedTime(time)
    setIsActive(true)

    const id = setInterval(() => updateElapsedTime(time), 250)

    return () => {
      clearInterval(id)
    }
  }, [time])

  const calculateDuration = (inTime: Time) => {
    const res = TimeUtils.revertSecondsToDate(inTime.from, dayjs(inTime.date).utc(true).toDate())
    return intervalToDuration({
      start: dayjs(res).utc(true).toDate(),
      end: dayjs().utc(true).toDate(),
    })
  }

  const updateElapsedTime = (inTime: Time) => {
    const duration = calculateDuration(inTime)

    setElapsed((duration.days ?? 0) * 86400 + (duration.hours ?? 0) * 3600 + (duration.minutes ?? 0) * 60 + (duration.seconds ?? 0))
  }

  const resetIcon = () => {
    if (icon === 'play' && isActive === false) {
      setIcon(baseIcon)
    } else if (icon === 'stop' && isActive) {
      setIcon(baseIcon)
    }
  }

  const handlePress = () => {
    if (DOUBLE_CLICK_ICON) {
      switch (icon) {
        case baseIcon:
          setIcon(isActive ? 'stop' : 'play')
          break
        case 'play':
          setTimeout(() => setIcon(baseIcon), 500)
          start()
          break
        case 'stop':
          setIcon(baseIcon)
          stop()
          break
      }
    } else {
      isActive ? stop() : start()
    }
  }

  const start = () => {
    onStart()
  }

  const stop = () => {
    setIsActive(false)
    setElapsed(0)
    onStop()
  }

  return (
    <AnimatedButton
      {...props}
      loadingIcon={loading}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{ paddingVertical: isActive ? 0 : undefined }}
      icon={['fal', icon]}
      color={icon === 'play' ? theme.general.info : icon === 'stop' ? theme.general.error : IMStyle.palette.white}
      iconSize={size}
      onPress={handlePress}
      text={
        time && isActive
          ? `Start: ${TimeUtils.secondsToTime(time.from, TimeFormat.TIME)}\n${TimeUtils.secondsToTime(elapsed, TimeFormat.TIME_WITH_DIMENSIONS)}`
          : undefined
      }
    />
  )
}

export default forwardRef(TimeButton)
