import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { Platform, SectionListRenderItemInfo } from 'react-native'

import { TicketArticle } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  data: TicketArticle[]
  value: string | undefined
  multiSelect?: boolean
  editable?: boolean
  spacing?: SpacingProps
  enableBackdropOpacity?: boolean
  onChange: ((value: TicketArticle | undefined) => void) | undefined
}

export default function ArticleSerialNumberSelector({ data, value, enableBackdropOpacity, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')

  const render = ({ item }: SectionListRenderItemInfo<TicketArticle, ListSection<TicketArticle>>, onPress?: () => void) => {
    return (
      <RadioPressable
        onPress={() => onPress?.()}
        selected={value ? item.serialnumberId === value : item.id === CONSTANTS.noSelectionId}
        text={ticketUtils.getArticleSn(item)}
        description={item.articleDescription}
      />
    )
  }

  const handleOnChange = (newValue: TicketArticle | undefined) => {
    onChange?.(newValue ? (newValue.id === CONSTANTS.noSelectionId ? undefined : newValue) : undefined)
  }

  const noSelectionEntry: TicketArticle = {
    id: CONSTANTS.noSelectionId,
    serialnumber: i18n.t('NONE'),
    addressId: '',
    articleCode: '',
    articleId: '',
    articleSearchtext: '',
    contracts: [],
    customerId: '',
    ticketId: '',
    serialnumberMonitoringState: 'None',
  }

  return (
    <>
      {data.length > 0 && (
        <SelectInput
          data={
            search === ''
              ? [noSelectionEntry, ...data]
              : appUtils.filter(data, search, ['serialnumber', 'serialnumberNumberManufactor', 'articleDescription'])
          }
          value={value ? data.find(el => el.serialnumberId === value) : noSelectionEntry}
          title={i18n.t('TICKET_ARTICLE')}
          screenTitle={i18n.t('TICKET_ARTICLES')}
          noDataMessage={i18n.t('NO_ARTICLE_FOUND')}
          onSearchChange={setSearch}
          renderItem={render}
          onChange={handleOnChange}
          focusSearch={Platform.OS === 'android' ? false : true} // TODO: the space to dismiss keyboard is pretty limited
          renderSelectedString={item => ticketUtils.getArticleSn(item)}
          fastInputEntries={4}
          loading={false}
          deferredTimeout={0}
          disableFastInputScrollView
          showNoneButton
          enableBackdropOpacity={enableBackdropOpacity}
          {...props}
        />
      )}
    </>
  )
}
