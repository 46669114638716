import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CalendarViewMode } from '@howljs/calendar-kit'
import { IMStyle, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import { useRecoilState } from 'recoil'

import { ThemeColorExpanded } from '../../types'
import CalendarUtils from '../../utils/calendarUtils'
import { calendarViewAtom } from '../../utils/stateManager'
import PressableIcon, { PressableIconProps } from '../Infominds/PressableIcon'
import TabSelector from '../TabSelector'
import { CALENDAR_ICON, LIST_ICON, MAP_ICON } from './headers/ServiceHeader'
import useScreen from './hooks/useScreen'
import { ScreenViewType } from './types'

const calendarViews: CalendarViewMode[] = ['day', 'threeDays', 'workWeek', 'week']

export type HeaderIcon = { type: ScreenViewType; icon: IconProp; size: number }

type HeaderChangeViewIconProps = {
  types: HeaderIcon[]
}

export default function HeaderChangeViewIcon({ types, ...props }: Omit<PressableIconProps, 'icon'> & HeaderChangeViewIconProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useDimensions()
  const { view, setView } = useScreen()
  const { sessionKey } = useAuthentication()

  const [viewMode, setViewMode] = useRecoilState(calendarViewAtom(sessionKey))

  useEffect(() => {
    Platform.OS === 'web' && view === ScreenViewType.LOCATION && isSmallDevice && setView(ScreenViewType.CALENDAR)
  }, [view, isSmallDevice])

  const currentViewIndex = Math.max(
    types.findIndex(t => t.type === view),
    0
  )
  const currentView = types.find(t => t.type === view) ?? types[0]
  const nextViewIndex = currentViewIndex >= types.length - 1 ? 0 : currentViewIndex + 1

  const currentCalendarView = calendarViews.findIndex(t => t === viewMode)
  const nextCalendarViewIndex = currentCalendarView >= calendarViews.length - 1 ? 0 : currentCalendarView + 1

  if (!currentView) return <></>

  return (
    <>
      {currentView.type === ScreenViewType.CALENDAR && (
        <PressableIcon
          color={IMStyle.palette.white}
          icon={CalendarUtils.getIcon(calendarViews[nextCalendarViewIndex])}
          size={currentView.size}
          onPress={() => setViewMode(calendarViews[nextCalendarViewIndex])}
          hitSlop={{ top: 10, bottom: 8 }}
          {...props}
        />
      )}
      {Platform.OS !== 'web' || isSmallDevice ? (
        <PressableIcon
          color={IMStyle.palette.white}
          icon={currentView.icon}
          size={currentView.size}
          onPress={() => setView(types[nextViewIndex].type)}
          hitSlop={{ top: 10, bottom: 8 }}
          {...props}
        />
      ) : (
        <TabSelector selectionColor={theme.drawerNavigator.bar} height={30} width={360}>
          <TabSelector.Item
            icon={LIST_ICON.icon}
            iconSize={LIST_ICON.size}
            text={i18n.t('LIST')}
            selected={view === ScreenViewType.LIST}
            textColor="white"
            selectedTextColor={theme.general.info}
            onPress={() => setView(ScreenViewType.LIST)}
            backgroundColor={theme.drawerNavigator.bar}
            dividerColor={theme.drawerNavigator.background}
          />
          <TabSelector.Item
            icon={MAP_ICON.icon}
            iconSize={MAP_ICON.size}
            text={i18n.t('MAP')}
            selected={view === ScreenViewType.LOCATION}
            onPress={() => setView(ScreenViewType.LOCATION)}
            textColor="white"
            selectedTextColor={theme.general.info}
            backgroundColor={theme.drawerNavigator.bar}
            dividerColor={theme.drawerNavigator.background}
          />
          <TabSelector.Item
            icon={CALENDAR_ICON.icon}
            iconSize={CALENDAR_ICON.size}
            text={i18n.t('CALENDAR')}
            selected={view === ScreenViewType.CALENDAR}
            onPress={() => setView(ScreenViewType.CALENDAR)}
            textColor="white"
            selectedTextColor={theme.general.info}
            disableDivider
            backgroundColor={theme.drawerNavigator.bar}
          />
        </TabSelector>
      )}
    </>
  )
}
