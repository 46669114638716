import { DataProviderCustomUpdateEffect } from '../../../dataProvider/types'
import { ActivitySparePart, ArticleSparePart, InvoiceType, UserSettings } from '../../types/apiResponseTypes'

export const ActivitySparePartsCustomUpdateEffect: DataProviderCustomUpdateEffect<ActivitySparePart> = async (data, props) => {
  data = Object.fromEntries(Object.entries(data).filter((_, v) => v !== undefined)) as ActivitySparePart
  if ('articlecode' in data && data.articlecode) data.articleCode = String(data.articlecode)
  if (props.requestParams.type === 'POST') {
    try {
      // with article id
      if (data.articleId) {
        const sparePart = (await props.dataStore.Get<ArticleSparePart>('article/sparepart')).find(
          sp => sp.articleId === data.articleId && (sp.serialnumberId === data.serialnumberId || (!data.serialnumberId && !sp.serialnumberId))
        )
        if (sparePart) {
          data.articleCode = sparePart.articleCode
          data.articleDescription = sparePart.articleDescription ?? ''
          if (data.serialnumberId) data.serialnumber = sparePart.serialnumber
          data.depositCode = sparePart.depositCode
          data.depositDescription = sparePart.depositDescription
          data.articleSearchtext = sparePart.articleSearchtext
          data.articleSearchtextParent = ''
          data.measurementUnit = sparePart.measurementUnit ?? ''
          data.measurementUnitId = sparePart.measurementUnitId ?? ''
        }
      }
      // manual input
      else {
        const userSettings = (await props.dataStore.Get<UserSettings>('userSettings'))?.shift()
        if (!data.depositId) data.depositId = userSettings?.depositId ?? ''
        if (!data.articleSearchtext) data.articleSearchtext = ''
        if (!data.articleSearchtextParent) data.articleSearchtextParent = ''
      }

      if (data.invoicingTypeId) {
        try {
          const invoiceType = (await props.dataStore.Get<InvoiceType>('common/invoicetypes', { id: data.invoicingTypeId }))?.shift()
          if (invoiceType) data.invoicingType = `${invoiceType?.code ?? ''}-${invoiceType?.description ?? ''}`
        } catch (e) {
          console.error(e)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  return props.defaultEffect(data)
}
