import React, { createContext, PropsWithChildren, useMemo, useState } from 'react'

interface Props {
  acsPsw: string | undefined
  ready: boolean
  setPassword: (val: string | undefined) => void
}

const ACSPasswordContext = createContext<Props | undefined>(undefined)

export const ACSPasswordProvider = ({ children }: PropsWithChildren) => {
  const [ready, setReady] = useState(false)
  const [password, setPassword] = useState<string | undefined>(undefined)

  const handlePassword = (val: string | undefined) => {
    if (password === undefined) {
      setReady(false)
      setPassword(undefined)
    }

    setReady(true)
    setPassword(val)
  }

  const props = useMemo<Props>(
    () => ({
      acsPsw: password,
      ready,
      setPassword: handlePassword,
    }),
    [password, ready]
  )

  return <ACSPasswordContext.Provider value={props}>{children}</ACSPasswordContext.Provider>
}

export default ACSPasswordContext
