import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { TicketStackScreenProps } from '../../navigation/types'
import { EditOrCreateViewRef, ModalScreenProps, ModalScreenRef } from '../../types'
import TicketCreationView from '../../views/ticket/TicketCreationView'

const TicketCreationScreen = (
  { controller }: Omit<TicketStackScreenProps<'TicketCreation'>, 'navigation'> & ModalScreenProps<void>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const createViewRef = createRef<EditOrCreateViewRef>()

  const { status, setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const isModal = controller !== undefined

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header
          goBack={handleGoBack}
          backHandlerCallback={handleGoBack}
          isModal={isModal}
          title={i18n.t('CREATE_TICKET')}
          onRightIconPress={() => {
            Keyboard.dismiss()
            createViewRef.current?.handleUpload()
          }}
          rightIcon={['fal', 'check']}
          disable={status === 'done' || status === 'mandatoryMissing'}
          loading={status === 'uploading'}
        />
        <Screen.Content>
          <FormProvider>
            <TicketCreationView ref={createViewRef} onDone={handleGoBack} onUploadStatus={setStatus} />
          </FormProvider>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(TicketCreationScreen)
