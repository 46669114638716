import { IM, IMLayout, useAlert, useEvent, useLanguage, useModalController, useTheme, Utils } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import isEqual from 'lodash/isEqual'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import { getApi } from '../../apis/apiCalls'
import { AccessData, Group, SerialNumber } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import AccessLevelInput from '../../components/input/AccessLevelInput'
import PasswordInput from '../../components/input/PasswordInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import AccessGroupSelector from '../../components/selectors/AccessGroupSelector'
import SerialnumberSelector from '../../components/selectors/SerialnumberSelector'
import { EDIT_ACCESS_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_EDIT_PASSWORD } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useForm from '../../hooks/useForm'
import useVault from '../../hooks/useVault'
import VaultModal from '../../modals/VaultModal'
import { EditOrCreateViewProps, EditOrCreateViewRef, ThemeColorExpanded, UploadStatus } from '../../types'

type Edit = {
  mode: 'edit'
  accessData: AccessData
}

type Creation = {
  mode: 'creation'
  accessData?: never
}

type Props = (Edit | Creation) & EditOrCreateViewProps & { customerId: string; isModal: boolean; onForceClose: () => void }

const AccessDataEditOrCreateView = (
  { accessData, customerId, mode, isModal, onUploadStatus, onForceClose }: Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) => {
  useImperativeHandle(ref, () => ({
    handleUpload: () => upload(),
  }))

  const initialData = useRef(accessData)
  const initialPsw = useRef<string | undefined>(undefined)

  const equalData = useRef(false)

  const { alert } = useAlert()
  const navigation = useNavigation()
  const passwordModalController = useModalController()
  const uploadModalController = useModalController()
  const modalAlreadyShown = uploadModalController.isShown || passwordModalController.isShown

  const { isOnline, client } = useDataProvider()

  const {
    item: response,
    loadItem: downloadPassword,
    loading,
  } = useControlledLoader(getApi(client).getPassword, {
    id: REQUEST_EDIT_PASSWORD,
    onResult: result => {
      if (result.vaultState === 'SessionClosed') {
        !modalAlreadyShown && passwordModalController.show()
        return 'vault-locked'
      } else {
        return 'vault-unlocked'
      }
    },
  })
  const {
    request: putAccessData,
    loading: loadingPutData,
    error: errorPutData,
  } = useRequest(getApi(client).updateAccessData, {
    onResult: result => {
      if (result.vaultState === 'SessionClosed') {
        !modalAlreadyShown && uploadModalController.show()
        return 'vault-locked'
      } else {
        initialData.current = state
        return 'vault-unlocked'
      }
    },
    disableAlert: true,
  })
  const {
    request: putPassword,
    loading: loadingPutPassword,
    error: errorPutPass,
  } = useRequest(getApi(client).updateAccessDataPassword, {
    onResult: result => {
      if (result.vaultState === 'SessionClosed') {
        !modalAlreadyShown && uploadModalController.show()
        return 'vault-locked'
      } else {
        initialPsw.current = pass
        return 'vault-unlocked'
      }
    },
    disableAlert: true,
  })
  const {
    request: postAccessData,
    loading: loadingPostData,
    error: errorPostData,
  } = useRequest(getApi(client).createAccessData, {
    onResult: result => {
      if (result.vaultState === 'SessionClosed') {
        !modalAlreadyShown && uploadModalController.show()
        return 'vault-locked'
      } else {
        return 'vault-unlocked'
      }
    },
    disableAlert: true,
  })

  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { error } = useForm()
  const { sessionId } = useVault()
  const [state, setState] = useState<AccessData | undefined>(initialData.current)
  const [pass, setPass] = useState<string | undefined>(undefined)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  const refreshAccessData = useEvent<string>({ key: EDIT_ACCESS_DATA_EVENT_KEY })

  useEffect(() => {
    pass === undefined && download(sessionId)
  }, [])

  useEffect(() => {
    if (loading === false) {
      initialPsw.current = response?.data ?? ''
      setPass(response?.data ?? '')
    }
  }, [loading])

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'
    let equal = error

    if (!error) {
      equalData.current = isEqual(initialData.current, state)

      if (mode === 'edit') {
        equal = equalData.current && initialPsw.current === pass
      } else {
        equal = state === undefined || JSON.stringify(state) === '{}'
      }

      if (equal === false && state?.email === undefined && state?.user === undefined) {
        updateUploadStatus('mandatoryMissing')
        return
      }
    }

    if (!equal) {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state, pass])

  useEffect(() => {
    if (mode === 'edit' ? loadingPutData === 'catched' || loadingPutPassword === 'catched' : loadingPostData === 'catched') {
      alert(
        isOnline === true ? i18n.t('API_CATCH_TITLE') : i18n.t('NO_INTERNET_CONNECTION_TITLE'),
        mode === 'edit'
          ? isOnline === true
            ? `${errorPutData}\n${errorPutPass}`
            : i18n.t('NO_INTERNET_CONNECTION_EDIT_ACCESS_DATA')
          : isOnline === true
            ? errorPostData
            : i18n.t('NO_INTERNET_CONNECTION_CREATE_ACCESS_DATA')
      )
      updateUploadStatus('waiting')

      return
    }

    const loadingDone = mode === 'edit' ? loadingPutData === false && loadingPutPassword === false : loadingPostData === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      refreshAccessData.emit(sessionId)
    }
  }, [loadingPutData, loadingPutPassword, loadingPostData])

  const download = (session?: string) => accessData && downloadPassword({ VaultSessionId: session ?? sessionId, id: accessData.id })

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = (id?: string) => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    if (mode === 'edit') {
      !equalData.current &&
        putAccessData({
          VaultSessionId: id ?? sessionId,
          id: state.id,
          accessdatagroupId: state.accessdatagroupId,
          serialnumberId: state.serialnumberId,
          inactive: false,
          note: state.note,
          note2: state.note2,
          noteWithAccesslevel: state.noteWithAccesslevel,
          email: state.email,
          url: state.url,
          user: state.user,
        })
      initialPsw.current !== pass && putPassword({ VaultSessionId: id ?? sessionId, id: state.id, password: pass })
    } else {
      postAccessData({
        VaultSessionId: id ?? sessionId,
        customerId: customerId,
        accesslevel: state.accesslevel,
        password: pass,
        accessdatagroupId: state.accessdatagroupId,
        serialnumberId: state.serialnumberId,
        note: state.note,
        note2: state.note2,
        noteWithAccesslevel: state.noteWithAccesslevel,
        email: state.email,
        url: state.url,
        user: state.user,
      })
    }
  }

  const handleChangeText = (newVal: Partial<AccessData>) => {
    if (waitingUpload === 'uploading') return
    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const handleBackPress = () => {
    if (isModal) {
      onForceClose()
    } else {
      navigation.goBack()
    }
  }

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: mode === 'edit' ? loading === false : true,
  }

  return (
    <>
      <ScrollViewForm>
        {mode === 'creation' && (
          <IM.View spacing="top">
            <IM.Text style={{ color: theme.general.info }}>{i18n.t('INFORMATION')}</IM.Text>
            <IM.Text>{i18n.t('PASSWORD_CREATION_INFO')} </IM.Text>
            <IM.View spacing="top" spacingType="margin" style={[styles.divider, { borderColor: theme.textPlaceholder }]} />
          </IM.View>
        )}
        <AccessGroupSelector
          value={state ? ({ description: state.accessdatagroup, id: state.accessdatagroupId } as Group) : undefined}
          onChange={value => handleChangeText({ accessdatagroup: value ? value.code : undefined, accessdatagroupId: value ? value.id : undefined })}
          onHardwareBackPress={handleBackPress}
          {...commonProps}
        />
        {mode === 'creation' && (
          <AccessLevelInput
            title={i18n.t('ACCESS_LEVEL')}
            type="number"
            maxLength={2}
            customerId={customerId}
            accessDataGroupId={state?.accessdatagroupId}
            onChangeText={numb => handleChangeText({ accesslevel: parseInt(numb, 10) })}
            onHardwareBackPress={handleBackPress}
            {...commonProps}
          />
        )}
        <TextInput
          title={state === undefined || state.user === undefined ? `${i18n.t('EMAIL')} *` : i18n.t('EMAIL')}
          value={state?.email ?? ''}
          type="email"
          onChangeText={text => handleChangeText({ email: text === '' ? undefined : text })}
          {...commonProps}
        />
        <TextInput
          title={state === undefined || state.email === undefined ? `${i18n.t('USER')} *` : i18n.t('USER')}
          value={state?.user ?? ''}
          onChangeText={text => handleChangeText({ user: text === '' ? undefined : text })}
          {...commonProps}
        />
        <PasswordInput
          title={i18n.t('PASSWORD')}
          value={pass ?? ''}
          onChangeText={setPass}
          loading={mode === 'edit' && loading !== false}
          showPasswordGeneration
          {...commonProps}
        />
        <SerialnumberSelector
          customerId={customerId}
          value={state ? ({ id: state.serialnumberId, number: state.serialnumber } as SerialNumber) : undefined}
          onChange={value => {
            handleChangeText(
              value ? { serialnumberId: value.id, serialnumber: value.number } : { serialnumberId: undefined, serialnumber: undefined }
            )
          }}
          {...commonProps}
        />
        <TextInput
          title="Url"
          value={state?.url ?? ''}
          type="url"
          onChangeText={text => handleChangeText({ url: text === '' ? undefined : text })}
          {...commonProps}
        />
        <TextInput
          title={i18n.t('COMMENT')}
          value={state?.note ?? ''}
          multiline
          fixMultilineHeight
          onChangeText={text => handleChangeText({ note: text })}
          {...commonProps}
        />
        <TextInput
          title={i18n.t('ADDITIONAL_NOTE')}
          value={state?.note2 ?? ''}
          multiline
          fixMultilineHeight
          onChangeText={text => handleChangeText({ note2: text })}
          {...commonProps}
        />
        <TextInput
          title={i18n.t('RESTRICTED_NOTE')}
          details={Utils.stringValueReplacer(i18n.t('RESTRICTED_NOTE_DETAILS'), state?.accesslevel)}
          value={state?.noteWithAccesslevel ?? ''}
          multiline
          fixMultilineHeight
          onChangeText={text => handleChangeText({ noteWithAccesslevel: text })}
          {...commonProps}
        />
      </ScrollViewForm>
      <VaultModal controller={passwordModalController} onAccept={download} onHardwareBackPress={handleBackPress} />
      <VaultModal controller={uploadModalController} onAccept={upload} onHardwareBackPress={handleBackPress} />
    </>
  )
}

const styles = StyleSheet.create({
  container: { paddingHorizontal: 2 * IMLayout.horizontalMargin, flex: 1 },
  divider: {
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
})

export default forwardRef(AccessDataEditOrCreateView)
