import { useContext } from 'react'

import { ReportContext } from '../contexts/ReportContext'

export default function useReport() {
  const context = useContext(ReportContext)

  if (context === undefined) {
    throw new Error('useReport() must be called inside ReportProvider')
  }

  return context
}
