import { IM, IMLayout, SpacingProps, useDimensions, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { Platform, StyleSheet } from 'react-native'
import { interpolateColor, useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'

import api from '../../apis/apiCalls'
import { TicketActivity } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import CardButton from '../../components/CardButton'
import CellText from '../../components/CellText'
import EmployeeBadgeTable from '../../components/EmployeeBadgeTable'
import DynamicView from '../../components/Infominds/DynamicView'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../components/Infominds/Pressable'
import Separator from '../../components/Infominds/Separator'
import NavigationIcon from '../../components/NavigationIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import Text from '../../components/Text'
import TextTable from '../../components/TextTable'
import { EDIT_ACTIVITY_TIME_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ACTIVITY_TIME } from '../../constants/Keys'
import useUserSettings from '../../hooks/useUserSettings'
import ActivityEditOrCreateTimeModal from '../../modals/activity/ActivityEditOrCreateTimeModal'
import { ActivityStackParamList } from '../../navigation/types'
import { ActivityEditOrCreateTimesParams, ThemeColorExpanded, TimeFormat } from '../../types'
import ticketUtils from '../../utils/TicketUtils'
import TimeUtils from '../../utils/TimeUtils'

type Props = {
  activity: TicketActivity
  type?: 'Closing' | 'Activity'
  spacing?: SpacingProps
  onlyRead?: boolean
  onlyCreate?: boolean
}

const firstColumn = IMLayout.flex.f1
const secondColumn = IMLayout.flex.f2
const thirdColumn = IMLayout.flex.f2
const fourthColumn = IMLayout.flex.f1
const fifthColum = IMLayout.flex.f1
const sixthColum = IMLayout.flex.f1
const seventhColumn = { width: 18 }

const ActivityTimeCard = memo(function ActivityTimeCard({ activity, spacing, onlyRead, onlyCreate, type = 'Activity' }: Props) {
  const timeRecording = useSharedValue(-1)
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { userSettings } = useUserSettings()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()
  const navigation = useNavigation<NavigationProp<ActivityStackParamList>>()
  const controller = useModalController<ActivityEditOrCreateTimesParams>()
  const { item: times, loadItem: getTimes, loading } = useControlledLoader(api.getActivityTime, { id: REQUEST_ACTIVITY_TIME })

  useEvent<string | undefined>({ key: EDIT_ACTIVITY_TIME_EVENT_KEY }, id => (id === undefined || activity.id === id) && refresh())

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    timeRecording.value = withRepeat(
      withTiming(-timeRecording.value, {
        duration: 1500,
      }),
      -1,
      true
    )
  }, [])

  const refresh = () => getTimes({ activityId: activity.id })

  const date = (value: string) => <TextTable text={TimeUtils.format(value, language)} />

  const time = (seconds?: number, format?: TimeFormat, timeIndicator?: 'top' | 'bottom') => (
    <TextTable text={seconds !== undefined ? TimeUtils.secondsToTime(seconds, format) : '-'} timeIndicator={timeIndicator} />
  )

  const invoice = (invoiceType?: string) => <TextTable text={invoiceType ?? '-'} />

  const handleAdd = () =>
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('ActivityEditOrCreateTimes', { activityId: activity.id })
      : controller.show({ activityId: activity.id })

  const animatedStyle = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      timeRecording.value,
      [-1, 1],
      colorScheme === 'light' ? [theme.pressable.animatedHighlight, '#FFFFFF00'] : ['#00000000', theme.pressable.animatedHighlight]
    )

    return {
      backgroundColor,
      borderRadius: IMLayout.borderRadius,
    }
  }, [])

  let displayStartStop = true
  if (type === 'Closing' && userSettings && !userSettings.displayStartAndEndOfTimesOnSummary) {
    displayStartStop = false
  }

  return (
    <>
      <IM.Card
        spacing={spacing}
        head={<CardLeftTitle text={i18n.t('TIMES')} />}
        style={styles.container}
        noContentSpacing
        buttons={{
          buttons:
            isSmallDevice || onlyRead
              ? undefined
              : [
                  {
                    icon: ['fal', 'plus'],
                    onPress: handleAdd,
                    backGroundColor: theme.general.info,
                  },
                ],
        }}>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <IM.View style={{ margin: isSmallDevice || times?.length === 0 ? 6 : 10 }}>
          {loading === false && times !== undefined && times.length !== 0 ? (
            <>
              {!isSmallDevice && (
                <IM.View>
                  <DynamicView style={[IMLayout.flex.f1, styles.headerContainer]}>
                    <CellText style={firstColumn} value={i18n.t('DATE')} secondary numberOfLines={1} />
                    <CellText style={secondColumn} value={i18n.t('COLLABORATOR')} secondary numberOfLines={1} />
                    <CellText style={thirdColumn} value={i18n.t('BILLING')} secondary numberOfLines={1} />
                    {displayStartStop === true && <CellText style={fourthColumn} value={i18n.t('START')} secondary numberOfLines={1} />}
                    {displayStartStop === true && <CellText style={fifthColum} value={i18n.t('END')} secondary numberOfLines={1} />}
                    <CellText style={sixthColum} value={i18n.t('DURATION')} secondary numberOfLines={1} />
                    <IM.View style={seventhColumn} />
                  </DynamicView>
                  <Separator spacing="none" type="primary" />
                </IM.View>
              )}
              {ticketUtils.orderTimeEntries(times).map((data, index) => {
                let onlyBilledTimes = false

                if (type === 'Closing' && userSettings && userSettings.showOnlyTheActivityTimesToBilledOnSummary) {
                  onlyBilledTimes = true
                }

                if (onlyBilledTimes === false || (onlyBilledTimes && data.toBilled)) {
                  return (
                    <IM.View key={data.id + (data.period ?? 0).toString()}>
                      <Pressable
                        disabled={onlyRead || onlyCreate}
                        onPress={() =>
                          isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                            ? navigation.navigate('ActivityEditOrCreateTimes', { time: data, activityId: activity.id })
                            : controller.show({ time: data, activityId: activity.id })
                        }
                        containerStyle={data.openTime ? animatedStyle : undefined}>
                        <IM.View style={IMLayout.flex.row}>
                          <DynamicView style={IMLayout.flex.f1} flexLimitSmallDevice={1}>
                            <CellText title={i18n.t('DATE')} value={date(data.date)} disableSpacing style={firstColumn} />
                            <CellText
                              title={i18n.t('COLLABORATOR')}
                              value={<EmployeeBadgeTable employeeId={data.employeeId} name={data.employee} />}
                              style={[styles.containerEmployee, secondColumn]}
                            />
                            <CellText title={i18n.t('BILLING')} value={invoice(data.invoicingType)} style={thirdColumn} />
                            {displayStartStop === true && (
                              <CellText
                                title={i18n.t('START')}
                                value={time(data.from, TimeFormat.TIME, data.openTime ? undefined : 'top')}
                                style={fourthColumn}
                              />
                            )}
                            {displayStartStop === true && (
                              <CellText
                                title={i18n.t('END')}
                                value={
                                  !data.openTime ? (
                                    time(data.until, TimeFormat.TIME, 'bottom')
                                  ) : (
                                    <IM.View style={styles.recording}>
                                      <Text secondary>{i18n.t('RECORDING')}</Text>
                                    </IM.View>
                                  )
                                }
                                style={fifthColum}
                              />
                            )}
                            <CellText
                              title={i18n.t('DURATION')}
                              value={time(data.until ? data.until - data.from : undefined, TimeFormat.TIME_WITH_DIMENSIONS)}
                              style={sixthColum}
                            />
                            {!isSmallDevice && (
                              <IM.View style={[IMLayout.flex.row, seventhColumn, styles.iconContainer]}>
                                {!onlyRead && !onlyCreate && <NavigationIcon style={styles.justify} />}
                              </IM.View>
                            )}
                          </DynamicView>
                          {!onlyRead && !onlyCreate && isSmallDevice && <NavigationIcon enableTableStyle />}
                        </IM.View>
                      </Pressable>
                      {index !== times?.length - 1 && <Separator spacing="none" />}
                    </IM.View>
                  )
                }

                return <></>
              })}
            </>
          ) : (
            <>
              {times === undefined ? (
                <IM.View spacing={isSmallDevice ? 'horizontal' : 'none'}>
                  {isSmallDevice ? (
                    <>
                      <SkeletonText width="80%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="vertical" />
                    </>
                  ) : (
                    <>
                      <SkeletonText width="100%" spacing="top" />
                      <Separator spacing="bottom" />
                      <SkeletonText width="100%" spacing="bottom" />
                    </>
                  )}
                </IM.View>
              ) : (
                <IM.View spacing="all">
                  <IM.Text secondary>{i18n.t('NO_TIMES_FOUND')}</IM.Text>
                </IM.View>
              )}
            </>
          )}
          {isSmallDevice && !onlyRead && (
            <CardButton
              onPress={handleAdd}
              style={styles.addButton}
              disableSeparator={loading === false && times !== undefined && times.length === 0}
            />
          )}
        </IM.View>
      </IM.Card>
      <ActivityEditOrCreateTimeModal controller={controller} />
    </>
  )
})

export default ActivityTimeCard

const styles = StyleSheet.create({
  addButton: { margin: 6, marginTop: 3 },
  container: {
    flexGrow: 1,
  },
  containerEmployee: { alignItems: 'center' },
  headerContainer: { paddingHorizontal: 8, marginBottom: 2 },
  iconContainer: { justifyContent: 'flex-end' },
  justify: { justifyContent: 'center' },
  note: { justifyContent: 'center', marginRight: 8 },
  recording: {
    flex: 1,
    justifyContent: 'center',
  },
})
