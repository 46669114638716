import React, { memo, ReactNode } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'
import DefaultModal from 'react-native-modal'
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context'

import TabletModal from './TabletModal'

export interface SelectModalBaseProps {
  isVisible: boolean
  children: ReactNode
  style?: StyleProp<ViewStyle>
  coverScreen?: boolean
  statusBarTranslucent?: boolean
  isTablet?: boolean
  disableBackdropOpacity?: boolean
  onClose: () => void
}

const SelectModalBase = memo(function SelectModalBase({
  isVisible,
  children,
  style,
  isTablet,
  disableBackdropOpacity = false,
  coverScreen = true,
  statusBarTranslucent = false,
  onClose,
}: SelectModalBaseProps) {
  return (
    <>
      {!isTablet ? (
        <DefaultModal
          isVisible={isVisible}
          animationIn={Platform.OS === 'android' ? 'slideInRight' : 'slideInRight'}
          animationOut={Platform.OS === 'android' ? 'slideOutRight' : 'slideOutRight'}
          onBackButtonPress={onClose}
          coverScreen={coverScreen}
          statusBarTranslucent={statusBarTranslucent}
          swipeDirection={['right']}
          onSwipeComplete={() => Platform.OS === 'ios' && onClose()}
          backdropOpacity={0}
          propagateSwipe
          hideModalContentWhileAnimating
          useNativeDriver={Platform.OS === 'android' || Platform.OS === 'ios'}
          useNativeDriverForBackdrop={Platform.OS === 'android' || Platform.OS === 'ios'}
          style={[styles.container, style]}>
          <SafeAreaProvider initialMetrics={initialWindowMetrics}>{children}</SafeAreaProvider>
        </DefaultModal>
      ) : (
        <TabletModal isVisible={isVisible} onClose={onClose} backdropOpacity={disableBackdropOpacity ? 0 : undefined}>
          {children}
        </TabletModal>
      )}
    </>
  )
})

export default SelectModalBase

const styles = StyleSheet.create({
  container: { margin: 0 },
})
