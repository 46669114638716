import { Storage } from '@infominds/react-native-components'
import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'

import { STORAGE_KEY_MENU_ORDER } from '../constants/Keys'
import useUserSettings from '../hooks/useUserSettings'
import { TabNavigationScreen } from '../navigation/types'
import { MenuPreferenceStorage } from '../types'
import navigationUtils from '../utils/navigationUtils'

export interface MenuContextProps<T extends string = string> {
  tabs: TabNavigationScreen<T>[]
  maxDynamicElements: number
  previousRoute: string | undefined
  onRouteChange: (prevRoute: T | undefined) => void
  reorder: (newTabs: TabNavigationScreen<T>[]) => void
  tabsDisabled: boolean
  setTabsDisabled: React.Dispatch<React.SetStateAction<boolean>>
}

const MenuContext = createContext<MenuContextProps | undefined>(undefined)

interface Props<T extends string> {
  children: ReactNode
  bottomTabs: TabNavigationScreen<T>[]
  maxDynamicElements: number
}

export const MenuProvider = <T extends string>({ children, bottomTabs, maxDynamicElements }: Props<T>) => {
  const { menuItems } = useUserSettings()
  const [tabs, setTabs] = useState<TabNavigationScreen<T>[]>(bottomTabs)
  const [tabsDisabled, setTabsDisabled] = useState(false)
  const [prevRoute, setPrevRoute] = useState<T | undefined>(undefined)
  const menuStorage = Storage<MenuPreferenceStorage[]>(STORAGE_KEY_MENU_ORDER)

  useEffect(() => {
    if (menuItems) {
      menuStorage
        .load()
        .then(pref => {
          if (!pref) {
            setTabs(bottomTabs)
          } else {
            const clone = bottomTabs.map(elem => {
              const foundId = pref.find(prefElem => prefElem.name === elem.name)?.id
              return { ...elem, id: foundId !== undefined ? foundId : elem.id }
            })

            setTabs(navigationUtils.sortTabs(clone, maxDynamicElements, menuItems))
          }
        })
        .catch(err => console.error('Failed loading menu storage', err))
    }
  }, [bottomTabs, menuItems])

  const handleReorder = useCallback((newTabs: TabNavigationScreen<T>[]) => {
    const storePreferences: MenuPreferenceStorage[] = []

    newTabs.forEach(elem => {
      if (elem.id !== undefined) {
        storePreferences.push({ id: elem.id, name: elem.name })
      }
    })

    menuStorage.save(storePreferences).catch(err => console.error('Failed saving menu storage', err))

    setTabs(newTabs)
    return
  }, [])

  const handleRouteChange = useCallback((newPrevRoute: T | undefined) => {
    setPrevRoute(newPrevRoute)
  }, [])

  const props = useMemo<MenuContextProps<T>>(
    () => ({
      tabs,
      maxDynamicElements,
      previousRoute: prevRoute,
      reorder: handleReorder,
      onRouteChange: handleRouteChange,
      tabsDisabled,
      setTabsDisabled,
    }),
    [tabs, prevRoute, maxDynamicElements, tabsDisabled]
  )

  return <MenuContext.Provider value={props as unknown as MenuContextProps<string>}>{children}</MenuContext.Provider>
}

export default MenuContext
