import { IMLayout, useLanguage } from '@infominds/react-native-components'
import { useAsset } from '@infominds/react-native-media'
import React from 'react'
import { Platform, StyleSheet } from 'react-native'

import { MasterDetailProp } from '../../components/MasterDetail/contexts/MasterDetailContext'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import NoEntry from '../../components/NoEntry'
import { InfoboxStackParamList } from '../../navigation/types'
import InfoboxAssetsView, { InfoboxMediaViewImperativeMethods } from './InfoboxMediaView'

interface Props {
  innerRef?: React.RefObject<InfoboxMediaViewImperativeMethods>
  customerId: string
}

export default function InfoboxAssetsViewMasterDetail({ innerRef, customerId }: Props) {
  const { i18n } = useLanguage()
  const { detailParams: params } = useMasterDetail<MasterDetailProp<InfoboxStackParamList, 'InfoboxMedia'>>()
  const { assets } = useAsset()

  if (params === undefined) {
    return <NoEntry description={i18n.t('NO_FOLDER_SELECTED')} />
  }

  return (
    <InfoboxAssetsView
      ref={innerRef}
      id={customerId}
      infoboxType={params.type}
      folderNumber={params.folderNumber}
      folderRight={params.folderRight}
      masterDetailFiles={params.masterDetailFiles}
      // eslint-disable-next-line react-native/no-inline-styles
      contentContainerStyle={{
        paddingTop: IMLayout.horizontalMargin * 1.5,
        paddingBottom: 0,
        paddingLeft: styles.paddingH.paddingHorizontal,
        paddingRight: styles.paddingH.paddingHorizontal * (Platform.OS === 'web' && assets.length !== 0 ? 2.5 : 1.5),
      }}
      buttonStyle={[styles.paddingV, styles.paddingH]}
    />
  )
}

const styles = StyleSheet.create({
  paddingH: {
    paddingHorizontal: IMLayout.horizontalMargin,
  },
  paddingV: {
    paddingVertical: IMLayout.horizontalMargin,
  },
})
