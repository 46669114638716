import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { StateResponse } from '../../apis/types/apiResponseTypes'
import { REQUEST_TICKET_STATE } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: string
  editable?: boolean
  spacing?: SpacingProps
  mandatory?: boolean
  onChange: (value: StateResponse | undefined) => void
  onDefaultFound?: (defaultId: string) => void
}

export default function TicketStateSelector({ id, mandatory, onChange, onDefaultFound, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const [selectedId, setSelectedId] = useState(id)
  const { item: data, loadItem, loading } = useControlledLoader(api.getTicketState, { id: REQUEST_TICKET_STATE })

  useEffect(() => {
    loadItem({})
  }, [])

  useEffect(() => {
    if (loading === false && data !== undefined) {
      const defaultFound = data.find(el => el.isDefault)

      defaultFound && onDefaultFound?.(defaultFound.id)
      setSelectedId(defaultFound ? defaultFound.id : undefined)
    }
  }, [loading, data])

  const handleOnChange = (newValue: StateResponse | undefined) => {
    setSelectedId(newValue?.id)
    onChange(newValue)
  }

  return (
    <SelectInput
      data={appUtils.filter(data ?? [], search, ['description', 'code', { searchStringCompositor: ticketUtils.composeStateString }])}
      value={data?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={() => loadItem({})}
      onSearchChange={setSearch}
      title={i18n.t('TICKET_STATE') + (mandatory ? ' *' : '')}
      screenTitle={i18n.t('TICKET_STATUSES')}
      noDataMessage={i18n.t('NO_TICKET_STATE_FOUND')}
      renderItem={(elem, onPress) => TicketStateRender(elem, selectedId, onPress)}
      onChange={handleOnChange}
      renderSelectedString={ticketUtils.composeStateString}
      deferredTimeout={0}
      disableLoadAfterMount
      disableReset
      {...props}
    />
  )
}

export const TicketStateRender = (
  { item }: SectionListRenderItemInfo<StateResponse, ListSection<StateResponse>>,
  selectedId: string | undefined,
  onPress?: () => void
) => {
  return <RadioPressable onPress={() => onPress?.()} selected={item.id === selectedId} text={ticketUtils.composeStateString(item)} />
}
