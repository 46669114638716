import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'

import { ThemeColorExpanded } from '../../../types'

interface Props {
  bottom?: number
  onChange: () => void
}

export function CenterButton({ bottom, onChange }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  return (
    <TouchableOpacity
      style={[
        styles.container,
        Platform.OS === 'ios' ? { backgroundColor: theme.header.main.background } : { backgroundColor: Color('white').fade(0.3).toString(), bottom },
      ]}
      onPress={() => onChange()}>
      <IM.View style={styles.buttonContainer}>
        {Platform.OS === 'android' && (
          <IM.Text style={[styles.text, { fontWeight: IMStyle.typography.fontWeightMedium, color: theme.general.blue }]}>{i18n.t('CENTER')}</IM.Text>
        )}
        <IM.Icon icon={['fas', 'location-arrow']} color={theme.general.blue} size={Platform.OS === 'android' ? 21 : 24} />
      </IM.View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 1,
    padding: 6,
    borderRadius: 10,
    ...Platform.select({
      android: { alignSelf: 'center' },
      ios: {
        left: 10,
        top: 50,
      },
    }),
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    paddingRight: 4,
  },
})
