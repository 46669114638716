import { IMLayout } from '@infominds/react-native-components'
import { StyleSheet } from 'react-native'

import { SCREEN_CONSTANTS } from '../components/screen/constants/constants'

const CONSTANTS = {
  toastVisibilityTime: 5000,
  appName: 'RX+ Service',
  maxLastUsedCustomers: 3,
  maxLastUsedCustomersTablet: 6,
  skeletonCards: 30,
  accessDataSkeletonCards: 12,
  customersChunkSize: 14,
  defaultChuckSize: 25,
  destinationsChuckSize: 25,
  maxNavBarElements: 4,
  maxTabletNavBarElements: 6,
  landscapeTabletMaxSearchBar: 250,
  portraitTabletMaxSearchBar: 130,
  landscapeTabletMaxFilterBar: 500,
  portraitTabletMaxFilterBar: 350,
  maxPhoneNumbers: 13,
  cardLeftWidth: 6,
  secondButtonBottom: 64,
  searchDeferredTimeout: 400,
  signatureTimeout: 700,
  verticalTextMinHeight: 70,
  margin: 6,
  disabledOpacityLight: 0.5,
  disabledOpacityDark: 0.38,
  minDataLengthToHaveInputBox: 2,
  noSelectionId: 'noSelectionId',
  resetCheckIcon: 2000,
}

export default CONSTANTS

export const closeActivityStyles = StyleSheet.create({
  header: {
    marginHorizontal: -16,
    paddingHorizontal: 16,
    paddingTop: 2 * IMLayout.verticalMargin,
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
})

export const SETTINGS_BORDER_RADIUS = SCREEN_CONSTANTS.headerRadius - 6
