import { IM, IMLayout, SpacingProps, useDimensions, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import CardButton from '../../components/CardButton'
import CellText from '../../components/CellText'
import EmployeeBadgeTable from '../../components/EmployeeBadgeTable'
import DynamicView from '../../components/Infominds/DynamicView'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import Pressable from '../../components/Infominds/Pressable'
import Separator from '../../components/Infominds/Separator'
import NavigationIcon from '../../components/NavigationIcon'
import SkeletonText from '../../components/skeleton/SkeletonText'
import TextTable from '../../components/TextTable'
import { EDIT_ACTIVITY_PLANNING_TIME_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ACTIVITY_PLANNING_TIME } from '../../constants/Keys'
import ActivityEditOrCreatePlanningTimeModal from '../../modals/activity/ActivityEditOrCreatePlanningTimeModal'
import { ActivityStackParamList } from '../../navigation/types'
import { ActivityEditOrCreatePlanningTimesParams, ThemeColorExpanded, TimeFormat } from '../../types'
import ticketUtils from '../../utils/TicketUtils'
import TimeUtils from '../../utils/TimeUtils'

type Props = {
  id: string
  ticketId: string
  spacing?: SpacingProps
  onlyRead?: boolean
}

const firstColumn = IMLayout.flex.f1
const secondColumn = IMLayout.flex.f4
const thirdColumn = IMLayout.flex.f1
const fourthColumn = IMLayout.flex.f2
const fifthColum = { width: 18 }

const ActivityPlanningCard = memo(function ActivityPlanningCard({ id, ticketId, spacing, onlyRead }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation<NavigationProp<ActivityStackParamList>>()
  const controller = useModalController<ActivityEditOrCreatePlanningTimesParams>()

  const { item: times, loadItem: getTimes, loading } = useControlledLoader(api.getPlanningTime, { id: REQUEST_ACTIVITY_PLANNING_TIME })

  useEvent({ key: EDIT_ACTIVITY_PLANNING_TIME_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => getTimes({ activityId: id })

  const date = (value: string) => <TextTable text={TimeUtils.format(value, language)} />

  const time = (seconds?: number, format?: TimeFormat, timeIndicator?: 'top' | 'bottom') => (
    <TextTable text={seconds !== undefined ? TimeUtils.secondsToTime(seconds, format) : '-'} timeIndicator={timeIndicator} />
  )

  const handleAdd = () =>
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('ActivityEditOrCreatePlanningTimes', { ticketId, activityId: id })
      : controller.show({ ticketId, activityId: id })

  return (
    <>
      <IM.Card
        spacing={spacing}
        head={<CardLeftTitle text={i18n.t('PLANNING')} />}
        style={styles.container}
        noContentSpacing
        buttons={{
          buttons:
            isSmallDevice || onlyRead
              ? undefined
              : [
                  {
                    icon: ['fal', 'plus'],
                    onPress: handleAdd,
                    backGroundColor: theme.card.button.active,
                  },
                ],
        }}>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <IM.View style={{ margin: isSmallDevice || times?.length === 0 ? 6 : 10 }}>
          {loading === false && times !== undefined && times.length !== 0 ? (
            <>
              {!isSmallDevice && (
                <IM.View>
                  <DynamicView style={[IMLayout.flex.f1, styles.headerContainer]}>
                    <CellText style={firstColumn} secondary numberOfLines={1} value={i18n.t('DATE')} />
                    <CellText style={secondColumn} secondary numberOfLines={1} value={i18n.t('COLLABORATOR')} />
                    <CellText style={thirdColumn} secondary numberOfLines={1} value={i18n.t('START')} />
                    <CellText style={fourthColumn} secondary numberOfLines={1} value={i18n.t('END')} />
                    <IM.View style={fifthColum} />
                  </DynamicView>
                  <Separator spacing="none" type="primary" />
                </IM.View>
              )}
              {ticketUtils.orderTimeEntries(times).map((data, index) => (
                <IM.View key={data.id}>
                  <Pressable
                    disabled={onlyRead}
                    onPress={() =>
                      isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                        ? navigation.navigate('ActivityEditOrCreatePlanningTimes', { time: data, ticketId, activityId: id })
                        : controller.show({ time: data, ticketId, activityId: id })
                    }>
                    <IM.View style={IMLayout.flex.row}>
                      <DynamicView style={IMLayout.flex.f1} flexLimitSmallDevice={1}>
                        <CellText title={i18n.t('DATE')} value={date(data.date)} style={firstColumn} disableSpacing />
                        <CellText
                          title={i18n.t('COLLABORATOR')}
                          value={<EmployeeBadgeTable employeeId={data.employeeId} name={data.employee} />}
                          style={[secondColumn, styles.containerEmployee]}
                        />
                        <CellText title={i18n.t('START')} style={thirdColumn} value={time(data.from, TimeFormat.TIME, 'top')} />
                        <CellText title={i18n.t('END')} style={fourthColumn} value={time(data.until, TimeFormat.TIME, 'bottom')} />
                        {!onlyRead && !isSmallDevice && (
                          <IM.View style={[IMLayout.flex.row, fifthColum, styles.iconContainer]}>
                            <NavigationIcon style={styles.justify} />
                          </IM.View>
                        )}
                      </DynamicView>
                      {!onlyRead && isSmallDevice && <NavigationIcon enableTableStyle />}
                    </IM.View>
                  </Pressable>
                  {index !== times?.length - 1 && <Separator spacing="none" />}
                </IM.View>
              ))}
            </>
          ) : (
            <>
              {times === undefined ? (
                <IM.View spacing="horizontal">
                  {isSmallDevice ? (
                    <>
                      <SkeletonText width="80%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="top" />
                      <SkeletonText width="100%" spacing="vertical" />
                    </>
                  ) : (
                    <>
                      <SkeletonText width="100%" spacing="top" />
                      <Separator spacing="bottom" />
                      <SkeletonText width="100%" spacing="bottom" />
                    </>
                  )}
                </IM.View>
              ) : (
                <IM.View spacing="all">
                  <IM.Text secondary>{i18n.t('NO_PLANNING_TIMES_FOUND')}</IM.Text>
                </IM.View>
              )}
            </>
          )}
          {isSmallDevice && !onlyRead && (
            <CardButton
              onPress={handleAdd}
              style={styles.addButton}
              disableSeparator={loading === false && times !== undefined && times.length === 0}
            />
          )}
        </IM.View>
      </IM.Card>
      <ActivityEditOrCreatePlanningTimeModal controller={controller} />
    </>
  )
})

export default ActivityPlanningCard

const styles = StyleSheet.create({
  addButton: { margin: 6, marginTop: 3 },
  container: {
    flexGrow: 1,
  },
  containerEmployee: { alignItems: 'center' },
  headerContainer: { paddingHorizontal: 8, marginBottom: 2 },
  iconContainer: { justifyContent: 'flex-end' },
  justify: { justifyContent: 'center' },
  note: { justifyContent: 'center', marginRight: 8 },
})
