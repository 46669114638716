import { IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'

import EmployeeBadge from '../../components/EmployeeBadge'

type Props = {
  codes: string[]
  description: string
  employeeId: string
  employee: string
  onPress?: () => void
}

export default function CalenderListCard({ codes, description, employee, employeeId, onPress }: Props) {
  return (
    <TouchableOpacity onPress={onPress} style={styles.root}>
      <IM.View style={styles.container}>
        <IM.View spacing="all" style={styles.content}>
          <IM.View>
            <IM.Text primary numberOfLines={3}>
              {description}
            </IM.Text>
            {codes.map((code, index) => (
              <IM.Text key={index} secondary style={styles.textDetail} numberOfLines={3}>
                {code}
              </IM.Text>
            ))}
          </IM.View>
          <IM.View style={styles.badgesContainer}>
            <EmployeeBadge name={employee} id={employeeId} style={[styles.badge]} />
          </IM.View>
        </IM.View>
      </IM.View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  root: { height: '100%' },
  container: { height: '100%' },
  content: { flex: 1, justifyContent: 'space-between' },
  textDetail: { fontSize: 10, paddingTop: 2 },
  badgesView: { justifyContent: 'flex-start' },
  badge: { alignItems: 'center', paddingVertical: 4, marginLeft: 6 },
  badgesContainer: { alignItems: 'flex-end' },
})
