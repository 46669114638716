import { IconProps, IM, IMLayout, IMStyle, useDimensions, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import CONSTANTS from '../constants/Constants'
import { ThemeColorExpanded } from '../types'
import Pressable from './Infominds/Pressable'
import Text from './Text'

interface Props extends Partial<IconProps> {
  id: string
  name: string
  active: boolean
  isHeader?: boolean
  disabled?: boolean
  style?: StyleProp<ViewStyle>
  onPress?: (id: string) => void
}

export default function Tag({ id, name, active, disabled, style, isHeader, onPress, ...iconProps }: Props) {
  const { isSmallDevice } = useDimensions()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const color = !isHeader && active ? theme.chip.highlightedText : theme.chip.text

  return (
    <IM.View
      style={[
        styles.filter,
        styles.common,
        styles.groupedFilter,
        {
          backgroundColor:
            colorScheme === 'light'
              ? theme.card.background
              : Platform.OS === 'web' || !isSmallDevice
                ? theme.header.detail.background
                : theme.header.main.background,
          borderColor: theme.chip.border,
        },
        !isHeader &&
          active && {
            backgroundColor: theme.chip.active.background,
            borderColor: theme.chip.active.border,
          },
        IMLayout.shadow,
        style,
      ]}>
      <Pressable onPress={() => onPress?.(id)} disabled={disabled} style={styles.common} containerStyle={styles.common} hitSlop={2}>
        {iconProps.icon && <IM.Icon icon={iconProps.icon} color={color} style={styles.iconLeft} {...iconProps} />}
        <Text style={[styles.text, { color }, CONSTANTS.noSelectionId === id && styles.textAlign]}>{name}</Text>
      </Pressable>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  common: {
    minWidth: 52,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconLeft: { paddingRight: 4 },
  filter: {
    borderRadius: IMLayout.borderRadius,
    justifyContent: 'center',
  },
  groupedFilter: {
    marginRight: 7,
  },
  text: {
    fontSize: IMStyle.typography.fontSizeSmall - 2,
  },
  textAlign: {
    textAlign: 'center',
  },
})
