import { IM, IMStyle, SpacingProps, TextProps } from '@infominds/react-native-components'
import isArray from 'lodash/isArray'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

interface Props {
  title: string
  description?: string | (string | undefined)[]
  secondaryTitle?: boolean
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const CardText = memo(function CardText({
  title,
  description,
  spacing,
  style,
  secondaryTitle,
  ...others
}: Props & Pick<TextProps, 'numberOfLines' | 'secondary'>) {
  return (
    <IM.View style={style} spacing={spacing} spacingType="margin">
      <IM.Text secondary={secondaryTitle} style={[cardTextStyle.title, styles.divider]}>
        {title}
      </IM.Text>
      {description && (
        <>
          {isArray(description) ? (
            <>
              {description.map((val, index) => (
                <IM.View key={`${val ?? ''}-${index}`}>
                  {val && (
                    <IM.Text {...others} style={index !== description.length - 1 && styles.divider}>
                      {val}
                    </IM.Text>
                  )}
                </IM.View>
              ))}
            </>
          ) : (
            <IM.Text {...others}>{description}</IM.Text>
          )}
        </>
      )}
    </IM.View>
  )
})

export default CardText

const styles = StyleSheet.create({
  divider: {
    marginBottom: 2,
  },
})

export const cardTextStyle = StyleSheet.create({
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
    fontWeight: IMStyle.typography.fontWeightBold,
  },
})
