import { IM, IMStyle } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { TextStyle } from 'react-native'

export interface DefaultBaseTextInputTitleProps {
  title?: string
  details?: string
  fontWeight?: TextStyle['fontWeight']
}

const DefaultBaseTextInputTitle = memo(function DefaultBaseTextInputTitle({ title, details, fontWeight }: DefaultBaseTextInputTitleProps) {
  return (
    <>
      <IM.Text style={{ fontWeight: fontWeight ?? IMStyle.typography.fontWeightMedium }}>{title}</IM.Text>
      {details && <IM.Text secondary>{details}</IM.Text>}
    </>
  )
})

export default DefaultBaseTextInputTitle
