import { IM, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'

interface Props {
  selected: boolean
  spacing?: SpacingProps
  disabled?: boolean
}

export default function RadioButton({ selected, disabled, spacing }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const background = selected ? (disabled ? theme.textDetail : theme.radioButton) : 'transparent'

  return (
    <IM.View style={styles.container} spacing={spacing}>
      <IM.View style={[styles.outside, { borderColor: disabled ? theme.textDetail : theme.radioButton }]} />
      <IM.View style={[styles.inside, { backgroundColor: background }]} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  outside: { borderWidth: 1, right: 0, borderRadius: 100, width: 20, height: 20 },
  inside: { borderRadius: 100, left: -16, width: 12, height: 12 },
})
