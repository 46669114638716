import { useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import { AdditionalField } from '../types'
import appUtils from '../utils/appUtils'
import CellText from './CellText'

interface Props {
  field: AdditionalField
  index: number
  style?: StyleProp<ViewStyle>
}

export default function AdditionalFieldElement({ field, index, style }: Props) {
  const { i18n, language } = useLanguage()

  const value = useMemo(() => appUtils.getAdditionFieldValue(field, language, i18n), [language])

  if (value === undefined) return <></>

  return <CellText title={field.definitionDescription} value={value} disableSpacing={index === 0} style={style} forceShowTitle forceSpacingTable />
}
