import { IM, IMLayout, useLanguage, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet, View } from 'react-native'

import { Ticket } from '../../../apis/types/apiResponseTypes'
import TextWithIcon from '../../../components/Infominds/TextWithIcon'
import Tag from '../../../components/Tag'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import TimeUtils from '../../../utils/TimeUtils'
import { TicketListType, TicketListView } from '../../../views/ticket/TicketList'
import TicketInfo from './TicketInfo'

type Props = {
  ticket: Ticket
  type: TicketListType
  showIcon?: boolean
  showEmployee?: boolean
  forceLayout?: ForceLayoutType
  timeRunning: boolean
  view: TicketListView
}

export default function TicketTitle({ ticket, style, type, showEmployee, showIcon, forceLayout, timeRunning, view, ...props }: Props & ViewProps) {
  const { theme } = useTheme()
  const { language, i18n } = useLanguage()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const formattedDocumentDate = useMemo(() => TimeUtils.format(ticket.documentDate, language), [ticket, language])
  const formattedDate = useMemo(() => (ticket.planDate ? TimeUtils.format(ticket.planDate, language) : undefined), [ticket, language])
  const startTime = useMemo(() => (ticket.planStartTime ? TimeUtils.formatSeconds(ticket.planStartTime, undefined, true) : undefined), [ticket])
  const endTime = useMemo(() => (ticket.planEndTime ? TimeUtils.formatSeconds(ticket.planEndTime, undefined, true) : undefined), [ticket])

  return (
    <IM.View style={[style, IMLayout.flex.row]} {...props}>
      <IM.View style={[IMLayout.flex.f1, type === 'carousel' && styles.map]}>
        <IM.View style={IMLayout.flex.f1}>
          <IM.Text>{ticket.code}</IM.Text>
          <TextWithIcon
            spacing={formattedDate && type === 'normal' ? 'bottom' : 'none'}
            icon={['fal', 'calendar-plus']}
            iconColor={theme.textDetail}
            style={{ marginTop: 0.5 * IMLayout.verticalMargin }}>
            {formattedDocumentDate}
          </TextWithIcon>
        </IM.View>
        <IM.View>
          {(formattedDate !== undefined || startTime !== undefined || endTime !== undefined) && (
            <TextWithIcon icon={['fal', 'calendar-range']} iconColor={theme.textDetail}>
              {`${formattedDate ? formattedDate : ''}${startTime || endTime ? '\n' : ''}${startTime ? startTime : ''}${endTime ? ` - ${endTime}` : ''}`}
            </TextWithIcon>
          )}
          {ticket.hasUnSyncedCompletedActivities && !isSmallDevice && (
            <IM.View style={[IMLayout.flex.row]} spacing="top">
              <Tag name={i18n.t('COMPLETED_PENDING_SYNC')} id="PendingSyncTag" active={true} />
            </IM.View>
          )}
        </IM.View>
      </IM.View>
      <TicketInfo view={view} ticket={ticket} showEmployee={showEmployee} showIcon={showIcon} forceLayout={forceLayout} timeRunning={timeRunning} />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  map: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
