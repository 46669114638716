import { Cursor } from 'react-native-local-mongodb'

import { DataStorage } from './DataStorage'

export type RequestType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'
// TData is required for autocomplete
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export type GetQueryProviderFun<TData, TGetRequest> = (request?: TGetRequest) => object | undefined
export type GetQueryProviderFunWrapper<TData, TGetRequest> = { fun: GetQueryProviderFun<TData, TGetRequest>; modifierKeys: (keyof TGetRequest)[] }
export type GetResultModifierFun<TData, TGetRequest> = (result: Cursor<TData[]>, request?: TGetRequest) => Cursor<TData[]>
export type GetResultModifierFunWrapper<TData, TGetRequest> = { fun: GetResultModifierFun<TData, TGetRequest>; modifierKeys: (keyof TGetRequest)[] }
export type GetApiResultModifierFun<TData> = (result?: unknown) => TData[]

export interface ApiResource {
  id: string
  resource: string //local resource  (db table)
  apiResource: string //api resource (url)
  syncOptions?: SyncOptions
}

export type DataProviderOptions<TData, TGetRequest = void> = {
  id: (keyof TData)[]
  enableOffline?: boolean
  get_queryProvider?: GetQueryProviderFun<TData, TGetRequest>
  get_resultModifier?: GetResultModifierFun<TData, TGetRequest>
  get_filter?: (data: TData, request: TGetRequest | undefined) => boolean
  id_provider?: (entry: TData) => Record<string, string | number> | null
  dataReducer?: (entry: TData) => TData
  /**
   * Replaces default update-local db function. Can be used to write custom update code
   */
  customUpdateEffect?: DataProviderCustomUpdateEffect<TData>
  post?: DataProviderPostOptions
  localResource?: string
  syncOptions?: SyncOptions
  subObjectLinks?: DataProviderObjectLink<TData>[]
  idType?: 'number' | 'string'
  deleteParametersAsQuery?: boolean
  /**
   * Save data of keys to files on device instead of saving it in async storage
   */
  saveDataOnDevice?: (keyof TData)[]
  /**
   * Modifier for local data (GET)
   */
  customGetModifier?: DataProviderCustomGetModifier<TData, TGetRequest>
  /**
   * only one object of this type will be saved to the local db
   */
  singleObject?: boolean
}

export type DataProviderPostOptions = {
  /**
   * Disables saving post data in local db. Can be used to only store requests and not the data itself
   */
  noLocalStorage?: boolean
  /**
   * If true creation of id will be forced and any given id will be overwritten
   */
  forceIdCreation?: boolean
}

export type DataProviderCustomGetModifier<TData, TGetRequest> = (
  data: TData[],
  props: DataProviderCustomGetModifierProps<TGetRequest>
) => TData[] | Promise<TData[]>

export type DataProviderCustomGetModifierProps<TGetRequest> = {
  /**
   * request
   */
  request: TGetRequest | undefined
  /**
   * DataStorage. can be used to access and modify the local-db
   */
  dataStore: DataStorage
  /**
   * resource
   */
  resource: string
}

export type DataProviderCustomUpdateEffect<TData> = (
  /**
   * data given to request
   */
  data: TData,
  /**
   * Update Properties. Contains storage, params, the default effect, etc.
   */
  props: DataProviderCustomUpdateEffectProps<TData>
) => void | Promise<void>

export type DataProviderCustomUpdateEffectProps<TData> = {
  /**
   * request params
   */
  requestParams: RequestManagerParams_Post<TData>
  /**
   * DataStorage. can be used to access and modify the local-db
   */
  dataStore: DataStorage
  /**
   * Runs the default Effect that would normally be executed if there was no custom effect (updates local db)
   */
  defaultEffect: (data: TData, type?: RequestType) => Promise<void>
  generateTemporaryId: (resourceKey: string) => string
}

export type DataProviderCoreOptions = {
  isAliveResource: string
  autoSync: boolean
}

export type DataProviderObjectLink<TData> = {
  key: keyof TData
  resource: string
}

export type DataProviderResult<TData, TGetRequest, TPostResult = string> = {
  GET: (request: TGetRequest, abortController?: AbortController, resultModifier?: GetApiResultModifierFun<TData>) => Promise<TData[]>
  GET_FIRST: (request: TGetRequest, abortController?: AbortController, resultModifier?: GetApiResultModifierFun<TData>) => Promise<TData | null>
  POST: (data: TData, abortController?: AbortController) => Promise<TPostResult>
  PUT: (data: TData, abortController?: AbortController) => Promise<string>
  PATCH: (data: TData, abortController?: AbortController) => Promise<string>
  DELETE: (data: Partial<TData>, abortController?: AbortController) => Promise<string>
}

export type RequestManagerParams = {
  resource: string
  apiResource: string
  abortController?: AbortController
}

export interface RequestManagerParams_Get<TData, TGetRequest> extends RequestManagerParams {
  request?: TGetRequest
  options?: DataProviderOptions<TData, TGetRequest>
  resultModifier?: GetApiResultModifierFun<TData>
}

export interface RequestManagerParams_Post<TData> extends RequestManagerParams {
  data: TData
  type: RequestType
  options?: DataProviderOptions<TData>
}

export interface RequestManagerParams_Update<TData> extends RequestManagerParams {
  data: TData
  type: RequestType
  options?: DataProviderOptions<TData>
}

export interface PendingRequest {
  id: string
  type: RequestType
  timestamp: number
  resource: string
  apiResource: string
  payload: object
  error?: string
  options?: PendingRequestOptions
}

export type PendingRequestOptions = {
  noLocalStorage?: boolean
  saveDataOnDevice?: string[]
}

export type AssignedIdPair = {
  id: string | number // temporary id
  erpId: string | number // id assigned by erp
}

export interface DataProviderState {
  enabled?: boolean
  isOnline?: boolean
  forcedOfflineMode?: boolean
  pendingDataToSync?: number
  failedRequests?: PendingRequest[]
  lastSyncDate?: number
  syncState?: DataProviderSyncState
}

export type DataProviderSyncState = 'none' | 'down-sync' | 'up-sync' | 'error'

export enum DataProviderStateActions {
  UpdateOnlineState,
  UpdateDataToSync,
  UpSyncFailed,
  ClearError,
  Enable,
  SyncComplete,
  UpdateSyncState,
}

export interface DataProviderStateAction {
  type: DataProviderStateActions
  payload: DataProviderState | null
}

export type DataProviderStorageResource = {
  resource: string
  count: number
  size?: number
}

export type SyncOptions = {
  /**
   * Synchronisation-type (is only required if the given sync type is not equal to the api resource url ('/' is the same as '_'.)
   * For example: the syncType 'customer_shippingAddress' and the endPoint 'customer/shippingAddress' are automatically linked
   */
  syncType?: string[]
  /**
   * try to merge pending requests.
   * This means instead of doing f.ex. a post and then a PATCH, the PATCH will instead be applied to the data of the original POST and only the updated POST-data will be send to the backend
   */
  mergePendingRequests?: boolean
  /**
   * Local data will be wiped when syncing data
   */
  clearOnSync?: boolean
}
