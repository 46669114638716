import { Platform } from 'react-native'

export const SCREEN_CONSTANTS = {
  headerRadius: 25,
  headerHorizontalMargin: 16,
  headerBarHeight: 54,
  headerDetailMinBarHeight: 64,
}

export const ScreenHeaderShadows = Platform.select({
  web: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  default: {
    elevation: 3, //android
    shadowColor: '#000000', //other platforms
    shadowOffset: { width: 1, height: -2 }, //other platforms
    shadowOpacity: 0.25, //other platforms
  },
})

export const ScreenCardsHeaderShadows = Platform.select({
  web: {
    shadowColor: '#000000',
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
  },
  default: {
    elevation: 3, //android
    shadowColor: '#000000', //other platforms
    shadowOffset: { width: 1, height: -1 }, //other platforms
    shadowOpacity: 0.25, //other platforms
  },
})
