import { useLanguage } from '@infominds/react-native-components'
import React, { createRef, ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'

import { Screen } from '../../components/screen/Screen'
import { FormProvider } from '../../contexts/FormContext'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { AccessDataStackScreenProps } from '../../navigation/types'
import { AccessDataEditOrCreateParams, EditOrCreateViewRef, ModalScreenProps, ModalScreenRef, UploadStatus } from '../../types'
import AccessDataEditOrCreateView from '../../views/accessData/AccessDataEditOrCreateView'

const AccessDataEditOrCreateScreen = (
  { route, controller }: Pick<AccessDataStackScreenProps<'AccessDataEditOrCreation'>, 'route'> & ModalScreenProps<AccessDataEditOrCreateParams>,
  ref: ForwardedRef<ModalScreenRef>
) => {
  useImperativeHandle(ref, () => ({
    handleGoBack: () => handleGoBack(),
  }))

  const editOrCreateViewRef = createRef<EditOrCreateViewRef>()

  const { i18n } = useLanguage()
  const { status, setStatus, handleGoBack, handleForceClose } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
    controller,
  })

  const mode = route.params.accessData !== undefined ? 'edit' : 'creation'
  const isModal = controller !== undefined

  const common = {
    ref: editOrCreateViewRef,
    isModal: isModal,
    onUploadStatus: (newStatus: UploadStatus) => setStatus(newStatus),
    onForceClose: handleForceClose,
  }

  return (
    <Screen transparent={isModal}>
      <Screen.Header
        goBack={handleGoBack}
        backHandlerCallback={handleGoBack}
        isModal={isModal}
        title={mode === 'edit' ? i18n.t('EDIT_ACCESS_DATA') : i18n.t('CREATE_ACCESS_DATA')}
        onRightIconPress={() => {
          Keyboard.dismiss()
          editOrCreateViewRef.current?.handleUpload()
        }}
        rightIcon={['fal', 'check']}
        disable={status === 'done' || status === 'mandatoryMissing'}
        loading={status === 'uploading'}
      />
      <Screen.Content>
        <FormProvider>
          {mode === 'edit' ? (
            <>
              {route.params.accessData && (
                <AccessDataEditOrCreateView mode="edit" accessData={route.params.accessData} customerId={route.params.customerId} {...common} />
              )}
            </>
          ) : (
            <AccessDataEditOrCreateView mode="creation" customerId={route.params.customerId} {...common} />
          )}
        </FormProvider>
      </Screen.Content>
    </Screen>
  )
}

export default forwardRef(AccessDataEditOrCreateScreen)
