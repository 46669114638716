import { IM, IMLayout, useDimensions, useLanguage } from '@infominds/react-native-components'
import React, { memo, useState } from 'react'
import { StyleSheet } from 'react-native'

import { Article } from '../apis/types/apiResponseTypes'
import useLayout from '../hooks/useLayout'
import { LoadingType } from '../types'
import appUtils from '../utils/appUtils'
import CellText from './CellText'
import RenderHTML from './RenderHTML'
import SkeletonText from './skeleton/SkeletonText'
import Text from './Text'

interface Props {
  articles: Article[] | undefined
  loading: LoadingType
}

const TicketArticleDetail = memo(function TicketArticleDetail({ articles, loading }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { isPortrait } = useLayout()

  const [width, setWidth] = useState(0)

  const article = articles?.at(0)

  return (
    <IM.View onLayout={e => setWidth(e.nativeEvent.layout.width)} spacing={isPortrait ? 'top' : 'none'}>
      {loading === false && article ? (
        <>
          <CellText title={i18n.t('ARTICLE')} value={article.code} forceShowTitle style={[IMLayout.flex.row, styles.center]} disableSpacing />
          {article.price && (
            <CellText
              title={i18n.t('PRICE')}
              value={`${appUtils.formatPrice(article.price, language)} €`}
              forceShowTitle
              style={[IMLayout.flex.row, styles.center]}
              disableSpacing
            />
          )}
          {loading === false && !!article.description && (
            <IM.View spacing="top">
              <RenderHTML width={width} source={article.description} />
            </IM.View>
          )}
        </>
      ) : (
        <>
          {article === undefined ? (
            <>
              {!isSmallDevice && (
                <>
                  <SkeletonText width="60%" />
                  <SkeletonText width="80%" spacing="top" />
                </>
              )}
              <SkeletonText width="100%" spacing={isSmallDevice ? 'top' : 'vertical'} />
            </>
          ) : (
            <Text secondary>{i18n.t('NO_DETAIL_FOUND')}</Text>
          )}
        </>
      )}
    </IM.View>
  )
})

export default TicketArticleDetail

const styles = StyleSheet.create({
  center: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
