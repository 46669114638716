import React, { createContext, PropsWithChildren } from 'react'
import { LatLng } from 'react-native-maps'

export interface MapContextProps<T = object> {
  selectedItem: T | null
  setSelectedItem: (item: T | null) => void
  moveMapToLocation: (location: LatLng) => void
  moveMapToItem: (item: T) => void
}

const MapContext = createContext<MapContextProps | undefined>(undefined)

export function MapProvider<T>({ children, ...mapContextProps }: PropsWithChildren & MapContextProps<T>) {
  return <MapContext.Provider value={mapContextProps as MapContextProps}>{children}</MapContext.Provider>
}

export default MapContext
