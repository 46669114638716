import { Language } from '@infominds/react-native-components'

export const numberUtils = {
  getDecimalSeparator(locale: Language) {
    return new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '')
  },
  getThousandsSeparator(locale: Language) {
    return new Intl.NumberFormat(locale).format(1111).replace(/1/g, '')
  },
  convertLocaleStringToIsoString(value: string | undefined, locale: Language) {
    if (value === undefined) return undefined

    const decimalSeparator = numberUtils.getDecimalSeparator(locale)
    const thousandSeparator = numberUtils.getThousandsSeparator(locale)

    return value.replaceAll(thousandSeparator, '').replaceAll(decimalSeparator, '.')
  },
  convertNumberToLocaleString(value: number | undefined, locale: Language) {
    if (value === undefined) return undefined

    return Number(value).toLocaleString(locale)
  },
  convertLocaleStringToNumber(value: string | undefined, locale: Language) {
    if (value === undefined) return undefined

    const decimalSeparator = numberUtils.getDecimalSeparator(locale)
    const thousandSeparator = numberUtils.getThousandsSeparator(locale)

    let reversedVal = value.replace(new RegExp('\\' + thousandSeparator, 'g'), '')
    reversedVal = reversedVal.replace(new RegExp('\\' + decimalSeparator, 'g'), '.')

    return parseFloat(Number.isNaN(reversedVal) ? '0' : reversedVal)
  },
}
