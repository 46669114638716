import { IM } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import CONSTANTS from '../../../constants/Constants'

interface Props {
  color: string
}

const AccessDataLeftElement = ({ color }: Props) => {
  return <IM.View style={[styles.container, { backgroundColor: color }]} />
}

const styles = StyleSheet.create({
  container: {
    width: CONSTANTS.cardLeftWidth,
    height: '100%',
  },
})

export default AccessDataLeftElement
