import { DataProviderCustomUpdateEffect } from '../../../dataProvider/types'
import { AdditionalField, FieldType } from '../../../types'
import { AdditionalFieldResponse } from '../../types/apiResponseTypes'

const StringTypes: FieldType[] = ['Text', 'TextHTML', 'TextRTF', 'Selection', 'ForeignKey']
const NumberTypes: FieldType[] = ['Numeric']
const DateTypes: FieldType[] = ['Date']
const LogicTypes: FieldType[] = ['Boolean']
export const ActivityAddFieldsUpdateEffect: DataProviderCustomUpdateEffect<AdditionalFieldResponse> = async (data, props) => {
  if (props.requestParams.type === 'PUT') {
    try {
      // load current value from local DB
      const currentValue = (await props.dataStore.Get<AdditionalFieldResponse>(props.requestParams.resource, { fkId: data.fkId }))?.shift()
      if (!currentValue) throw new Error('additionalFieldsOfTickets not found')

      const result: AdditionalField[] = []
      for (const addField of currentValue.additionalfields) {
        // search the update data for a record either containing the same id or definitionId
        const foundUpdateValue = data.additionalfields.find(c => (!!c.id && c.id === addField.id) || c.definitionId === addField.definitionId)
        // the update data is contained in the value field which is not part of the type...
        const value = !!foundUpdateValue && 'value' in foundUpdateValue && foundUpdateValue.value
        // if no value was given / found reset the values
        if (value === undefined || value === null) {
          result.push({
            ...addField,
            stringValue: undefined,
            numberValue: undefined,
            dateValue: undefined,
            logicValue: undefined,
          })
          continue
        }

        // apply the updated value on the correct property
        result.push({
          ...addField,
          stringValue: StringTypes.includes(addField.definitionFeldType) ? String(value) : undefined,
          numberValue: NumberTypes.includes(addField.definitionFeldType) ? Number(value) : undefined,
          dateValue: DateTypes.includes(addField.definitionFeldType) ? String(value) : undefined,
          logicValue: LogicTypes.includes(addField.definitionFeldType) ? Boolean(value) : undefined,
        })
      }
      data.additionalfields = result
    } catch (e) {
      console.error(e)
    }
  }

  return props.defaultEffect(data)
}
