import { IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { SMNote } from '../apis/types/apiResponseTypes'
import useLayout, { ForceLayoutType } from '../hooks/useLayout'
import { SMNoteType, ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'
import TimeUtils from '../utils/TimeUtils'
import Chip from './Chip'
import EmployeeBadge from './EmployeeBadge'
import DynamicView from './Infominds/DynamicView'
import Pressable from './Infominds/Pressable'
import Separator from './Infominds/Separator'
import NavigationIcon from './NavigationIcon'
import Text from './Text'

interface Props {
  type: SMNoteType
  note: SMNote
  code: string
  description?: string
  index: number
  isLast: boolean
  onlyRead?: boolean
  forceLayout: ForceLayoutType | undefined
  onPress: (note: SMNote) => void
}

export default function NoteComponent({ type, code, note, description: ticketDescription, index, isLast, onlyRead, forceLayout, onPress }: Props) {
  const alert = useAlert()
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const { theme } = useTheme<ThemeColorExpanded>()

  const noteDate = useMemo(() => TimeUtils.format(note.date, language), [note, language])

  const stateBadge = () => {
    if (note.notegroup === undefined) return

    return (
      // eslint-disable-next-line react-native/no-inline-styles
      <IM.View style={{ marginTop: isSmallDevice ? 4 : 0 }}>
        <Chip value={note.notegroup} />
      </IM.View>
    )
  }

  const employeeBadge = () => <EmployeeBadge name={note.employee} id={note.employeeId} showName />

  const date = () => {
    return (
      // eslint-disable-next-line react-native/no-inline-styles
      <IM.View style={{ marginTop: isSmallDevice ? 4 : 0 }}>
        <IM.Text>{noteDate}</IM.Text>
      </IM.View>
    )
  }

  const description = (lines: number) => {
    return (
      // eslint-disable-next-line react-native/no-inline-styles
      <IM.View style={{ marginTop: isSmallDevice ? 4 : 0 }}>
        <Text style={{ fontWeight: IMStyle.typography.fontWeightLight }} numberOfLines={lines}>
          {note.noteNoRtf}
        </Text>
      </IM.View>
    )
  }

  return (
    <IM.View style={styles.container}>
      {index !== 0 && <Separator spacing="none" />}
      <IM.Card
        borderless
        noContentSpacing
        // @ts-ignore working on web
        style={{ ...Platform.select({ web: { height: '-webkit-fill-available' } }) }}
        buttons={{
          buttons: [
            {
              icon: ['fal', 'paper-plane'],
              onPress: onlyRead
                ? undefined
                : () =>
                    appUtils.openEmail(
                      alert,
                      i18n.t('OPEN_EMAIL_ERROR'),
                      undefined,
                      `${type === 'Ticket' ? i18n.t('TICKET') : i18n.t('RX_ACTIVITY')}: ${code}\n${note.noteNoRtf}`,
                      ticketDescription ? `${i18n.t('NOTE')} ${code}: ${ticketDescription}` : `${i18n.t('NOTE')} ${code}`
                    ),
              disableBottomBorder: onlyRead && isLast ? false : true,
              disableTopBorder: index === 0 ? false : true,
              style: { marginBottom: 0 },
              ...appUtils.getCardButtonColor(!onlyRead, theme),
            },
          ],
        }}>
        <Pressable style={styles.pressable} disabled={onlyRead} onPress={onlyRead ? undefined : () => onPress(note)} spacing={['vertical', 'right']}>
          <IM.View style={IMLayout.flex.f1}>
            {isSmallDevice ? (
              <>
                <IM.View style={styles.badge}>
                  <IM.View style={IMLayout.flex.f1}>{employeeBadge()}</IM.View>
                  {!onlyRead && <NavigationIcon style={styles.justify} />}
                </IM.View>
                {date()}
                {description(3)}
                {stateBadge()}
              </>
            ) : (
              <DynamicView style={[IMLayout.flex.f1, styles.justify]} forceLayout={forceLayout}>
                <IM.View style={[IMLayout.flex.f1, styles.justify, { marginRight: IMLayout.horizontalMargin }]}>{date()}</IM.View>
                <IM.View style={[IMLayout.flex.f2, styles.justify, { marginRight: IMLayout.horizontalMargin }]}>{employeeBadge()}</IM.View>
                <IM.View style={[IMLayout.flex.f1, styles.justify, { marginHorizontal: 2 * IMLayout.horizontalMargin }]}>{stateBadge()}</IM.View>
                <IM.View style={[IMLayout.flex.f4, styles.justify, { marginHorizontal: IMLayout.horizontalMargin }]}>{description(1)}</IM.View>
                {!onlyRead && <NavigationIcon style={styles.justify} />}
              </DynamicView>
            )}
          </IM.View>
        </Pressable>
      </IM.Card>
      {!onlyRead && isLast && <Separator spacing="none" />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  pressable: { flexDirection: 'row', flex: 1 },
  badge: { flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignItems: 'center' },
  container: { marginLeft: 12 },
  image: {
    width: 50,
    height: 50,
    resizeMode: 'contain',
    borderRadius: 4,
  },
  justify: { justifyContent: 'center' },
  align: { alignItems: 'center' },
})
