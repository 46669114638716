import { IM, IMLayout, IMStyle, SpacingProps, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import type { Activity } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import DynamicView from '../../components/Infominds/DynamicView'
import Text from '../../components/Text'
import { ThemeColorExpanded } from '../../types'
import DetailInfo from '../common/DetailInfo'
import ActivityListCardTitle from './components/ActivityListCardTitle'

type Props = {
  type?: 'planning' | 'other'
  activity: Activity
  spacing?: SpacingProps
  borderless?: boolean
  forceLayout?: false | 'small' | 'medium' | 'large'
  style?: StyleProp<ViewStyle>
  onPress?: () => void
}

const ActivityListCard = memo(function ActivityListCard({ type = 'other', activity, spacing, onPress, borderless, style }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()
  const { isSmallDevice } = useDimensions()

  return (
    <IM.Card
      head={<CardLeftTitle color={theme.header.detail.background} />}
      onPress={onPress}
      borderless={borderless}
      spacing={spacing}
      style={[{ borderRadius: IMLayout.borderRadius }, style]}>
      <DynamicView flexLimitSmallDevice={1} separatorVertical separatorHorizontal>
        <ActivityListCardTitle type={type} activity={activity} style={IMLayout.flex.f1} />
        <DetailInfo object={activity} style={IMLayout.flex.f2} />
        <IM.View style={IMLayout.flex.f2}>
          <Text numberOfLines={isSmallDevice ? 3 : 4}>{activity.ticketDescription}</Text>
          {!!activity.customerDescription && (
            <IM.View style={styles.customerDescription}>
              <Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{i18n.t('ACTIVITY_DESCRIPTION')}</Text>
              <Text numberOfLines={isSmallDevice ? 3 : 4}>{activity.customerDescription}</Text>
            </IM.View>
          )}
        </IM.View>
      </DynamicView>
    </IM.Card>
  )
})

export default ActivityListCard

const styles = StyleSheet.create({
  customerDescription: {
    paddingTop: 6,
  },
})
