import { useContext } from 'react'

import FilterContext from '../contexts/FilterContext'

export default function useFilter() {
  const context = useContext(FilterContext)

  if (context === undefined) {
    throw new Error('useFilter() must be called inside FilterProvider')
  }

  return context
}
