import React, { createContext, Dispatch, ReactNode, SetStateAction, useMemo, useState } from 'react'

export interface FormContextProps {
  error: boolean
  setError: Dispatch<SetStateAction<boolean>>
}

const FormContext = createContext<FormContextProps | undefined>(undefined)

export const FormProvider = ({ children }: { children: ReactNode }) => {
  const [error, setError] = useState(false)

  const props = useMemo<FormContextProps>(() => ({ error, setError }), [error])

  return <FormContext.Provider value={props}>{children}</FormContext.Provider>
}

export default FormContext
