import { cloneDeep } from 'lodash'

import { DataProviderCustomUpdateEffect } from '../../../dataProvider/types'
import { QualityPost } from '../../../types'
import { Quality } from '../../types/apiResponseTypes'

export const QualityCustomUpdateEffect: DataProviderCustomUpdateEffect<Quality> = async (data, props) => {
  if (props.requestParams.type === 'PATCH' || props.requestParams.type === 'POST') {
    const currentData = await props.dataStore.Get<Quality>(
      props.requestParams.resource,
      props.requestParams.type === 'PATCH'
        ? { id: data.id }
        : {
            activityId: data.activityId,
            serialnumberId: data.serialnumberId,
          }
    )
    if (!currentData.length) {
      throw new Error(`QualityControl with id ${data.id} was not found`)
    }
    const currentValue = cloneDeep(currentData[0])
    const updatedValue = cloneDeep(currentValue)
    const values = (data as unknown as QualityPost).values

    updatedValue.characteristics.forEach(char => {
      const foundValue = values.find(v => v.qualityCharacteristicId === char.qualityCharacteristicId)
      if (!foundValue) return
      char.value = foundValue.value
      char.note = foundValue.note
    })

    if (!updatedValue.id) updatedValue.id = data.id

    const query = { activityId: currentValue.activityId, serialnumberId: currentValue.serialnumberId }
    if (props.requestParams.type === 'PATCH') Object.assign(query, { id: currentValue.id })
    return props.dataStore.UpdateObject(props.requestParams.resource, query, updatedValue)
  }

  return props.defaultEffect(data, props.requestParams.type)
}
