import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import ActivityTimesEditOrCreateScreen from '../../screens/activity/ActivityTimesEditOrCreateScreen'
import { ActivityEditOrCreateTimesParams, ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<ActivityEditOrCreateTimesParams>
}

export default function ActivityEditOrCreateTimeModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      {controller.data && (
        <ActivityTimesEditOrCreateScreen
          ref={ref}
          controller={controller}
          route={{
            key: '',
            name: 'ActivityEditOrCreateTimes',
            params: controller.data,
          }}
        />
      )}
    </BaseServiceModal>
  )
}
