import type { LanguageResource } from '@infominds/react-native-components'

import de from './resources/de.json'
import en from './resources/en.json'
import it from './resources/it.json'

export const languageResources: LanguageResource[] = [
  {
    lng: 'en',
    resources: en,
  },
  {
    lng: 'it',
    resources: it,
  },
  {
    lng: 'de',
    resources: de,
  },
]
