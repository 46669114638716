import { useContext } from 'react'

import { CloseActivityIdContext } from '../contexts/CloseActivityIdContext'

export default function useCloseActivityId() {
  const context = useContext(CloseActivityIdContext)

  if (context === undefined) {
    throw new Error('useCloseActivityId() must be called inside CloseActivityIdProvider')
  }

  return context
}
