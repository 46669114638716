import { IMLayout, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StatusBar } from 'react-native'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'

import { InfoboxStackScreenProps } from '../../navigation/types'
import InfoboxCameraSettingsView from '../../views/infobox/InfoboxCameraSettingsView'

const InfoboxCameraSettingsScreen = ({}: InfoboxStackScreenProps<'InfoboxNoBottomTabCameraSettings'>) => {
  const { colorScheme, theme } = useTheme()

  return (
    <>
      <StatusBar backgroundColor="transparent" barStyle={colorScheme === 'dark' ? 'light-content' : 'dark-content'} translucent />
      <SafeAreaProvider>
        <SafeAreaView style={[IMLayout.flex.f1, { backgroundColor: theme.background }]}>
          <InfoboxCameraSettingsView />
        </SafeAreaView>
      </SafeAreaProvider>
    </>
  )
}

export default InfoboxCameraSettingsScreen
