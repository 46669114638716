import { DataProviderSettings } from '../../../dataProvider/DataProviderSettings'
import { DataProviderCustomGetModifier, DataProviderCustomUpdateEffect, PendingRequest } from '../../../dataProvider/types'
import { FolderRight } from '../../../types'
import { GetInfoboxFilesRequest, GetInfoboxFolderWithCountRequest, InfoboxFile } from '../../types/apiRequestTypes'
import { FolderWithCount, UserSettings } from '../../types/apiResponseTypes'

export const InfoboxFileUpdateEffect: DataProviderCustomUpdateEffect<InfoboxFile> = async (data, props) => {
  if (props.requestParams.type === 'POST') {
    if (!data.date) data.date = new Date().toISOString()
    if (!data.extension) data.extension = data.filename.split('.').pop()?.toUpperCase() ?? ''
    if (!data.createUserId) {
      const userSettings = (await props.dataStore.Get<UserSettings>('userSettings'))?.shift()
      data.createUserId = userSettings?.employeeId ?? ''
    }
    if (!data.description) data.description = data.filename
    if (data.canDelete === undefined) data.canDelete = true
    data.fkId = data.id
    data.id = props.generateTemporaryId(props.requestParams.resource)
    data.doctype = 1
    data.origin = 1
  }
  if (props.requestParams.type === 'DELETE') {
    const file = (await props.dataStore.Get<InfoboxFile>(props.requestParams.resource, { id: data.id })).shift()
    if (file) {
      const pendingRequestsToDelete = (
        await props.dataStore.Get<PendingRequest>(DataProviderSettings.PendingRequestsResourceKey, { resource: props.requestParams.resource })
      ).filter(q => ('file' in q.payload && q.payload.file === file.file) || ('id' in q.payload && q.payload.id === data.id))
      if (pendingRequestsToDelete.length) await props.dataStore.Delete(DataProviderSettings.PendingRequestsResourceKey, pendingRequestsToDelete)
    }
  }
  if (props.requestParams.type === 'PATCH') {
    const file = (await props.dataStore.Get<InfoboxFile>(props.requestParams.resource, { id: data.id })).shift()
    if (file) {
      const pendingRequestsToEdit = (
        await props.dataStore.Get<PendingRequest>(DataProviderSettings.PendingRequestsResourceKey, { resource: props.requestParams.resource })
      ).find(q => ('file' in q.payload && q.payload.file === file.file) || ('id' in q.payload && q.payload.id === data.id))
      const pendingRequestsToDelete = (
        await props.dataStore.Get<PendingRequest>(DataProviderSettings.PendingRequestsResourceKey, { resource: props.requestParams.resource })
      ).filter(q => 'id' in q.payload && q.payload.id === data.id)
      if (pendingRequestsToEdit) {
        Object.assign(pendingRequestsToEdit.payload, { note: data.note })
        await props.dataStore.Update(DataProviderSettings.PendingRequestsResourceKey, [pendingRequestsToEdit])
      }
      if (pendingRequestsToDelete?.length) await props.dataStore.Delete(DataProviderSettings.PendingRequestsResourceKey, pendingRequestsToDelete)
    }
  }
  return props.defaultEffect(data)
}

export const InfoboxGetFileModifier: DataProviderCustomGetModifier<InfoboxFile, GetInfoboxFilesRequest> = data => {
  for (const entry of data) {
    entry.data = entry.file
  }
  return data
}

export const InfoboxGetFilesModifier: DataProviderCustomGetModifier<InfoboxFile, GetInfoboxFilesRequest> = data => {
  for (const entry of data) {
    const ext = entry.extension.toUpperCase()
    if (ext === 'JPEG' || ext === 'PNG') entry.thumbImageAsPNG = entry.file
  }
  return data
}

export const InfoboxGetFoldersWithCountModifier: DataProviderCustomGetModifier<FolderWithCount, GetInfoboxFolderWithCountRequest> = async (
  data,
  props
) => {
  const result: FolderWithCount[] = []
  for (const entry of data) {
    const visibility = entry.visibilities?.find(v => v.type === props.request?.infoboxTyp)
    if (!visibility || visibility.right !== FolderRight.write) continue
    entry.files = await props.dataStore.Count('common/infoboxfiles', {
      infoboxTyp: props.request?.infoboxTyp,
      folderNumber: entry.number,
      fkId: props.request?.fkId,
    })
    result.push(entry)
  }
  return result
}
