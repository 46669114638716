import { IM, useTheme } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import DataBar from '../../components/Infominds/DataBar'
import LoadingIcon from '../../components/LoadingIcon'
import { ThemeColorExpanded } from '../../types'

export type SyncProgressViewProps = {
  text: string
  currentProgress?: number
  totalProgress?: number
  busy?: boolean
}

export default function SyncProgressView({ text, currentProgress, totalProgress, busy }: SyncProgressViewProps) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const textToShow = useMemo(
    () => (busy && currentProgress !== undefined && totalProgress !== undefined ? `${text} ${currentProgress}/${totalProgress}` : text),
    [text, busy, currentProgress, totalProgress]
  )

  return (
    <IM.View spacing={'all'}>
      <IM.View style={styles.textView} spacing={'bottom'}>
        <IM.Text secondary>{textToShow}</IM.Text>
      </IM.View>
      {!busy && (
        <IM.View>
          <LoadingIcon />
        </IM.View>
      )}
      {busy && (
        <DataBar
          elements={[currentProgress]}
          totalValue={totalProgress ?? 100}
          elementDataProvider={value => ({ value: value ?? 0, color: theme.primary })}
          backgroundColor={theme.loader.background}
          borderColor={theme.background}
        />
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  textView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
})
