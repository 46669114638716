import { IM, IMLayout, useAlert, useLanguage, useTheme, useToastMessage, Utils } from '@infominds/react-native-components'
import { useIsFocused, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'

import Button from '../../components/Button'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useSynchronization from '../../dataProvider/hooks/useSynchronization'
import { DataProviderStateActions, PendingRequest } from '../../dataProvider/types'
import useNavigationLocker from '../../hooks/useNavigationLocker'
import { ThemeColorExpanded } from '../../types'
import { ExceptionUtils } from '../../utils/ExceptionUtils'
import DownSynchronizationView from './DownSynchronizationView'
import SynchronizationResourcesView from './SynchronizationResourcesView'
import UpSynchronizationView from './UpSynchronizationView'

type SyncState = 'downSync' | 'upSync' | 'none'

export default function SynchronizationView() {
  const { i18n } = useLanguage()
  const navigation = useNavigation()
  const { theme } = useTheme<ThemeColorExpanded>()
  const toast = useToastMessage({ placement: 'top', normalColor: '#FCA131' })
  const { dispatch: dispatchDataProvider, enabled } = useDataProvider()
  const { forcedOfflineMode, getPendingRequests, dataStorage, dataSyncManager, onUpSyncComplete, checkApiConnection, isOnline } = useSynchronization()
  const [state, setState] = useState<SyncState>('none')
  const isFocused = useIsFocused()
  const [pendingRequests, setPendingRequests] = useState<PendingRequest[]>([])
  const [loadingPendingRequests, setLoadingPendingRequests] = useState(false)
  const { item: resources, loading: loadingResources, loadItem: loadResources } = useControlledLoader(() => dataStorage.GetResourceCount(true))
  const alert = useAlert()

  const showGoOfflineButton = !pendingRequests.length && !forcedOfflineMode && state === 'none' && isOnline
  const showGoOnlineButton = (!!forcedOfflineMode || !!pendingRequests.length || !isOnline) && state === 'none'

  useNavigationLocker(
    () => navigation.navigate('BottomTab', { screen: 'SynchronizationStack', params: { screen: 'Synchronization' } }),
    state === 'upSync' || state === 'downSync'
  )

  useEffect(() => {
    loadResources(undefined)
    if (state === 'none') {
      dispatchDataProvider({ type: DataProviderStateActions.UpdateSyncState, payload: { syncState: 'none' } })
    }
    if (state === 'upSync') {
      dispatchDataProvider({ type: DataProviderStateActions.UpdateSyncState, payload: { syncState: 'up-sync' } })
    }
    if (state === 'downSync') {
      dispatchDataProvider({ type: DataProviderStateActions.UpdateSyncState, payload: { syncState: 'down-sync' } })
    }
  }, [state, enabled])

  useEffect(() => {
    if (!isFocused) return
    refreshPendingRequests()
  }, [isFocused, state])

  function refreshPendingRequests() {
    loadPendingRequests().catch(console.error)
  }

  async function loadPendingRequests() {
    try {
      setLoadingPendingRequests(true)
      const result = (await getPendingRequests()) ?? []
      setPendingRequests([...result])
      return result
    } catch (ex) {
      console.error(ex)
      alert.alert(Utils.stringValueReplacer(i18n.t('SYNC_ERROR')), ExceptionUtils.exceptionToString(ex))
    } finally {
      setLoadingPendingRequests(false)
    }
    return []
  }

  function handleGoOffline() {
    preSyncCheck()
      .then(() => setState('downSync'))
      .catch(console.error)
  }

  function handleGoOnline() {
    preSyncCheck()
      .then(() => {
        return loadPendingRequests()
      })
      .then(requestsToSync => {
        if (requestsToSync.length) {
          setState('upSync')
        } else {
          onUpSyncDone()
          dataSyncManager.postSyncCleanup().catch(console.error)
        }
      })
      .catch(console.error)
  }

  function onUpSyncDone() {
    toast.show(i18n.t('OFFLINE_MODE_DEACTIVATED'))
    onUpSyncComplete()
    setState('none')
    resetApp()
  }

  function onDownSyncDone() {
    toast.show(i18n.t('OFFLINE_MODE_ACTIVATED'))
    setState('none')
    resetApp()
  }

  function resetApp() {
    navigation.reset({
      routes: [
        {
          name: 'BottomTab',
          state: {
            routes: [{ name: 'SynchronizationStack', state: { routes: [{ name: 'Synchronization' }] } }],
          },
        },
      ],
    })
  }

  async function preSyncCheck() {
    const result = await checkApiConnection()
    if (result) return true
    alert.alert(i18n.t('ERROR'), i18n.t('NO_API_CONNECTION'))
    throw new Error('NO API CONNECTION')
  }

  return (
    <IM.View style={IMLayout.flex.f1}>
      {state === 'downSync' && <DownSynchronizationView onDone={onDownSyncDone} onAbort={() => setState('none')} />}
      {state !== 'downSync' && (
        <>
          {(state === 'upSync' || !!pendingRequests.length) && (
            <UpSynchronizationView
              sync={state === 'upSync'}
              pendingRequests={pendingRequests}
              loadPendingRequests={refreshPendingRequests}
              onDone={onUpSyncDone}
              loadingPendingRequests={loadingPendingRequests}
            />
          )}
          {state === 'none' && !pendingRequests?.length && (
            <SynchronizationResourcesView resources={resources ?? []} loadingResources={loadingResources} loadResources={loadResources} />
          )}
        </>
      )}

      {showGoOfflineButton && (
        <IM.View spacing={['all']}>
          <Button color={theme.primary} title={i18n.t('GO_OFFLINE')} onPress={handleGoOffline} />
        </IM.View>
      )}
      {showGoOnlineButton && (
        <IM.View spacing={['all']}>
          <Button color={theme.primary} title={i18n.t('GO_ONLINE')} onPress={handleGoOnline} />
        </IM.View>
      )}
    </IM.View>
  )
}
