import { useLanguage } from '@infominds/react-native-components'
import React from 'react'

import OnlineView from '../../components/offline/OnlineView'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import { DefaultSearchDetailHeaderProps } from '../../components/screen/headers/default/DefaultSearchDetailHeader'
import HeaderWithIcons from '../../components/screen/headers/HeaderWithIcons'
import { Screen } from '../../components/screen/Screen'
import { PasswordStackScreenProps } from '../../navigation/types'
import customerUtils from '../../utils/customerUtils'
import CustomersListView from '../../views/passwords/CustomersListView'

export default function CustomersListScreen({ navigation }: PasswordStackScreenProps<'PasswordCustomersList'>) {
  const { i18n } = useLanguage()

  const commonSearchProps: DefaultSearchDetailHeaderProps = {
    enableQR: false, // TODO: QR not implemented yet
    deferredTimeout: 10,
  }

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header>
          <HeaderWithIcons
            title={i18n.t('CUSTOMERS_LIST')}
            disableFilter
            tabletSearchComponent={<SearchDetailHeaderContainer iconPosition="right" {...commonSearchProps} />}
            sync
          />
        </Screen.Header>
        <Screen.DetailHeader>
          <SearchDetailHeaderContainer {...commonSearchProps} />
        </Screen.DetailHeader>
        <Screen.Content>
          <OnlineView showOfflineIndicator>
            <CustomersListView
              onCustomerSelect={customer =>
                navigation.navigate('PasswordAccessDataStack', {
                  screen: 'AccessData',
                  params: {
                    customerId: customer.id,
                    customerName: customer.description,
                    customerNumber: customer.number,
                    blocked: customerUtils.isBlocked(customer),
                    vat: customer.isoVatnumber && customerUtils.getVat(customer.isoVatnumber, i18n),
                  },
                })
              }
            />
          </OnlineView>
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}
