import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

export type QualityCheckValue = {
  serialNumberId: string
  characteristicsId: string
  value: string | undefined
}

export type QualityCheckNote = {
  serialNumberId: string
  characteristicsId: string
  note: string | undefined
}

interface Props {
  qualityValues: QualityCheckValue[]
  qualityNotes: QualityCheckNote[]
  enableDone: boolean
  setQualityValue: (val: QualityCheckValue) => void
  setQualityNote: (val: QualityCheckNote) => void
  getQualityValue: (charId: string, serNumbId: string, type: 'note' | 'value') => string | undefined
  changeInitials: (initialValues: QualityCheckValue[], initialNotes: QualityCheckNote[]) => void
}

export const QualityCheckContext = createContext<Props | undefined>(undefined)

interface ProviderProps extends PropsWithChildren {
  initValue: QualityCheckValue[] | undefined
  initNote: QualityCheckNote[] | undefined
}

export const QualityCheckProvider = ({ children, initNote, initValue }: ProviderProps) => {
  const [initialVal, setInitialVal] = useState<QualityCheckValue[] | undefined>(initValue)
  const [initialNote, setInitialNote] = useState<QualityCheckNote[] | undefined>(initNote)
  const [enableDone, setEnableDone] = useState(false)
  const [qualityValues, setQualityValues] = useState<QualityCheckValue[]>([])
  const [qualityNotes, setQualityNotes] = useState<QualityCheckNote[]>([])

  useEffect(() => {
    if (initValue === undefined || initNote === undefined) return
    setQualityValues(initValue)
    setQualityNotes(initNote)
    setInitialVal(initValue)
    setInitialNote(initNote)
  }, [initValue, initNote])

  useEffect(() => {
    const equalVal = isEqual(qualityValues, initialVal)
    const equalNote = isEqual(qualityNotes, initialNote)
    setEnableDone(!equalVal || !equalNote)
  }, [qualityValues, initialVal, qualityNotes, initialNote])

  const handleNewValue = useCallback(
    (val: QualityCheckValue) => {
      const clone = cloneDeep(qualityValues)

      let found = false
      clone.map(el => {
        if (el.serialNumberId === val.serialNumberId && el.characteristicsId === val.characteristicsId) {
          found = true
          el.value = val.value ?? ''
        }
      })

      if (!found) clone.push(val)

      setQualityValues(clone)
    },
    [qualityValues]
  )

  const handleNewNote = useCallback(
    (val: QualityCheckNote) => {
      const clone = cloneDeep(qualityNotes)

      let found = false
      clone.map(el => {
        if (el.serialNumberId === val.serialNumberId && el.characteristicsId === val.characteristicsId) {
          found = true
          el.note = val.note ?? ''
        }
      })

      if (!found) clone.push(val)

      setQualityNotes(clone)
    },
    [qualityNotes]
  )

  const getQualityValue = useCallback(
    (charId: string, serNumbId: string, type: 'note' | 'value') => {
      let toRet: string | undefined

      if (type === 'value') {
        qualityValues.map(el => {
          if (el.serialNumberId === serNumbId && el.characteristicsId === charId) {
            toRet = el.value
          }
        })
      } else {
        qualityNotes.map(el => {
          if (el.serialNumberId === serNumbId && el.characteristicsId === charId) {
            toRet = el.note
          }
        })
      }

      return toRet
    },
    [qualityValues, qualityNotes]
  )

  const changeInitials = useCallback((newInitVal: QualityCheckValue[], newInitNote: QualityCheckNote[]) => {
    setInitialVal(newInitVal)
    setInitialNote(newInitNote)
  }, [])

  const contextValue: Props = useMemo(
    () => ({
      qualityValues,
      qualityNotes,
      enableDone,
      setQualityValue: handleNewValue,
      setQualityNote: handleNewNote,
      getQualityValue,
      changeInitials,
    }),
    [qualityValues, qualityNotes, enableDone]
  )

  return <QualityCheckContext.Provider value={contextValue}>{children}</QualityCheckContext.Provider>
}
