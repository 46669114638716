import { IM, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { SerialNumber } from '../../apis/types/apiResponseTypes'
import CardBasic from '../../components/Infominds/CardBasic'
import TextWithIcon from '../../components/Infominds/TextWithIcon'

interface Props {
  item: SerialNumber
  selected?: boolean
  onPress?: () => void
  spacing?: SpacingProps
}

export default function SerialNumberCard({ item, selected = false, onPress, spacing }: Props) {
  const { theme } = useTheme()
  const { language } = useLanguage()

  const formatSN = () => {
    if (item.numberManufactor) return `${item.number} (${item.numberManufactor})`

    return item.number
  }

  return (
    <IM.View spacing={spacing}>
      <CardBasic onPress={onPress} selected={selected}>
        <IM.View spacing="all" style={styles.itemContainer}>
          <IM.View style={styles.container}>
            <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular }}>{item.article.searchtext}</IM.Text>
            <TextWithIcon style={{ color: theme.textDetail }} color={theme.textDetail} icon={['fal', 'barcode']}>
              {formatSN()}
            </TextWithIcon>
            {item.article.descriptions.map(description => {
              if (description.language.toLowerCase() === language) {
                return (
                  <IM.Text style={{ color: theme.textDetail }} numberOfLines={2} key={`${item.id}-${description.language}`}>
                    {description.text}
                  </IM.Text>
                )
              }
              return <IM.View key={`${item.id}-${description.language}`} />
            })}
          </IM.View>
        </IM.View>
      </CardBasic>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  itemContainer: { flexDirection: 'row', alignItems: 'center' },
})
