import { useEvent, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import api from '../../apis/apiCalls'
import { Ticket } from '../../apis/types/apiResponseTypes'
import useRequest from '../../components/Infominds/hooks/useRequest'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import HeaderWithIcons from '../../components/screen/headers/HeaderWithIcons'
import { Screen } from '../../components/screen/Screen'
import { REFRESH_TICKET_DETAIL_EVENT_KEY, REFRESH_TICKET_LIST_EVENT_KEY } from '../../constants/EmitterKeys'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import { TicketStackScreenProps } from '../../navigation/types'
import { ModalScreenProps, ModalScreenRef, TicketEditPriorityParams } from '../../types'
import TicketEditPriorityView from '../../views/ticket/TicketEditPriorityView'

function TicketEditPriorityScreen(
  { route, controller }: Omit<TicketStackScreenProps<'TicketEditPriority'>, 'navigation'> & ModalScreenProps<TicketEditPriorityParams>,
  ref: ForwardedRef<ModalScreenRef>
) {
  useImperativeHandle(ref, () => ({
    handleGoBack: handleGoBack,
  }))

  const { i18n } = useLanguage()
  const [goBackLoading, setGoBackLoading] = useState(false)
  const [uploadStarted, setUploadStarted] = useState(false)

  const { emit } = useEvent({ key: REFRESH_TICKET_DETAIL_EVENT_KEY })
  const { emit: refreshList } = useEvent({ key: REFRESH_TICKET_LIST_EVENT_KEY })

  const { handleGoBack } = useEditOrCreateScreenBackManager({
    title: '',
    description: '',
    controller,
  })

  const { request, loading } = useRequest(api.editTicket)

  useEffect(() => {
    if (uploadStarted && loading === false) {
      setUploadStarted(false)
      emit()
      refreshList()
      handleGoBack()
    }

    if (uploadStarted && (loading === 'catched' || loading === 'aborted')) {
      setUploadStarted(false)
      setGoBackLoading(false)
    }
  }, [uploadStarted, loading])

  const isModal = controller !== undefined

  return (
    <SearchProvider>
      <Screen transparent={isModal}>
        <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack} isModal={isModal} goBackLoading={goBackLoading}>
          <HeaderWithIcons title={i18n.t('PRIORITIES')} disableFilter tabletSearchComponent={<SearchDetailHeaderContainer iconPosition="right" />} />
        </Screen.Header>
        <Screen.DetailHeader>
          <SearchDetailHeaderContainer />
        </Screen.DetailHeader>
        <Screen.Content>
          <TicketEditPriorityView
            priorityId={route.params.priorityId}
            onChange={newPriorityId => {
              setUploadStarted(true)
              setGoBackLoading(true)
              request({
                id: route.params.ticketId,
                priorityId: newPriorityId ?? '',
              } as unknown as Ticket)
            }}
          />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default forwardRef(TicketEditPriorityScreen)
