import { useDidUpdate } from '@infominds/react-native-components'
import { useEffect, useRef } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'

import api from '../apis/apiCalls'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'
import { REQUEST_ACTIVITY_SPARE_PARTS, REQUEST_INVOICE_TYPE, REQUEST_TICKET_ARTICLE } from '../constants/Keys'
import { invoiceTypeAtom, shoppingCartAtom, shoppingCartRemovalAtom, ticketArticleAtom } from '../utils/stateManager'
import ticketUtils from '../utils/TicketUtils'

interface Props {
  activityId: string
  ticketId: string
}

export default function usePopulateShoppingCart({ activityId, ticketId }: Props) {
  const refActivityId = useRef<string | undefined>()
  const refTicketId = useRef<string | undefined>()

  const [invoiceType, setInvoiceType] = useRecoilState(invoiceTypeAtom)
  const [shoppingCart, setShoppingCart] = useRecoilState(shoppingCartAtom)
  const [shoppingCartRemoval, setShoppingCarRemoval] = useRecoilState(shoppingCartRemovalAtom)
  const setTicketArticle = useSetRecoilState(ticketArticleAtom)

  const { item: parts, loadItem: getParts, loading } = useControlledLoader(api.getActivitySpareParts, { id: REQUEST_ACTIVITY_SPARE_PARTS })
  const {
    item: articles,
    loadItem: getArticles,
    loading: loadingArticles,
  } = useControlledLoader(api.getTicketArticle, { id: REQUEST_TICKET_ARTICLE })
  const { item: invoiceData, loadItem: getInvoice, loading: loadingInvoice } = useControlledLoader(api.getInvoiceType, { id: REQUEST_INVOICE_TYPE })

  useEffect(() => {
    if (activityId !== refActivityId.current) {
      getParts({ activityId })
      refActivityId.current = activityId
    }

    if (ticketId !== refTicketId.current) {
      getArticles({ ticketId })
      refTicketId.current = ticketId
    }
  }, [activityId, ticketId])

  useEffect(() => {
    invoiceType === undefined && getInvoice()
  }, [invoiceType])

  useDidUpdate(() => {
    if (loading === false && parts !== undefined && shoppingCart === undefined) {
      setShoppingCart(ticketUtils.initShoppingCart(parts, 'Installation'))
      // setStockQuantity(ticketUtils.initStockQuantity(parts))
    }
  }, [loading, parts, shoppingCart])

  useDidUpdate(() => {
    if (loading === false && parts !== undefined && shoppingCartRemoval === undefined) {
      setShoppingCarRemoval(ticketUtils.initShoppingCart(parts, 'Removed'))
    }
  }, [loading, parts, shoppingCartRemoval])

  useDidUpdate(() => {
    loadingInvoice === false && invoiceData !== undefined && setInvoiceType(invoiceData)
  }, [loadingInvoice, invoiceData])

  useDidUpdate(() => {
    loadingArticles === false && articles !== undefined && setTicketArticle(articles)
  }, [loadingArticles, articles])
}
