export const ACTIVITY_SPARE_PARTS_DONE_BUTTON_ID = 'ActivitySparePartsDoneButtonId'
export const ACTIVITY_SPARE_PARTS_REMOVAL_DONE_BUTTON_ID = 'ActivitySparePartsRemovalDoneButtonId'
export const ADD_ACCESS_DATA_BUTTON_ID = 'AddAccessDataButtonId'
export const ADD_CONTACT_DESTINATION_BUTTON_ID = 'AddContactDestinationButtonId'
export const ADD_TICKET_BUTTON_ID = 'AddTicketButtonId'
export const QUALITY_DONE_BUTTON_ID = 'QualityDoneButtonId'
export const TICKET_ARTICLE_INFOBOX_ID = 'TicketArticleInfoboxId'
export const TICKET_CLOSE_ACTIVITY_ID = 'TicketCloseActivityId'
export const TICKET_DETAIL_INFOBOX_ID = 'TicketDetailInfoboxId'
export const TICKET_SPARE_PARTS_DONE_ID = 'TicketSparePartsId'
export const TICKET_SPARE_PARTS_REMOVAL_DONE_ID = 'TicketSparePartsRemovalId'
