import { IconType } from '../types'

export const TicketIcons: IconType[] = [
  { type: 'Internal', icon: ['fal', 'screwdriver-wrench'] }, //internal service ([0] = default)
  { type: 'None', icon: ['fal', 'ticket'] },
  { type: 'Hotline', icon: ['fal', 'headset'] }, //hotline
  { type: 'RemoteService', icon: ['fal', 'display'] }, //remote support
  { type: 'EMail', icon: ['fal', 'envelope'] }, //email
  { type: 'External', icon: ['fal', 'car-wrench'] }, //external service
]

export const defaultIconType: IconType = TicketIcons[0]
