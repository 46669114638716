import { IM, IMLayout, useDimensions } from '@infominds/react-native-components'
import React, { memo, useEffect } from 'react'

import api from '../apis/apiCalls'
import { REQUEST_ARTICLE } from '../constants/Keys'
import useLayout from '../hooks/useLayout'
import DynamicView from './Infominds/DynamicView'
import useControlledLoader from './Infominds/hooks/useControlledLoader'
import OnlineView from './offline/OnlineView'
import TicketArticleDetail from './TicketArticleDetail'
import TicketArticleGraphic from './TicketArticleGraphic'

interface Props {
  articleId: string
  graphicId?: string
}

const ArticleDetailsAndGraphic = memo(function ArticleDetailsAndGraphic({ articleId, graphicId }: Props) {
  const { isPortrait } = useLayout()
  const { isSmallDevice } = useDimensions()
  const { item: articles, loadItem: loadArticle, loading: loadingArticles } = useControlledLoader(api.getArticle, { id: REQUEST_ARTICLE + articleId })

  useEffect(() => {
    loadArticle({ id: articleId })
  }, [articleId])

  const article = articles?.at(0)
  const imageId = graphicId || (article && article.graficId)

  return (
    <DynamicView style={IMLayout.flex.f6} forceLayout={!isSmallDevice && isPortrait ? 'small' : undefined}>
      <OnlineView>
        {imageId && (
          <IM.View style={IMLayout.flex.f7}>
            <TicketArticleGraphic id={imageId} />
          </IM.View>
        )}
      </OnlineView>
      <IM.View style={IMLayout.flex.f5} spacing={imageId === undefined ? 'none' : isPortrait ? 'top' : 'none'}>
        <TicketArticleDetail articles={articles} loading={loadingArticles} />
      </IM.View>
    </DynamicView>
  )
})

export default ArticleDetailsAndGraphic
