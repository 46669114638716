import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { StyleSheet } from 'react-native'

import ScrollViewData from '../../components/ScrollViewData'
import Tag from '../../components/Tag'
import { QualityCheckGroupType, QualityCheckOrderType } from '../../contexts/QualityCheckFilterContext'
import { QualityCheckFilterEmitter } from '../../types'

type Emitter = QualityCheckFilterEmitter<QualityCheckGroupType, QualityCheckOrderType>

export interface QualityCheckFilterViewRef {
  getState: () => Emitter
}

const QualityCheckFilterView = (props: Emitter, ref: ForwardedRef<QualityCheckFilterViewRef>) => {
  useImperativeHandle(ref, () => ({
    getState: () => state,
  }))

  const { i18n } = useLanguage()
  const [state, setState] = useState<Emitter>(props)

  useEffect(() => {
    setState(props)
  }, [props])

  return (
    <ScrollViewData>
      <IM.View>
        <IM.View style={styles.titleContainer}>
          <IM.Icon icon={['fal', 'layer-group']} spacing="right" />
          <IM.Text style={styles.title}>{i18n.t('GROUP_BY')}</IM.Text>
        </IM.View>
        <IM.View style={styles.groupContainer}>
          {state.groups.map(group => {
            return (
              <Tag
                key={group.data.id}
                id={group.data.id}
                name={group.data.name}
                active={group.active}
                style={styles.tag}
                onPress={id =>
                  setState(prev => {
                    const clone = cloneDeep(prev)

                    clone.groups.forEach(elem => {
                      if (elem.data.id === id) elem.active = !elem.active
                      else elem.active = false
                    })

                    return clone
                  })
                }
              />
            )
          })}
        </IM.View>
      </IM.View>
      <IM.View spacing="top">
        <IM.View style={styles.titleContainer}>
          <IM.Icon icon={['fal', 'arrow-up-arrow-down']} spacing="right" />
          <IM.Text style={styles.title}>{i18n.t('SORT_BY')}</IM.Text>
        </IM.View>
        <IM.View style={styles.groupContainer}>
          {state.orders.map(order => {
            return (
              <Tag
                key={order.data.id}
                id={order.data.id}
                name={order.data.name}
                active={order.active}
                icon={order.data.icon}
                size={14}
                style={styles.tag}
                onPress={id =>
                  setState(prev => {
                    const clone = cloneDeep(prev)

                    clone.orders.forEach(elem => {
                      if (elem.data.id === id) elem.active = !elem.active
                      else elem.active = false
                    })

                    return clone
                  })
                }
              />
            )
          })}
        </IM.View>
      </IM.View>
    </ScrollViewData>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  groupContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 14,
  },
  tag: { marginVertical: 3 },
  center: {
    alignItems: 'center',
  },
})

export default forwardRef(QualityCheckFilterView)
