import { IM, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useState } from 'react'
import { Modal, ScrollView, StyleSheet } from 'react-native'

import Pressable from '../../components/Infominds/Pressable'
import RenderHTML from '../../components/RenderHTML'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'

interface Props {
  htmlMessage: string
  controller: ModalController
}

export default function MessageHtmlViewerModal({ controller, htmlMessage }: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const [width, setWidth] = useState<number | undefined>()

  const onClose = () => controller.close()

  return (
    <Modal statusBarTranslucent visible={controller.isShown} onRequestClose={onClose} transparent>
      <IM.View style={[styles.centeredView, { backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100 * 1.2}` }]}>
        <IM.View
          style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme) }]}
          onLayout={e => setWidth(e.nativeEvent.layout.width)}>
          <ScrollView>{width && <RenderHTML width={width} source={htmlMessage} />}</ScrollView>
          <IM.View style={styles.buttonsContainer} spacing="top">
            <Pressable style={styles.button} onPress={onClose}>
              <IM.Text>{i18n.t('CLOSE')}</IM.Text>
            </Pressable>
          </IM.View>
        </IM.View>
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    borderRadius: 20,
    paddingHorizontal: 24,
    paddingTop: 26,
    paddingBottom: 20,
    shadowColor: '#000',
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: '90%',
    maxWidth: 400,
    maxHeight: '90%',
  },
  button: {
    paddingHorizontal: 14,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: 38,
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 12,
  },
})
