import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useCallback, useEffect, useState } from 'react'
import { AlertButton } from 'react-native'

import useTimeManager from './useTimeManager'
import useUserSettings from './useUserSettings'
import useValidateFields, { AdditionalFieldValidationType } from './useValidateFields'

export default function useCloseManager(activityId: string, ticketId: string, documentId: string, usePopUpAlert = false) {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { userSettings } = useUserSettings()

  const [canBeClosed, setCanBeClosed] = useState<boolean | 'checking' | 'init'>('init')
  const [validated, setValidated] = useState<boolean | undefined>(undefined)

  const { stop, type } = useTimeManager(activityId, false, undefined, true)
  const {
    validate: validateAddFields,
    result: resultAddFieldsValidation,
    type: fieldsValidation,
  } = useValidateFields(activityId, documentId, ticketId)

  useEffect(() => {
    if (canBeClosed === 'init') return

    if (fieldsValidation === 'done' || fieldsValidation === 'init') {
      setValidated(resultAddFieldsValidation.every(el => el.result))
    } else if (fieldsValidation === 'failed') {
      setCanBeClosed(false)
    }
  }, [fieldsValidation, resultAddFieldsValidation, canBeClosed])

  useEffect(() => {
    if (canBeClosed === 'checking' && validated !== undefined) {
      if (validated) {
        stop()
      } else {
        const popUpElements: AdditionalFieldValidationType[] = []

        resultAddFieldsValidation.forEach(el => {
          if (!el.result) {
            popUpElements.push(el.type)
          }
        })

        if (usePopUpAlert) {
          const title = i18n.t('MANDATORY_FIELDS_MISSING')
          let baseMessage = i18n.t('CLOSING_MANDATORY_FIELDS_MISSING')

          const buttons: AlertButton[] = []

          buttons.push(
            {
              text: i18n.t('NO'),
              style: 'destructive',
              onPress: () => {
                return setCanBeClosed('init')
              },
            },
            {
              text: i18n.t('YES'),
              style: 'default',
              onPress: () => {
                setValidated(true)
                return
              },
            }
          )

          if (popUpElements.length > 1) {
            popUpElements.forEach(el => {
              if (el === 'Activity') {
                baseMessage += '\n' + i18n.t('ACTIVITY_ADD_FIELDS')
              } else if (el === 'Ticket') {
                baseMessage += '\n' + i18n.t('TICKET_ADD_FIELDS')
              } else if (el === 'TicketClassifications') {
                baseMessage += '\n' + i18n.t('TICKET_CLASSIFICATIONS')
              } else if (el === 'Quality') {
                baseMessage += '\n' + i18n.t('ACTIVITY_QUALITY_CHECK')
              } else if (el === 'Counters') {
                baseMessage += '\n' + i18n.t('SERIAL_NUMBER_COUNTERS')
              }
            })
          } else if (popUpElements.at(0) === 'Activity') {
            baseMessage = i18n.t('ACTIVITY_ADD_FIELDS_MANDATORY_MISSING')
          } else if (popUpElements.at(0) === 'Ticket') {
            baseMessage = i18n.t('TICKET_ADD_FIELDS_MANDATORY_MISSING')
          } else if (popUpElements.at(0) === 'TicketClassifications') {
            baseMessage = i18n.t('TICKET_CLASSIFICATIONS_MANDATORY_MISSING')
          } else if (popUpElements.at(0) === 'Quality') {
            baseMessage = i18n.t('QUALITY_CHECK_MANDATORY_MISSING')
          } else if (popUpElements.at(0) === 'Counters') {
            baseMessage = i18n.t('SERIAL_NUMBERS_COUNTER_MANDATORY_MISSING')
          }

          const found = popUpElements.filter(el => el === 'Ticket' || el === 'Activity')
          const missingOnlyAddFields = found && found.length === popUpElements.length

          const continueMessage = (popUpElements.length > 1 ? '\n\n' : '\n') + i18n.t('CONTINUE_QUESTION')

          const enableContinue =
            !userSettings?.abortOnClosingActivityIfAdditionalFieldsWithMandatoryInputHaveNoValue && missingOnlyAddFields ? true : false

          if (enableContinue) {
            alert(title, baseMessage + (continueMessage ?? ''), buttons)
          } else {
            alert(title, baseMessage)
            setCanBeClosed('init')
          }
        } else {
          setCanBeClosed(false)
        }
      }
    }
  }, [canBeClosed, validated, userSettings, type])

  useEffect(() => {
    if (canBeClosed === 'checking' && validated !== undefined) {
      if (type === 'stopped' && validated) {
        setCanBeClosed(true)
      }
    }
  }, [canBeClosed, type, validated])

  const check = useCallback(() => {
    setCanBeClosed('checking')
    setValidated(undefined)
    validateAddFields()
  }, [])

  return { check, canBeClosed }
}
