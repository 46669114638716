import { useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import PressableTextIcon from './Infominds/PressableTextIcon'

interface Props {
  onPress: () => void
}

export default function ChangeButton({ onPress }: Props) {
  const { i18n } = useLanguage()

  return (
    <PressableTextIcon
      spacing="top"
      icon={['fal', 'chevron-right']}
      alignIcon="right"
      secondary
      iconSize={14}
      onPress={onPress}
      containerStyle={styles.changeButton}>
      {i18n.t('CHANGE')}
    </PressableTextIcon>
  )
}
const styles = StyleSheet.create({
  changeButton: {
    alignItems: 'flex-end',
  },
})
