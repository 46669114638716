import React from 'react'
import { StyleSheet, View } from 'react-native'

type Props = {
  position: 'left' | 'right'
  color?: string
}

export const TriangleCorner = ({ color, position }: Props) => {
  return <View style={[styles.triangleCorner, position === 'left' ? styles.leftTriangle : styles.rightTriangle, { borderTopColor: color }]} />
}

const styles = StyleSheet.create({
  triangleCorner: {
    width: 0,
    height: 0,
    backgroundColor: 'transparent',
    borderStyle: 'solid',
    borderRightWidth: 10,
    borderTopWidth: 10,
    borderRightColor: 'transparent',
  },
  leftTriangle: {
    transform: [{ rotate: '90deg' }],
  },
  rightTriangle: {
    transform: [{ rotate: '0deg' }],
  },
})
