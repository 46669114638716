import { IM } from '@infominds/react-native-components'
import React from 'react'

interface Props {
  size?: 'small' | 'big'
}

export default function SkeletonCard({ size = 'big' }: Props) {
  return (
    <IM.SkeletonContainer height={size === 'big' ? 74 : 60}>
      <IM.Rect x="0" y="0" rx="7" ry="7" width="64" height={size === 'big' ? 74 : 60} />
      <IM.Rect x="84" y="14" rx="5" ry="5" width="100" height="12" />
      <IM.Rect x="84" y="33" rx="5" ry="5" width="180" height="12" />
      {size === 'big' && <IM.Rect x="84" y="51" rx="5" ry="5" width="260" height="12" />}
    </IM.SkeletonContainer>
  )
}
