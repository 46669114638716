import { IM } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { createRef, useEffect, useRef } from 'react'
import { StyleSheet, useWindowDimensions } from 'react-native'
import Carousel, { ICarouselInstance } from 'react-native-reanimated-carousel'

import { Ticket } from '../apis/types/apiResponseTypes'
import TicketMapCard from '../cards/ticketList/TicketMapCard'
import useLayout from '../hooks/useLayout'
import { TicketStackParamList } from '../navigation/types'
import { LoadingType } from '../types'
import { utils } from '../utils/utils'
import useMap from './map/hooks/useMap'

interface Props {
  tickets: Ticket[]
  height: number
  loading: LoadingType
}

export default function TicketCarousel({ tickets, height, loading }: Props) {
  const { isSmallDevice } = useLayout()
  const { width } = useWindowDimensions()
  const { selectedItem, moveMapToItem, setSelectedItem } = useMap<Ticket>()
  const currentItem = useRef(tickets.length ? tickets[0] : null)
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const carouselRef = createRef<ICarouselInstance>()

  useEffect(() => {
    if (tickets.length > 0) {
      moveMapToItemWithIndex(0)
      setSelectedItem(tickets[0])
    }
  }, [tickets])

  useEffect(() => {
    selectedItem &&
      tickets.forEach((ticket, index) => {
        if (ticket.id === selectedItem.id) {
          carouselRef.current?.scrollTo({ index, animated: true })
        }
      })
  }, [selectedItem])

  const navigateToDetail = (ticket: Ticket) => {
    navigation.navigate('TicketDetail', { ticketId: ticket.id, ticketCode: ticket.code })
  }

  const moveMapToItemWithIndex = (index: number) => {
    currentItem.current = index < tickets.length && index >= 0 ? tickets[index] : null
    if (currentItem.current) {
      moveMapToItem(currentItem.current)
      setSelectedItem(currentItem.current)
    }
  }

  const renderItem = ({ item, index }: { item: Ticket; index: number }) => {
    return (
      <TicketMapCard
        key={`MapTicket${item.id}`}
        ticket={item}
        borderless
        height={height}
        onPress={() => navigateToDetail(item)}
        index={index + 1}
        total={tickets.length}
        hasCoordinates={item.mapLat !== undefined && item.mapLong !== undefined && (item.mapLat !== 0 || item.mapLong !== 0)}
        loading={loading}
      />
    )
  }

  return (
    <>
      {isSmallDevice && (
        <IM.View style={styles.carousel}>
          <Carousel
            ref={carouselRef}
            data={
              loading === false
                ? tickets
                : [
                    {
                      activityEmployees: [],
                      addressId: '',
                      code: '',
                      customer: '',
                      customerId: '',
                      dateTimeForOrder: '',
                      description: '',
                      documentDate: '',
                      documentId: '',
                      id: utils.generateUuid(),
                      priority: 0,
                      priorityColorHex: '',
                      priorityColorInt: 0,
                      priorityType: 'Normal',
                      priorityCode: '',
                      stateCode: '',
                      stateDescription: '',
                      priorityDescription: '',
                      state: 'AfterVisitation',
                    },
                  ]
            }
            renderItem={renderItem}
            onSnapToItem={moveMapToItemWithIndex}
            height={height}
            loop
            pagingEnabled
            snapEnabled
            width={width}
            mode="parallax"
            modeConfig={{
              parallaxScrollingScale: 0.9,
              parallaxScrollingOffset: 50,
            }}
          />
        </IM.View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  carousel: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
})
