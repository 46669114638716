import { IM, useDimensions, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useMemo } from 'react'
import { SectionListRenderItemInfo, StyleSheet, useWindowDimensions } from 'react-native'

import api from '../../apis/apiCalls'
import { Customer } from '../../apis/types/apiResponseTypes'
import CustomerCard from '../../cards/customer/CustomerCard'
import useInfiniteLoader from '../../components/Infominds/hooks/useInfiniteLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import SkeletonCard from '../../components/skeleton/SkeletonCard'
import CONSTANTS from '../../constants/Constants'
import useLastUsed from '../../hooks/useLastsUsed'
import { ListSection } from '../../types'
import { lastUsedCustomersAtom } from '../../utils/stateManager'
import CustomersLastUsedTabletView from './CustomersLastUsedTabletView'

let abortController: AbortController | null = null

interface Props {
  onCustomerSelect: (customer: Customer) => void
}

export default function CustomersListView({ onCustomerSelect }: Props) {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { isSmallDevice } = useDimensions()
  const { width } = useWindowDimensions()
  const { sessionKey } = useAuthentication()
  const {
    item: customers,
    loadItem: loadCustomers,
    loading,
    loadMore,
    allDataLoaded,
  } = useInfiniteLoader(api.getCustomer, {
    chuckSize: CONSTANTS.customersChunkSize,
  })
  const { items: lastUsedCustomers, setItem: updateLastUsedCustomer } = useLastUsed(api.getCustomer, lastUsedCustomersAtom(sessionKey), {
    maxLastUsedShown: isSmallDevice ? CONSTANTS.maxLastUsedCustomers : CONSTANTS.maxLastUsedCustomersTablet,
  })

  useEffect(() => {
    refresh()
  }, [search])

  const refresh = () => {
    loadCustomers(
      search !== '' ? { searchText: search, userPropertyForFiltering: 'APPSM_KUNIDFILTER' } : { userPropertyForFiltering: 'APPSM_KUNIDFILTER' }
    )
  }

  const handleCustomerPress = (customerId: string) => {
    const foundCustomer = customers.find(customer => customer.id === customerId)

    if (foundCustomer) {
      updateLastUsedCustomer(foundCustomer)
      onCustomerSelect(foundCustomer)
    } else {
      if (abortController) {
        abortController.abort()
      }

      abortController = new AbortController()

      requestAnimationFrame(() => {
        abortController &&
          api
            .getCustomer({ id: customerId }, abortController)
            .then(customer => {
              if (customer.length === 1) {
                updateLastUsedCustomer(customer[0])
                onCustomerSelect(customer[0])
              }
            })
            .catch(err => console.error(`Failed loading customer ${customerId}`, err))
      })
    }
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<Customer, ListSection<Customer>>) => {
    return (
      <IM.View spacing={['bottom', 'horizontal']}>
        <CustomerCard customer={item} onPress={() => handleCustomerPress(item.id)} />
      </IM.View>
    )
  }

  const data: ListSection<Customer>[] = useMemo(() => {
    const displayData: ListSection<Customer>[] = []

    if (isSmallDevice && !search && lastUsedCustomers.length) {
      displayData.push({
        title: i18n.t('LAST_USED'),
        data: lastUsedCustomers,
      })
    }

    if (customers.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        title: i18n.t('CUSTOMERS'),
        data: customers,
      })
    }

    return displayData
  }, [lastUsedCustomers, isSmallDevice, customers, loading, i18n])

  return (
    <IM.View style={styles.container}>
      {!isSmallDevice && (
        <CustomersLastUsedTabletView lastUsedCustomers={lastUsedCustomers} containerStyle={{ width: width / 3 }} onPress={handleCustomerPress} />
      )}
      <SectionList
        loading={loading}
        skeletonElements={CONSTANTS.accessDataSkeletonCards}
        sections={data}
        renderItem={renderItem}
        noDataIcon={['fal', 'user-slash']}
        noDataMessage={i18n.t('NO_CUSTOMER_FOUND')}
        noDataSection={i18n.t('CUSTOMERS')}
        loadingSection={i18n.t('CUSTOMERS')}
        onRefresh={refresh}
        skeletonComponent={<SkeletonCard />}
        onLoadMore={loadMore}
        allDataLoaded={allDataLoaded}
      />
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: '100%',
  },
})
