import { ModalController, useDimensions } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { Platform } from 'react-native'

import { ModalScreenRef } from '../types'
import TabletModal from './TabletModal'

interface Props<T> extends PropsWithChildren {
  childRef?: React.RefObject<ModalScreenRef>
  controller: ModalController<T>
  backdropOpacity?: number
}

export default function BaseServiceModal<T>({ children, controller, backdropOpacity, childRef }: Props<T>) {
  const { isSmallDevice } = useDimensions()

  const handleClose = () => (childRef ? childRef.current?.handleGoBack() : controller.close())

  return (
    <TabletModal
      isVisible={controller.isShown}
      onClose={handleClose}
      isFullWidth={Platform.OS === 'web' && isSmallDevice}
      backdropOpacity={backdropOpacity}>
      {children}
    </TabletModal>
  )
}
