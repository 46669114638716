import { IM, useDimensions } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet, TextProps } from 'react-native'

import IndicatorBottom from './IndicatorBottom'
import IndicatorTop from './IndicatorTop'
import Text from './Text'

interface Props {
  text: string
  timeIndicator?: 'top' | 'bottom'
}

export default function TextTable({ text, timeIndicator, ...others }: Props & Pick<TextProps, 'numberOfLines'>) {
  const { isSmallDevice } = useDimensions()

  return (
    <IM.View style={styles.container}>
      <Text {...others}>{text}</Text>
      {isSmallDevice && timeIndicator === 'top' && <IndicatorTop />}
      {isSmallDevice && timeIndicator === 'bottom' && <IndicatorBottom />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, justifyContent: 'center' },
})
