import { useContext } from 'react'

import { DataProviderContext } from '../context/DataProviderContext'

export function useDataProvider() {
  const context = useContext(DataProviderContext)

  if (!context) {
    throw new Error('useDataProvider needs to be called inside of DataProviderContext')
  }

  return context
}
