import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CardProps, IM, IMLayout, IMStyle, useAlert, useLanguage, useTheme, Utils } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import DeleteButton from '../../components/DeleteButton'
import useRequest from '../../components/Infominds/hooks/useRequest'
import Pressable from '../../components/Infominds/Pressable'
import PressableIcon from '../../components/Infominds/PressableIcon'
import LoadingIcon from '../../components/LoadingIcon'
import { PendingRequest } from '../../dataProvider/types'
import { ThemeColorExpanded } from '../../types'

export type PendingRequestCardProps = {
  pendingRequest: PendingRequest
  onRetry?: () => void
  onDelete?: () => void
  busy: boolean
} & CardProps

const ProtocolCommand = 'SyncError'

export default function PendingRequestCard({ pendingRequest, onRetry, onDelete, busy: busyExt, ...cardProps }: PendingRequestCardProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const alert = useAlert()
  const [showDetail, setShowDetail] = useState(false)
  const [errorIconState, setErrorIconState] = useState<false | 'error' | 'refresh'>(false)
  const { request: sendProtocol, loading: busySendingProtocol, response: protocolResponse } = useRequest(api.sendProtocol)
  const busy = busySendingProtocol === 'reloading' || busyExt

  useEffect(() => {
    if (!protocolResponse) return
    onDelete?.()
  }, [protocolResponse])

  const backgroundColor = useMemo(() => {
    switch (pendingRequest.type) {
      case 'POST':
        return '#0FCEAD'
      case 'PUT':
        return '#FCA131'
      case 'PATCH':
        return '#FCA131'
      case 'DELETE':
        return '#FF5858'
      default:
        return '#61AFFE'
    }
  }, [theme, pendingRequest.type])

  const headerIcon = useMemo<IconProp | null>(() => {
    switch (pendingRequest.type) {
      case 'POST':
        return ['fal', 'file-circle-plus']
      case 'PUT':
        return ['fal', 'file-pen']
      case 'PATCH':
        return ['fal', 'file-pen']
      case 'DELETE':
        return ['fal', 'file-circle-minus']
      default:
        return null
    }
  }, [pendingRequest.type, theme])

  useEffect(() => {
    if (!pendingRequest.error) {
      setErrorIconState(false)
      return
    }
    setShowDetail(true)
    setErrorIconState('error')
    const timeOutId = setTimeout(() => {
      setErrorIconState('refresh')
    }, 1500)
    return () => clearTimeout(timeOutId)
  }, [pendingRequest.error])

  function handleProtocolAndDelete() {
    alert.alert(i18n.t('INFO'), i18n.t('SYNC_PROTOCOL_INFO_ALERT'), [
      {
        text: i18n.t('OK'),
        style: 'destructive',
        onPress: () => {
          sendProtocol({
            command: ProtocolCommand,
            destination: `${pendingRequest.type} - ${pendingRequest.apiResource}`,
            text: `${pendingRequest.type} - ${pendingRequest.apiResource}\r\n
            Payload:\r\n${JSON.stringify(pendingRequest.payload, undefined, 2).replaceAll('\n', '\r\n')}\r\n\r\n
            Error:\r\n${pendingRequest.error ?? ''}`,
          })
        },
      },
      {
        text: i18n.t('CANCEL'),
        style: 'cancel',
        isPreferred: true,
      },
    ])
  }

  return (
    <IM.CardBasic
      onPress={() => {
        setShowDetail(prev => !prev)
        if (!showDetail && __DEV__) console.debug(JSON.stringify(pendingRequest.payload, undefined, 2))
      }}
      onLongPress={__DEV__ ? onDelete : undefined}
      disabled={showDetail && !!pendingRequest.error}
      {...cardProps}>
      <IM.View style={IMLayout.flex.row}>
        <IM.View style={[{ backgroundColor: backgroundColor }, styles.cardHeader, showDetail && styles.cardHeaderOpen]}>
          {!!headerIcon && <IM.Icon icon={headerIcon} color={IMStyle.palette.white} size={22} />}
        </IM.View>
        <IM.View style={[IMLayout.flex.f1, styles.titleView]}>
          <IM.Text primary>{pendingRequest.resource}</IM.Text>
        </IM.View>
        <IM.View style={[styles.iconView]}>
          {busy && <LoadingIcon />}
          {!busy && !pendingRequest.error && (
            <PressableIcon
              icon={['fal', 'cloud-arrow-up']}
              disabledColor={'#fff'}
              size={20}
              style={[styles.syncPendingIcon, { backgroundColor: theme.header.main.background }]}
              disabled
            />
          )}
          {!busy && !!pendingRequest.error && (
            <PressableIcon
              icon={errorIconState === 'error' ? ['fal', 'times'] : ['fal', 'refresh']}
              color={'#fff'}
              disabledColor={'#fff'}
              size={20}
              style={errorIconState === 'error' ? styles.errorIcon : styles.retryIcon}
              onPress={onRetry}
              disabled={errorIconState === 'error'}
            />
          )}
        </IM.View>
      </IM.View>
      {showDetail && (
        <IM.View>
          {!!pendingRequest.error && <DataView data={pendingRequest.error} type="error" />}

          {!!pendingRequest.payload && (
            <DataView data={pendingRequest.payload} type="payload" initiallyOpen={!pendingRequest.error} requestType={pendingRequest.type} />
          )}

          {!!pendingRequest.error && (
            <IM.View spacing={['horizontal', 'vertical']}>
              <DeleteButton onPress={handleProtocolAndDelete} title={i18n.t('SYNC_PROTOCOL_AND_DELETE')} disabled={busy} />
            </IM.View>
          )}
        </IM.View>
      )}
    </IM.CardBasic>
  )
}

function DataView({
  data,
  type,
  initiallyOpen,
  requestType,
}: {
  data: object | string
  type: 'payload' | 'error'
  initiallyOpen?: boolean
  requestType?: string
}) {
  const [open, setOpen] = useState(!!initiallyOpen)
  const { theme } = useTheme<ThemeColorExpanded>()
  const content = useMemo(() => {
    if (typeof data === 'string') return `Error: ${data}`

    return (
      (requestType ? `${requestType}\n` : '') +
      Object.keys(data)
        .sort((a, b) => Utils.compareStringsForSort(a, b))
        .map((key: string) => {
          const value = data[key as keyof typeof data] as unknown
          const valueAsString = value ? JSON.stringify(value, undefined, 2) : undefined
          return `• ${key} : ${!valueAsString || valueAsString?.length < 500 ? String(valueAsString) : '{...}'}`
        })
        .join('\n')
    )
  }, [data])

  return (
    <Pressable onPress={() => setOpen(prev => !prev)} style={styles.pressable}>
      <IM.View spacing={['all']} spacingType="margin" style={{ backgroundColor: theme.sync.payloadBackground, borderRadius: IMLayout.borderRadius }}>
        <IM.View spacing={'all'}>
          <IM.Text numberOfLines={open ? undefined : 3} style={{ color: type === 'error' ? theme.error : theme.text }}>
            {content}
          </IM.Text>
        </IM.View>
      </IM.View>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  syncPendingIcon: {
    backgroundColor: '#535F71',
    borderRadius: 100,
  },
  retryIcon: {
    backgroundColor: '#FDCF57',
    borderRadius: 100,
  },
  errorIcon: {
    backgroundColor: '#FF5858',
    borderRadius: 100,
  },
  text: {
    color: '#fff',
  },
  iconView: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
  },
  titleView: {
    justifyContent: 'center',
    paddingHorizontal: IMLayout.horizontalMargin,
  },
  pressable: {
    padding: 0,
  },
  cardHeader: {
    paddingVertical: 20,
    width: 65,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: IMLayout.borderRadius,
  },
  cardHeaderOpen: {
    borderBottomRightRadius: IMLayout.borderRadius,
    borderBottomLeftRadius: 0,
  },
})
