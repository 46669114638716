/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { IMLayout, useTheme } from '@infominds/react-native-components'
import { LoginScreen as IMLoginScreen, OverrideThemeColors } from '@infominds/react-native-license'
import React from 'react'
import { Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useRecoilValue } from 'recoil'

import KeyboardAware from '../../components/KeyboardAware'
import DemoMode from '../../constants/DemoMode'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import { DataProviderStateActions } from '../../dataProvider/types'
import { landingPageUrlAtom } from '../../utils/stateManager'

export default function LoginScreen() {
  const { colorScheme } = useTheme()

  const landingPage = useRecoilValue(landingPageUrlAtom)
  const { forcedOfflineMode, dispatch } = useDataProvider()

  const colors: OverrideThemeColors = {
    dark: { background: '#1E1E1E', button: { default: { background: { disable: '#2F2F2F' }, text: { disable: '#4E4E4E' } } } },
    light: { background: '#F5F5F5', button: { default: { background: { disable: '#ECECEC' }, text: { disable: '#BBBBBB' } } } },
  }

  function onSuccessfulLogin() {
    if (Platform.OS === 'web') window.history.pushState(null, document.title, landingPage)
    if (forcedOfflineMode) dispatch({ type: DataProviderStateActions.UpdateOnlineState, payload: { forcedOfflineMode: false, isOnline: true } })
  }

  return (
    <SafeAreaView style={[IMLayout.flex.f1, { backgroundColor: colorScheme === 'light' ? colors.light.background : colors.dark.background }]}>
      <KeyboardAware>
        <IMLoginScreen
          demoData={{
            license: DemoMode.DEMO_LICENSE_KEY,
            username: DemoMode.DEMO_USERNAME,
            password: DemoMode.DEMO_PASSWORD,
          }}
          iconSource={require('../../assets/img/icon.png')}
          disableStatusBar
          keyboardAvoiding={false}
          colorScheme={colorScheme}
          onSuccess={onSuccessfulLogin}
          overrideThemeColors={colors}
        />
      </KeyboardAware>
    </SafeAreaView>
  )
}
