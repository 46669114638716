import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useCallback, useEffect, useRef, useState } from 'react'

import { LoadingType, VaultLockType } from '../../../types'
import { abortUtils } from '../../../utils/abortController'
import appUtils from '../../../utils/appUtils'
import { utils } from '../../../utils/utils'

type Options<TResponse> = {
  id?: string
  disableAlert?: boolean
  onResult?: (response: TResponse) => VaultLockType
}

interface Result<TRequest, TResponse> {
  response: TResponse | undefined
  request: (request: TRequest) => void
  loading: LoadingType
  error: string
}

export default function useRequest<TRequest extends object, TResponse>(
  requestFunc: (request: TRequest, abortController: AbortController) => Promise<TResponse>,
  options?: Options<TResponse>
): Result<TRequest, TResponse> {
  const alert = useAlert()
  const { i18n } = useLanguage()

  const hash = useRef(utils.generateUuid())
  const [response, setResponse] = useState<TResponse>()
  const [loading, setLoading] = useState<LoadingType>(false)
  const request = useRef<TRequest>({} as TRequest)
  const errorMessage = useRef('')

  useEffect(() => {
    return () => {
      options && options.id && abortUtils.abort(options.id, hash.current)
    }
  }, [])

  useEffect(() => {
    loading === 'reloading' && loader()
  }, [loading])

  const loader = useCallback(() => {
    let controller: AbortController | undefined

    if (options && options.id) {
      abortUtils.abort(options.id)
      controller = abortUtils.add(options.id, hash.current)
    }

    errorMessage.current = ''

    requestFunc(request.current, controller ?? new AbortController())
      .then(result => {
        if (options === undefined || options?.onResult === undefined || options.onResult(result) === 'vault-unlocked') {
          setResponse(result)
          setLoading(false)
        } else {
          setLoading('aborted')
        }
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          // setLoading('aborted')
          console.debug('Aborted useRequest', requestFunc.name, options?.id ?? '-', request.current)
          return
        }

        errorMessage.current = appUtils.getBackendErrorMessage(err)
        setLoading('catched')

        if (options === undefined || !!options?.disableAlert === false) {
          alert.alert(i18n.t('API_CATCH_TITLE'), errorMessage.current)
        }
      })
      .finally(() => {
        options && options.id && abortUtils.remove(options.id)
      })

    return
  }, [request, response])

  const load = useCallback(
    (requestData: TRequest) => {
      request.current = requestData
      setLoading('reloading')
    },
    [request]
  )

  return { response, request: load, loading, error: errorMessage.current }
}
