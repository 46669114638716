import { useDimensions, useIsMounted } from '@infominds/react-native-components'
import React, { useEffect } from 'react'
import { NativeMethods } from 'react-native'

import useScreen from './useScreen'

interface Props<T> {
  ref?: React.MutableRefObject<T | null>
  disable?: boolean
  focus?: boolean
}

/**
 * Hook that focus element when detail header opens.
 */
export default function useFocus<T extends NativeMethods>({ ref, focus, disable = false }: Props<T>) {
  const mounted = useIsMounted()
  const { detail } = useScreen()
  const { isSmallDevice } = useDimensions()

  useEffect(() => {
    if (disable) return

    if (mounted && (isSmallDevice ? detail.open : focus)) {
      ref?.current?.focus()
    }

    return () => {
      ref?.current?.blur()
    }
  }, [mounted, detail, focus, isSmallDevice])
}
