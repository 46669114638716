import React, { useCallback, useEffect } from 'react'

import api from '../../apis/apiCalls'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { REQUEST_QUALITY } from '../../constants/Keys'
import { QualityCheckNote, QualityCheckProvider, QualityCheckValue } from '../../contexts/QualityCheckContext'
import { CommonStackScreenProps } from '../../navigation/types'
import QualityCheckViewWrapper from '../../views/common/QualityCheckViewWrapper'

export default function QualityCheckScreen({ route }: CommonStackScreenProps<'QualityCheck'>) {
  const { item: characteristics, loadItem, loading } = useControlledLoader(api.getQuality, { id: REQUEST_QUALITY })

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    if (route.params.serialNumberId === undefined && route.params.activityId === undefined) {
      console.error('At least one between serialnumberId and activityId should not be undefined')
      return
    }

    loadItem({ activityId: route.params.activityId, serialnumberId: route.params.serialNumberId })
  }

  const getInitialsNote = useCallback(() => {
    if (loading !== false || characteristics === undefined) return undefined

    const initial: QualityCheckNote[] = []
    characteristics?.forEach(characteristic => {
      characteristic.value &&
        initial.push({
          characteristicsId: characteristic.qualityCharacteristicId,
          serialNumberId: characteristic.serialnumberId,
          note: characteristic.note,
        })
    })

    return initial
  }, [loading, characteristics])

  const getInitialsValues = useCallback(() => {
    if (loading !== false || characteristics === undefined) return undefined

    const initial: QualityCheckValue[] = []
    characteristics?.forEach(characteristic => {
      if (characteristic.qualityCharacteristicFieldTyp === 'Boolean') {
        initial.push({
          characteristicsId: characteristic.qualityCharacteristicId,
          serialNumberId: characteristic.serialnumberId,
          value: (characteristic.value ?? 'false').toLowerCase(),
        })
      } else {
        characteristic.value &&
          initial.push({
            characteristicsId: characteristic.qualityCharacteristicId,
            serialNumberId: characteristic.serialnumberId,
            value: characteristic.value,
          })
      }
    })

    return initial
  }, [loading, characteristics])

  return (
    <QualityCheckProvider initValue={getInitialsValues()} initNote={getInitialsNote()}>
      <SearchProvider>
        <QualityCheckViewWrapper
          activityCode={route.params.activityCode}
          serialNumber={route.params.serialNumber}
          serialNumberManufacturer={route.params.serialNumberManufacturer}
          characteristics={characteristics ?? []}
          onRefresh={refresh}
          loading={loading !== 'reloading' ? false : true}
        />
      </SearchProvider>
    </QualityCheckProvider>
  )
}
