import { CardProps } from '@infominds/react-native-components'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'
import React, { createRef, memo, useEffect, useMemo, useRef, useState } from 'react'
import { SetterOrUpdater, useRecoilState } from 'recoil'

import { ArticleSparePart, InvoiceType, TicketArticle } from '../../apis/types/apiResponseTypes'
import { arrayUtils } from '../../components/Infominds/utils/array'
import { PartDto, SparePartCardRef, SparePartDirection } from '../../types'
import { shoppingCartAtom, shoppingCartRemovalAtom, stockQuantityAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'
import SparePartsCard from './SparePartsCard'

interface Props extends CardProps {
  activityId: string
  sparePart: ArticleSparePart
  ticketArticles: TicketArticle[]
  invoiceTypes: InvoiceType[]
  direction: SparePartDirection
}

const ArticleSparePartCard = memo(function ArticleSparePartCard({
  direction,
  activityId,
  sparePart,
  invoiceTypes,
  ticketArticles,
  ...others
}: Props) {
  const lastElem = useRef(sparePart)
  const contentRef = createRef<SparePartCardRef>()

  const common: PartDto = useMemo(() => {
    return {
      ...sparePart,
      serialnumberId: sparePart.serialnumberId === '' ? undefined : sparePart.serialnumberId,
      initSerialnumberIdParent: '',
      direction: direction,
      price: 0,
      quantity: 0,
      isLottoSerialnumberActive: sparePart.isDummy ? false : sparePart.isLottoSerialnumberActive,
      serialnumberIdParent: ticketArticles.at(0)?.serialnumberId,
      serialnumberParent: ticketArticles.at(0)?.serialnumber,
      serialnumberNumberManufactorParent: ticketArticles.at(0)?.serialnumberNumberManufactor,
      articleSearchtextParent: ticketArticles.at(0)?.articleSearchtext,
      date: dayjs().startOf('day').toISOString(),
      status: 'new',
      prevStatus: 'new',
    }
  }, [sparePart, ticketArticles, direction])

  const [shown, setShown] = useState(false)
  const [dto, setDto] = useState<PartDto>(common)
  const [defaultInvoiceTypeId, setDefaultInvoiceTypeId] = useState<string>()
  const [parts, setParts] = useRecoilState(shoppingCartAtom)
  const [removalParts, setRemovalParts] = useRecoilState(shoppingCartRemovalAtom)
  const [commonStockQuantity, setCommonStockQuantity] = useRecoilState(stockQuantityAtom)

  useEffect(() => {
    if (sparePart.serialnumberId !== lastElem.current.serialnumberId && sparePart.articleId !== lastElem.current.articleId) {
      lastElem.current = sparePart
      resetState()
    }
  }, [sparePart])

  useEffect(() => {
    setDto(common)
  }, [ticketArticles])

  const handleAdd = () => {
    direction === 'Installation' ? handleEditSparePart(dto, parts, setParts) : handleEditSparePart(dto, removalParts, setRemovalParts)

    resetState()
  }

  const handleEditSparePart = (newPart: PartDto, partsState: PartDto[] | undefined, setPartsState: SetterOrUpdater<PartDto[] | undefined>) => {
    if (!partsState) return

    if (newPart.invoicingTypeId === undefined) {
      const findSamePartWithInvoice = partsState.find(
        item => ticketUtils.getPartIdentifier(item, true) === ticketUtils.getPartIdentifier(newPart, true)
      )

      if (findSamePartWithInvoice !== undefined) {
        newPart = { ...newPart, invoicingTypeId: findSamePartWithInvoice.invoicingTypeId, invoicingType: findSamePartWithInvoice.invoicingType }
      }
    }

    let cloneStockQuantity = cloneDeep(commonStockQuantity)

    let foundQnt = false

    cloneStockQuantity = cloneStockQuantity.map(el => {
      if (
        !foundQnt &&
        el.articleId === newPart.articleId &&
        el.serialnumberId === newPart.serialnumberId &&
        el.depositId === newPart.depositId &&
        newPart.quantity
      ) {
        foundQnt = true
        return {
          ...el,
          pickedQuantity: newPart.quantity + (el.pickedQuantity ?? 0),
        }
      }

      return el
    })

    if (!foundQnt) {
      cloneStockQuantity.push({
        articleId: newPart.articleId,
        depositId: newPart.depositId,
        serialnumberId: newPart.serialnumberId,
        pickedQuantity: newPart.quantity,
        stockQuantity: newPart.stockQuantity,
      })
    }

    setCommonStockQuantity(cloneStockQuantity)

    const condition = (item: PartDto) => ticketUtils.getPartIdentifier(item) === ticketUtils.getPartIdentifier(newPart)

    const clone = cloneDeep(partsState)

    const found = clone.filter(condition)
    const foundIndex = clone.findIndex(condition)
    const othersParts = clone.filter(item => !condition(item))

    if (found.length === 1 && found[0].quantity !== undefined) {
      found[0].quantity += newPart.quantity ?? 0

      if (found[0].isSerialnumberActive && !found[0].isLottoSerialnumberActive) {
        found[0].quantity = 1
      } else {
        if (found[0].stockQuantity && found[0].quantity > found[0].stockQuantity) {
          found[0].quantity = found[0].quantity ?? 0
        }
      }

      found[0].status = ticketUtils.getSparePartStatus(found[0])
    }

    setPartsState(
      arrayUtils
        .insert(
          othersParts,
          found.length === 1
            ? { ...found[0], invoicingTypeId: found[0].invoicingTypeId ?? defaultInvoiceTypeId }
            : { ...newPart, invoicingTypeId: newPart.invoicingTypeId ?? defaultInvoiceTypeId },
          foundIndex
        )
        .sort(
          (a, b) =>
            a.articleSearchtext?.localeCompare(b.articleSearchtext) ||
            (a.serialnumber && b.serialnumber ? a.serialnumber?.localeCompare(b.serialnumber) : 0)
        )
    )
  }

  const resetState = () => {
    setShown(false)
    setDto(common)
    contentRef.current?.reset()
  }

  return (
    <SparePartsCard
      ref={contentRef}
      activityId={activityId}
      shown={shown}
      articles={ticketArticles}
      dto={dto}
      invoices={invoiceTypes}
      onChangeDto={setDto}
      onConfirm={handleAdd}
      onChangeShown={setShown}
      direction={direction}
      type="spareParts"
      disableButton={dto.quantity === 0 || dto.quantity === undefined || (dto.isDummy && dto.articleCode === '')}
      setDefaultInvoiceTypeId={setDefaultInvoiceTypeId}
      {...others}
    />
  )
})

export default ArticleSparePartCard
