import { IM, IMStyle, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { StyleSheet } from 'react-native'

import ScrollViewData from '../../components/ScrollViewData'
import Tag from '../../components/Tag'
import { QualityFilterType, QualityGroupType, QualityOrderType } from '../../contexts/QualityFilterProvider'
import { FilterEmitter } from '../../types'

type Emitter = FilterEmitter<QualityFilterType, QualityGroupType, QualityOrderType>

export interface QualityFilterViewRef {
  getState: () => Emitter
}

const QualityFilterView = (props: Emitter, ref: ForwardedRef<QualityFilterViewRef>) => {
  useImperativeHandle(ref, () => ({
    getState: () => state,
  }))

  const { i18n } = useLanguage()
  const [state, setState] = useState<Emitter>(props)

  useEffect(() => {
    setState(props)
  }, [props])

  const noFilter = () => (
    <IM.Text secondary style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }}>
      {i18n.t('NO_SERIAL_NUMBER')}
    </IM.Text>
  )

  return (
    <ScrollViewData>
      <IM.View>
        <IM.View style={styles.titleContainer}>
          <IM.Icon icon={['fal', 'layer-group']} spacing="right" />
          <IM.Text style={styles.title}>{i18n.t('GROUP_BY')}</IM.Text>
        </IM.View>
        <IM.View style={styles.groupContainer}>
          {state.groups.map(group => {
            return (
              <Tag
                key={group.data.id}
                id={group.data.id}
                name={group.data.name}
                active={group.active}
                style={styles.tag}
                onPress={id =>
                  setState(prev => {
                    const clone = cloneDeep(prev)

                    clone.groups.forEach(elem => {
                      if (elem.data.id === id) elem.active = !elem.active
                      else elem.active = false
                    })

                    return clone
                  })
                }
              />
            )
          })}
        </IM.View>
      </IM.View>
      <IM.View spacing="top">
        <IM.View style={styles.titleContainer}>
          <IM.Icon icon={['fal', 'arrow-up-arrow-down']} spacing="right" />
          <IM.Text style={styles.title}>{i18n.t('SORT_BY')}</IM.Text>
        </IM.View>
        <IM.View style={styles.groupContainer}>
          {state.orders.map(order => {
            return (
              <Tag
                key={order.data.id}
                id={order.data.id}
                name={order.data.name}
                active={order.active}
                icon={order.data.icon}
                size={14}
                style={styles.tag}
                onPress={id =>
                  setState(prev => {
                    const clone = cloneDeep(prev)

                    clone.orders.forEach(elem => {
                      if (elem.data.id === id) elem.active = !elem.active
                      else elem.active = false
                    })

                    return clone
                  })
                }
              />
            )
          })}
        </IM.View>
      </IM.View>
      <IM.View spacing="top">
        <IM.View style={styles.titleContainer}>
          <IM.Icon icon={['fal', 'filter']} spacing="right" size={15} />
          <IM.Text style={styles.title}>{i18n.t('FILTERS')}</IM.Text>
        </IM.View>
        <IM.View spacing="top">
          {state.filters.length === 0 && noFilter()}
          {state.filters.map(filter => {
            return (
              <IM.View key={filter.id}>
                <IM.Text style={{ fontSize: IMStyle.typography.fontSizeSmall - 1 }}>{filter.name}</IM.Text>
                <IM.View style={styles.filterContainer}>
                  {filter.elements.length === 0 ? (
                    <>{noFilter()}</>
                  ) : (
                    filter.elements.map(el => {
                      return (
                        <Tag
                          key={el.id}
                          id={el.id}
                          name={el.id + (el.description ? '\n' + el.description?.replace(`${el.id} - `, '') : '')}
                          active={el.active}
                          style={styles.tag}
                          onPress={id =>
                            setState(prev => {
                              const clone = cloneDeep(prev)

                              clone.filters.forEach(clonedFilter => {
                                if (clonedFilter.id === filter.id) {
                                  clonedFilter.elements.forEach(clonedElement => {
                                    if (clonedElement.id === id) clonedElement.active = !clonedElement.active
                                  })
                                }
                              })

                              return clone
                            })
                          }
                        />
                      )
                    })
                  )}
                </IM.View>
              </IM.View>
            )
          })}
        </IM.View>
      </IM.View>
    </ScrollViewData>
  )
}

const styles = StyleSheet.create({
  title: {
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  titleContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  filterContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 2,
    marginBottom: 10,
  },
  groupContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 6,
    marginBottom: 14,
  },
  tag: { marginVertical: 3 },
  center: {
    alignItems: 'center',
  },
})

export default forwardRef(QualityFilterView)
