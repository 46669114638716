import { useDimensions } from '@infominds/react-native-components'
import { ParamListBase, useNavigation } from '@react-navigation/native'
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useMemo, useState } from 'react'

import { AllBottomTabRoutesParamList } from '../../../navigation/types'

interface Props {
  forceSmallDevice?: boolean
}

type Keyof<T extends object> = Extract<keyof T, string>

export type MasterDetailProp<ParamList extends ParamListBase, RouteName extends keyof ParamList = Keyof<ParamList>> = ParamList[RouteName]

export interface IMasterDetailContext<T extends MasterDetailProp<ParamListBase> = MasterDetailProp<ParamListBase>> {
  detailParams: T | undefined
  setDetailParams: Dispatch<SetStateAction<T | undefined>>
  active: boolean
  navigate<Name extends keyof AllBottomTabRoutesParamList>(
    ...args: Name extends unknown
      ? undefined extends AllBottomTabRoutesParamList[Name]
        ? [screen: Name] | [screen: Name, params: AllBottomTabRoutesParamList[Name]]
        : [screen: Name, params: AllBottomTabRoutesParamList[Name]]
      : never
  ): void
}

export const MasterDetailContext = createContext<IMasterDetailContext | undefined>(undefined)

export function MasterDetailProvider<T extends MasterDetailProp<ParamListBase>>({ forceSmallDevice, children }: PropsWithChildren<Props>) {
  const { isSmallDevice } = useDimensions()
  const navigation = useNavigation()
  const active = !isSmallDevice && !forceSmallDevice

  const [detailParameters, setDetailParameters] = useState<T>()

  const handleNavigation = <Name extends keyof AllBottomTabRoutesParamList>(
    ...args: Name extends unknown
      ? undefined extends AllBottomTabRoutesParamList[Name]
        ? [screen: Name] | [screen: Name, params: AllBottomTabRoutesParamList[Name]]
        : [screen: Name, params: AllBottomTabRoutesParamList[Name]]
      : never
  ) => {
    if (!active) {
      //@ts-ignore discover why there is a ts error
      navigation.navigate(...args)
    } else {
      //@ts-ignore discover why there is a ts error
      setDetailParameters(args[1])
    }
  }

  const props = useMemo<IMasterDetailContext<T>>(
    () => ({ detailParams: detailParameters, setDetailParams: setDetailParameters, navigate: handleNavigation, active: active }),
    [detailParameters, isSmallDevice, forceSmallDevice]
  )

  // @ts-ignore: to understand
  return <MasterDetailContext.Provider value={props}>{children}</MasterDetailContext.Provider>
}
