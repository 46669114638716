import { useEffect, useRef } from 'react'
import { NativeMethods, NativeScrollEvent, NativeSyntheticEvent, StyleProp, ViewStyle } from 'react-native'
import {
  AnimatedStyle,
  runOnJS,
  useAnimatedReaction,
  useAnimatedScrollHandler,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'

import useScreen from './useScreen'

export type AnimatedOnScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => void

interface HookReturn {
  animatedStyle: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>
  onScrollHandler: AnimatedOnScroll
}

/**
 * Hook that return function/components used to animate the...
 */
export default function useContentAnimation<T extends NativeMethods>(): HookReturn {
  const { detail, height, tmpDetailOpen, setDetailTmpStatus } = useScreen()
  const offset = useSharedValue(0)
  const scrollOffset = useSharedValue(0)
  const scrolling = useSharedValue(false)
  const scrollDownTolerance = useSharedValue(25)
  const containerRef = useSharedValue(useRef<T>(null))

  useEffect(() => {
    offset.value = detail.open ? height.detail : 0
  }, [detail])

  useEffect(() => {
    if (tmpDetailOpen) offset.value = height.detail
  }, [tmpDetailOpen])

  useAnimatedReaction(
    () => {
      if (scrolling.value === undefined) return undefined

      if (offset.value === 0) {
        return false
      }

      if (offset.value === height.detail) {
        return true
      }

      return undefined
    },
    (result, previous) => {
      if (result !== previous) {
        result !== undefined && runOnJS(setDetailTmpStatus)(result)
      }
    }
  )

  const animatedStyle = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withSpring(offset.value, { damping: 14, mass: 0.6 }),
        },
      ],
    }
  }, [height])

  const onScrollHandler = useAnimatedScrollHandler<{ prevY: number }>({
    onScroll: (event, ctx) => {
      if (!detail.open || !scrolling.value || ctx === undefined) return

      const diff = event.contentOffset.y - (scrollOffset.value || 0)

      if (diff < 0) {
        // down
        if (ctx.prevY - event.contentOffset.y > scrollDownTolerance.value) {
          if (offset.value - diff >= height.detail) {
            offset.value = height.detail
          } else {
            offset.value = offset.value - diff
          }
        }
      } else {
        // up
        if (offset.value - diff <= 0) {
          offset.value = 0
        } else {
          offset.value = offset.value - diff
        }
      }

      scrollOffset.value = event.contentOffset.y
    },
    onBeginDrag: (event, ctx) => {
      if (ctx === undefined) return

      scrolling.value = true
      containerRef.value.current?.focus()
      ctx.prevY = event.contentOffset.y
    },
    onEndDrag: (event, ctx) => {
      scrolling.value = false

      if (!detail.open || ctx === undefined) return
      const diff = (event.contentOffset.y - ctx.prevY) * -1
      const closeToBottom = event.layoutMeasurement.height + event.contentOffset.y >= event.contentSize.height - 10
      const closeToTop = event.contentOffset.y <= 10

      if (diff < 0) {
        if (Math.abs(diff) > height.detail / 2) {
          offset.value = 0
        } else {
          if (!closeToBottom) {
            offset.value = height.detail
          } else {
            offset.value = 0
          }
        }
      } else {
        if (diff - scrollDownTolerance.value > height.detail / 2) {
          offset.value = height.detail
        } else {
          if (closeToTop) {
            offset.value = height.detail
          } else {
            offset.value = 0
          }
        }
      }
    },
  })

  return { animatedStyle, onScrollHandler }
}
