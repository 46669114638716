import { DataProviderCustomUpdateEffect } from '../../../dataProvider/types'
import { TicketClassification } from '../../../types'
import { ClassificationDefinitionValue, TicketClassificationRequest } from '../../types/apiResponseTypes'

export const TicketClassificationCustomUpdateEffect: DataProviderCustomUpdateEffect<TicketClassificationRequest> = async (data, props) => {
  if (props.requestParams.type === 'PUT') {
    try {
      // get current value from db. since given data only contains either id and/or classificationId
      const currentValue = (
        await props.dataStore.Get<TicketClassificationRequest>('common/classificationsOfTickets', { fkId: data.fkId, type: data.type })
      )?.shift()
      if (!currentValue) throw new Error('classificationsOfTickets not found')

      const result: TicketClassification[] = []
      for (const classification of currentValue.classifications) {
        // load all classificationDefinitionValues with the definitionId of the current classification
        const classificationDefinitionValues = await props.dataStore.Get<ClassificationDefinitionValue>('common/classificationDefinitionValue', {
          classificationDefinitionId: classification.definitionId,
        })

        // search the update data for a record either containing the same id or being included in the list of classificationDefinitionValues with the same definitionId
        const foundUpdateValue = data.classifications.find(
          c => (!!c.id && c.id === classification.id) || classificationDefinitionValues.find(cdv => cdv.id === c.classificationId)
        )

        const classificationDefinitionValue = classificationDefinitionValues.find(cld => cld.id === foundUpdateValue?.classificationId)

        if (!foundUpdateValue || !classificationDefinitionValue) {
          result.push({
            ...classification,
            classificationId: undefined,
            classificationCode: undefined,
            classificationDescription: undefined,
          })
          continue
        }

        result.push({
          ...classification,
          classificationId: classificationDefinitionValue.id,
          classificationCode: classificationDefinitionValue.code,
          classificationDescription: classificationDefinitionValue.description,
        })
      }
      data.classifications = result
    } catch (e) {
      console.error(e)
    }
  }

  return props.defaultEffect(data)
}
