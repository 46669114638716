import { IM } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Border } from '../../../types'

interface Props {
  borderColor?: string
  disableBorder?: Border | Border[]
  style?: StyleProp<ViewStyle>
}

const BaseTextInputExternalIcon = memo(function BaseTextInputExternalIcon({ children, style }: PropsWithChildren<Props>) {
  return <IM.View style={[styles.container, style]}>{children}</IM.View>
})

export default BaseTextInputExternalIcon

const styles = StyleSheet.create({
  container: { marginLeft: 4, alignItems: 'center', justifyContent: 'center' },
})
