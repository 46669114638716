import { IM, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import BooleanInput, { BooleanInputProps } from '../../components/input/BooleanInput'
import EmailInputSelector from '../../components/inputSelector/EmailInputSelector'

type Props = {
  activityId: string
  initialEmail?: string
  spacing?: SpacingProps
  editable?: boolean
  popUpPosition?: 'top' | 'bottom'
  onEmailEntered?: (emails: string[]) => void
}

const styles = StyleSheet.create({
  card: {
    zIndex: -1, // needed when signature contact person selector is open
  },
})

const ActivityReportCard = memo(function ActivityReportCard({
  initialEmail,
  spacing,
  editable,
  activityId,
  onEmailEntered,
  ...others
}: Props & Required<Pick<BooleanInputProps, 'value' | 'onValueChange'>>) {
  const { i18n } = useLanguage()

  return (
    <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('REPORT')} />} style={styles.card}>
      <BooleanInput title={i18n.t('SEND_REPORT')} spacing="bottom" {...others} />
      {others.value && (
        <EmailInputSelector
          activityId={activityId}
          editable={editable}
          value={initialEmail}
          onDataEntered={onEmailEntered}
          spacing="top"
          popUpPosition="top"
        />
      )}
    </IM.Card>
  )
})

export default ActivityReportCard
