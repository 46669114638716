import { IM, IMLayout, IMStyle, useEvent, useLanguage } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import React, { createRef, useEffect, useMemo } from 'react'
import { useSetRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { Activity, SMActivityType } from '../../apis/types/apiResponseTypes'
import ActivityListCard from '../../cards/activityList/ActivityListCard'
import FlashListData from '../../components/FlashListData'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import { REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ACTIVITY } from '../../constants/Keys'
import { FilterType, GroupType, OrderType } from '../../contexts/FilterContext'
import useFilter from '../../hooks/useFilter'
import { PlanningStackParamList } from '../../navigation/types'
import { FilterElement } from '../../types'
import appUtils from '../../utils/appUtils'
import { activityPlanningFilterEnableAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'
import TimeUtils from '../../utils/TimeUtils'

export default function PlanningView() {
  const { search } = useSearch()
  const { i18n, language } = useLanguage()
  const { filters, groups, orders, initFilters } = useFilter()
  const navigation = useNavigation<NavigationProp<PlanningStackParamList>>()
  const enableFilter = useSetRecoilState(activityPlanningFilterEnableAtom)

  const listRef = createRef<FlashList<string | Activity>>()

  useEvent({ key: REFRESH_ACTIVITY_PLANNING_LIST_EVENT_KEY }, () => refresh())

  const { item: activities, loadItem: load, loading } = useControlledLoader(api.getActivity, { id: REQUEST_ACTIVITY })

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [filters, groups, orders])

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (loading === false) {
      const activitiesType: FilterElement[] = []
      const assistance: FilterElement[] = []
      const team: FilterElement[] = []
      const zone: FilterElement[] = []

      activities?.forEach(activity => {
        activity.activityType && activitiesType.push({ id: activity.activityType, active: false })
        activity.area && zone.push({ id: activity.area, active: false })
        activity.team && team.push({ id: activity.team, active: false })
        activity.maintenanceType && assistance.push({ id: activity.maintenanceType, active: false })
      })

      initFilters(activitiesType, assistance, zone, team)
      enableFilter(true)
    } else {
      enableFilter(false)
    }
  }, [loading, activities])

  function refresh() {
    load({ state: 'UserFilterActivitiesToPlanning', smActivityType: SMActivityType.InPlanning })
  }

  const renderItem = (elem: ListRenderItemInfo<Activity | string>) => {
    const isFirst = elem.index === 0
    const isLast = elem.index === (filtered?.length ?? 0) - 1
    const item = elem.item

    if (typeof item === 'string') {
      return (
        <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}>
          <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{item}</IM.Text>
        </IM.View>
      )
    } else {
      return (
        <ActivityListCard
          type="planning"
          activity={item}
          onPress={() =>
            navigation.navigate('PlanningActivityStack', {
              screen: 'ActivityPlanning',
              params: {
                activityId: item.id,
                ticketId: item.ticketId,
                documentId: item.ticketDocumentId,
                activityCode: item.code,
                start: false,
                ticketCode: item.ticketCode,
              },
            })
          }
          style={[
            // eslint-disable-next-line react-native/no-inline-styles
            {
              marginTop: IMLayout.horizontalMargin,
              marginBottom: isLast ? IMLayout.horizontalMargin : 0,
              marginHorizontal: 2 * IMLayout.horizontalMargin,
            },
          ]}
        />
      )
    }
  }

  const filtered: (Activity | string)[] | undefined = useMemo(() => {
    let activitiesClone = cloneDeep(activities)

    // Search
    activitiesClone = activitiesClone
      ? appUtils.filter(
          activitiesClone,
          search,
          [
            'code',
            'ticketCode',
            'customer',
            'articleDescription',
            'serialnumber',
            'ticketDescription',
            'serialnumberManufacturerNumber',
            'serialnumberLocation',
            'location',
            'planDate',
          ],
          language
        )
      : []

    // Grouping
    let activityGrouped: { [key: string]: Activity[] } = { undefined: activitiesClone }
    const activeGroups = groups.find(group => group.active)
    switch (activeGroups?.data.id) {
      case GroupType.Assistance: {
        activityGrouped = groupBy(activitiesClone, el => el.maintenanceType)
        activityGrouped = ticketUtils.replaceUndefinedSection(activityGrouped, i18n.t('NO_ASSISTANCE_TYPE'))

        break
      }
      case GroupType.Customer: {
        activityGrouped = groupBy(activitiesClone, el => el.customer)
        activityGrouped = ticketUtils.replaceUndefinedSection(activityGrouped, i18n.t('NO_CUSTOMER'))

        break
      }
      case GroupType.Zone: {
        activityGrouped = groupBy(activitiesClone, el => el.area)
        activityGrouped = ticketUtils.replaceUndefinedSection(activityGrouped, i18n.t('NO_ZONE'))

        break
      }
    }

    const activeOrder = orders.find(order => order.active)
    switch (activeOrder?.data.id) {
      case OrderType.DateAscending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = TimeUtils.sortDate(activityGrouped[key], 'date', 'asc', (a, b) => a.code.localeCompare(b.code))
        })

        break
      }
      case OrderType.DateDescending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = TimeUtils.sortDate(activityGrouped[key], 'date', 'desc', (a, b) => b.code.localeCompare(a.code))
        })

        break
      }
      case OrderType.PlanningAscending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = TimeUtils.sortDate(
            activityGrouped[key],
            'planDate',
            'asc',
            (a, b) => (a.planStartTime ?? 0) - (b.planStartTime ?? 0)
          )
        })

        break
      }
      case OrderType.PlanningDescending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = TimeUtils.sortDate(
            activityGrouped[key],
            'planDate',
            'desc',
            (a, b) => (b.planStartTime ?? 0) - (a.planStartTime ?? 0)
          )
        })

        break
      }
      case OrderType.TownAscending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = appUtils.sortAlphabetically(activityGrouped[key], 'town', 'asc', (a, b) => a.code.localeCompare(b.code))
        })

        break
      }
      case OrderType.TownDescending: {
        Object.keys(activityGrouped).forEach(key => {
          activityGrouped[key] = appUtils.sortAlphabetically(activityGrouped[key], 'town', 'desc', (a, b) => b.code.localeCompare(a.code))
        })

        break
      }
    }

    const activeFilters = ticketUtils.activeFilters(filters)
    activeFilters.forEach(filter => {
      switch (filter.id) {
        case FilterType.Teams: {
          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone => (clone.team ? filter.elements.find(el => el.id === clone.team) !== undefined : false)),
            })
          )
          break
        }
        case FilterType.Activity: {
          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone =>
                clone.activityType ? filter.elements.find(el => el.id === clone.activityType) !== undefined : false
              ),
            })
          )
          break
        }
        case FilterType.Assistance: {
          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone =>
                clone.maintenanceType ? filter.elements.find(el => el.id === clone.maintenanceType) !== undefined : false
              ),
            })
          )
          break
        }
        case FilterType.Zone: {
          Object.keys(activityGrouped).forEach(key =>
            Object.assign(activityGrouped, {
              ...activityGrouped,
              [key]: activityGrouped[key].filter(clone => (clone.area ? filter.elements.find(el => el.id === clone.area) !== undefined : false)),
            })
          )

          break
        }
      }
    })

    const data: (string | Activity)[] = []
    const keys = Object.keys(activityGrouped)

    keys.forEach(key => {
      if (activityGrouped[key].length !== 0) {
        if (key !== 'undefined') {
          data.push(key)
          data.push(...activityGrouped[key])
        } else {
          data.push(...activityGrouped[key])
        }
      }
    })

    return data
  }, [filters, groups, orders, activities, search, language])

  return (
    <FlashListData
      ref={listRef}
      data={filtered}
      loading={loading}
      noDataMessage={i18n.t('NO_ACTIVITY_FOUND')}
      renderItem={renderItem}
      isSearching={search !== ''}
      refresh={refresh}
    />
  )
}
