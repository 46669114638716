import { IM, IMLayout, IMStyle, useTheme, ViewProps } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { StyleSheet } from 'react-native'
import Animated, { Extrapolation, interpolate, useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'

import { Ticket } from '../../../apis/types/apiResponseTypes'
import EmployeeBadge from '../../../components/EmployeeBadge'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import { ThemeColorExpanded } from '../../../types'
import { TicketListView } from '../../../views/ticket/TicketList'

type Props = {
  ticket: Ticket
  showIcon?: boolean
  showEmployee?: boolean
  forceLayout?: ForceLayoutType
  timeRunning: boolean
  view: TicketListView
}

const ICON_SIZE = 22

export default function TicketInfo({ view, ticket, style, showIcon, showEmployee, forceLayout, timeRunning, ...props }: Props & ViewProps) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useLayout(true, forceLayout)

  const [width, setWidth] = useState(0)
  const pulsingAnimation = useSharedValue(-1)

  const alignItems = isSmallDevice ? 'flex-end' : 'center'
  const minWidth = isSmallDevice ? 70 : undefined

  useEffect(() => {
    pulsingAnimation.value = withRepeat(
      withTiming(-pulsingAnimation.value, {
        duration: 1000,
      }),
      -1,
      true
    )

    return () => {
      pulsingAnimation.value = -1
    }
  }, [])

  const animatedStyles = useAnimatedStyle(() => {
    const scale = interpolate(pulsingAnimation.value, [-1, 1], [1, 1.2], {
      extrapolateRight: Extrapolation.CLAMP,
    })

    return {
      transform: [{ scale: scale }],
    }
  }, [])

  return (
    <>
      {(showIcon || showEmployee) && (
        <IM.View style={[style, styles.container, { minWidth }]} {...props}>
          {showIcon && (
            // eslint-disable-next-line react-native/no-inline-styles
            <IM.View style={[styles.iconContainer, { justifyContent: isSmallDevice ? 'flex-end' : 'center' }]}>
              {view === 'map' ? (
                <IM.View style={IMLayout.flex.f1}>
                  {(ticket.mapLat === undefined || ticket.mapLong === undefined || (ticket.mapLat === 0 && ticket.mapLong === 0)) && (
                    <IM.Icon style={{ alignItems }} color={theme.textDetail} size={ICON_SIZE} icon={['fal', 'location-dot-slash']} />
                  )}
                </IM.View>
              ) : (
                <>
                  {!!ticket.tip && (
                    <IM.Icon style={[styles.icon, { alignItems }]} color={theme.textDetail} size={ICON_SIZE} icon={['fal', 'message-dots']} />
                  )}
                  {ticket.countOfActivityTimes !== undefined && ticket.countOfActivityTimes > 0 && !timeRunning && (
                    <IM.Icon style={[styles.icon, { alignItems }]} color={theme.textDetail} size={ICON_SIZE} icon={['fal', 'stopwatch']} />
                  )}
                  {timeRunning && (
                    <IM.View style={styles.icon} onLayout={e => setWidth(e.nativeEvent.layout.width)}>
                      <Animated.View
                        style={[
                          styles.animation,
                          IMLayout.shadow,
                          // eslint-disable-next-line react-native/no-inline-styles
                          { backgroundColor: theme.general.info, right: isSmallDevice ? 6 : (width - styles.animation.width) / 2 },
                          animatedStyles,
                        ]}
                      />
                      <IM.Icon style={{ alignItems }} color={IMStyle.palette.white} size={ICON_SIZE} icon={['fal', 'stopwatch']} />
                    </IM.View>
                  )}
                </>
              )}
            </IM.View>
          )}
          {showEmployee && (
            <IM.View style={styles.employeeContainer}>
              <IM.View style={styles.employeeListContainer}>
                {ticket.activityEmployees.map(employee => {
                  return (
                    <IM.View key={employee.employeeId} style={styles.employeeBadgeContainer}>
                      <EmployeeBadge
                        key={employee.employeeId}
                        name={employee.employee}
                        id={employee.employeeId}
                        style={styles.badge}
                        blobPlaceholderColor={theme.card.background}
                      />
                    </IM.View>
                  )
                })}
              </IM.View>
            </IM.View>
          )}
        </IM.View>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  container: { alignItems: 'center' },
  animation: {
    top: -5,
    borderRadius: IMLayout.iconRadius,
    width: 34,
    height: 34,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  employeeContainer: {
    flex: 1,
    width: '100%',
    maxWidth: 155,
    alignItems: 'stretch',
  },
  employeeListContainer: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  employeeBadgeContainer: {
    alignItems: 'center',
    marginBottom: 6,
  },
  employeeBadgeOdd: {
    paddingRight: 8,
  },
  employeeBadgeEven: {
    paddingLeft: 8,
  },
  badge: { alignItems: 'center', paddingVertical: 4 },
  iconContainer: {
    marginBottom: 8,
    flexDirection: 'row',
    maxWidth: 155,
    width: '100%',
  },
  icon: { paddingHorizontal: 12 },
})
