import { IM, SpacingProps, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import ActivityComponent from '../../components/ActivityComponent'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import CardButton from '../../components/CardButton'
import Error from '../../components/Error'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import SkeletonCard from '../../components/skeleton/SkeletonCard'
import { REFRESH_TICKET_ACTIVITY_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_TICKET_ACTIVITY } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useLayout, { ForceLayoutType } from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import TicketActivityCreationModal from '../../modals/tickets/TicketActivityCreationModal'
import { TicketStackParamList } from '../../navigation/types'
import { ThemeColorExpanded, TicketCreateActivityParams } from '../../types'

interface Props {
  ticketId: string
  ticketCode: string
  spacing?: SpacingProps
  isPast?: boolean
  forceLayout: ForceLayoutType | undefined
}

const TicketDetailActivityCard = memo(function TicketDetailActivityCard({ spacing, ticketId, ticketCode, isPast, forceLayout }: Props) {
  const { i18n } = useLanguage()
  const { isOnline } = useDataProvider()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const controller = useModalController<TicketCreateActivityParams>()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  const { item: activities, loadItem: getActivities, loading } = useControlledLoader(api.getTicketActivity, { id: REQUEST_TICKET_ACTIVITY })

  useEvent({ key: REFRESH_TICKET_ACTIVITY_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [userSettings])

  const refresh = () => {
    if (userSettings) {
      getActivities({
        ticketId: ticketId,
        employeeId: isPast ? undefined : userSettings.showOnlyOwnActivitiesIntoTicketDetail ? userSettings.employeeId : undefined,
        state: isPast ? undefined : userSettings.showOnlyActivitiesWithStateEditingIntoTicketDetail ? 'Editing' : undefined,
      })
    }
  }

  const handleAdd = () => {
    if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
      navigation.navigate('TicketActivityCreation', { ticketId })
    } else {
      controller.show({ ticketId })
    }
  }

  const canAddActivity = isOnline && !isPast && userSettings?.allowCreationOfNewActivityForExistingTicket

  return (
    <>
      <IM.Card noContentSpacing head={<CardLeftTitle text={i18n.t('ACTIVITIES')} color={theme.general.info} />} spacing={spacing}>
        {loading === false ? (
          <>
            {activities?.length === 0 ? (
              <IM.View style={styles.container}>
                <IM.Text secondary>{isPast ? i18n.t('NO_ACTIVITY_FOUND') : i18n.t('NO_ACTIVITIES')}</IM.Text>
              </IM.View>
            ) : (
              <>
                {activities?.map((activity, index) => {
                  return (
                    <ActivityComponent
                      key={activity.id}
                      activity={activity}
                      index={index}
                      ticketCode={ticketCode}
                      isLast={index === activities.length - 1}
                      onlyRead={isPast}
                      disableLastSeparator={!canAddActivity}
                      forceLayout={forceLayout}
                    />
                  )
                })}
              </>
            )}
            {canAddActivity && (
              // eslint-disable-next-line react-native/no-inline-styles
              <IM.View style={{ margin: 12, marginTop: 6 }}>
                <CardButton onPress={handleAdd} disableSeparator />
              </IM.View>
            )}
          </>
        ) : (
          <IM.View style={styles.container}>{loading === 'catched' ? <Error /> : <SkeletonCard />}</IM.View>
        )}
      </IM.Card>
      <TicketActivityCreationModal controller={controller} />
    </>
  )
})

export default TicketDetailActivityCard

const styles = StyleSheet.create({
  container: {
    margin: 12,
  },
})
