import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { SectionList, SectionListRenderItemInfo, StyleProp, ViewStyle } from 'react-native'

import { Customer } from '../../apis/types/apiResponseTypes'
import CustomerCard from '../../cards/customer/CustomerCard'
import NoEntry from '../../components/NoEntry'
import { ListSection } from '../../types'
import customerUtils from '../../utils/customerUtils'

interface Props {
  lastUsedCustomers: Customer[]
  containerStyle?: StyleProp<ViewStyle>
  onPress: (is: string, blocked: boolean) => void
}

export default function CustomersLastUsedTabletView({ lastUsedCustomers, containerStyle, onPress }: Props) {
  const { i18n } = useLanguage()

  const renderSection = (title: string) => {
    return (
      <IM.View spacing="all">
        <IM.Text>{title}</IM.Text>
      </IM.View>
    )
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<Customer, ListSection<Customer>>) => {
    return (
      <IM.View spacing={['bottom', 'horizontal']}>
        <CustomerCard customer={item} onPress={() => onPress(item.id, customerUtils.isBlocked(item))} />
      </IM.View>
    )
  }

  const renderNoLastUsed = () => {
    return (
      <>
        {renderSection(i18n.t('LAST_USED'))}
        <NoEntry description={i18n.t('NO_CUSTOMER_FOUND')} icon={['fal', 'user-slash']} />
      </>
    )
  }

  const data: ListSection<Customer>[] = useMemo(() => {
    const displayData: ListSection<Customer>[] = []

    if (lastUsedCustomers.length) {
      displayData.push({
        title: i18n.t('LAST_USED'),
        data: lastUsedCustomers,
      })
    }

    return displayData
  }, [lastUsedCustomers, i18n])

  return (
    <IM.View style={containerStyle}>
      <SectionList
        sections={data}
        keyExtractor={item => item.id}
        renderItem={renderItem}
        renderSectionHeader={({ section }) => renderSection(section.title ?? '')}
        ListEmptyComponent={renderNoLastUsed}
        stickySectionHeadersEnabled={false}
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={{
          marginHorizontal: IMLayout.horizontalMargin,
        }}
      />
    </IM.View>
  )
}
