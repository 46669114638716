import { useContext } from 'react'

import ACSPasswordContext from '../contexts/ACSPasswordContext'

export default function useAcsPassword() {
  const context = useContext(ACSPasswordContext)

  if (context === undefined) {
    throw new Error('useAcsPassword() must be called inside AcsPasswordProvider')
  }

  return context
}
