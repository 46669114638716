import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import { useRoute } from '@react-navigation/native'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../../../../types'
import { StringContextText } from '../../types'

interface DefaultHeaderProps {
  title?: string
  subtitle?: StringContextText
}

const DefaultHeader = ({ title, subtitle }: DefaultHeaderProps) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { name } = useRoute()

  return (
    <IM.View style={styles.container}>
      <IM.Text style={[styles.contentText, styles.title]}>{title ?? i18n.t(`TAB_${name.toUpperCase()}`, { defaultValue: name })}</IM.Text>
      {subtitle && (
        <>
          {typeof subtitle === 'string' ? (
            <IM.Text style={[styles.contentText, styles.subtitle]}>{subtitle}</IM.Text>
          ) : (
            <>
              {subtitle.text !== '' && (
                <IM.View style={styles.subTitleContainer}>
                  <IM.Icon icon={subtitle.icon} color={IMStyle.palette.white} />
                  <IM.View spacing="left" />
                  <IM.Text
                    style={[
                      styles.subtitle,
                      { color: subtitle.text === undefined ? theme.header.main.text.secondary : theme.header.main.text.primary },
                    ]}>
                    {subtitle.text}
                  </IM.Text>
                </IM.View>
              )}
            </>
          )}
        </>
      )}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    flex: 1,
  },
  subTitleContainer: { flexDirection: 'row', alignItems: 'center' },
  contentText: {
    color: IMStyle.palette.white,
  },
  subtitle: {
    fontSize: IMStyle.typography.fontSizeSmall,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular,
  },
})

export default React.memo(DefaultHeader)
