import { IM, IMStyle, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { TicketArticle } from '../../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import MonitoringState from '../../../components/MonitoringState'
import TimeUtils from '../../../utils/TimeUtils'

interface Props {
  article: TicketArticle
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const TicketArticleMonitoringCard = memo(function TicketArticleMonitoringCard({ spacing, article, style }: Props) {
  const { i18n, language } = useLanguage()
  const date = useMemo(
    () => (article.serialnumberMonitoringLastDate ? TimeUtils.format(article.serialnumberMonitoringLastDate, language) : '-'),
    [article, language]
  )

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('MONITORING')} />} spacing={spacing} style={[styles.container, style]}>
      <IM.View style={styles.contentContainer}>
        <IM.Text style={{ fontSize: IMStyle.typography.fontSizeRegular, fontWeight: IMStyle.typography.fontWeightBold }}>
          {article.serialnumberMonitoring}
        </IM.Text>
        <MonitoringState state={article.serialnumberMonitoringState} spacing="vertical" />
        <IM.View spacing="top">
          <IM.Text>{`${i18n.t('LAST_READ')}: ${date}`}</IM.Text>
        </IM.View>
      </IM.View>
    </IM.Card>
  )
})

export default TicketArticleMonitoringCard

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  contentContainer: {
    marginHorizontal: 2,
  },
})
