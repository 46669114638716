import { IM, IMLayout, Spacing, SpacingProps, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import { Contract } from '../../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import CardText from '../../../components/CardText'
import ContractLoader from '../../../components/ContractLoader'
import DynamicView from '../../../components/Infominds/DynamicView'
import TextWithIcon from '../../../components/Infominds/TextWithIcon'
import { ThemeColorExpanded } from '../../../types'
import TimeUtils from '../../../utils/TimeUtils'

interface Props {
  contracts: Contract[]
  spacing?: SpacingProps
}

const TicketArticleContractCard = memo(function TicketArticleContractCard({ spacing, contracts }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { theme } = useTheme<ThemeColorExpanded>()

  const align = isSmallDevice ? 'flex-start' : 'flex-end'

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('CONTRACT')} />} spacing={spacing} style={styles.container}>
      <DynamicView separatorHorizontal separatorVertical style={styles.contentContainer}>
        {contracts.map((contract, index) => {
          const space: Spacing[] = []

          if (contracts.length > 1 && !isSmallDevice) space.push(index % 2 === 0 ? 'right' : 'left')

          return (
            <IM.View key={contract.id} style={IMLayout.flex.f6} spacing={space}>
              <DynamicView>
                <CardText
                  secondaryTitle={contract.code === undefined}
                  title={contract.code ?? ''}
                  secondary={contract.description === undefined && contract.contractType === undefined}
                  description={[contract.description, contract.contractType]}
                  style={IMLayout.flex.f8}
                />
                <IM.View style={[IMLayout.flex.f4, { alignItems: align }]}>
                  <TextWithIcon
                    icon={['fas', contract.isAutomaticRenewal ? 'circle-check' : 'circle-xmark']}
                    alignIcon="right"
                    iconColor={contract.isAutomaticRenewal ? theme.general.info : theme.general.error}
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={{
                      marginTop: isSmallDevice ? 2 : 0,
                      color: isSmallDevice ? theme.text : theme.textDetail,
                    }}>
                    {i18n.t('AUTOMATIC_RENEWAL')}
                  </TextWithIcon>
                </IM.View>
              </DynamicView>
              <IM.View style={{ marginTop: 2 * IMLayout.horizontalMargin }}>
                <IM.View spacing="bottom" style={styles.dateContainer}>
                  {contract.validFrom && <IM.Text secondary>{TimeUtils.format(contract.validFrom, language)}</IM.Text>}
                  {contract.validUntil && <IM.Text secondary>{TimeUtils.format(contract.validUntil, language)}</IM.Text>}
                </IM.View>
                <ContractLoader contract={contract} />
              </IM.View>
            </IM.View>
          )
        })}
      </DynamicView>
    </IM.Card>
  )
})

export default TicketArticleContractCard

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  contentContainer: {
    marginHorizontal: 3,
  },
  dateContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
