import { IM, useDimensions, useEvent, useLanguage, useModalController } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useMemo, useState } from 'react'
import { Animated, Platform, SectionListRenderItemInfo } from 'react-native'
import { useSetRecoilState } from 'recoil'

import { getApi } from '../../apis/apiCalls'
import { AccessData } from '../../apis/types/apiResponseTypes'
import AccessDataCard from '../../cards/accessData/AccessDataCard'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import NoEntry from '../../components/NoEntry'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import SkeletonAccessData from '../../components/skeleton/SkeletonAccessData'
import { ADD_ACCESS_DATA_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { EDIT_ACCESS_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_ACCESS_DATA } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useAccessDataFilter from '../../hooks/useAccessDataFilter'
import useAcsPassword from '../../hooks/useAcsPassword'
import useUserSettings from '../../hooks/useUserSettings'
import useVault from '../../hooks/useVault'
import AccessDataEditOrCreateModal from '../../modals/accessData/AccessDataEditOrCreateModal'
import VaultModal from '../../modals/VaultModal'
import { AccessDataStackParamList } from '../../navigation/types'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import { accessDataFilterEnableAtom } from '../../utils/stateManager'

interface Props {
  customerId: string
  buttonAnimationValue: Animated.Value
  onEdit: (data: AccessData, customerId: string) => void
  onCreate: (customerId: string) => void
}

export default function AccessDataListView({ customerId, buttonAnimationValue, onEdit, onCreate }: Props) {
  const { search } = useSearch()
  const { i18n } = useLanguage()
  const { sessionId } = useVault()
  const navigation = useNavigation()
  const { isOnline, client } = useDataProvider()
  const { isSmallDevice } = useDimensions()
  const { userSettings } = useUserSettings()
  const tabletNavigation = useMasterDetail()
  const vaultModalController = useModalController()
  const { setPassword } = useAcsPassword()
  const { filters, initFilters, permissions } = useAccessDataFilter()
  const modalController = useModalController<AccessDataStackParamList['AccessDataEditOrCreation']>()
  const enableFilter = useSetRecoilState(accessDataFilterEnableAtom)

  const [show, setShow] = useState(false)

  const {
    item: response,
    loadItem: loadAccessData,
    loading,
  } = useControlledLoader(getApi(client).getAccessData, {
    id: REQUEST_ACCESS_DATA,
    onResult: result => {
      if (result.vaultState === 'SessionClosed') {
        vaultModalController.show()
        return 'vault-locked'
      } else {
        result.data?.forEach(el => {
          if (el.accessdatagroup === 'SW-USER' && el.user && el.user.toLowerCase() === '#acsservices') {
            getApi(client)
              .getPassword({ id: el.id, VaultSessionId: sessionId })
              .then(val => {
                val.data && setPassword(val.data)
              })
              .catch(err => console.error('Failed fetching acs psw', err))
          }
        })
        return 'vault-unlocked'
      }
    },
  })

  const hasAccess = userSettings?.isVaultActive ? userSettings?.hasVaultAccess : true

  useEvent<string>({ key: EDIT_ACCESS_DATA_EVENT_KEY }, id => {
    refresh(id)
  })

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (isOnline) {
      loading === 'catched' && response === undefined && refresh()
      setShow(loading === false)
    } else {
      setShow(false)
    }
  }, [isOnline, loading])

  useEffect(() => {
    if (response !== undefined && response.data && response.data.length !== 0 && loading === false) {
      initFilters(appUtils.getAccessDataGroups(response.data))
    }
  }, [response, loading])

  useEffect(() => {
    enableFilter(loading === false)
  }, [loading])

  const refresh = (id?: string) => {
    setPassword(undefined)
    tabletNavigation.setDetailParams(undefined)
    hasAccess && loadAccessData({ VaultSessionId: id ?? sessionId, customerId })
  }

  const renderItem = ({ item }: SectionListRenderItemInfo<AccessData, ListSection<AccessData>>) => {
    return (
      <IM.View spacing={['bottom', 'horizontal']}>
        <AccessDataCard
          data={item}
          onEdit={() => {
            if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
              onEdit(item, customerId)
            } else {
              modalController.show({ accessData: item, customerId })
            }
          }}
          onTabletPress={(levelAccess, groupAccess, color) =>
            tabletNavigation.navigate('AccessDataDetail', {
              accessData: item,
              levelAccessGranted: levelAccess,
              groupAccessGranted: groupAccess,
              color: color,
            })
          }
        />
      </IM.View>
    )
  }

  const data: ListSection<AccessData>[] = useMemo(() => {
    const displayData: ListSection<AccessData>[] = []

    if (response && response.data && response.data.length && loading === false) {
      let filteredData = appUtils.filter(response.data, search, [
        'email',
        'user',
        'lastModificationUser',
        'note',
        'note2',
        'noteWithAccesslevel',
        'serialnumber',
        'url',
        'accessdatagroup',
      ])

      const activeFiltersId: string[] = []

      filters.forEach(filter => {
        if (filter.active) activeFiltersId.push(filter.data.id)
      })

      filteredData = filteredData.filter(elem => (activeFiltersId.length !== 0 ? activeFiltersId.find(id => id === elem.accessdatagroupId) : true))

      const activePermission = permissions.filter(elem => elem.active)
      if (activePermission.length === 1) {
        filteredData = filteredData.filter(elem =>
          activePermission[0].data === 'granted'
            ? elem.userAccesslevel >= elem.accesslevel && !elem.accessdatagroupBlocked
            : elem.userAccesslevel < elem.accesslevel || elem.accessdatagroupBlocked
        )
      }

      filteredData.length > 0 && displayData.push(...appUtils.group(filteredData, 'accessdatagroup', i18n.t('WITHOUT_GROUP')))
    }

    return displayData
  }, [response, loading, i18n, search, filters, permissions])

  return (
    <>
      {hasAccess ? (
        <>
          <SectionList
            loading={loading}
            skeletonElements={CONSTANTS.accessDataSkeletonCards}
            sections={data}
            renderItem={renderItem}
            noDataMessage={i18n.t('NO_ACCESS_DATA_FOUND')}
            loadingSection={i18n.t('ACCESS_DATA')}
            noDataSection={i18n.t('ACCESS_DATA')}
            onRefresh={refresh}
            skeletonComponent={<SkeletonAccessData />}
            hideButtonId={ADD_ACCESS_DATA_BUTTON_ID}
            closeEndThreshold={80}
          />
          {show && (
            <AnimatedButton
              id={ADD_ACCESS_DATA_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'plus']}
              onPress={() => {
                if (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')) {
                  onCreate(customerId)
                } else {
                  modalController.show({ customerId })
                }
              }}
            />
          )}
          <VaultModal controller={vaultModalController} onAccept={refresh} onHardwareBackPress={navigation.goBack} />
          <AccessDataEditOrCreateModal controller={modalController} />
        </>
      ) : (
        <NoEntry description={i18n.t('VAULT_NO_ACCESS')} icon={['fal', 'lock']} iconSize={23} />
      )}
    </>
  )
}
