import { IM, SwitchProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { AnimatedStyle } from 'react-native-reanimated'

import { ThemeColorExpanded } from '../types'
import LoadingIcon from './LoadingIcon'

interface Props extends Omit<SwitchProps, 'onColor'> {
  loading?: boolean
  loadingStyle?: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>
  style?: StyleProp<ViewStyle>
}

export default function Switch({ loading, loadingStyle, style, ...others }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const opacity = others.disabled ? 0.4 : 1

  return (
    <IM.View style={[style, { opacity }]}>
      {loading ? <LoadingIcon size={20} color={theme.general.info} style={loadingStyle} /> : <IM.Switch {...others} onColor={theme.general.info} />}
    </IM.View>
  )
}
