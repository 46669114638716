import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../../types'
import PressableIcon, { PressableIconProps } from '../Infominds/PressableIcon'
import useScreen from './hooks/useScreen'
import { DetailHeaderType } from './types'

interface Props {
  type: DetailHeaderType
  changesView?: boolean
  highlight?: boolean
}

const HeaderIcon = ({ type, highlight, ...props }: PressableIconProps & Props) => {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { detail, setDetailOpen } = useScreen()

  return (
    <IM.View
      style={
        detail.open &&
        detail.type === type && {
          backgroundColor: theme.header.detail.background,
          borderRadius: IMLayout.iconRadius,
        }
      }>
      <PressableIcon
        color={highlight ? theme.general.info : IMStyle.palette.white}
        onPress={() => setDetailOpen(type)}
        hitSlop={{ top: 10, bottom: 8 }}
        {...props}
      />
    </IM.View>
  )
}

export default HeaderIcon
