import { IM, useAlert, useEvent, useLanguage, Utils } from '@infominds/react-native-components'
import isEqual from 'lodash/isEqual'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { Destination } from '../../apis/types/apiResponseTypes'
import DeleteButton from '../../components/DeleteButton'
import useRequest from '../../components/Infominds/hooks/useRequest'
import BooleanInput from '../../components/input/BooleanInput'
import EmailInputGroup from '../../components/input/EmailInputGroup'
import PhoneInputGroup from '../../components/input/PhoneInputGroup'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import CountrySelector from '../../components/selectors/CountrySelector'
import { EDIT_DESTINATION_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import useForm from '../../hooks/useForm'
import { EditOrCreateViewProps, EditOrCreateViewRef, UploadStatus } from '../../types'
import appUtils from '../../utils/appUtils'
import customerUtils from '../../utils/customerUtils'

type Edit = {
  mode: 'edit'
  destination: Destination
  customerId?: never
}

type Creation = {
  mode: 'creation'
  destination?: never
  customerId: string
}

type Props = (Edit | Creation) & EditOrCreateViewProps

const DestinationEditOrCreateView = ({ mode, destination, disabled, customerId, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { request: create, loading: loadingCreate } = useRequest(api.createCustomerDestinations)
  const { request: edit, loading: loadingEdit } = useRequest(api.editCustomerDestinations)
  const { request: deleteData, loading: deleting } = useRequest(api.deleteCustomerDestinations)

  const initialData = useRef(destination)
  const equalData = useRef(false)

  const alert = useAlert()
  const { error } = useForm()
  const { i18n } = useLanguage()

  const [state, setState] = useState<Destination | undefined>(destination)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')
  const [deleteRequested, setDeleteRequested] = useState(false)

  const refreshContact = useEvent({ key: EDIT_DESTINATION_DATA_EVENT_KEY })

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'
    let equal = error

    if (!error) {
      equalData.current = isEqual(initialData.current, state)

      if (mode === 'edit') {
        equal = equalData.current
      } else {
        equal = state === undefined || JSON.stringify(state) === '{}'
      }

      if (equal === false && (state?.description === undefined || state?.country === undefined)) {
        updateUploadStatus('mandatoryMissing')
        return
      }
    }

    if (!equal) {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state, error])

  useEffect(() => {
    if (mode === 'edit' ? loadingEdit === 'catched' || deleting === 'catched' : loadingCreate === 'catched') {
      updateUploadStatus('waiting')

      return
    }

    const loadingDone = mode === 'edit' ? loadingEdit === false && deleting === false : loadingCreate === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      refreshContact.emit()
    }
  }, [loadingCreate, loadingEdit, deleting, deleteRequested])

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const handleDeleteDestination = () => {
    alert.alert(
      i18n.t('DELETE_DESTINATION'),
      Utils.stringValueReplacer(i18n.t('DELETE_DESCRIPTION'), customerUtils.formatAddress(initialData.current, 'street')),
      [
        {
          text: i18n.t('DELETE'),
          onPress: () => {
            setDeleteRequested(true)
            updateUploadStatus('uploading')
            // @ts-ignore improve data provider ts
            deleteData({ id: initialData.current.id })
          },
          style: 'destructive',
        },
        {
          text: i18n.t('CANCEL'),
          onPress: () => {
            return
          },
          style: 'cancel',
        },
      ]
    )
  }

  const upload = () => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    if (mode === 'edit') {
      edit(state)
    } else {
      create({
        ...state,
        customerId: customerId,
        inactive: Object.keys(state).find(key => key === 'inactive') === undefined ? false : true,
      })
    }
  }

  const handleChangeText = (newVal: Partial<Destination>) => {
    if (waitingUpload === 'uploading') return
    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: !disabled,
  }

  return (
    <ScrollViewForm>
      {((disabled && state?.description !== undefined) || !disabled) && (
        <TextInput
          title={`${i18n.t('DESCRIPTION')} *`}
          value={state?.description ?? ''}
          onChangeText={text => handleChangeText({ description: text === '' ? undefined : text })}
          {...commonProps}
        />
      )}
      {((disabled && state?.street !== undefined) || !disabled) && (
        <TextInput
          title={i18n.t('ADDRESS_STREET')}
          value={state?.street ?? ''}
          onChangeText={text => handleChangeText({ street: text === '' ? undefined : text })}
          type="address"
          multiline
          fixMultilineHeight
          {...commonProps}
        />
      )}
      {((disabled && state?.streetnumber !== undefined) || !disabled) && (
        <TextInput
          title={i18n.t('ADDRESS_NUMBER')}
          value={state?.streetnumber ?? ''}
          onChangeText={text => handleChangeText({ streetnumber: text === '' ? undefined : text })}
          type="number"
          multiline
          fixMultilineHeight
          {...commonProps}
        />
      )}
      {((disabled && state?.postalCode !== undefined) || !disabled) && (
        <TextInput
          title={i18n.t('POSTAL_CODE')}
          value={state?.postalCode ?? ''}
          type="postal-code"
          onChangeText={text => handleChangeText({ postalCode: text === '' ? undefined : text })}
          multiline
          fixMultilineHeight
          {...commonProps}
        />
      )}
      {((disabled && state?.town !== undefined) || !disabled) && (
        <TextInput
          title={i18n.t('ADDRESS_TOWN')}
          type="town"
          value={state?.town ?? ''}
          onChangeText={text => handleChangeText({ town: text === '' ? undefined : text })}
          {...commonProps}
        />
      )}
      {((disabled && state?.province !== undefined) || !disabled) && (
        <TextInput
          title={i18n.t('ADDRESS_PROVINCE')}
          type="province"
          value={state?.province ?? ''}
          onChangeText={text => handleChangeText({ province: text === '' ? undefined : text })}
          {...commonProps}
        />
      )}
      {((disabled && state?.country !== undefined) || !disabled) && (
        <CountrySelector
          mandatory
          countryCode={state?.country}
          onChange={text => handleChangeText({ country: text === undefined ? undefined : text.code })}
          {...commonProps}
        />
      )}
      <PhoneInputGroup
        title={i18n.t('PHONE')}
        addValueText={i18n.t('ADD_PHONE_NUMBER')}
        values={state?.phone}
        handleChange={newVal => handleChangeText({ phone: newVal })}
        editable={!disabled}
        disableFastAction={state?.inactive}
        onActionPress={
          disabled
            ? id => {
                const selected = state?.phone?.at(id)
                selected && appUtils.openPhone(selected.number, alert, i18n)
              }
            : undefined
        }
        getTypeRequest={api.getPhoneTypes}
      />
      <EmailInputGroup
        title={i18n.t('EMAIL')}
        addValueText={i18n.t('ADD_EMAIL_ADDRESS')}
        values={state?.email}
        handleChange={newVal => handleChangeText({ email: newVal })}
        editable={!disabled}
        disableFastAction={state?.inactive}
        onActionPress={
          disabled
            ? id => {
                const selected = state?.email?.at(id)
                selected && appUtils.openEmail(alert, i18n.t('OPEN_EMAIL_ERROR'), selected.address)
              }
            : undefined
        }
        getTypeRequest={api.getEmailTypes}
      />
      <PhoneInputGroup
        title={i18n.t('FAX')}
        addValueText={i18n.t('ADD_FAX_NUMBER')}
        values={state?.fax}
        handleChange={newVal => handleChangeText({ fax: newVal })}
        editable={!disabled}
        getTypeRequest={api.getFaxTypes}
      />
      <BooleanInput
        title={i18n.t('INACTIVE')}
        value={state?.inactive ?? false}
        onValueChange={() => handleChangeText({ inactive: !state?.inactive })}
        disabled={disabled}
        spacing={commonProps.spacing}
      />

      {mode === 'edit' && !disabled && (
        <IM.View {...commonProps}>
          <DeleteButton title={i18n.t('DELETE_DESTINATION')} onPress={handleDeleteDestination} />
        </IM.View>
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(DestinationEditOrCreateView)
