import { IM, IMLayout, SpacingProps, useDimensions, useLanguage } from '@infominds/react-native-components'
import React, { memo, useEffect } from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import api from '../../../apis/apiCalls'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import CellText from '../../../components/CellText'
import DynamicView from '../../../components/Infominds/DynamicView'
import useControlledLoader from '../../../components/Infominds/hooks/useControlledLoader'
import Separator from '../../../components/Infominds/Separator'
import SkeletonText from '../../../components/skeleton/SkeletonText'
import { REQUEST_SERIAL_NUMBER_ACCESSORIES } from '../../../constants/Keys'
import TimeUtils from '../../../utils/TimeUtils'

interface Props {
  serialNumberId: string
  spacing?: SpacingProps
  style?: StyleProp<ViewStyle>
}

const firstColumn = IMLayout.flex.f2
const secondColumn = IMLayout.flex.f2
const thirdColumn = IMLayout.flex.f2
const fourthColumn = IMLayout.flex.f3
const fifthColumn = IMLayout.flex.f2
const sixthColumn = IMLayout.flex.f1

const TicketArticleAccessoryCard = memo(function TicketArticleAccessoryCard({ serialNumberId, spacing, style }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()

  const { item: accessories, loadItem, loading } = useControlledLoader(api.getSerialNumberAccessories, { id: REQUEST_SERIAL_NUMBER_ACCESSORIES })

  useEffect(() => {
    loadItem({ serialnumberId: serialNumberId })
  }, [serialNumberId])

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('ACCESSORIES')} />} spacing={spacing} style={[styles.container, style]} noContentSpacing>
      {/* eslint-disable-next-line react-native/no-inline-styles */}
      <IM.View style={{ margin: isSmallDevice || accessories?.length === 0 ? 6 : 10 }}>
        {loading === false && accessories !== undefined && accessories.length !== 0 ? (
          <>
            {!isSmallDevice && (
              <IM.View>
                <DynamicView style={[IMLayout.flex.f1, styles.headerContainer]}>
                  <CellText style={firstColumn} secondary numberOfLines={1} value={i18n.t('ARTICLE_CODE')} />
                  <CellText style={secondColumn} secondary numberOfLines={1} value={i18n.t('SERIAL_NUMBER')} />
                  <CellText style={thirdColumn} secondary numberOfLines={1} value={i18n.t('INSTALLATION_DATE')} />
                  <CellText style={fourthColumn} secondary numberOfLines={1} value={i18n.t('DESCRIPTION')} />
                  <CellText style={fifthColumn} secondary numberOfLines={1} value={i18n.t('MODEL')} />
                  <CellText style={sixthColumn} secondary numberOfLines={1} value={i18n.t('QUANTITY')} />
                </DynamicView>
                <Separator spacing="none" type="primary" />
              </IM.View>
            )}
            {accessories.map((data, index) => (
              <IM.View key={data.id}>
                <DynamicView style={[IMLayout.flex.f1, styles.row]} flexLimitSmallDevice={2}>
                  <CellText title={i18n.t('ARTICLE_CODE')} value={data.article} disableSpacing style={firstColumn} />
                  <CellText title={i18n.t('SERIAL_NUMBER')} value={data.serialnumber} style={secondColumn} />
                  <CellText title={i18n.t('INSTALLATION_DATE')} value={TimeUtils.format(data.installationDate, language)} style={thirdColumn} />
                  <CellText
                    title={i18n.t('DESCRIPTION')}
                    value={data.description}
                    style={fourthColumn}
                    numberOfLines={Platform.OS === 'web' ? undefined : 4}
                  />
                  <CellText title={i18n.t('MODEL')} value={data.model ?? '-'} style={fifthColumn} />
                  <CellText title={i18n.t('QUANTITY')} value={data.quantity} style={sixthColumn} />
                </DynamicView>
                {index !== accessories.length - 1 && <Separator spacing="none" />}
              </IM.View>
            ))}
          </>
        ) : (
          <IM.View spacing={isSmallDevice ? 'horizontal' : 'none'}>
            {accessories === undefined ? (
              <>
                {isSmallDevice ? (
                  <>
                    <SkeletonText width="100%" spacing="top" />
                    <SkeletonText width="100%" spacing="top" />
                    <SkeletonText width="100%" spacing="top" />
                    <SkeletonText width="100%" spacing="top" />
                    <SkeletonText width="100%" spacing="top" />
                    <SkeletonText width="100%" spacing="vertical" />
                  </>
                ) : (
                  <>
                    <SkeletonText width="100%" spacing="top" />
                    <Separator spacing="bottom" />
                    <SkeletonText width="100%" spacing="bottom" />
                  </>
                )}
              </>
            ) : (
              <IM.View spacing="vertical">
                <IM.Text secondary>{i18n.t('NO_ACCESSORY_FOUND')}</IM.Text>
              </IM.View>
            )}
          </IM.View>
        )}
      </IM.View>
    </IM.Card>
  )
})

export default TicketArticleAccessoryCard

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
  },
  row: {
    padding: 8,
  },
  headerContainer: { paddingHorizontal: 8, marginBottom: 2 },
})
