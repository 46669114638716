import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import TicketAddSerialNumberScreen from '../../screens/tickets/TicketAddSerialNumberScreen'
import { ModalScreenRef, TicketAddSNParams } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<TicketAddSNParams>
}

export default function TicketAddSerialNumberModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      {controller.data && (
        <TicketAddSerialNumberScreen
          ref={ref}
          controller={controller}
          route={{
            key: '',
            name: 'TicketAddSerialNumber',
            params: controller.data,
          }}
        />
      )}
    </BaseServiceModal>
  )
}
