import React, { createContext, ReactNode, useEffect, useMemo } from 'react'
import { StyleProp, ViewStyle } from 'react-native'
import { AnimatedStyle, cancelAnimation, Easing, useAnimatedStyle, useSharedValue, withRepeat, withTiming } from 'react-native-reanimated'

export interface VaultContextProps {
  animatedStyles: StyleProp<AnimatedStyle<StyleProp<ViewStyle>>>
}

const AnimationContext = createContext<VaultContextProps | undefined>(undefined)

export const AnimationProvider = ({ children }: { children: ReactNode }) => {
  const rotation = useSharedValue(0)

  useEffect(() => {
    rotation.value = withRepeat(
      withTiming(360, {
        duration: 800,
        easing: Easing.linear,
      }),
      0
    )
    return () => cancelAnimation(rotation)
  }, [])

  const animatedStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          rotateZ: `${rotation.value}deg`,
        },
      ],
    }
  }, [rotation.value])

  const props = useMemo<VaultContextProps>(() => ({ animatedStyles }), [animatedStyles])

  return <AnimationContext.Provider value={props}>{children}</AnimationContext.Provider>
}

export default AnimationContext
