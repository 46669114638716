import { ScannerView } from '@infominds/react-native-barcode-scanner'
import { IM, useDimensions, useEvent, useLanguage } from '@infominds/react-native-components'
import { LicenseLibEventKeys } from '@infominds/react-native-license'
import React from 'react'
import { Platform, StatusBar, StyleSheet } from 'react-native'
import { OrientationType } from 'react-native-orientation-locker'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { BARCODE_EVENT_KEY } from '../../constants/EmitterKeys'
import { AppRootStackScreenProps } from '../../navigation/types'

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'black',
  },
})

export type BarcodeEvent = {
  barcode: string
  id: string
}

const ScannerCameraScreen = ({ navigation, route }: AppRootStackScreenProps<'Scanner'>) => {
  const { language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { top, bottom } = useSafeAreaInsets()

  const { emit } = useEvent<BarcodeEvent>({ key: BARCODE_EVENT_KEY })
  const { emit: emitLoginCode } = useEvent<string>({ key: LicenseLibEventKeys.CODE_SCANNED })

  return (
    <IM.View
      style={[
        {
          marginTop: Platform.OS === 'ios' ? undefined : top,
          marginBottom: Platform.OS === 'ios' ? undefined : bottom,
        },
        styles.container,
      ]}>
      <StatusBar backgroundColor="#000000" barStyle="dark-content" translucent />
      <ScannerView
        // disableOrientationLock={Platform.OS === 'ios'}
        onClose={navigation.goBack}
        languageCode={language}
        onScanned={result => {
          route.params.id !== undefined ? emit({ barcode: result[0], id: route.params.id }) : emitLoginCode(result[0])
          navigation.goBack()
        }}
        scannerPostCloseOrientation={isSmallDevice ? OrientationType.PORTRAIT : undefined}
      />
    </IM.View>
  )
}

export default ScannerCameraScreen
