import { IM, IMLayout, IMStyle, SpacingProps, useDimensions, useLanguage } from '@infominds/react-native-components'
import React, { memo, useMemo } from 'react'
import { Image, StyleSheet } from 'react-native'

import { TicketArticle } from '../../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../../components/card/left/CardLeftTitle'
import CardText from '../../../components/CardText'
import DynamicView from '../../../components/Infominds/DynamicView'
import CONSTANTS from '../../../constants/Constants'
import TimeUtils from '../../../utils/TimeUtils'

interface Props {
  article: TicketArticle
  spacing?: SpacingProps
}

const TicketArticleInfoCard = memo(function TicketArticleInfoCard({ spacing, article }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()

  const date = useMemo(
    () => (article.serialnumberOutgoingDate ? TimeUtils.format(article.serialnumberOutgoingDate, language) : '-'),
    [article, language]
  )

  const image = () => (
    <>
      {article.thumbImageAsPNG && (
        <IM.View style={[!isSmallDevice && styles.marginRight]} spacing={isSmallDevice ? 'bottom' : 'none'} spacingType="margin">
          <Image source={{ uri: `data:image/png;base64,${article.thumbImageAsPNG}` }} style={styles.image} />
        </IM.View>
      )}
    </>
  )

  return (
    <IM.Card head={<CardLeftTitle text={i18n.t('INFORMATION')} />} spacing={spacing}>
      <IM.View style={!isSmallDevice && IMLayout.flex.row}>
        {image()}
        <DynamicView style={[IMLayout.flex.f1, styles.item]} flexLimitSmallDevice={4} flexLimitLargeDevice={6}>
          <CardText
            title={i18n.t('SN_MANUFACTURER')}
            description={article.serialnumberNumberManufactor ?? '-'}
            secondary={article.serialnumberNumberManufactor === undefined}
            style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginRight]}
            spacing="bottom"
          />
          <CardText
            title={i18n.t('ARTICLE')}
            description={article.articleCode}
            style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginLeft]}
            spacing="bottom"
          />
          <CardText
            title={i18n.t('MODEL')}
            description={article.articleModel ?? '-'}
            secondary={article.articleModel === undefined}
            style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginRight]}
            spacing="bottom"
          />
          <CardText
            title={i18n.t('LOCATION')}
            description={article.serialnumberLocation ?? '-'}
            secondary={article.serialnumberLocation === undefined}
            style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginLeft]}
            spacing="bottom"
          />
          <CardText
            title={i18n.t('OUT_GOING')}
            description={date}
            secondary={date === undefined}
            style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginRight]}
            spacing="bottom"
          />
          <IM.View style={[IMLayout.flex.f2, !isSmallDevice && styles.marginHorizontal, isSmallDevice && styles.marginLeft]} />
          <CardText
            title={i18n.t('DESCRIPTION')}
            description={article.articleDescription ?? '-'}
            style={[IMLayout.flex.f12, !isSmallDevice && styles.marginLeft]}
          />
        </DynamicView>
      </IM.View>
    </IM.Card>
  )
})

export default TicketArticleInfoCard

const styles = StyleSheet.create({
  container: { flexDirection: 'row', flex: 1 },
  image: {
    width: 90,
    height: 90,
    resizeMode: 'contain',
    borderRadius: IMLayout.borderRadius,
  },
  item: { justifyContent: 'center' },
  titleContainer: { flexDirection: 'row', alignItems: 'center' },
  description: {
    flex: 1,
    fontWeight: IMStyle.typography.fontWeightMedium,
    fontSize: IMStyle.typography.fontSizeRegular,
  },
  marginHorizontal: {
    marginHorizontal: CONSTANTS.margin,
  },
  marginRight: { marginRight: CONSTANTS.margin },
  marginLeft: { marginLeft: CONSTANTS.margin },
})
