import { useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import React, { createRef, memo, useEffect, useState } from 'react'

import { getApi } from '../../apis/apiCalls'
import { REQUEST_GENERATE_PASSWORD_DEFAULTS } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useVault from '../../hooks/useVault'
import GeneratePasswordModal, { GeneratePasswordModalData } from '../../modals/passwords/GeneratePasswordModal'
import VaultModal from '../../modals/VaultModal'
import { TextInputRef, ThemeColorExpanded } from '../../types'
import FormButton from '../FormButton'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import PressableIcon from '../Infominds/PressableIcon'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputSpinningIcon from './baseTextInput/BaseTextInputSpinningIcon'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

type Props = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    details?: string
    showPasswordGeneration?: boolean
    focusOnMount?: boolean
  }

const PasswordInput = memo(function PasswordInput({
  title,
  details,
  editable,
  error,
  disableFocus,
  loading,
  showPasswordGeneration = false,
  focusOnMount = false,
  ...textInputProps
}: Props) {
  const { i18n } = useLanguage()
  const { sessionId } = useVault()
  const { theme } = useTheme<ThemeColorExpanded>()
  const vaultModalController = useModalController()
  const generatePswController = useModalController<GeneratePasswordModalData>()
  const { client } = useDataProvider()
  const textRef = createRef<TextInputRef>()

  const [visible, setVisible] = useState(false)

  const {
    item: passwordDefaults,
    loadItem: getPasswordDefaults,
    loading: loadingPasswordDefaults,
  } = useControlledLoader(getApi(client).getPasswordGenerationDefaults, {
    id: REQUEST_GENERATE_PASSWORD_DEFAULTS,
    onResult: result => {
      if (result.vaultState === 'SessionClosed') {
        vaultModalController.show()
        return 'vault-locked'
      } else {
        return 'vault-unlocked'
      }
    },
  })

  useEffect(() => {
    getDefaults()
  }, [])

  useEffect(() => {
    focusOnMount && textRef.current?.focus()
  }, [focusOnMount])

  const getDefaults = (session?: string) => showPasswordGeneration && getPasswordDefaults({ VaultSessionId: session ?? sessionId })

  return (
    <>
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput {...textInputProps} ref={textRef} secureTextEntry={!visible}>
          <BaseTextInput.Title title={title} details={details} />
          <BaseTextInput.RightIcon>
            {loading && <BaseTextInputSpinningIcon loading={loading} />}

            {!loading && (
              <PressableIcon
                icon={['fal', visible ? 'eye-slash' : 'eye']}
                size={18}
                color={theme.textDetail}
                onPress={() => setVisible(prev => !prev)}
              />
            )}
          </BaseTextInput.RightIcon>
        </BaseTextInput>
      </BaseTextInputProvider>
      {showPasswordGeneration && (
        <>
          <FormButton
            title={i18n.t('GENERATE_PASSWORD')}
            onPress={() =>
              passwordDefaults &&
              passwordDefaults.data &&
              passwordDefaults.data.passwordComplexity &&
              passwordDefaults.data.length &&
              generatePswController.show({ complexity: passwordDefaults.data.passwordComplexity, length: passwordDefaults.data.length })
            }
            disabled={loadingPasswordDefaults !== false}
            color={theme.general.info}
            spacing="bottom"
          />
          <GeneratePasswordModal controller={generatePswController} onAccept={psw => textInputProps.onChangeText?.(psw)} />
          <VaultModal controller={vaultModalController} onAccept={getDefaults} onHardwareBackPress={generatePswController.close} />
        </>
      )}
    </>
  )
})

export default PasswordInput
