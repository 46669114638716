import { IM, IMLayout, SpacingProps, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { TicketArticle } from '../../apis/types/apiResponseTypes'
import TicketArticleCard from '../../cards/common/TicketArticleCard'
import { InfiniteLoadingType, ListSection } from '../../types'
import ticketUtils from '../../utils/TicketUtils'
import SelectInput from './selectInput/SelectInput'

interface Props {
  value: TicketArticle | undefined
  articles: TicketArticle[] | undefined
  editable?: boolean
  spacing?: SpacingProps
  loading: InfiniteLoadingType
  onChange: (value: TicketArticle | undefined) => void
}

export default function TicketArticleSelector({ articles, value, loading, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const [article, setArticle] = useState<TicketArticle | undefined>(undefined)
  const sortedArticle = articles ? cloneDeep(articles).sort((a, b) => a.articleSearchtext.localeCompare(b.articleSearchtext)) : []

  useEffect(() => {
    value !== undefined && article === undefined && handleOnChange(value)
  }, [value, article])

  const render = ({ item }: SectionListRenderItemInfo<TicketArticle, ListSection<TicketArticle>>, onPress?: () => void) => {
    const isSelected = value?.id === item.id
    const isLast = articles && articles.length > 0 && item.id === sortedArticle[sortedArticle.length - 1].id

    return (
      <IM.View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ marginBottom: isLast ? 2 * IMLayout.verticalMargin : 0 }}>
        <TicketArticleCard item={item} onPress={onPress} selected={isSelected} spacing={['horizontal', 'top']} />
      </IM.View>
    )
  }

  const handleOnChange = (newValue: TicketArticle | undefined) => {
    setArticle(newValue)
    onChange(newValue)
  }

  return (
    <SelectInput
      data={sortedArticle}
      value={article}
      loading={loading}
      title={i18n.t('SERIAL_NUMBER')}
      screenTitle={i18n.t('SERIAL_NUMBERS')}
      noDataMessage={i18n.t('NO_SN_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => ticketUtils.getArticleSn(item)}
      fastInputEntries={2}
      disableLoadAfterMount
      disableFastInputScrollView
      disableReset
      enableBackdropOpacity
      {...props}
    />
  )
}
