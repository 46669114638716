import {
  IM,
  IMLayout,
  IMStyle,
  useAlert,
  useEvent,
  useLanguage,
  useModalController,
  useOrientation,
  useTheme,
} from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { useEffect } from 'react'
import { Animated, Platform, StyleSheet, useWindowDimensions } from 'react-native'

import api from '../../apis/apiCalls'
import { Customer } from '../../apis/types/apiResponseTypes'
import ContactInformation from '../../components/ContactInformation'
import InfoboxButton from '../../components/InfoboxButton'
import AnimatedButton from '../../components/Infominds/AnimatedButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import Map from '../../components/map/Map'
import { SCREEN_CONSTANTS } from '../../components/screen/constants/constants'
import useSearch from '../../components/screen/hooks/useSearch'
import { ADD_CONTACT_DESTINATION_BUTTON_ID } from '../../constants/ButtonIds'
import CONSTANTS from '../../constants/Constants'
import { REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_INFOBOX_FILES } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useUserSettings from '../../hooks/useUserSettings'
import CreateContactOrDestinationModal from '../../modals/CreateContactOrDestinationModal'
import ContactEditOrCreateModal from '../../modals/customer/ContactEditOrCreateModal'
import DestinationEditOrCreateModal from '../../modals/customer/DestinationEditOrCreateModal'
import { CustomerStackParamList } from '../../navigation/types'
import { ContactEditOrCreateParams, DestinationEditOrCreateParams } from '../../types'
import { utils } from '../../utils/utils'
import ContactsView from './ContactsView'
import DestinationsView from './DestinationsView'

interface Props {
  customer: Customer
  buttonAnimationValue: Animated.Value
}

export type TabType = 'contacts' | 'destinations' | 'infobox'

const CustomerDetailsLargeDeviceView = ({ customer, buttonAnimationValue }: Props) => {
  const alert = useAlert()
  const { theme } = useTheme()
  const { i18n } = useLanguage()
  const { tabletOpen } = useSearch()
  const { landscape } = useOrientation()
  const { isOnline } = useDataProvider()
  const { height } = useWindowDimensions()
  const { userSettings } = useUserSettings()
  const creationChoiceModal = useModalController()
  const navigation = useNavigation<NavigationProp<CustomerStackParamList>>()
  const contactEditOrCreateModalController = useModalController<ContactEditOrCreateParams>()
  const destinationEditOrCreateModalController = useModalController<DestinationEditOrCreateParams>()

  const mapShown = !tabletOpen && customer.mapLat !== 0 && customer.mapLong !== 0
  const top = mapShown ? undefined : 0
  const fixedHeight = Math.floor(height * 0.6)
  const mapHeight = mapShown ? fixedHeight : '100%'

  const {
    item: files,
    loadItem: loadFiles,
    loading: loadingFiles,
  } = useControlledLoader(api.getInfoboxFiles, {
    id: REQUEST_INFOBOX_FILES,
  })

  useEvent({ key: REFRESH_INFOBOX_BUTTON_COUNTER_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => {
    loadFiles({ infoboxTyp: 'Customer', id: customer.id })
  }

  const contactsAndDestinations = () => (
    <IM.View style={[styles.row, IMLayout.flex.f1]}>
      <IM.View style={styles.list}>
        <ContactsView customerAddressId={customer.addressId} showTitle skeletonNumber={10} show onRefresh={refresh} />
      </IM.View>
      <IM.View style={styles.list}>
        <DestinationsView customerId={customer.id} showTitle skeletonNumber={10} show onRefresh={refresh} />
      </IM.View>
    </IM.View>
  )

  return (
    <>
      <IM.View style={IMLayout.flex.f1}>
        {customer.mapLat !== 0 && customer.mapLong !== 0 && (
          <Map
            items={[customer]}
            mapMarkerRenderItem={MapMarker}
            mapPadding={{ bottom: fixedHeight + 100, top: 0, left: 0, right: 0 }}
            hideMyLocationButton
            hideCenterButton
            disableMapTouch
            delta={0.002}
            onMapPress={() => utils.openMaps(customer.mapLat, customer.mapLong, alert, i18n.t('OPEN_MAP_LINK_ERROR'))}
            style={Platform.OS === 'web' && { marginBottom: fixedHeight }}
          />
        )}
        <IM.View style={[styles.screen, { height: mapHeight, top: top }]}>
          <IM.View style={[styles.screenBorder, IMLayout.shadow, IMLayout.flex.f1, { backgroundColor: theme.tabNavigator.background }]}>
            <ContactInformation flexLimitLargeDevice={landscape ? 18 : 6} customer={customer} />
            <IM.View
              style={[
                styles.screenBorder,
                IMLayout.shadow,
                IMLayout.flex.f1,
                {
                  backgroundColor: theme.background,
                },
              ]}>
              {contactsAndDestinations()}
            </IM.View>
          </IM.View>
        </IM.View>
      </IM.View>

      {isOnline && (
        <>
          <InfoboxButton
            id={ADD_CONTACT_DESTINATION_BUTTON_ID}
            value={buttonAnimationValue}
            loading={loadingFiles}
            onPress={() =>
              files &&
              navigation.navigate('CustomerDetailInfoboxStack', {
                screen: 'InfoboxFolders',
                params: { id: customer.id, type: 'Customer', subTitle: customer.description },
              })
            }
            text={files?.length.toString() ?? 'NaN'}
            bottom={CONSTANTS.secondButtonBottom}
          />
          {(userSettings?.allowContactCreation || userSettings?.allowShippingAddressCreation) && (
            <AnimatedButton
              id={ADD_CONTACT_DESTINATION_BUTTON_ID}
              value={buttonAnimationValue}
              icon={['fal', 'plus']}
              onPress={() => creationChoiceModal.show()}
            />
          )}
        </>
      )}

      <CreateContactOrDestinationModal
        controller={creationChoiceModal}
        onPress={value => {
          creationChoiceModal.close()

          if (value === 'contact') {
            contactEditOrCreateModalController.show({ addressId: customer.addressId })
          } else {
            destinationEditOrCreateModalController.show({ customerId: customer.id })
          }
        }}
      />
      <ContactEditOrCreateModal controller={contactEditOrCreateModalController} />
      <DestinationEditOrCreateModal controller={destinationEditOrCreateModalController} />
    </>
  )
}

function MapMarker() {
  return <IM.Icon icon={['fas', 'location-dot']} color={IMStyle.palette.red} size={30} />
}

const styles = StyleSheet.create({
  customerInfo: {
    position: 'absolute',
    top: 0,
    width: '93%',
    borderRadius: SCREEN_CONSTANTS.headerRadius - 10,
    marginTop: 18,
  },
  list: { width: '50%' },
  row: {
    flexDirection: 'row',
  },
  screen: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  screenBorder: {
    borderTopLeftRadius: SCREEN_CONSTANTS.headerRadius,
    borderTopRightRadius: SCREEN_CONSTANTS.headerRadius,
  },
})

export default CustomerDetailsLargeDeviceView
