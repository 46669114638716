import { IMStyle } from '@infominds/react-native-components'
import React, { memo } from 'react'

import Text from './Text'

interface Props {
  initials: string
  size?: number
}

const EmployeeBadgePlaceholderText = memo(function EmployeeBadge({ initials, size }: Props) {
  return (
    <Text
      style={{
        color: IMStyle.palette.white,
        fontSize: (size ?? 20) * 0.5,
      }}
      allowFontScaling={false}>
      {initials}
    </Text>
  )
})

export default EmployeeBadgePlaceholderText
