import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import ArticleDetailsAndGraphic from '../../components/ArticleDetailsAndGraphic'
import useMasterDetail from '../../components/MasterDetail/hooks/useMasterDetail'
import NoEntry from '../../components/NoEntry'
import ScrollViewData from '../../components/ScrollViewData'

export type SparePartsDetailsViewParams = {
  articleId: string
  graphicId?: string
}

export default function SparePartsDetailsView() {
  const { i18n } = useLanguage()
  const { detailParams } = useMasterDetail<SparePartsDetailsViewParams>()

  return (
    <ScrollViewData contentContainerStyle={styles.scrollView}>
      {detailParams ? (
        <IM.Card
          style={[
            {
              marginTop: 1.98 * IMLayout.horizontalMargin,
              marginBottom: IMLayout.horizontalMargin,
              marginHorizontal: 2 * IMLayout.horizontalMargin,
            },
          ]}>
          <ArticleDetailsAndGraphic articleId={detailParams.articleId} graphicId={detailParams.graphicId} />
        </IM.Card>
      ) : (
        <IM.View spacing="top" style={IMLayout.flex.f1}>
          <NoEntry description={i18n.t('NO_SPARE_PART_SELECTED')} />
        </IM.View>
      )}
    </ScrollViewData>
  )
}

const styles = StyleSheet.create({
  scrollView: {
    padding: 0,
  },
})
