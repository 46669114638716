import { IM, IMLayout, useLanguage } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import { REQUEST_CUSTOMER_CONTACTS } from '../../constants/Keys'
import EmployeeBadge from '../EmployeeBadge'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import Pressable from '../Infominds/Pressable'
import Text from '../Text'
import BaseInputSelector, { BaseInputSelectorProps } from './baseInputSelector/BaseInputSelector'

interface Props {
  addressId: string
  mandatory?: boolean
}

export default function ContactInputSelector({
  addressId,
  mandatory,
  ...others
}: Omit<BaseInputSelectorProps<Contact>, 'type' | 'onTestInput'> & Props) {
  const { i18n } = useLanguage()
  const {
    item: contacts,
    loadItem: loadContacts,
    loading: loadingContacts,
    setItem: resetContacts,
  } = useControlledLoader(api.getCustomerContacts, { id: REQUEST_CUSTOMER_CONTACTS })

  const search = (text: string | undefined) => loadContacts({ searchtext: text, addressId })

  const renderContact = (item: Contact, isFirst: boolean, onPressCallback: (id: string, value: string) => void) => {
    return (
      <Pressable
        key={item.id}
        style={[IMLayout.flex.row, styles.pressable]}
        spacing={isFirst ? 'none' : 'none'}
        onPress={() => onPressCallback(item.id, item.description)}>
        <EmployeeBadge showName={false} id={item.id} name={item.description} />
        <IM.View spacing="left">
          <Text>{item.description}</Text>
        </IM.View>
      </Pressable>
    )
  }

  return (
    <BaseInputSelector
      {...others}
      title={i18n.t('CONTACT_PERSON') + (mandatory ? ' *' : '')}
      searchLoading={loadingContacts}
      searchResult={contacts}
      onSearchStart={text => search(text)}
      onResetSearch={() => resetContacts(undefined)}
      renderSearchItem={renderContact}
      maxItems={1}
      popUpPosition="top"
    />
  )
}

const styles = StyleSheet.create({
  pressable: { alignItems: 'center', justifyContent: 'flex-start' },
})
