import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../types'

export default function IndicatorBottom() {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        backgroundColor: theme.timeline.background,
        width: 10,
        height: 10,
        borderRadius: 50,
        position: 'absolute',
        left: 43,
      }}
    />
  )
}
