import { IM, SpacingProps, SpacingType } from '@infominds/react-native-components'
import React, { PropsWithChildren } from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import DefaultCardHeader from '../headers/DefaultCardHeader'

export interface CardHeaderContainerProps {
  style?: StyleProp<ViewStyle>
  spacing?: SpacingProps
  spacingType?: SpacingType
}

export default function CardHeaderContainer({ children, spacingType, spacing, style }: PropsWithChildren<CardHeaderContainerProps>) {
  return (
    <IM.View spacing={spacing} spacingType={spacingType} style={style}>
      {children ? children : <DefaultCardHeader />}
    </IM.View>
  )
}
