import { IM, TextProps, useLanguage } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, memo, useImperativeHandle, useState } from 'react'
import { StyleSheet } from 'react-native'

import { TextExpandableRef } from '../types'
import Pressable from './Infominds/Pressable'
import PressableTextIcon from './Infominds/PressableTextIcon'
import Text from './Text'
import TextTruncated from './TextTruncated'

interface Props extends Omit<TextProps, 'ref'> {
  inline?: boolean
}

const TextExpandable = memo(
  forwardRef(function TextExpandable({ inline, numberOfLines, children, ...others }: Props, ref: ForwardedRef<TextExpandableRef>) {
    useImperativeHandle(ref, () => ({
      reset: () => handleReset(),
    }))

    const { i18n } = useLanguage()

    const [shownMore, setShownMore] = useState(false)
    const [hasHiddenLines, setHasHiddenLines] = useState(false)

    const handleChange = () => setShownMore(prev => !prev)

    const handleReset = () => {
      setShownMore(false)
      setHasHiddenLines(false)
    }

    return (
      <>
        <TextTruncated shownMore={shownMore} numberOfLines={numberOfLines} onHiddenLines={setHasHiddenLines} {...others}>
          {children}
        </TextTruncated>
        {inline && hasHiddenLines && (
          <Pressable onPress={handleChange} style={styles.inlinePressable} containerStyle={styles.inlinePressableContainer} hitSlop={14}>
            <Text>{shownMore ? i18n.t('LESS') : i18n.t('MORE')}</Text>
          </Pressable>
        )}
        {!inline && (
          <IM.View style={styles.pressableContainer}>
            {hasHiddenLines && (
              <PressableTextIcon
                icon={['fal', shownMore ? 'chevron-up' : 'chevron-down']}
                onPress={handleChange}
                secondary
                spacing="top"
                hitSlop={{ left: 20, right: 20, top: 10, bottom: 10 }}
                pressableStyle={styles.pressable}>
                {shownMore ? i18n.t('LESS') : i18n.t('MORE')}
              </PressableTextIcon>
            )}
          </IM.View>
        )}
      </>
    )
  })
)

export default TextExpandable

const styles = StyleSheet.create({
  pressable: { paddingVertical: 2, paddingHorizontal: 6 },
  pressableContainer: {
    alignItems: 'center',
  },
  inlinePressable: { padding: 0 },
  inlinePressableContainer: { alignItems: 'flex-end' },
})
