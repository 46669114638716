import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { StyleSheet } from 'react-native'

import '../../../types'

import { ThemeColorExpanded } from '../../../types'
import Pressable from '../../Infominds/Pressable'
import TextWithIcon from '../../Infominds/TextWithIcon'
import DefaultHeader from './default/DefaultHeader'

interface Props {
  title: string
  description?: string
  disabled?: boolean
  buttonDescription?: string
  onPress?: () => void
}

const ShoppingCartHeader = ({ onPress, disabled, description, buttonDescription, ...others }: Props) => {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={styles.container}>
      <DefaultHeader {...others} subtitle={description} />
      <Pressable disabled={disabled} onPress={onPress} hitSlop={{ top: 10, bottom: 8 }}>
        <TextWithIcon
          disabled={disabled}
          alignIcon="left"
          icon={['fal', 'check']}
          iconSize={20}
          color={disabled ? undefined : theme.general.info}
          viewStyle={styles.view}>
          {i18n.t('DONE')}
        </TextWithIcon>
        {buttonDescription && <IM.Text style={styles.description}>{buttonDescription}</IM.Text>}
      </Pressable>
    </IM.View>
  )
}

export default memo(ShoppingCartHeader)

const styles = StyleSheet.create({
  container: { flexDirection: 'row', flex: 1, alignItems: 'center', justifyContent: 'space-between' },
  view: {
    justifyContent: 'flex-end',
  },
  description: {
    color: 'white',
    fontSize: IMStyle.typography.fontSizeSmall - 2,
  },
})
