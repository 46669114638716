import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import AccessDataEditOrCreateScreen from '../../screens/accessData/AccessDataEditOrCreateScreen'
import { AccessDataEditOrCreateParams, ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController<AccessDataEditOrCreateParams>
}

export default function AccessDataEditOrCreateModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      {controller.data && (
        <AccessDataEditOrCreateScreen
          ref={ref}
          controller={controller}
          route={{
            key: '',
            name: 'AccessDataEditOrCreation',
            params: controller.data,
          }}
        />
      )}
    </BaseServiceModal>
  )
}
