import { IM, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { Folder } from '../../apis/types/apiResponseTypes'
import { ThemeColorExpanded } from '../../types'

interface Props {
  folder: Folder
  description?: string
  spacing?: SpacingProps
  selected?: boolean
  onPress: () => void
}

export default function InfoboxFolder({ folder, description, spacing, selected, onPress }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()
  const { i18n } = useLanguage()

  return (
    <IM.Card
      head={{ backGroundColor: selected ? theme.general.info : theme.card.headBackground, icon: ['fal', 'folder'] }}
      defaultContent={{
        texts: [
          {
            text: folder.description ?? i18n.t('FOLDER_WITHOUT_NAME'),
            primary: true,
          },
          {
            text: description,
            secondary: true,
          },
        ],
      }}
      onPress={onPress}
      spacing={spacing}
    />
  )
}
