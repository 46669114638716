import { useAuthentication } from '@infominds/react-native-license'
import React from 'react'
import { useResetRecoilState } from 'recoil'

import { selectedFolderIdAtom } from '../utils/stateManager'
import AnimatedButton, { AnimatedButtonProps } from './Infominds/AnimatedButton'

export default function InfoboxButton({ loading, disabled, onPress, ...props }: AnimatedButtonProps) {
  const { sessionKey } = useAuthentication()
  const resetId = useResetRecoilState(selectedFolderIdAtom(sessionKey))

  return (
    <AnimatedButton
      {...props}
      loading={loading}
      disabled={loading === false ? disabled : true}
      icon={['fal', 'photo-film-music']}
      iconSize={21}
      onPress={e => {
        resetId()
        onPress?.(e)
      }}
    />
  )
}
