import { useEvent } from '@infominds/react-native-components'
import React, { createContext, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'

import { ACTIVITY_CLOSE_REPORT_EVENT_KEY } from '../constants/EmitterKeys'
import useCloseActivity from '../hooks/useCloseActivity'
import { CloseActivityEvent, ClosingDto } from '../types'

interface ContextProps {
  enabled: boolean
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>
  value: string[]
  setValue: React.Dispatch<React.SetStateAction<string[]>>
  initial: string | undefined
}

export const ReportContext = createContext<ContextProps | undefined>(undefined)

interface ProviderProps {
  enabled?: boolean
  initial?: string
}

export const ReportProvider = ({ children, enabled = false, initial }: PropsWithChildren & ProviderProps) => {
  const initialValue = useRef(initial)
  const [switchEnabled, setSwitchEnabled] = useState(enabled)
  const [value, setValue] = useState<string[]>(initial ? [initial] : [])
  const { closeType } = useCloseActivity()

  const { emit } = useEvent<CloseActivityEvent>({ key: ACTIVITY_CLOSE_REPORT_EVENT_KEY })

  useEffect(() => {
    const event: CloseActivityEvent<Pick<ClosingDto, 'reportEmails'>> = {
      done: switchEnabled ? value.length !== 0 : true,
      pending: switchEnabled ? value.length !== 1 || value[0] !== initialValue.current : false,
      dto: { reportEmails: value },
    }

    emit(event)
  }, [closeType, value, switchEnabled])

  const contextValue: ContextProps = useMemo(
    () => ({
      enabled: switchEnabled,
      setEnabled: setSwitchEnabled,
      initial: initialValue.current,
      value,
      setValue,
    }),
    [switchEnabled, initialValue, value]
  )

  return <ReportContext.Provider value={contextValue}>{children}</ReportContext.Provider>
}
