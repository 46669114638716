import { IM, IMLayout, useDimensions } from '@infominds/react-native-components'
import React, { PropsWithChildren, useMemo } from 'react'
import { Keyboard, NativeScrollEvent, NativeSyntheticEvent, Platform, ScrollView, ScrollViewProps } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import { InfiniteLoadingType } from '../types'
import KeyboardAware from './KeyboardAware'
import SkeletonScrollView from './skeleton/SkeletonScrollView'

interface Props {
  loading?: InfiniteLoadingType
  disableHideKeyboardOnScroll?: boolean
}

export default function ScrollViewForm({
  children,
  disableHideKeyboardOnScroll,
  loading = false,
  ...others
}: Props & PropsWithChildren<Omit<ScrollViewProps, 'style'>>) {
  const { bottom } = useSafeAreaInsets()
  const { isSmallDevice } = useDimensions()
  const paddingBottom = useMemo(
    () => (Platform.OS === 'ios' && !isSmallDevice ? bottom + 2 * IMLayout.horizontalMargin : 2 * IMLayout.horizontalMargin),
    [isSmallDevice]
  )

  const handleOnScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    !disableHideKeyboardOnScroll && Platform.OS === 'ios' && Keyboard.isVisible() && Keyboard.dismiss()
    others.onScroll?.(event)
  }

  return (
    <KeyboardAware>
      <ScrollView
        style={IMLayout.flex.f1}
        keyboardShouldPersistTaps={Platform.OS === 'android' ? 'never' : 'handled'}
        scrollEnabled={loading !== 'reloading'}
        onScroll={handleOnScroll}
        scrollEventThrottle={1000}
        {...others}>
        {loading === false ? (
          <IM.View
            style={{ paddingHorizontal: 2 * IMLayout.horizontalMargin, paddingTop: IMLayout.verticalMargin, paddingBottom: paddingBottom }}
            //  onStartShouldSetResponder should return true to detect the press outside the text field input
            //  https://github.com/react-native-modal/react-native-modal/issues/236#issuecomment-808917067
            onStartShouldSetResponder={() => true}>
            {children}
          </IM.View>
        ) : (
          <SkeletonScrollView />
        )}
      </ScrollView>
    </KeyboardAware>
  )
}
