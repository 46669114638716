import React, { ForwardedRef, forwardRef, memo } from 'react'
import { StyleSheet, TextStyle } from 'react-native'
import CountryFlag from 'react-native-country-flag'

import { TextInputRef } from '../../types'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

const styles = StyleSheet.create({ flag: { borderRadius: 4 } })

type TextInputProps = BaseTextInputProps &
  BaseTextInputProviderProps & {
    countryCode?: string
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
  }

const TextInputWithFlag = memo(
  forwardRef(function TextInputWithFlag(
    { countryCode, title, titleFontWeight, editable, error, loading, disableFocus, ...textInputProps }: TextInputProps,
    ref: ForwardedRef<TextInputRef>
  ) {
    return (
      <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
        <BaseTextInput ref={ref} {...textInputProps}>
          {title && <BaseTextInput.Title title={title} fontWeight={titleFontWeight} />}
          {countryCode && (
            <BaseTextInput.RightIcon disableBorder={textInputProps.disableBorderRadius}>
              <CountryFlag isoCode={countryCode} size={20} style={styles.flag} />
            </BaseTextInput.RightIcon>
          )}
        </BaseTextInput>
      </BaseTextInputProvider>
    )
  })
)

export default TextInputWithFlag
