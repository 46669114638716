import { ModalController } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import TicketCreationScreen from '../../screens/tickets/TicketCreationScreen'
import { ModalScreenRef } from '../../types'
import BaseServiceModal from '../BaseServiceModal'

interface Props {
  controller: ModalController
}

export default function TicketCreationModal({ controller }: Props) {
  const ref = createRef<ModalScreenRef>()

  return (
    <BaseServiceModal controller={controller} childRef={ref}>
      <TicketCreationScreen ref={ref} route={{ key: '', name: 'TicketCreation' }} controller={controller} />
    </BaseServiceModal>
  )
}
