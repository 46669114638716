import { DataProviderCustomGetModifier, DataProviderCustomUpdateEffect } from '../../../dataProvider/types'
import { ClosedActivityStates } from '../../../types'
import { GetActivityRequest } from '../../types/apiRequestTypes'
import { Activity, ActivityTicketArticle, Customer, StateResponse, Ticket } from '../../types/apiResponseTypes'

export const ActivityCustomUpdateEffect: DataProviderCustomUpdateEffect<Activity> = async (data, props) => {
  if (props.requestParams.type === 'PATCH') {
    try {
      const state = (await props.dataStore.Get<StateResponse>('ticket/states'))?.find(q =>
        data.stateId ? data.stateId === q.id : q.code.toLowerCase() === data.state.toLowerCase()
      )
      if (state) {
        data.state = state.state
        data.stateCode = state.code
        data.stateDescription = state.description
      }
    } catch (e) {
      console.error(e)
    }

    // if activity is being closed, set hasUnSyncedCompletedActivities on ticket
    if (ClosedActivityStates.includes(data.state)) {
      try {
        const activity = (await props.dataStore.Get<Activity>('activity', { id: data.id }))?.shift()
        if (activity) {
          const ticket = (await props.dataStore.Get<Ticket>('ticket', { id: activity.ticketId }))?.shift()
          if (ticket && !ticket.hasUnSyncedCompletedActivities) {
            await props.dataStore.Update('ticket', [{ id: ticket.id, hasUnSyncedCompletedActivities: true }], 'patch')
          }
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
  return props.defaultEffect(data)
}

export const ActivityCustomGetModifier: DataProviderCustomGetModifier<Activity, GetActivityRequest> = async (data, props) => {
  for (const entry of data) {
    if (!entry.customer) {
      const customer = (await props.dataStore.Get<Customer>('customer', { id: entry.customerId }))?.shift()
      if (customer) {
        entry.customer ??= customer?.description ?? ''
        entry.customerLocation ??= [
          [customer.street, customer.streetnumber].filter(Boolean).join(' '),
          [customer.postalCode, customer.town].filter(Boolean).join(' '),
        ]
          .filter(Boolean)
          .join(', ')
      }
    }
    const ticket = (await props.dataStore.Get<Ticket>('ticket', { id: entry.ticketId }))?.shift()
    if (ticket) {
      entry.ticketCode ??= ticket.code
      entry.ticketDescription ??= ticket.description
    }
    if (!entry.ticketArticles) {
      entry.ticketArticles = (await props.dataStore.Get<ActivityTicketArticle>('ticket/article', { ticketId: entry.ticketId })).map(q => ({
        ...q,
        thumbImageAsPNG: undefined,
      }))
    }
    if (!entry.serialnumber) {
      const found = entry.ticketArticles.find(art => art.position === 1) ?? entry.ticketArticles.find(art => art.serialnumber)

      if (found) {
        entry.articleDescription = found.articleDescription
        entry.serialnumber = found.serialnumber
        entry.serialnumberLocation = found.serialnumberLocation
        entry.serialnumberManufacturerNumber = found.serialnumberManufacturerNumber
      }
    }
  }
  return data
}
