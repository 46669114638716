import { IM } from '@infominds/react-native-components'
import React, { memo, PropsWithChildren } from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { Border } from '../../../types'
import { baseTextInputStyles, handleBorderStyle } from './BaseTextInput'
import useBaseTextInput from './hooks/useBaseTextInput'

interface Props {
  borderColor?: string
  disableBorder?: Border | Border[]
  style?: StyleProp<ViewStyle>
}

const BaseTextInputRightIcon = memo(function BaseTextInputRightIcon({ children, borderColor, disableBorder, style }: PropsWithChildren<Props>) {
  const { backgroundColor, borderColor: contextBorderColor } = useBaseTextInput()

  return (
    <IM.View
      style={[
        baseTextInputStyles.icon,
        baseTextInputStyles.rightIcon,
        styles.container,
        { backgroundColor, borderColor: borderColor ?? contextBorderColor },
        disableBorder && handleBorderStyle(disableBorder, { borderTopRightRadius: 0 }, { borderBottomRightRadius: 0 }),
        style,
      ]}>
      {children}
    </IM.View>
  )
})

export default BaseTextInputRightIcon

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
})
