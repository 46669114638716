import { IMLayout } from '@infominds/react-native-components'
import React, { Dispatch, memo, ReactNode, SetStateAction, useEffect, useMemo } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { SearchProvider } from '../components/screen/contexts/SearchContext'
import SectionList from '../components/SectionList'
import SkeletonText from '../components/skeleton/SkeletonText'
import CONSTANTS from '../constants/Constants'
import { FiniteLoadList, InfiniteLoadingType, InfiniteLoadList, ListSection } from '../types'
import SelectModal from './SelectModal'

interface Props {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
}

export type ScreenSelectModalProps<T> = (FiniteLoadList | InfiniteLoadList) & {
  noDataMessage: string
  loading: InfiniteLoadingType
  focusSearch?: boolean
  screenTitle: string
  deferredTimeout?: number | undefined
  extraIcon?: ReactNode
  data: T[]
  disableLoadAfterMount?: boolean
  enableBackdropOpacity?: boolean
  refresh?: () => void
  renderItem: (renderItem: SectionListRenderItemInfo<T, ListSection<T>>, onPress?: () => void) => JSX.Element
  onChange: (value: T | undefined) => void
  onClose?: () => void
  onSearchChange?: (searchText: string) => void
}

function ScreenSelectModal<T extends { id: string }>({
  data,
  isVisible,
  noDataMessage,
  focusSearch,
  deferredTimeout,
  extraIcon,
  loading,
  disableLoadAfterMount = false,
  screenTitle,
  enableBackdropOpacity,
  ...others
}: ScreenSelectModalProps<T> & Props) {
  const { onSearchChange, onChange, onClose, refresh, renderItem, setIsVisible } = others

  useEffect(() => {
    !disableLoadAfterMount && isVisible && refresh?.()
    !isVisible && onClose?.()
  }, [isVisible])

  const render = (renderObj: SectionListRenderItemInfo<T, ListSection<T>>) => {
    return renderItem(renderObj, () => {
      onChange(renderObj.item)
      setIsVisible(false)
    })
  }

  const sections: ListSection<T>[] = useMemo(() => {
    const displayData: ListSection<T>[] = []

    if (data?.length && loading !== 'reloading' && loading !== 'aborted') {
      displayData.push({
        data: data,
      })
    }

    return displayData
  }, [data, loading])

  return (
    <SearchProvider>
      <SelectModal
        focusSearch={focusSearch}
        isVisible={isVisible}
        close={() => setIsVisible(false)}
        title={screenTitle}
        deferredTimeout={deferredTimeout}
        extraIcon={extraIcon}
        onSearchChange={onSearchChange}
        disableBackdropOpacity={!enableBackdropOpacity}>
        <SectionList
          {...others}
          sections={sections}
          loading={loading}
          renderItem={render}
          noDataMessage={noDataMessage}
          skeletonElements={CONSTANTS.skeletonCards}
          skeletonComponent={<SkeletonText height={20} width="100%" spacing="bottom" />}
          skeletonTopSpacing
          onRefresh={refresh}
          contentContainerStyle={{ margin: IMLayout.verticalMargin, paddingBottom: 2 * IMLayout.verticalMargin }}
        />
      </SelectModal>
    </SearchProvider>
  )
}

export default memo(ScreenSelectModal) as typeof ScreenSelectModal
