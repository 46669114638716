import { SpacingProps, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import { getApi } from '../../apis/apiCalls'
import { Group } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_ACCESS_DATA_GROUPS } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useVault from '../../hooks/useVault'
import VaultModal from '../../modals/VaultModal'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  value: Group | undefined
  onChange: (value: Group | undefined) => void
  editable?: boolean
  spacing?: SpacingProps
  onHardwareBackPress: () => void
}

export default function AccessGroupSelector({ value, onChange, onHardwareBackPress, ...props }: Props) {
  const { sessionId } = useVault()
  const { i18n } = useLanguage()
  const controller = useModalController()
  const { client } = useDataProvider()

  const [search, setSearch] = useState('')
  const [selectedId, setSelectedId] = useState(value?.id ?? CONSTANTS.noSelectionId)

  const { item: groups, loadItem, loading } = useControlledLoader(getApi(client).getGroups, { id: REQUEST_ACCESS_DATA_GROUPS })

  const noSelectionEntry: Group = {
    id: CONSTANTS.noSelectionId,
    code: i18n.t('NO_SELECTION'),
    description: i18n.t('NO_SELECTION'),
    inactive: false,
  }

  useEffect(() => {
    load()
  }, [])

  const load = (id?: string) => loadItem({ VaultSessionId: id ?? sessionId })

  const render = ({ item }: SectionListRenderItemInfo<Group, ListSection<Group>>, onPress: (() => void) | undefined) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.id === selectedId} text={item.description ?? ''} />
  }

  const handleChange = (newValue: Group | undefined) => {
    setSelectedId(newValue?.id ?? CONSTANTS.noSelectionId)

    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  return (
    <>
      <SelectInput
        value={groups?.data?.find(elem => elem.id === selectedId)}
        data={appUtils.filter(groups?.data ? [noSelectionEntry, ...groups.data] : [noSelectionEntry], search, ['description'])}
        loading={loading}
        refresh={load}
        title={i18n.t('GROUP')}
        screenTitle={i18n.t('GROUP')}
        noDataMessage={i18n.t('NO_GROUP_FOUND')}
        renderItem={render}
        onChange={handleChange}
        renderSelectedString={item => item.description ?? ''}
        onSearchChange={setSearch}
        deferredTimeout={10}
        disableLoadAfterMount
        {...props}
      />
      <VaultModal controller={controller} onAccept={load} onHardwareBackPress={onHardwareBackPress} />
    </>
  )
}
