import { IM, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StyleSheet, TouchableOpacity } from 'react-native'

import { ThemeColorExpanded } from '../../../types'

export function MyLocationButtonIOS({ onChange }: { onChange: () => void }) {
  const { theme } = useTheme<ThemeColorExpanded>()

  if (Platform.OS === 'ios') {
    return (
      <TouchableOpacity
        style={[styles.container, { backgroundColor: theme.header.main.background }]}
        onPress={() => {
          onChange()
        }}>
        <IM.Icon icon={['fal', 'crosshairs']} color={IMStyle.palette.white} size={22} />
      </TouchableOpacity>
    )
  }

  return <></>
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 1,
    left: 10,
    top: 10,
    padding: 7,
    borderRadius: 10,
  },
})
