import { useContext } from 'react'

import { NextActivityContext } from '../contexts/NextActivityContext'

export default function useNextActivity() {
  const context = useContext(NextActivityContext)

  if (context === undefined) {
    throw new Error('useNextActivity() must be called inside nextActivityProvider')
  }

  return context
}
