import { IconProp } from '@fortawesome/fontawesome-svg-core'

export enum DetailHeaderType {
  SEARCH = 'SEARCH',
  FILTER = 'FILTER',
  LOCATION = 'LOCATION',
}

export enum ScreenViewType {
  LIST,
  LOCATION,
  CALENDAR,
}

export type StringContextText = string | { text: string; icon: IconProp }
