import { DataProviderCustomUpdateEffect } from '../../../dataProvider/types'
import { StateResponse, Ticket, TicketPriorityResponse } from '../../types/apiResponseTypes'

export const TicketCustomUpdateEffect: DataProviderCustomUpdateEffect<Ticket> = async (data, props) => {
  if (props.requestParams.type === 'PATCH') {
    try {
      if (data.stateId) {
        const state = (await props.dataStore.Get<StateResponse>('ticket/states'))?.find(q => q.id === data.stateId)
        if (state) {
          data.state = state.state
          data.stateCode = state.code
          data.stateDescription = state.description
        }
      }
      if (data.priorityId) {
        const priority = (await props.dataStore.Get<TicketPriorityResponse>('ticket/priorities'))?.find(q => q.id === data.priorityId)
        data.priorityCode = priority?.code
        data.priorityColorHex = priority?.color ?? ''
        data.priorityDescription = priority?.description
      }
    } catch (e) {
      console.error(e)
    }
  }
  return props.defaultEffect(data)
}
