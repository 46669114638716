import { IM, IMStyle, ModalController, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { Modal, Platform, ScrollView, StyleSheet } from 'react-native'

import Pressable from '../components/Infominds/Pressable'
import TextInput from '../components/input/TextInput'
import KeyboardAware from '../components/KeyboardAware'
import { ThemeColorExpanded } from '../types'
import appUtils from '../utils/appUtils'

export type EditElementModalType = { value: string | undefined }

interface Props {
  controller: ModalController<EditElementModalType>
  title: string
  onClose: (newValue: string | undefined) => void
}

export default function EditElementModal({ controller, title, onClose }: Props) {
  const { i18n } = useLanguage()
  const { theme, colorScheme } = useTheme<ThemeColorExpanded>()

  const initial = useRef(controller.data?.value)
  const [text, setText] = useState(controller.data?.value)

  useEffect(() => {
    if (controller.isShown) {
      initial.current = controller.data?.value
      setText(controller.data?.value)
    }
  }, [controller.isShown])

  const disabled = initial.current === text

  return (
    <Modal
      statusBarTranslucent
      visible={controller.isShown}
      onRequestClose={controller.close}
      hardwareAccelerated={Platform.OS !== 'web'}
      transparent>
      <IM.View style={[styles.centeredView, { backgroundColor: `#000000${appUtils.getModalOpacity(colorScheme) * 100 * 1.2}` }]}>
        <KeyboardAware style={styles.avoidingView}>
          <IM.View style={[styles.modalView, { backgroundColor: appUtils.getModalBackground(colorScheme, theme), borderColor: theme.error }]}>
            <ScrollView canCancelContentTouches={false}>
              <IM.View spacing="bottom">
                <IM.Text style={styles.title}>{title}</IM.Text>
              </IM.View>
              <TextInput value={text ?? ''} onChangeText={val => setText(val === '' ? undefined : val)} multiline />
              <IM.View style={styles.buttonsContainer} spacing="top">
                <Pressable style={styles.button} onPress={controller.close}>
                  <IM.Text style={styles.text}>{i18n.t('CANCEL').toUpperCase()}</IM.Text>
                </Pressable>
                <Pressable disabled={disabled} style={styles.button} onPress={() => onClose(text)}>
                  <IM.Text secondary={disabled} style={[styles.text, !disabled && { color: theme.general.info }]}>
                    {i18n.t('SAVE').toUpperCase()}
                  </IM.Text>
                </Pressable>
              </IM.View>
            </ScrollView>
          </IM.View>
        </KeyboardAware>
      </IM.View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalView: {
    borderRadius: 20,
    paddingHorizontal: 30,
    paddingTop: 28,
    paddingBottom: 20,
    width: '90%',
    maxWidth: 400,
  },
  button: {
    paddingHorizontal: 14,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    minHeight: 38,
  },
  title: {
    fontSize: IMStyle.typography.fontSizeRegular + 2,
    fontWeight: IMStyle.typography.fontWeightMedium,
  },
  text: {
    textAlign: 'center',
  },
  buttonsContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  avoidingView: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
})
