import { useLanguage } from '@infominds/react-native-components'
import React, { createRef } from 'react'

import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import { Screen } from '../../components/screen/Screen'
import useInfoboxManagement from '../../hooks/useInfoboxManagement'
import { InfoboxStackScreenProps } from '../../navigation/types'
import InfoboxMediaView, { InfoboxMediaViewImperativeMethods } from '../../views/infobox/InfoboxMediaView'

const InfoboxMediaScreen = ({ route }: InfoboxStackScreenProps<'InfoboxMedia'>) => {
  const { i18n } = useLanguage()
  const mediaRef = createRef<InfoboxMediaViewImperativeMethods>()
  const { displayMethod, newAssets, handleInfoboxGoBack, handleInfoboxIconPress } = useInfoboxManagement({
    mediaRef,
  })

  return (
    <SearchProvider>
      <Screen>
        <Screen.Header
          title={route.params.screenTitle ?? i18n.t('INFOBOX')}
          subTitle={route.params.description ? { text: route.params.description, icon: ['fal', 'folder-open'] } : undefined}
          goBack={handleInfoboxGoBack}
          backHandlerCallback={() => {
            handleInfoboxGoBack()
          }}
          rightIcon={newAssets > 0 ? ['fal', 'check'] : ['fal', displayMethod === 'twice' ? 'list' : 'grid-2']}
          onRightIconPress={handleInfoboxIconPress}
        />
        <Screen.Content>
          <InfoboxMediaView
            ref={mediaRef}
            id={route.params.id}
            folderNumber={route.params.folderNumber}
            folderRight={route.params.folderRight}
            infoboxType={route.params.type}
          />
        </Screen.Content>
      </Screen>
    </SearchProvider>
  )
}

export default InfoboxMediaScreen
