import { useDimensions, useLanguage, useModalController } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { Platform, StyleProp, ViewStyle } from 'react-native'

import { QualityFilterType, QualityGroupType, QualityOrderType } from '../../../contexts/QualityFilterProvider'
import useQualityFilter from '../../../hooks/useQualityFilter'
import QualityFilterModal from '../../../modals/common/QualityFilterModal'
import { CommonStackParamList } from '../../../navigation/types'
import ticketUtils from '../../../utils/TicketUtils'
import CommonFilterDetailHeader from './CommonFilterDetailHeader'

interface Props {
  style?: StyleProp<ViewStyle>
  enabled: boolean
  onSDNavigation: () => void
}

export default function QualityFilterDetailHeader({ style, enabled, onSDNavigation }: Props) {
  const { language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { filters, groups, orders, changeFilterStatus } = useQualityFilter()

  const controller = useModalController<CommonStackParamList['Filter']>()

  const activeFilters = useMemo(() => ticketUtils.activeFilters(filters), [filters, language])
  const activeGroups = useMemo(() => groups.filter(el => el.active), [groups, language])
  const activeOrders = useMemo(() => orders.filter(el => el.active), [orders, language])

  return (
    <>
      <CommonFilterDetailHeader
        activeFilters={activeFilters}
        activeGroups={activeGroups}
        activeOrders={activeOrders}
        enabled={enabled}
        changeFilterStatus={(id, emitId) => {
          changeFilterStatus(id as QualityOrderType | QualityFilterType | QualityGroupType, emitId)
        }}
        onNavigate={() => (isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios') ? onSDNavigation() : controller.show(undefined))}
        style={style}
      />
      <QualityFilterModal controller={controller} />
    </>
  )
}
