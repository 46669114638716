import { ApiClient, apiClient } from '@infominds/react-api'
import { Utils } from '@infominds/react-native-components'
import { _INTERNAL_LicenseAuthenticationGlobals, _INTERNAL_LicenseDataGlobals } from '@infominds/react-native-license'
import cloneDeep from 'lodash/cloneDeep'
import { Platform } from 'react-native'

import { DataProviderSettings } from './DataProviderSettings'
import { DataStorage } from './DataStorage'
import {
  ApiResource,
  DataProviderCoreOptions,
  DataProviderOptions,
  DataProviderState,
  DataProviderStateAction,
  DataProviderStateActions,
  GetApiResultModifierFun,
  RequestManagerParams_Get,
  RequestManagerParams_Post,
  RequestManagerParams_Update,
  RequestType,
} from './types'
import { DataProviderFileHandler } from './utils/DataProviderFileHandler'
import { DataProviderSyncManager } from './utils/DataProviderSyncManager'
import DataProviderUtils from './utils/DataProviderUtils'
import { ResourceManager } from './utils/ResourceManager'

export class DataProviderCore {
  public static enabled = true
  public static debug = true
  public resources
  public dataStorage: DataStorage
  private dispatchStateCallback: React.Dispatch<DataProviderStateAction> | null = null //used to update state for app to use
  public options: DataProviderCoreOptions = { autoSync: true, isAliveResource: '/isAlive' }
  public syncManager

  #client: ApiClient
  get client() {
    return this.#client
  }

  private fileMap = new Map<string, string>()

  public state: DataProviderState = { enabled: false, isOnline: true }

  constructor(
    storage: DataStorage,
    clientConfig: {
      urlPrefix?: string
      debugLog?: boolean
      useEncoding?: boolean
      useExperimental204ReturnsNull?: boolean
    }
  ) {
    this.dataStorage = storage
    this.resources = new ResourceManager<ApiResource>()
    this.syncManager = new DataProviderSyncManager(this, storage)
    this.#client = apiClient(
      _INTERNAL_LicenseDataGlobals,
      clientConfig.urlPrefix,
      _INTERNAL_LicenseAuthenticationGlobals,
      clientConfig.debugLog,
      clientConfig.useEncoding,
      clientConfig.useExperimental204ReturnsNull
    )
  }

  public Init(state: DataProviderState, dispatchStateCallback: React.Dispatch<DataProviderStateAction>, options?: Partial<DataProviderCoreOptions>) {
    this.state = state
    this.dispatchStateCallback = dispatchStateCallback
    this.syncManager.Init()
    this.syncManager.PendingRequestsExits().catch(console.error)
    if (options) {
      Object.assign(this.options, options)
    }
  }

  public AllowOffline() {
    return (Platform.OS === 'android' || Platform.OS === 'ios') && DataProviderCore.enabled && !!this.state.enabled
  }

  public async CheckApiAlive() {
    try {
      return (await fetch(this.PrepareAliveUrl(_INTERNAL_LicenseDataGlobals)))?.ok
    } catch (e) {
      console.error(e)
    }
    return false
  }

  private PrepareAliveUrl({ baseUrl }: { baseUrl: string }) {
    return baseUrl + this.options.isAliveResource
  }

  public async CheckIsOnline() {
    try {
      if (this.state.forcedOfflineMode) {
        return false
      }
      const result = await this.CheckApiAlive()
      this.SetOnline(result)
      return result
    } catch (_exception) {
      this.SetOnline(false)
      return false
    }
  }

  public SetOnline(value: boolean) {
    this.state.isOnline = value
    this.DispatchState(DataProviderStateActions.UpdateOnlineState, { isOnline: value })
  }

  public RegisterResource<TData, TRequest>(
    resource: string,
    apiResource: string,
    syncType?: string[] | false,
    options?: DataProviderOptions<TData, TRequest>
  ) {
    if (this.AllowOffline()) return
    this.resources.Register({
      id: resource,
      resource,
      apiResource,
      syncOptions: syncType ? { ...options?.syncOptions, syncType: syncType } : undefined,
    })
    return this.dataStorage.RegisterResource(resource, options)
  }

  public GetResource(resource: string) {
    return this.dataStorage.GetStorageByKey(resource)
  }

  public DispatchState(type: DataProviderStateActions, payload?: DataProviderState) {
    if (!this.dispatchStateCallback) return
    this.dispatchStateCallback({ type, payload: payload ?? null })
  }

  public async ApiGet<TData extends object, TRequest>(
    resource: string,
    request?: TRequest,
    abortController?: AbortController,
    externalResultModifier?: GetApiResultModifierFun<TData>,
    options?: DataProviderOptions<TData, TRequest>
  ) {
    const resultModifier = (result: object | object[]) => {
      let arrayResult = DataProviderUtils.resultToArray<TData>(result)
      // if id provider
      if (options?.id_provider) {
        arrayResult = arrayResult.map(r => Object.assign(r, options.id_provider?.(r)))
      }
      // use external result modifier if given
      if (externalResultModifier) {
        arrayResult = externalResultModifier(arrayResult)
      }
      return arrayResult
    }
    const result = await this.#client.GET<TData[]>(resource, request, resultModifier, abortController)

    return result
  }

  public async ApiSend<TData>(
    type: RequestType,
    resource: string,
    data: TData,
    options?: DataProviderOptions<TData, void>,
    abortController?: AbortController
  ) {
    let result: unknown
    if (type === 'GET') throw new Error(`ApiSend '${resource}' invalid type '${type}'`)
    if (type === 'DELETE') {
      result = await this.#client.DELETE<object, unknown, unknown>(
        resource,
        options?.deleteParametersAsQuery ? undefined : data,
        options?.deleteParametersAsQuery ? data : undefined,
        abortController
      )
    } else {
      result = await this.#client[type]<object>(resource, data, abortController)
    }
    // cast result in
    if (typeof result === 'object') {
      return result
    }
    if (typeof result === 'string' || typeof result === 'number') {
      if (!options?.id) {
        return { id: result }
      }
      return options.id.reduce((obj, id) => ({ ...obj, [id]: result }), {})
    } else {
      return {
        result: result,
      }
    }
  }

  /**
   * Get Data from Api or Local DB depending on state
   */
  public Get<TData extends object, TRequest>(params: RequestManagerParams_Get<TData, TRequest>) {
    return DataProviderUtils.cancelablePromise(this.GetData(params), params.abortController)
  }

  private async GetData<TData extends object, TRequest>(params: RequestManagerParams_Get<TData, TRequest>) {
    const { resource, apiResource, request, resultModifier: externalResultModifier } = params
    const options = cloneDeep(params.options)

    const modifiedRequestData = DataProviderUtils.modifyRequest(apiResource, request)

    const requestApiData = (useModifier?: GetApiResultModifierFun<TData>) =>
      this.ApiGet<TData, TRequest>(modifiedRequestData.modifiedApiResource, modifiedRequestData.modifiedRequest, undefined, useModifier, options)

    //if offline mode is disabled or not allowed run request directly. ResultModifier is applied directly
    if (!this.AllowOffline() || !options?.enableOffline) return await requestApiData(externalResultModifier)

    // if there is no request stack the forward request to api, otherwise return local db data (this is necessary since data may have pending modifications)
    if (await this.AllowApiData()) {
      try {
        // request data from api (resultModifier is applied after local DB update)
        let data = await requestApiData()
        if (this.options.autoSync) {
          // update local db. awaiting is not necessary
          this.dataStorage.UpdateOrCreate(resource, DataProviderUtils.reduceData(data, options.dataReducer)).catch(console.error)
        }
        if (externalResultModifier) data = externalResultModifier(data)
        if (DataProviderCore.debug) console.debug('DataProviderCore GetData', resource, 'returned api data')
        return DataProviderUtils.cleanDataProviderObject(data, options)
      } catch (exception) {
        // only return local db data if exception is a "offline" exception
        if (!DataProviderUtils.detectIsOfflineFromFetchException(exception)) throw exception
        this.SetOnline(false)
      }
    }
    //Get data from local db
    try {
      const { query, modifyDBResult } = DataProviderUtils.createGetModifier<TData, TRequest>(request, options)

      let data = await this.dataStorage.Get<TData>(resource, query, modifyDBResult)
      if (DataProviderCore.debug) console.debug('DataProviderCore GetData', resource, 'returned offline data', data.length)
      if (externalResultModifier) data = externalResultModifier(data)
      if (options.get_filter) data = data.filter(element => options.get_filter?.(element, request))
      await this.ManageGetFileSync(data, options)
      if (options.customGetModifier) data = await options.customGetModifier(data, { request: request, dataStore: this.dataStorage, resource })
      return DataProviderUtils.cleanDataProviderObject(data, options)
    } catch (exception) {
      console.error('DataProvider: Get data error', exception)
      return []
    }
  }

  public Post<TData extends object>(params: RequestManagerParams_Post<TData>) {
    return DataProviderUtils.cancelablePromise(this.PostData(params), params.abortController)
  }

  /**
   * Post Data to Api or Local DB depending on state and save the changes as a "pendingRequest"
   */
  public async PostData<TData extends object>(params: RequestManagerParams_Post<TData>) {
    const { resource, apiResource, type, data, options } = params
    if (type !== 'POST') throw new Error(`DataProviderCore PostData() Error: Invalid type ${type}`)

    if (DataProviderCore.debug) console.debug('DataProviderCore PostData', resource)

    //if offline mode is disabled or not allowed run request directly
    if (!options?.enableOffline || !this.AllowOffline()) return await this.ApiSend(type, apiResource, data, options)

    const dataToSave = this.provideObjectWithTemporaryIds(data, options, resource)
    await this.ManageFileSync(dataToSave, type, options)

    //Save object to local DB and add request to pendingRequests
    const saveToLocalDb = async (addRequestToPendingRequests?: boolean) => {
      if (addRequestToPendingRequests) {
        await this.syncManager.AddRequestToPendingRequests(type, resource, apiResource, dataToSave, options)
      }
      if (!options.post?.noLocalStorage || options?.customUpdateEffect) {
        if (options?.customUpdateEffect) {
          await options.customUpdateEffect(cloneDeep(dataToSave), {
            dataStore: this.dataStorage,
            requestParams: params,
            defaultEffect: (d, t) => this.defaultUpdateEffect(d, t ?? type, resource),
            generateTemporaryId: (resourceKey: string) => this.generateTempId(resourceKey),
          })
        } else {
          await this.defaultUpdateEffect(dataToSave, 'POST', resource)
        }
      }
    }

    //if requestStack already has saved requests then request cannot be done directly and needs to be added to the stack
    if (!(await this.AllowApiData())) {
      await saveToLocalDb(true)
      return dataToSave
    }

    try {
      const result = await this.ApiSend<TData>(type, apiResource, data, options)
      await saveToLocalDb()
      return result
    } catch (exception) {
      // only add request to stack if exception is a "offline" exception
      if (!DataProviderUtils.detectIsOfflineFromFetchException(exception)) throw exception
      this.SetOnline(false)
      //Save object to local DB
      await saveToLocalDb(true)
    }

    //return (temporary) id
    return dataToSave
  }

  public Update<TData extends object>(params: RequestManagerParams_Update<TData>) {
    return DataProviderUtils.cancelablePromise(this.UpdateData(params), params.abortController)
  }

  /**
   * Send Data to Api or Local DB depending on state and save the changes as a "pendingRequest"
   */
  public async UpdateData<TData extends object>(params: RequestManagerParams_Update<TData>): Promise<unknown> {
    const { resource, apiResource, type, data, options } = params
    if (type !== 'PATCH' && type !== 'PUT' && type !== 'DELETE') throw new Error(`DataProviderCore UpdateData() Error: Invalid type ${type}`)

    const sendApiData = async () => {
      const result = await this.ApiSend(type, apiResource, data, options)
      if (typeof result === 'object') return result
      const anyId = options?.id?.length ? options.id[0] : 'id'
      return { [anyId]: result }
    }

    //if offline mode is disabled or not allowed run request directly
    if (!this.AllowOffline() || !options?.enableOffline) return await sendApiData()

    //Update local DB
    const updateLocalData = async (addRequestToPendingRequests?: boolean) => {
      if (addRequestToPendingRequests) {
        await this.syncManager.AddRequestToPendingRequests(type, resource, apiResource, data, options)
      }

      if (options?.customUpdateEffect) {
        await options.customUpdateEffect(cloneDeep(data), {
          dataStore: this.dataStorage,
          requestParams: params,
          defaultEffect: (d, t) => this.defaultUpdateEffect(d, t ?? type, resource),
          generateTemporaryId: (resourceKey: string) => this.generateTempId(resourceKey),
        })
      } else {
        await this.defaultUpdateEffect(data, type, resource)
      }
    }

    //if requestStack already has saved requests then request cannot be done directly and needs to be added to the stack
    if (!(await this.AllowApiData())) {
      await updateLocalData(true)
      return DataProviderUtils.provideSendApiResultFromData(data, options.id)
    }

    try {
      const result = await sendApiData()
      await updateLocalData()
      return result
    } catch (exception) {
      // only add request to stack if exception is a "offline" exception
      if (!DataProviderUtils.detectIsOfflineFromFetchException(exception)) throw exception
      this.SetOnline(false)
      await updateLocalData(true)
    }

    //return (temporary) id
    return DataProviderUtils.provideSendApiResultFromData(data, options.id)
  }

  private provideObjectWithTemporaryIds<TData extends object>(data: TData, options: DataProviderOptions<TData>, resource: string) {
    const createdData = { ...data }
    for (const key of options.id) {
      if (options.post?.forceIdCreation || !(key in data) || data[key] === undefined || data[key] === null || data[key] === '') {
        if (options.idType === 'number') {
          //@ts-ignore temporary id
          createdData[key] = DataProviderCore.tempIdIdentifierNumber - new Date().getTime()
        } else {
          //@ts-ignore temporary id
          createdData[key] = this.generateTempId(resource)
        }
      }
    }
    return createdData
  }

  private async defaultUpdateEffect<TData extends object>(updatedData: TData, requestType: RequestType | undefined, resource: string) {
    if (!requestType || !['POST', 'PATCH', 'PUT', 'DELETE'].includes(requestType)) {
      console.error('DataProviderCore.defaultUpdateEffect() invalid requestType error', requestType)
      return
    }
    switch (requestType) {
      case 'POST':
        await this.dataStorage.Create<TData>(resource, [updatedData])
        break
      case 'PATCH':
        await this.dataStorage.Patch<TData>(resource, [DataProviderUtils.cleanObject(updatedData)])
        break
      case 'PUT':
        await this.dataStorage.Put(resource, [updatedData])
        break
      case 'DELETE':
        await this.dataStorage.Delete(resource, [updatedData])
        break
    }
  }

  private generateTempId(resource: string) {
    return `${DataProviderSettings.tempIdIdentifier}-${resource}-${Utils.getUid()}`
  }

  private async AllowApiData() {
    if (this.state.forcedOfflineMode) return false
    await this.CheckIsOnline()
    if (!this.state.isOnline) return false
    const upSyncOk = await this.syncManager.CheckPendingUpSync()
    return upSyncOk
  }

  private generateFileName() {
    return `${DataProviderSettings.dataProviderFile}${Utils.getUid()}`
  }

  public async ManageFileSync<TData extends object>(data: TData, type: Omit<RequestType, 'GET'>, options: DataProviderOptions<TData>) {
    if (!options.saveDataOnDevice?.length) return data
    if (type === 'POST') {
      for (const key of options.saveDataOnDevice) {
        const value = data[key]
        if (!value || typeof value !== 'string') continue
        const fileName = this.generateFileName()
        const content = typeof value === 'string' ? value : JSON.stringify(value)
        await DataProviderFileHandler.writeFile(fileName, content)
        Object.assign(data, { [key]: fileName })
        this.fileMap.set(fileName, content)
      }
    }

    return data
  }

  public async ManageGetFileSync<TData extends object, TRequest = void>(
    data: TData[],
    options: Pick<DataProviderOptions<TData, TRequest>, 'saveDataOnDevice'>
  ) {
    if (!options.saveDataOnDevice?.length) return data
    for (const entry of data) {
      for (const key of options.saveDataOnDevice) {
        const value = entry[key]
        if (!value || typeof value !== 'string' || !value.startsWith(DataProviderSettings.dataProviderFile)) continue
        let result = ''
        if (this.fileMap.has(value)) {
          result = this.fileMap.get(value) ?? ''
        } else {
          result = await DataProviderFileHandler.readFile(value)
          this.fileMap.set(value, result)
        }

        Object.assign(entry, { [key]: result })
      }
    }

    return data
  }
}
