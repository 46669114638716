import React from 'react'

import SettingsHeader from '../../components/screen/headers/SettingsHeader'
import { Screen } from '../../components/screen/Screen'
import SettingsView from '../../views/settings/SettingsView'

export default function SettingsScreen() {
  return (
    <Screen>
      <Screen.Header>
        <SettingsHeader />
      </Screen.Header>
      <Screen.Content>
        <SettingsView />
      </Screen.Content>
    </Screen>
  )
}
