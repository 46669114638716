import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

import { LoadingType, VaultLockType } from '../../../types'
import { abortUtils } from '../../../utils/abortController'
import appUtils from '../../../utils/appUtils'
import { utils } from '../../../utils/utils'

export type Options<TResponse> = {
  id?: string
  onResult?: (response: TResponse) => VaultLockType
  onLoadedCallback?: (item: TResponse) => void
  onErrorCallback?: (err: unknown) => void
}

interface Result<TRequest, TResponse> {
  item: TResponse | undefined
  loadItem: (request: TRequest) => void
  loading: LoadingType
  setItem: Dispatch<SetStateAction<TResponse | undefined>>
}

export default function useControlledLoader<TRequest, TResponse>(
  getData: (request: TRequest, abortController: AbortController) => Promise<TResponse>,
  options?: Options<TResponse>
): Result<TRequest, TResponse> {
  const hash = useRef(utils.generateUuid())
  const [item, setItem] = useState<TResponse>()
  const [loading, setLoading] = useState<LoadingType>('init')
  const request = useRef<TRequest>()

  useEffect(() => {
    return () => {
      // Need to add component hash to prevent abort from other copies that get destroyed
      options && options.id && abortUtils.abort(options.id, hash.current)
    }
  }, [])

  useEffect(() => {
    loading === 'reloading' && loader()
  }, [loading])

  const loader = useCallback(() => {
    let controller: AbortController | undefined

    if (options && options.id) {
      // Hash should not be passed here because new call from different components should abort previous request to the same endpoint
      abortUtils.abort(options.id)
      controller = abortUtils.add(options.id, hash.current)
    }

    getData(request.current as TRequest, controller ?? new AbortController())
      .then(result => {
        if (options === undefined || options?.onResult === undefined || options.onResult(result) === 'vault-unlocked') {
          setItem(() => {
            options?.onLoadedCallback?.(result)
            return result
          })

          setLoading(false)
        } else {
          setLoading('aborted')
        }
      })
      .catch(err => {
        if (appUtils.isAbortError(err)) {
          // setLoading('aborted')
          console.debug(`Aborted useControlledLoader - name: ${getData.name} -  id: ${options?.id ?? 'None'} - request:`, request.current)
          return
        }

        options?.onErrorCallback?.(err)
        console.error('Failed loader() on useControlledLoader:', err)

        setLoading('catched')
      })
      .finally(() => {
        options && options.id && abortUtils.remove(options.id)
      })

    return
  }, [request, item])

  const load = useCallback(
    (requestData: TRequest) => {
      setItem(undefined)
      request.current = requestData
      setLoading('reloading')
    },
    [request]
  )

  return { item, loadItem: load, loading, setItem }
}
