import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import { mediaUtils, useAsset } from '@infominds/react-native-media'
import { useNavigation } from '@react-navigation/native'
import { useRecoilState } from 'recoil'

import appUtils from '../utils/appUtils'
import { infoboxAssetToSyncAtom, mediaSortingMethodAtom } from '../utils/stateManager'
import { InfoboxMediaViewImperativeMethods } from '../views/infobox/InfoboxMediaView'
import { useBeforeUnload } from './useBeforeUnload'
import usePopState from './usePopState'

interface Props {
  mediaRef: React.RefObject<InfoboxMediaViewImperativeMethods>
}

export default function useInfoboxManagement({ mediaRef }: Props) {
  const alert = useAlert()
  const { i18n } = useLanguage()
  const { set } = useAsset()
  const navigation = useNavigation()
  const { sessionKey } = useAuthentication()

  const [assets, setAssets] = useRecoilState(infoboxAssetToSyncAtom)
  const [displayMethod, setDisplayMethod] = useRecoilState(mediaSortingMethodAtom(sessionKey))

  useBeforeUnload(assets > 0)
  usePopState(assets > 0, i18n.t('UNSAVED_CHANGES_TITLE'), i18n.t('DISCARD_UNSAVED_CHANGES'), () => {
    reset()
  })

  const handleInfoboxIconPress = () => {
    if (assets > 0) {
      mediaRef.current?.handleUpload()
    } else {
      setDisplayMethod(mediaUtils.getFollowingDisplayMethod(displayMethod))
    }
  }

  const handleInfoboxGoBack = () => {
    const unsavedChanges = assets > 0

    if (unsavedChanges) {
      appUtils.infoboxAlert(alert, i18n, () => {
        setTimeout(() => reset(), 150)
        navigation.goBack()
      })
    } else {
      navigation.goBack()
    }
  }

  const reset = () => {
    set([])
    setAssets(0)
  }

  return {
    displayMethod,
    newAssets: assets,
    handleInfoboxIconPress,
    handleInfoboxGoBack,
  }
}
