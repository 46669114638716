import { IMStyle, Spacing, useLanguage, Utils } from '@infominds/react-native-components'
import React, { memo, useState } from 'react'

import { AdditionalFieldDefinition } from '../../apis/types/apiResponseTypes'
import { AddFieldValue } from '../../types'
import { numberUtils } from '../../utils/numberUtils'
import AddFieldSelector from '../selectors/AddFieldSelector'
import BooleanInput from './BooleanInput'
import DateInput, { convertDateToInputFormat } from './DateInput'
import TextInput, { TextInputProps } from './TextInput'

interface Props {
  definition: AdditionalFieldDefinition
  spacing?: Spacing
  value?: string
  skipValidation?: boolean
  onChange: (value: AddFieldValue) => void
  onError: (value: boolean) => void
}

const AdditionalFieldDefinitionInput = memo(function AdditionalFieldDefinitionInput({
  definition,
  value,
  spacing,
  skipValidation = false,
  onChange,
  onError,
}: Props) {
  const { i18n, language } = useLanguage()
  const [text, setText] = useState(value ?? '')

  const onChangeText = (newValue: string) => {
    setText(newValue)
    onChange({ definitionId: definition.id, value: newValue === '' ? undefined : newValue })
  }

  const getComponent = () => {
    const common: Required<Pick<TextInputProps, 'title'>> & Pick<TextInputProps, 'titleFontWeight' | 'value' | 'spacing' | 'onChangeText'> = {
      title: definition.description + (!skipValidation && definition.isMandatory ? ' *' : ''),
      titleFontWeight: IMStyle.typography.fontWeightLight,
      spacing,
      onChangeText: onChangeText,
    }

    switch (definition.fieldType) {
      case 'Text':
        return <TextInput {...common} value={text} />
      case 'TextHTML':
      case 'TextRTF':
        return <TextInput {...common} value={text} multiline fixMultilineHeight />
      case 'Numeric': {
        let error = false
        let errorMessage = ''

        const split = text.split(numberUtils.getDecimalSeparator(language))
        if (split.length > 2) {
          error = true
          errorMessage = i18n.t('ONE_ONE_COMMA')
        } else {
          if (split.length === 2 && split[1].length > definition.fieldDecimal) {
            error = true
            errorMessage =
              definition.fieldDecimal === 1
                ? i18n.t('TOO_MANY_DECIMAL')
                : Utils.stringValueReplacer(i18n.t('TOO_MANY_DECIMAL_PLURAL'), definition.fieldDecimal)
          }
        }

        onError(error)
        return (
          <TextInput
            {...common}
            type={definition.fieldDecimal === 0 ? 'number' : 'decimal'}
            value={text}
            maxLength={definition.fieldDecimal === 0 ? definition.fieldLength : definition.fieldLength + 1}
            error={{ value: error, message: errorMessage }}
          />
        )
      }
      case 'Date':
        return (
          <DateInput
            {...common}
            value={text === '' ? undefined : convertDateToInputFormat(new Date(text), language)}
            onChangeDate={date => {
              setText(date?.toString() ?? '')
              onChange({ definitionId: definition.id, value: date?.toISOString() ?? '' })
            }}
          />
        )
      case 'Selection':
        return (
          <AddFieldSelector
            {...common}
            id={text}
            definitionId={definition.id}
            onChange={val => {
              setText(val.definitionId)
              onChange(val)
            }}
          />
        )
      case 'Boolean':
        return (
          <BooleanInput
            value={text === 'false' || text === '' ? false : true}
            title={common.title}
            titleFontWeight={common.titleFontWeight}
            spacing={common.spacing}
            onValueChange={() => {
              const newText = text === 'false' ? 'true' : 'false'
              setText(newText)
              onChange({ definitionId: definition.id, value: newText })
            }}
          />
        )
      case 'ForeignKey':
      case 'None':
        return <></>
    }
  }

  return getComponent()
})

export default AdditionalFieldDefinitionInput
