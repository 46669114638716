import { IMStyle, TextProps, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import { useWindowDimensions } from 'react-native'

interface Props extends TextProps {
  shownMore: boolean
  onHiddenLines?: (value: boolean) => void
}

const TextTruncated = memo(function TextTruncated({ shownMore, onHiddenLines, ...others }: Props) {
  const { theme } = useTheme()
  const { width } = useWindowDimensions()

  const handleReflow = ({ clamped }: { clamped: boolean }) => {
    !shownMore && onHiddenLines?.(clamped)
  }

  return (
    <HTMLEllipsis
      key={width}
      unsafeHTML={others.children as string}
      maxLine={shownMore ? Number.MAX_VALUE : others.numberOfLines}
      ellipsis="..."
      basedOn="letters"
      onReflow={handleReflow}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{
        fontSize: IMStyle.typography.fontSizeSmall,
        fontWeight: IMStyle.typography.fontWeightRegular,
        color: others.secondary ? theme.textDetail : theme.text,
        whiteSpace: 'pre-line',
      }}
    />
  )
})

export default TextTruncated
