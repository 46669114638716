import { IM, IMLayout, SpacingProps, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import { ThemeColorExpanded } from '../types'

export type QualityCheckState = 'passed' | 'failed' | undefined

interface Props {
  state: QualityCheckState
  spacing?: SpacingProps
}

export default function QualityCheck({ state, spacing }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  const manageColor = () => {
    let color: string | undefined

    switch (state) {
      case 'failed': {
        color = theme.access.denied
        break
      }
      case 'passed': {
        color = theme.access.granted
        break
      }
      default: {
        color = theme.access.inactive
        break
      }
    }

    return color
  }

  return <IM.View style={[styles.view, { backgroundColor: manageColor() }]} spacing={spacing} spacingType="margin" />
}

const styles = StyleSheet.create({
  view: {
    width: 15,
    height: 15,
    borderRadius: IMLayout.iconRadius,
  },
})
