import { useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { getApi } from '../../apis/apiCalls'
import { PostSerialNumberBody } from '../../apis/types/apiRequestTypes'
import FormButton from '../../components/FormButton'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import ArticleSelector from '../../components/selectors/ArticleSelector'
import { REFRESH_SERIAL_NUMBER_VIEW_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_SERIAL_NUMBER_NUMBER } from '../../constants/Keys'
import { useDataProvider } from '../../dataProvider/hooks/useDataProvider'
import useForm from '../../hooks/useForm'
import { EditOrCreateViewProps, EditOrCreateViewRef, ThemeColorExpanded, UploadStatus } from '../../types'

type Props = EditOrCreateViewProps & { customerId: string; customerShippingAddressId?: string; onDone: () => void }

const TicketSerialNumberCreateView = (
  { customerId, customerShippingAddressId, disabled, onDone, onUploadStatus }: Props,
  ref: ForwardedRef<EditOrCreateViewRef>
) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { error } = useForm()
  const { i18n } = useLanguage()

  const { client } = useDataProvider()
  const { theme } = useTheme<ThemeColorExpanded>()

  const {
    item: serialNumberNumber,
    loadItem: getSNNumber,
    loading,
  } = useControlledLoader(getApi(client).getSerialNumberNumber, { id: REQUEST_SERIAL_NUMBER_NUMBER })
  const { request: create, loading: loadingCreate } = useRequest(getApi(client).createSerialNumber)

  const { emit } = useEvent({ key: REFRESH_SERIAL_NUMBER_VIEW_EVENT_KEY })

  const [state, setState] = useState<PostSerialNumberBody | undefined>(undefined)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'
    const equal = error || state === undefined

    if (!equal) {
      if (state?.articleId === undefined || state?.number === undefined) {
        uploadStatus = 'mandatoryMissing'
      } else {
        uploadStatus = 'waiting'
      }
    }

    updateUploadStatus(uploadStatus)
  }, [state, error])

  useEffect(() => {
    if (loading !== false) return

    // @ts-ignore: ok
    serialNumberNumber && setState(prev => ({ ...prev, number: serialNumberNumber }))
  }, [loading, serialNumberNumber])

  useEffect(() => {
    if (loadingCreate === 'catched') {
      updateUploadStatus('waiting')

      return
    }

    const loadingDone = loadingCreate === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      emit()
      onDone()
    }
  }, [loadingCreate])

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = () => {
    if (!state) return

    updateUploadStatus('uploading')

    create({ ...state, customerId, customerShippingAddressId })
  }

  const handleChangeText = (newVal: Partial<PostSerialNumberBody>) => {
    if (waitingUpload === 'uploading') return
    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const handleSNGeneration = () => state?.articleId && getSNNumber({ articleId: state?.articleId })

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: !disabled,
  }

  return (
    <ScrollViewForm>
      <ArticleSelector isMandatory onChange={val => handleChangeText({ articleId: val === undefined ? undefined : val.id })} {...commonProps} />
      <TextInput
        title={i18n.t('SERIAL_NUMBER') + ' *'}
        value={state?.number}
        onChangeText={val => handleChangeText({ number: val === '' ? undefined : val })}
        {...commonProps}
        spacing="top"
      />
      <FormButton
        title={i18n.t('GENERATE_SERIAL_NUMBER')}
        onPress={handleSNGeneration}
        color={theme.general.info}
        spacing="vertical"
        disabled={state?.articleId === undefined}
      />
      <TextInput
        title={i18n.t('SERIAL_NUMBER_MANUFACTURER')}
        value={state?.numberManufactor}
        onChangeText={val => handleChangeText({ numberManufactor: val === '' ? undefined : val })}
        {...commonProps}
      />
      <TextInput
        title={i18n.t('NOTE')}
        value={state?.note}
        multiline
        fixMultilineHeight
        onChangeText={text => handleChangeText({ note: text === '' ? undefined : text })}
        {...commonProps}
      />
    </ScrollViewForm>
  )
}

export default forwardRef(TicketSerialNumberCreateView)
