import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { NoteGroup } from '../../apis/types/apiResponseTypes'
import { REQUEST_NOTE_GROUP } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  id?: string
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: NoteGroup | undefined) => void
}

export default function NoteGroupSelector({ id, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const [search, setSearch] = useState('')
  const [selectedId, setSelectedId] = useState(id)
  const { item, loadItem, loading } = useControlledLoader(api.getNoteGroup, { id: REQUEST_NOTE_GROUP })

  useEffect(() => {
    loadItem({})
  }, [])

  const render = ({ item: note }: SectionListRenderItemInfo<NoteGroup, ListSection<NoteGroup>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={note.id === selectedId} text={note.description} />
  }

  const handleOnChange = (newValue: NoteGroup | undefined) => {
    setSelectedId(newValue?.id)
    onChange(newValue)
  }

  return (
    <SelectInput
      data={appUtils.filter(item ?? [], search, ['description'])}
      value={item?.find(elem => elem.id === selectedId)}
      loading={loading}
      refresh={() => loadItem({})}
      onSearchChange={setSearch}
      title={i18n.t('GROUP')}
      screenTitle={i18n.t('GROUPS')}
      noDataMessage={i18n.t('NO_GROUP_FOUND')}
      disableReset
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={el => el.description}
      deferredTimeout={0}
      disableLoadAfterMount
      {...props}
    />
  )
}
