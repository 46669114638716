import { useInfomindsAnalyticsNavigation } from '@infominds/react-native-analytics'
import { useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { DarkTheme, DefaultTheme, InitialState, NavigationState, NavigationContainer as ReactNavigationContainer } from '@react-navigation/native'
import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { Platform } from 'react-native'
import { useSetRecoilState } from 'recoil'

import { HIDE_NAVIGATION_EVENT_KEY } from '../constants/EmitterKeys'
import { STORAGE_KEY_NAVIGATION_STATE } from '../constants/Keys'
import useMenu from '../hooks/useMenu'
import appUtils from '../utils/appUtils'
import navigationUtils from '../utils/navigationUtils'
import { landingPageUrlAtom, navigationRefAtom } from '../utils/stateManager'
import { utils } from '../utils/utils'
import { RootStackParamList } from './types'

const InfoboxStackNames = {
  screens: {
    InfoboxFolders: 'infobox',
    InfoboxMedia: 'assets',
    InfoboxNoBottomTabAsset: 'asset',
    InfoboxNoBottomTabAssetInfo: 'info',
  },
}

const CustomerStackNames = {
  path: 'customer',
  screens: {
    CustomerDetail: 'detail',
    CustomerDetailInfoboxStack: InfoboxStackNames,
  },
}

const AccessDataStackNames = {
  path: 'accessdata',
  screens: {
    AccessData: 'list',
    AccessDataEditOrCreation: 'edit',
    AccessDataFilter: 'filters',
    AccessDataCustomerStack: CustomerStackNames,
  },
}

const ActivityStackNames = {
  path: 'activity',
  screens: {
    Activity: 'detail',
    ActivityPast: 'past-activity',
    ActivityHistory: 'history-activity',
    ActivityPlanning: 'planning-activity',
    ActivityClose: 'close',
    ActivityInfoboxStack: InfoboxStackNames,
    ActivitySpareParts: 'parts',
    ActivitySparePartsShoppingCart: 'cart',
    ActivityCommonStack: {
      screens: {
        QualityCheck: 'qualitycheck',
      },
    },
  },
}

interface Props extends PropsWithChildren {
  basePath: string
}

export default function NavigationContainer({ children, basePath }: Props) {
  const previousRouteStack = useRef<string>()

  const { i18n } = useLanguage()
  const { colorScheme } = useTheme()
  const { onRouteChange } = useMenu()

  const [isNavigationReady, setNavigationReady] = useState(!__DEV__)
  const [initialState, setInitialState] = useState<InitialState | undefined>()
  const [key, setKey] = useState(utils.generateUuid())

  const setNavigationRef = useSetRecoilState(navigationRefAtom)
  const setLandingPage = useSetRecoilState(landingPageUrlAtom)

  const { navigationRef, onReady, onStateChange: onStateChangeAnalytics } = useInfomindsAnalyticsNavigation<keyof RootStackParamList>()

  const { emit } = useEvent<boolean>({ key: HIDE_NAVIGATION_EVENT_KEY })

  useEffect(() => {
    Platform.OS === 'web' && setLandingPage(window.location.href)
  }, [])

  useEffect(() => {
    Platform.OS === 'web' && console.info(`base path: ${basePath}`)
  }, [basePath])

  useEffect(() => {
    if (!isNavigationReady) {
      AsyncStorage.getItem(STORAGE_KEY_NAVIGATION_STATE)
        .then(savedStateString => {
          const state = savedStateString ? (JSON.parse(savedStateString) as InitialState) : undefined
          setInitialState(state)
        })
        .catch(console.error)
        .finally(() => setNavigationReady(true))
    }
  }, [isNavigationReady])

  useEffect(() => {
    if (navigationRef.current) {
      setNavigationRef(navigationRef)

      const currentRoute = navigationRef.getCurrentRoute()

      if (currentRoute) {
        previousRouteStack.current = navigationUtils.findStack(navigationRef.getRootState(), currentRoute.key)
      }
    }
  }, [key, navigationRef])

  const onStateChange = useCallback((state: NavigationState | undefined) => {
    AsyncStorage.setItem(STORAGE_KEY_NAVIGATION_STATE, JSON.stringify(state)).catch(err => console.error('Can not save state', err))

    onStateChangeAnalytics()

    const currentRoute = navigationRef.getCurrentRoute()

    if (currentRoute?.name.includes('InfoboxNoBottomTab')) {
      emit(true)
    } else {
      emit(false)
    }

    if (currentRoute) {
      onRouteChange(previousRouteStack.current)
      previousRouteStack.current = navigationUtils.findStack(navigationRef.getRootState(), currentRoute.key)
    }
  }, [])

  return (
    <>
      {isNavigationReady && (
        <ReactNavigationContainer
          ref={navigationRef}
          onReady={() => {
            setKey(utils.generateUuid())
            onReady()
          }}
          initialState={Platform.OS === 'web' || !__DEV__ ? undefined : initialState}
          onStateChange={onStateChange}
          documentTitle={{
            formatter: (_options, route) => appUtils.getDocumentTitle(route, i18n),
          }}
          linking={{
            enabled: true,
            prefixes: [],
            config: {
              screens: {
                BottomTab: {
                  path: basePath,
                  screens: {
                    HistoryStack: {
                      path: 'history',
                      screens: {
                        History: 'list',
                        HistoryActivityStack: ActivityStackNames,
                      },
                    },
                    PasswordsStack: {
                      path: 'password',
                      screens: {
                        PasswordCustomersList: 'list',
                        PasswordAccessDataStack: AccessDataStackNames,
                      },
                    },
                    PlanningStack: {
                      path: 'planning',
                      screens: {
                        Planning: 'list',
                        PlanningActivityStack: ActivityStackNames,
                      },
                    },
                    QualityStack: {
                      screens: {
                        Quality: 'quality',
                      },
                    },
                    SettingsStack: {
                      screens: {
                        Settings: 'settings',
                      },
                    },
                    SparePartsStack: {
                      screens: {
                        SpareParts: 'spareparts',
                      },
                    },
                    SynchronizationStack: {
                      screens: {
                        Synchronization: 'sync',
                      },
                    },
                    TicketsStack: {
                      path: 'ticket',
                      screens: {
                        Tickets: 'list',
                        TicketDetail: 'detail',
                        TicketCustomerStack: CustomerStackNames,
                        TicketAccessDataStack: AccessDataStackNames,
                        TicketInfoboxStack: InfoboxStackNames,
                        TicketReport: 'report',
                        TicketArticle: 'article',
                        TicketActivityStack: ActivityStackNames,
                        TicketDetailPastTicket: 'past-ticket',
                      },
                    },
                    MoreEmpty: {},
                  },
                },
                EditNavigation: `${basePath}edit`,
                Login: `${basePath}login`,
                NotFound: `${basePath}*`,
                More: `${basePath}more`,
              },
            },
          }}
          theme={colorScheme === 'light' ? DefaultTheme : DarkTheme}>
          {children}
        </ReactNavigationContainer>
      )}
    </>
  )
}
