import { IM, useDimensions, useOrientation } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleSheet } from 'react-native'

import CONSTANTS from '../../../../constants/Constants'
import { DividerType } from '../../../../types'
import Divider from '../../../Divider'
import { DetailHeaderType } from '../../types'

interface Props {
  children: ReactNode
  dividers?: DividerType[]
}

export default function FilterDetailHeaderContainer({ children, dividers }: Props) {
  const { isSmallDevice } = useDimensions()
  const { landscape } = useOrientation()

  return (
    <>
      {!isSmallDevice ? (
        <IM.View style={styles.container}>
          {!isSmallDevice && dividers && dividers.find(divider => divider === 'left') && <Divider />}
          <IM.View style={[{ maxWidth: landscape ? CONSTANTS.landscapeTabletMaxFilterBar : CONSTANTS.portraitTabletMaxFilterBar }]}>
            {children}
          </IM.View>
          {!isSmallDevice && dividers && dividers.find(divider => divider === 'right') && <Divider />}
        </IM.View>
      ) : (
        children
      )}
    </>
  )
}

FilterDetailHeaderContainer.type = DetailHeaderType.FILTER

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
})
