import { DataProviderCustomUpdateEffect } from '../../../dataProvider/types'
import { SMNote, UserSettings } from '../../types/apiResponseTypes'

export const SMNoteCustomUpdateEffect: DataProviderCustomUpdateEffect<SMNote> = async (data, props) => {
  if (props.requestParams.type === 'POST') {
    if (!data.date) data.date = new Date().toISOString()
    if (!data.employee || !data.employeeId) {
      try {
        const userSettings = (await props.dataStore.Get<UserSettings>('userSettings'))?.shift()
        if (userSettings) {
          data.employee = data.employee || [userSettings.employeeFirstname, userSettings.employeeLastname].filter(q => !!q).join(' ')
          data.employeeId = data.employeeId || userSettings.employeeId || ''
        }
      } catch (_e) {
        /* empty */
      }
    }
  }
  return props.defaultEffect(data)
}
