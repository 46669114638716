import { IMLayout, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useMemo, useState } from 'react'

import api from '../../apis/apiCalls'
import { TicketPriorityResponse } from '../../apis/types/apiResponseTypes'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import SectionList from '../../components/SectionList'
import { RenderPriorityElement } from '../../components/selectors/TicketPrioritySelector'
import SkeletonText from '../../components/skeleton/SkeletonText'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_TICKET_PRIORITY } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import ticketUtils from '../../utils/TicketUtils'

interface Props {
  priorityId: string | undefined
  onChange: (priorityId: string | undefined) => void
}

export default function TicketEditPriorityView({ priorityId, onChange }: Props) {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const [selectedId, setSelectedId] = useState(priorityId ?? CONSTANTS.noSelectionId)

  const { item: items, loadItem, loading } = useControlledLoader(api.getTicketPriority, { id: REQUEST_TICKET_PRIORITY })
  const noSelectionEntry: TicketPriorityResponse = {
    id: CONSTANTS.noSelectionId,
    code: '',
    description: i18n.t('NO_SELECTION'),
    color: 'transparent',
  }

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => loadItem({})

  const handlePress = (id: string) => {
    setSelectedId(id ?? CONSTANTS.noSelectionId)

    if (id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(id)
    }
  }

  const sections: ListSection<TicketPriorityResponse>[] = useMemo(() => {
    const displayData: ListSection<TicketPriorityResponse>[] = []

    const data = items ? [noSelectionEntry, ...items] : []

    if (data?.length && loading !== 'reloading' && loading !== 'aborted') {
      const filteredData = appUtils.filter(data, search, ['code', 'description', { searchStringCompositor: ticketUtils.composePriorityString }])

      displayData.push({
        data: filteredData,
      })
    }

    return displayData
  }, [items, loading, search])

  return (
    <SectionList
      sections={sections}
      loading={loading}
      renderItem={elem => RenderPriorityElement(elem, selectedId, () => handlePress(elem.item.id))}
      noDataMessage={i18n.t('NO_PRIORITY_FOUND')}
      skeletonElements={CONSTANTS.skeletonCards}
      skeletonComponent={<SkeletonText height={20} width="100%" spacing="top" />}
      skeletonTopSpacing
      onRefresh={refresh}
      contentContainerStyle={{ margin: IMLayout.verticalMargin, paddingBottom: 3 * IMLayout.verticalMargin }}
    />
  )
}
