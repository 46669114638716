import cloneDeep from 'lodash/cloneDeep'
import { useCallback, useEffect } from 'react'
import { RecoilState, useRecoilState } from 'recoil'

interface Options {
  maxLastUsedShown: number
}

interface Prototype {
  id: string
}

interface HookReturn<T> {
  items: T[]
  setItem: (val: T) => void
}

export default function useLastUsed<T extends Prototype, TRequest extends Prototype>(
  getData: (request: TRequest, abortController: AbortController) => Promise<T[]>,
  lastUsedAtom: RecoilState<T[]>,
  options: Options
): HookReturn<T> {
  const [lastUsed, setLastUsed] = useRecoilState(lastUsedAtom)

  useEffect(() => {
    validate()
  }, [])

  const update = useCallback((customer: T) => {
    setTimeout(
      () =>
        setLastUsed(prev => {
          const filtered = prev.filter(elem => {
            return elem.id !== customer.id
          })

          return [customer, ...filtered]
        }),
      500
    )
  }, [])

  const validate = () => {
    const promises: Promise<void>[] = []
    const idToRemove: string[] = []

    lastUsed.forEach(elem => {
      promises.push(
        getData({ id: elem.id } as TRequest, new AbortController())
          .then(found => {
            if (found.length === 0) {
              idToRemove.push(elem.id)
            }
          })
          .catch(err => console.error(`Failed validation of last used id ${elem.id}`, err))
      )
    })

    Promise.all(promises)
      .then(() => {
        if (idToRemove.length !== 0) {
          setLastUsed(prev => {
            let copy = cloneDeep(prev)

            idToRemove.forEach(toRemove => {
              copy = copy.filter(elem => elem.id !== toRemove)
            })

            return copy
          })
        }
      })
      .catch(err => console.error('Last used validation failed', err))
  }

  return { items: lastUsed.slice(0, options.maxLastUsedShown), setItem: update }
}
