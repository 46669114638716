import { SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Country } from '../../apis/types/apiResponseTypes'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_COUNTRY } from '../../constants/Keys'
import { ListSection } from '../../types'
import appUtils from '../../utils/appUtils'
import useControlledLoader from '../Infominds/hooks/useControlledLoader'
import RadioPressable from '../RadioPressable'
import SelectInput from './selectInput/SelectInput'

interface Props {
  countryCode: string | undefined
  mandatory?: boolean
  editable?: boolean
  spacing?: SpacingProps
  error?: boolean
  onChange: (value: Country | undefined) => void
}

export default function CountrySelector({ countryCode, mandatory, error, onChange, ...props }: Props) {
  const { i18n } = useLanguage()

  const [search, setSearch] = useState('')

  const { item: data, loadItem, loading } = useControlledLoader(api.getCountries, { id: REQUEST_COUNTRY })
  const noSelectionEntry: Country = { id: CONSTANTS.noSelectionId, code: '', description: i18n.t('NO_SELECTION') }

  useEffect(() => {
    loadItem()
  }, [])

  const render = ({ item }: SectionListRenderItemInfo<Country, ListSection<Country>>, onPress?: () => void) => {
    return <RadioPressable onPress={() => onPress?.()} selected={item.code === countryCode} text={item.description ?? ''} />
  }

  const handleOnChange = (newValue: Country | undefined) => {
    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  const value = data?.find(elem => elem.code === countryCode)

  return (
    <SelectInput
      value={value}
      data={appUtils.filter(data ? [noSelectionEntry, ...data] : [noSelectionEntry], search, ['description'])}
      loading={loading}
      refresh={loadItem}
      title={i18n.t('COUNTRY') + (mandatory ? ' *' : '')}
      screenTitle={i18n.t('COUNTRY')}
      noDataMessage={i18n.t('NO_COUNTRY_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.description}
      onSearchChange={setSearch}
      error={error}
      deferredTimeout={0}
      disableLoadAfterMount
      {...props}
    />
  )
}
