import { useAlert, useLanguage } from '@infominds/react-native-components'
import { useNavigation } from '@react-navigation/native'
import { useEffect } from 'react'

import useMenu from './useMenu'

export default function useNavigationLocker(navigateToCurrentScreen: () => void, lock = true, alertMessage = '') {
  const navigation = useNavigation()
  const { setTabsDisabled } = useMenu()
  const { alert } = useAlert()
  const { i18n } = useLanguage()

  useEffect(() => {
    if (!lock) return
    setTabsDisabled(true)

    const removeOnRemoveListener = navigation.addListener('beforeRemove', e => {
      e.preventDefault()
      showAlert()
    })

    // required because of swipe gesture (on ios it can be disabled, but not on android)
    const removeBlurListener = navigation.addListener('blur', () => {
      showAlert()
      navigateToCurrentScreen()
    })

    return () => {
      setTabsDisabled(false)
      removeBlurListener()
      removeOnRemoveListener()
    }
  }, [lock])

  function showAlert() {
    if (!alertMessage) return
    alert(i18n.t('INFO'), alertMessage)
  }
}
