import { IM, IMLayout, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { DimensionValue, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'
import Separator from './Infominds/Separator'

interface Props {
  color?: string
  height?: DimensionValue
  style?: StyleProp<ViewStyle>
}

const Divider = memo(function Divider({ height = '60%', color, style }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View style={[styles.divider, { height, marginHorizontal: 2 * IMLayout.horizontalMargin }, style]}>
      <Separator direction="vertical" color={color ?? theme.header.detail.background} spacing="none" />
    </IM.View>
  )
})

export default Divider

const styles = StyleSheet.create({
  divider: { width: StyleSheet.hairlineWidth, borderRadius: IMLayout.borderRadius, zIndex: 10000 },
})
