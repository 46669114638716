import { useEvent } from '@infominds/react-native-components'
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Platform } from 'react-native'

import { ACTIVITY_CLOSE_SIGNATURE_EVENT_KEY } from '../constants/EmitterKeys'
import useCloseActivity from '../hooks/useCloseActivity'
import useUserSettings from '../hooks/useUserSettings'
import { CloseActivityEvent, ClosingDto } from '../types'

interface ContextProps<ContextType extends { id: string } = { id: string }> {
  scrollEnabled: boolean
  setScrollEnabled: React.Dispatch<React.SetStateAction<boolean>>
  signature: string | undefined
  setSignature: (signature: string | undefined, signaturePenColor?: string) => void
  reset: () => void
  initialContact?: ContextType
  contact?: ContextType
  changeContact: (newContact: ContextType | undefined) => void
}

export const SignatureContext = createContext<ContextProps | undefined>(undefined)

interface ProviderProps<T> {
  contact?: T
}
export const SignatureProvider = <Type extends { id: string }>({ children, contact }: PropsWithChildren & ProviderProps<Type>) => {
  const [scrollEnabled, setScrollEnabled] = useState(true)
  const [signContact, setSignContact] = useState(contact)
  const [sign, setSign] = useState<{ val: string | undefined; color?: string } | undefined>()
  const initialContact = useRef(contact)
  const { closeType } = useCloseActivity()
  const { userSettings } = useUserSettings()

  const { emit } = useEvent<CloseActivityEvent>({ key: ACTIVITY_CLOSE_SIGNATURE_EVENT_KEY })

  useEffect(() => {
    const event: CloseActivityEvent<Pick<ClosingDto, 'signatureName' | 'signatureImageData' | 'signaturePenColor'>> = {
      done: Platform.OS === 'web' ? true : checkSignaturePolicy(),
      pending: Platform.OS === 'web' ? false : closeType === undefined ? false : sign !== undefined || signContact !== initialContact.current,
      dto: {
        signatureImageData: sign?.val,
        signaturePenColor: sign?.color,
        signatureName: signContact?.id,
      },
    }

    emit(event)
  }, [closeType, signContact, sign, initialContact])

  const reset = useCallback(() => {
    setScrollEnabled(true)
    setSignContact(undefined)
  }, [])

  const onSignatureChange = useCallback((signature: string | undefined, signaturePenColor?: string) => {
    setSign(signature === undefined ? undefined : { val: signature, color: signaturePenColor })
  }, [])

  const changeContact = (newContact: Type | undefined) => {
    setSignContact(newContact)
  }

  const checkSignaturePolicy = () => {
    // If no signature has been drawn, button Done enabled
    if (sign === undefined) return true

    // If signature has been drawn, more checks are needed
    if (userSettings?.mandatoryInputContactOnClosingTheActivityWithSignature) {
      // When mandatoryInputContactOnClosingTheActivityWithSignature is true, the contact must be present in order to enable Done button
      return signContact !== undefined
    }

    // When mandatoryInputContactOnClosingTheActivityWithSignature is false and signature is present, enable Done button
    return true
  }

  const contextValue: ContextProps<Type> = useMemo(
    () => ({
      scrollEnabled,
      setScrollEnabled,
      reset,
      initialContact: initialContact.current,
      contact: signContact,
      changeContact,
      signature: sign?.val,
      setSignature: onSignatureChange,
    }),
    [scrollEnabled, initialContact, signContact, sign]
  )

  // @ts-ignore boh
  return <SignatureContext.Provider value={contextValue}>{children}</SignatureContext.Provider>
}
