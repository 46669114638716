import { IMLayout, useTheme } from '@infominds/react-native-components'
import Color from 'color'
import React from 'react'

import { ThemeColorExpanded } from '../types'
import PressableTextIcon from './Infominds/PressableTextIcon'

interface Props {
  title: string
  onPress: () => void
  disabled?: boolean
  color?: string
}

export default function DeleteButton({ title, onPress, disabled, color }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <PressableTextIcon
      icon={['fal', 'trash']}
      color={color ?? theme.header.main.error}
      iconSize={16}
      containerStyle={{
        backgroundColor: Color(color ?? theme.header.main.error)
          .fade(0.9)
          .toString(),
        borderRadius: IMLayout.borderRadius,
      }}
      onPress={onPress}
      disabled={disabled}>
      {title.toUpperCase()}
    </PressableTextIcon>
  )
}
