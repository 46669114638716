import { useCallback, useEffect, useRef, useState } from 'react'

import api from '../apis/apiCalls'
import { TravelTime } from '../apis/types/apiResponseTypes'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'
import useRequest from '../components/Infominds/hooks/useRequest'
import { REQUEST_TRAVEL_TIME } from '../constants/Keys'
import { ApiStateType } from '../types'
import useUserSettings from './useUserSettings'

export default function useTravelTimeApi() {
  const { userSettings } = useUserSettings()
  const [state, setState] = useState<ApiStateType>('init')

  const patching = useRef(false)
  const posting = useRef(false)
  const getting = useRef(false)

  const { item: time, loadItem: getTimes, loading } = useControlledLoader(api.getTravelTime, { id: REQUEST_TRAVEL_TIME })
  const { request: createTime, loading: loadingPost } = useRequest(api.startTravelTime)
  const { request: editTime, loading: loadingEdit } = useRequest(api.stopTravelTime)

  useEffect(() => {
    if (patching.current) {
      if (loadingEdit === false) {
        setState('patched')
      } else if (loadingEdit === 'catched' || loadingEdit === 'aborted') {
        setState('failed-patching')
      }

      if (loadingEdit !== 'reloading') patching.current = false

      return
    }

    if (posting.current) {
      if (loadingPost === false) {
        setState('posted')
      } else if (loadingPost === 'catched' || loadingPost === 'aborted') {
        setState('failed-posting')
      }

      if (loadingPost !== 'reloading') posting.current = false

      return
    }

    if (getting.current) {
      if (loading === false) {
        setState('loaded')
      } else if (loading === 'catched' || loading === 'aborted') {
        setState('failed-loading')
      }

      if (loading !== 'reloading') getting.current = false

      return
    }
  }, [loading, loadingEdit, loadingPost])

  useEffect(() => {
    if (state === 'patched' || state === 'posted') {
      load()
    }
  }, [state])

  const load = useCallback(() => {
    if (userSettings && userSettings.employeeId) {
      getting.current = true
      setState('loading')
      getTimes({ employeeId: userSettings.employeeId })
    }
  }, [userSettings])

  const create = useCallback((newTime: Omit<TravelTime, 'id'>) => {
    posting.current = true
    setState('posting')
    createTime(newTime)
  }, [])

  const edit = useCallback((newTime: TravelTime) => {
    patching.current = true
    setState('patching')
    editTime(newTime)
  }, [])

  return { state, time, load, create, edit }
}
