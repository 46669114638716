import { IMLayout, SpacingProps, useLanguage } from '@infominds/react-native-components'
import React, { useEffect, useRef, useState } from 'react'
import { SectionListRenderItemInfo } from 'react-native'

import api from '../../apis/apiCalls'
import { Contact } from '../../apis/types/apiResponseTypes'
import CustomerContactCard from '../../cards/customer/CustomerContactCard'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_CUSTOMER_CONTACTS } from '../../constants/Keys'
import { Gender, ListSection } from '../../types'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import SelectInput from './selectInput/SelectInput'

interface Props {
  addressId: string | undefined
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: Contact | undefined) => void
  disableFastInput?: boolean
}

export default function ContactSelector({ addressId, onChange, disableFastInput, ...props }: Props) {
  const { i18n } = useLanguage()

  const noSelectionEntry: Contact = {
    id: CONSTANTS.noSelectionId,
    addressId: '',
    companyId: '',
    gender: Gender.None,
    inactive: false,
    description: i18n.t('NO_SELECTION'),
  }

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(noSelectionEntry)

  const oldAddressId = useRef(addressId)
  const {
    item: data,
    loadItem,
    allDataLoaded,
    loadMore,
    loading,
  } = useInfiniteLoader(api.getCustomerContacts, { chuckSize: CONSTANTS.defaultChuckSize, id: REQUEST_CUSTOMER_CONTACTS })

  useEffect(() => {
    if (oldAddressId.current !== addressId) {
      setSelected(noSelectionEntry)
    }

    oldAddressId.current = addressId

    if (!addressId) return
    refresh(search)
  }, [search, addressId])

  const refresh = (text?: string) => addressId && loadItem({ addressId: addressId, searchtext: text })

  const render = ({ item }: SectionListRenderItemInfo<Contact, ListSection<Contact>>, onPress?: () => void) => {
    const isLast = allDataLoaded && data.length > 0 && item.id === data[data.length - 1].id
    const isFirst = item.id === CONSTANTS.noSelectionId

    return (
      <CustomerContactCard
        disableIcon={isFirst}
        contact={item}
        selected={item.id === selected.id}
        onPress={onPress}
        hideButtons
        spacing={['horizontal', isFirst ? 'vertical' : isLast ? 'none' : 'bottom']}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ marginBottom: isLast ? 3 * IMLayout.verticalMargin : 0 }}
      />
    )
  }

  const handleOnChange = (newValue: Contact | undefined) => {
    setSelected(newValue ?? noSelectionEntry)

    if (newValue?.id === CONSTANTS.noSelectionId) {
      onChange(undefined)
    } else {
      onChange(newValue)
    }
  }

  return (
    <SelectInput
      data={search !== '' ? data : data ? [noSelectionEntry, ...data] : [noSelectionEntry]}
      value={selected.id === CONSTANTS.noSelectionId ? undefined : selected}
      loading={addressId === undefined ? false : loading}
      refresh={refresh}
      onSearchChange={setSearch}
      title={i18n.t('CONTACT')}
      screenTitle={i18n.t('CONTACTS')}
      noDataMessage={i18n.t('NO_CONTACT_FOUND')}
      renderItem={render}
      onChange={handleOnChange}
      renderSelectedString={item => item.description}
      allDataLoaded={allDataLoaded}
      onLoadMore={loadMore}
      disableLoadAfterMount
      disableFastInput={disableFastInput}
      {...props}
    />
  )
}
