import { IM, SpacingProps, useEvent, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import CardButton from '../../components/CardButton'
import Error from '../../components/Error'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import SkeletonCard from '../../components/skeleton/SkeletonCard'
import Text from '../../components/Text'
import TicketArticleComponent from '../../components/TicketArticleComponent'
import { REFRESH_TICKET_ARTICLE_EVENT_KEY } from '../../constants/EmitterKeys'
import { REQUEST_TICKET_ARTICLE } from '../../constants/Keys'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useLayout, { ForceLayoutType } from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import TicketAddSerialNumberModal from '../../modals/tickets/TicketAddSerialNumberModal'
import { TicketStackParamList } from '../../navigation/types'
import { ThemeColorExpanded, TicketAddSNParams } from '../../types'
import appUtils from '../../utils/appUtils'

interface Props {
  ticketId: string
  customerId: string
  shippingAddressId: string | undefined
  spacing?: SpacingProps
  onlyRead?: boolean
  forceLayout: ForceLayoutType | undefined
}

const TicketDetailArticleCard = memo(function TicketDetailArticleCard({
  spacing,
  customerId,
  ticketId,
  shippingAddressId,
  onlyRead,
  forceLayout,
}: Props) {
  const { i18n } = useLanguage()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()
  const isOnline = useIsOnline()
  const controller = useModalController<TicketAddSNParams>()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  const { item: articles, loadItem: getArticles, loading } = useControlledLoader(api.getTicketArticle, { id: REQUEST_TICKET_ARTICLE })

  useEvent({ key: REFRESH_TICKET_ARTICLE_EVENT_KEY }, () => refresh())

  useEffect(() => {
    refresh()
  }, [])

  const refresh = () => getArticles({ ticketId: ticketId })

  const handleAdd = () =>
    isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
      ? navigation.navigate('TicketAddSerialNumber', { customerId, ticketId, shippingAddressId })
      : controller.show({ customerId, ticketId, shippingAddressId })

  const noButton = onlyRead || !isOnline
  const disabled = userSettings?.allowAddingAndChangingTicketArticle === false

  return (
    <>
      <IM.Card
        head={<CardLeftTitle text={i18n.t('SERIAL_NUMBERS')} />}
        spacing={spacing}
        noContentSpacing
        buttons={{
          buttons:
            isSmallDevice || noButton
              ? undefined
              : [
                  {
                    icon: ['fal', 'plus'],
                    onPress: disabled ? undefined : handleAdd,
                    ...appUtils.getCardButtonColor(!disabled, theme),
                  },
                ],
        }}>
        <IM.View style={styles.container}>
          {loading === false ? (
            <>
              {articles?.length === 0 ? (
                <IM.View spacing="all">
                  <Text secondary>{i18n.t('NO_SN_FOUND')}</Text>
                  {isSmallDevice && !noButton && <CardButton disableSeparator onPress={handleAdd} disabled={disabled} />}
                </IM.View>
              ) : (
                <>
                  {articles?.map((article, index) => {
                    return (
                      <TicketArticleComponent
                        key={article.id}
                        article={article}
                        index={index}
                        ticketId={ticketId}
                        onlyRead={onlyRead}
                        forceLayout={forceLayout}
                      />
                    )
                  })}
                  {isSmallDevice && !noButton && <CardButton onPress={handleAdd} disabled={disabled} style={styles.container} />}
                </>
              )}
            </>
          ) : (
            <IM.View spacing="all">{loading === 'catched' ? <Error /> : <SkeletonCard />}</IM.View>
          )}
        </IM.View>
      </IM.Card>
      <TicketAddSerialNumberModal controller={controller} />
    </>
  )
})

export default TicketDetailArticleCard

const styles = StyleSheet.create({
  container: {
    margin: 6,
    justifyContent: 'center',
  },
})
