import { Cursor } from 'react-native-local-mongodb'

import { DataProviderObjectLink, GetQueryProviderFun, GetQueryProviderFunWrapper, GetResultModifierFun, GetResultModifierFunWrapper } from '../types'

type SortDataType<T> = {
  key: keyof T
  inverse?: boolean
}

const DataProviderHelper = {
  query: {
    /**
     * Added keys will be ignored by the autoQuerying
     */
    ignore: <TData, TRequest>(requestKeys?: (keyof TRequest)[]): GetQueryProviderFunWrapper<TData, TRequest> | undefined => {
      if (!requestKeys?.length) return undefined

      return {
        modifierKeys: requestKeys,
        fun: () => undefined,
      }
    },
    /**
     * Query to match request field with data field (f.ex. customerId = clientId) The type of both values must be the same to work.
     */
    match: <TData, TRequest>(requestKey: keyof TRequest, dataKey?: keyof TData): GetQueryProviderFunWrapper<TData, TRequest> | undefined => {
      if (!requestKey) return undefined

      return {
        modifierKeys: [requestKey],
        fun: (request?: TRequest) => {
          if (!request) return undefined
          const value = request[requestKey]
          if (value === undefined) return undefined
          return { [dataKey ?? requestKey]: value }
        },
      }
    },
    /**
     * Query to match array-request field with data field (f.ex. customerIds includes customerId) The type of both must be the same to work.
     */
    matchArray: <TData, TRequest>(requestKey: keyof TRequest, dataKey: keyof TData): GetQueryProviderFunWrapper<TData, TRequest> | undefined => {
      if (!requestKey) return undefined

      return {
        modifierKeys: [requestKey],
        fun: (request?: TRequest) => {
          if (!request) return undefined
          const values = request[requestKey] as string[] | number[]
          if (!values || !Array.isArray(values)) return undefined
          return { $or: values.map(value => ({ [dataKey]: value })) }
        },
      }
    },
    /**
     * Query to match request field with **numeric** data field (f.ex. request.searchText (string) = companyId (number))
     */
    matchAsNumber: <TData, TRequest>(requestKey: keyof TRequest, dataKey?: keyof TData): GetQueryProviderFunWrapper<TData, TRequest> | undefined => {
      if (!requestKey) return undefined

      return {
        modifierKeys: [requestKey],
        fun: (request?: TRequest) => {
          if (!request) return undefined
          const value = request[requestKey]
          if (!value) return undefined
          const numericValue = Number.parseInt(value.toString(), 10)
          if (Number.isNaN(numericValue)) return undefined
          return { [dataKey ?? requestKey]: numericValue }
        },
      }
    },
    /**
     * Query to search on multiple data-values with a like (includes) comparison
     * @param requestKey key in request object
     * @param dataKeys keys in data object
     */
    matchLike: <TData, TRequest>(requestKey: keyof TRequest, dataKeys: (keyof TData)[]): GetQueryProviderFunWrapper<TData, TRequest> | undefined => {
      if (!dataKeys.length) return undefined
      return {
        modifierKeys: [requestKey],
        fun: (request?: TRequest) => {
          if (!request || request[requestKey] === undefined || request[requestKey] === null || request[requestKey] === '') return undefined
          // split and filter searchTerms
          const searchTerms =
            request[requestKey]
              ?.toString()
              .trim()
              .split(' ')
              .filter(q => !!q) ?? []
          if (!searchTerms.length) return undefined

          return {
            $or: dataKeys.map(field => {
              if (searchTerms.length === 1) return { [field]: { $regex: new RegExp(searchTerms[0], 'gmi') } }
              return { $and: searchTerms.map(term => ({ [field]: { $regex: new RegExp(term, 'gmi') } })) }
            }),
          }
        },
      }
    },
    compareDate: <TData, TRequest>(
      requestKey: keyof TRequest,
      dataKey: keyof TData,
      operation: 'gte' | 'lte' | 'gt' | 'lt' | 'ne'
    ): GetQueryProviderFunWrapper<TData, TRequest> | undefined => {
      return {
        modifierKeys: [requestKey],
        fun: (request?: TRequest) => {
          if (!request || !request[requestKey]) return undefined
          return { [dataKey]: { [`$${operation}`]: request[requestKey] } }
        },
      }
    },
    compareNumber: <TData, TRequest>(
      requestKey: keyof TRequest,
      dataKey: keyof TData,
      operation: 'eq' | 'gte' | 'lte' | 'gt' | 'lt' | 'ne',
      value?: number
    ): GetQueryProviderFunWrapper<TData, TRequest> | undefined => {
      return {
        modifierKeys: [requestKey],
        fun: (request?: TRequest) => {
          if (!request || [null, undefined, false].includes(request[requestKey] as boolean)) return undefined
          const compareValue = value ?? request[requestKey]
          return { [dataKey]: operation === 'eq' ? compareValue : { [`$${operation}`]: compareValue } }
        },
      }
    },
    /**
     * Add logical and for given queries
     */
    and: <TData, TRequest>(
      ...args: (GetQueryProviderFunWrapper<TData, TRequest> | undefined | null | false)[]
    ): GetQueryProviderFunWrapper<TData, TRequest> | undefined => {
      if (!args?.length) return undefined
      return {
        modifierKeys: args.reduce<(keyof TRequest)[]>((keys, arg) => (arg ? [...keys, ...arg.modifierKeys] : keys), []),
        fun: (request?: TRequest) => ({
          $and: args
            .filter(arg => !!arg)
            .map(arg => (arg ? arg.fun(request) : null))
            .filter(q => !!q),
        }),
      }
    },
    /**
     * @deprecated Should not be required
     */
    or: <TData, TRequest>(
      ...args: (GetQueryProviderFun<TData, TRequest> | undefined | null | false)[]
    ): GetQueryProviderFun<TData, TRequest> | undefined => {
      if (!args?.length) return undefined
      return (request?: TRequest) => ({
        $or: args
          .filter(arg => !!arg)
          .map(arg => (arg ? arg(request) : null))
          .filter(q => !!q),
      })
    },
  },
  modify: {
    skipTake: <TData, TRequest extends { skip?: number | null; take?: number | null }>(): GetResultModifierFunWrapper<TData, TRequest> => {
      return {
        fun: (result: Cursor<TData[]>, request?: TRequest) => {
          if (!request) return result
          result.skip(request.skip ?? 0)
          if (request.take) result.limit(request.take)
          return result
        },
        modifierKeys: ['skip', 'take'],
      }
    },
    sort: <TData, TRequest>(
      sortBy: keyof TData | SortDataType<TData> | (keyof TData | SortDataType<TData>)[]
    ): GetResultModifierFunWrapper<TData, TRequest> | undefined => {
      const fun = sort(sortBy)
      if (!fun) return undefined
      return {
        fun,
        modifierKeys: [],
      }
    },
  },
  reducer: <TData>(...keys: (keyof TData)[]) => {
    return (data: TData) => keys.reduce<TData>((reducedData, key) => ({ ...reducedData, [key]: data[key] }), {} as TData)
  },
  link: {
    /**
     * Allows linking of sub-objects to other resources (only required if that resource is also handed by app/api).
     */
    subObject: <TData>(key: keyof TData, resource: string): DataProviderObjectLink<TData> => {
      return { key, resource }
    },
  },
}

export default DataProviderHelper

function sort<TData, TRequest>(
  sortBy: keyof TData | SortDataType<TData> | (keyof TData | SortDataType<TData>)[]
): GetResultModifierFun<TData, TRequest> | undefined {
  if (!sortBy) return undefined
  let sortArray: (keyof TData | SortDataType<TData>)[] = []
  if (!Array.isArray(sortBy)) sortArray = [sortBy]
  else sortArray = sortBy
  const keys = sortArray.map<SortDataType<TData>>(item => {
    if (typeof item === 'object') return item
    else return { key: item }
  })
  return (result: Cursor<TData[]>) => {
    result.sort(keys.filter(key => !!key).reduce((query, key) => ({ ...query, [key?.key]: key?.inverse ? -1 : 1 }), {}))
    return result
  }
}
