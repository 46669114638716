import { IM, useTheme } from '@infominds/react-native-components'
import React from 'react'

import { ThemeColorExpanded } from '../types'

export default function IndicatorTop() {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <>
      <IM.View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          backgroundColor: theme.timeline.background,
          width: 10,
          height: 10,
          borderRadius: 50,
          position: 'absolute',
          left: 43,
        }}
      />
      <IM.View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          backgroundColor: theme.timeline.background,
          width: 2,
          height: 22,
          borderRadius: 0,
          position: 'absolute',
          left: 47,
          top: 11,
        }}
      />
    </>
  )
}
