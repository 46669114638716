import cloneDeep from 'lodash/cloneDeep'
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useCallback, useMemo, useState } from 'react'

import { AccessDataFilter, FilterInfo } from '../types'

export interface AccessDataPermission {
  data: 'granted' | 'denied'
  active: boolean
}

interface FilterContextProps {
  filters: AccessDataFilter[]
  permissions: AccessDataPermission[]
  setFilters: Dispatch<SetStateAction<AccessDataFilter[]>>
  initFilters: (newFilters: FilterInfo[]) => void
  isActive: (id: string) => boolean
  changeFilterStatus: (id: string) => void
  changePermissionStatus: (id: string) => void
  setPermissions: Dispatch<SetStateAction<AccessDataPermission[]>>
}

const AccessDataFilterContext = createContext<FilterContextProps | undefined>(undefined)

export const AccessDataFilterProvider = ({ children }: PropsWithChildren) => {
  const [filters, setFilters] = useState<AccessDataFilter[]>([])
  const [permissions, setPermissions] = useState<AccessDataPermission[]>([
    { active: false, data: 'granted' },
    {
      active: false,
      data: 'denied',
    },
  ])

  const initFilters = useCallback(
    (newFilters: FilterInfo[]) => {
      const clone = cloneDeep(filters)

      newFilters.forEach(elem => {
        const alreadyPresent = filters.find(filter => filter.data.id === elem.id)

        if (alreadyPresent === undefined) {
          clone.push({
            active: false,
            data: elem,
          })
        }
      })

      setFilters(clone)
    },
    [filters]
  )

  const changeFilterStatus = useCallback(
    (id: string) => {
      const clone = cloneDeep(filters)

      clone.forEach(filter => {
        if (filter.data.id === id) {
          filter.active = !filter.active
        }
      })

      setFilters(clone)
    },
    [filters]
  )

  const changePermissionStatus = useCallback(
    (id: string) => {
      const clone = cloneDeep(permissions)

      clone.forEach(permission => {
        if (permission.data === id) {
          permission.active = !permission.active
        }
      })

      setPermissions(clone)
    },
    [filters]
  )

  const isActive = useCallback(
    (id: string) => {
      return filters.find(elem => elem.data.id === id)?.active ?? false
    },
    [filters]
  )

  const props = useMemo<FilterContextProps>(
    () => ({ filters, setFilters, permissions, setPermissions, changePermissionStatus, changeFilterStatus, initFilters, isActive }),
    [filters, permissions]
  )

  return <AccessDataFilterContext.Provider value={props}>{children}</AccessDataFilterContext.Provider>
}

export default AccessDataFilterContext
