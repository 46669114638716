import { IM, IMLayout, IMStyle, useLanguage } from '@infominds/react-native-components'
import { FlashList, ListRenderItemInfo } from '@shopify/flash-list'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import React, { createRef, useEffect, useMemo } from 'react'
import { useSetRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { QualityList } from '../../apis/types/apiResponseTypes'
import QualityCard from '../../cards/quality/QualityCard'
import FlashListData from '../../components/FlashListData'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useSearch from '../../components/screen/hooks/useSearch'
import { REQUEST_QUALITY_LIST } from '../../constants/Keys'
import { QualityFilterType, QualityGroupType, QualityOrderType } from '../../contexts/QualityFilterProvider'
import useQualityFilter from '../../hooks/useQualityFilter'
import { FilterElement } from '../../types'
import appUtils from '../../utils/appUtils'
import { qualityFilterEnableAtom } from '../../utils/stateManager'
import ticketUtils from '../../utils/TicketUtils'

export default function QualityView() {
  const { i18n } = useLanguage()
  const { search } = useSearch()
  const { filters, groups, orders, initFilters } = useQualityFilter()
  const enableFilter = useSetRecoilState(qualityFilterEnableAtom)

  const { item: quality, loadItem, loading } = useControlledLoader(api.getQualityList, { id: REQUEST_QUALITY_LIST })
  const listRef = createRef<FlashList<string | QualityList>>()

  useEffect(() => {
    listRef.current?.scrollToOffset({
      animated: true,
      offset: 0,
    })
  }, [filters, groups, orders])

  useEffect(() => {
    refresh()
  }, [])

  useEffect(() => {
    if (loading === false) {
      const serialNumber: FilterElement[] = []

      quality?.forEach(qual => {
        qual.serialnumber &&
          serialNumber.push({ id: qual.serialnumber, description: qual.serialnumber + ' - ' + qual.articleDescription, active: false })
      })

      initFilters(serialNumber)
      enableFilter(true)
    } else {
      enableFilter(false)
    }
  }, [loading, quality])

  const refresh = () => loadItem()

  const renderItem = (elem: ListRenderItemInfo<QualityList | string>) => {
    const isFirst = elem.index === 0
    const item = elem.item

    if (typeof item === 'string') {
      return (
        <IM.View style={{ marginHorizontal: 2 * IMLayout.horizontalMargin, marginTop: (isFirst ? 1 : 2) * IMLayout.horizontalMargin }}>
          <IM.Text style={{ fontWeight: IMStyle.typography.fontWeightMedium }}>{item}</IM.Text>
        </IM.View>
      )
    } else {
      return (
        <QualityCard
          quality={item}
          style={[
            {
              marginTop: IMLayout.horizontalMargin,
              marginHorizontal: 2 * IMLayout.horizontalMargin,
            },
          ]}
        />
      )
    }
  }

  const filtered: (QualityList | string)[] | undefined = useMemo(() => {
    let clone = cloneDeep(quality)

    // Search
    clone = clone
      ? appUtils.filter(clone, search, [
          'ticketCode',
          'customer',
          'customerId',
          'articleDescription',
          'serialnumber',
          'shippingAddress',
          'articleDescription',
        ])
      : []

    // Ticket grouping
    let grouped: { [key: string]: QualityList[] } = { undefined: clone }
    const activeGroups = groups.find(group => group.active)
    switch (activeGroups?.data.id) {
      case QualityGroupType.Customer: {
        grouped = groupBy(clone, el => el.customer)
        grouped = ticketUtils.replaceUndefinedSection(grouped, i18n.t('NO_CUSTOMER'))

        break
      }
      case QualityGroupType.Ticket: {
        grouped = groupBy(clone, el => el.ticketCode)
        grouped = ticketUtils.replaceUndefinedSection(grouped, i18n.t('NO_TICKET'))

        break
      }
    }

    const activeOrder = orders.find(order => order.active)
    switch (activeOrder?.data.id) {
      case QualityOrderType.SerialNumberDescending: {
        Object.keys(grouped).forEach(key => {
          grouped[key] = grouped[key].sort((a, b) => b.serialnumber.localeCompare(a.serialnumber))
        })

        break
      }
      case QualityOrderType.SerialNumberAscending: {
        Object.keys(grouped).forEach(key => {
          grouped[key] = grouped[key].sort((a, b) => a.serialnumber.localeCompare(b.serialnumber))
        })

        break
      }

      case QualityOrderType.SortKeyDescending: {
        Object.keys(grouped).forEach(key => {
          grouped[key] = grouped[key].sort((a, b) => b.serialnumberSortkey - a.serialnumberSortkey)
        })

        break
      }
      case QualityOrderType.SortKeyAscending: {
        Object.keys(grouped).forEach(key => {
          grouped[key] = grouped[key].sort((a, b) => a.serialnumberSortkey - b.serialnumberSortkey)
        })

        break
      }
    }

    const activeFilters = ticketUtils.activeFilters(filters)
    activeFilters.forEach(filter => {
      switch (filter.id) {
        case QualityFilterType.SerialNumber: {
          Object.keys(grouped).forEach(key =>
            Object.assign(grouped, {
              ...grouped,
              [key]: grouped[key].filter(cln => (cln.serialnumber ? filter.elements.find(el => el.id === cln.serialnumber) !== undefined : false)),
            })
          )
          break
        }
      }
    })

    const data: (string | QualityList)[] = []
    const keys = Object.keys(grouped)

    keys.forEach(key => {
      if (grouped[key].length !== 0) {
        if (key !== 'undefined') {
          data.push(key)
          data.push(...grouped[key])
        } else {
          data.push(...grouped[key])
        }
      }
    })

    return data
  }, [filters, groups, orders, quality, search])

  return (
    <FlashListData
      ref={listRef}
      data={filtered}
      loading={loading}
      renderItem={renderItem}
      closeEndThreshold={40}
      noDataMessage={i18n.t('NO_ELEMENT_FOUND')}
      refresh={refresh}
    />
  )
}
