import { IM, useAlert, useEvent, useLanguage } from '@infominds/react-native-components'
import isEqual from 'lodash/isEqual'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { SMNote } from '../../apis/types/apiResponseTypes'
import DeleteButton from '../../components/DeleteButton'
import useRequest from '../../components/Infominds/hooks/useRequest'
import DateInput, { convertDateToInputFormat } from '../../components/input/DateInput'
import TextInput, { TextInputProps } from '../../components/input/TextInput'
import ScrollViewForm from '../../components/ScrollViewForm'
import NoteGroupSelector from '../../components/selectors/NoteGroupSelector'
import { EDIT_NOTE_DATA_EVENT_KEY } from '../../constants/EmitterKeys'
import useForm from '../../hooks/useForm'
import { EditOrCreateViewProps, EditOrCreateViewRef, SMNoteType, UploadStatus } from '../../types'
import TimeUtils from '../../utils/TimeUtils'

type Edit = {
  mode: 'edit'
  note: SMNote
  id?: never
}

type Creation = {
  mode: 'creation'
  note?: never
  id: string
}

type Props = (Edit | Creation) &
  EditOrCreateViewProps & {
    type: SMNoteType
  }

const NoteEditOrCreateView = ({ type, mode, note, id, disabled, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { request: create, loading: loadingCreate } = useRequest(api.createSMNote)
  const { request: edit, loading: loadingEdit } = useRequest(api.editSMNote)
  const { request: deleteNote, loading: deleting } = useRequest(api.deleteSMNote)

  const initialData = useRef(
    note
      ? {
          ...note,
          date: note?.date ? TimeUtils.resetTimeOfISOString(note.date) : TimeUtils.resetTimeOfISOString(new Date().toISOString()),
        }
      : undefined
  )
  const equalData = useRef(false)

  const alert = useAlert()
  const { error } = useForm()
  const { i18n, language } = useLanguage()

  const [state, setState] = useState<SMNote | undefined>(initialData.current)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')
  const [deleteRequested, setDeleteRequested] = useState(false)

  const refresh = useEvent({ key: EDIT_NOTE_DATA_EVENT_KEY + type })

  useEffect(() => {
    let uploadStatus: UploadStatus = 'done'
    let equal = error

    if (!error) {
      equalData.current = isEqual(initialData.current, state)

      if (mode === 'edit') {
        equal = equalData.current
      } else {
        equal = state === undefined || equalData.current
      }

      if (equal === false && state?.note === undefined) {
        updateUploadStatus('mandatoryMissing')
        return
      }
    }

    if (!equal) {
      uploadStatus = 'waiting'
    }

    updateUploadStatus(uploadStatus)
  }, [state, error])

  useEffect(() => {
    if (mode === 'edit' ? loadingEdit === 'catched' || deleting === 'catched' : loadingCreate === 'catched') {
      updateUploadStatus('waiting')

      return
    }

    const loadingDone = mode === 'edit' ? loadingEdit === false && deleting === false : loadingCreate === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
      refresh.emit()
    }
  }, [loadingCreate, loadingEdit, deleting, deleteRequested])

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const handleDelete = () => {
    alert.alert(i18n.t('DELETE_NOTE'), i18n.t('DELETE_DESCRIPTION_GENERAL'), [
      {
        text: i18n.t('DELETE'),
        onPress: () => {
          setDeleteRequested(true)
          updateUploadStatus('uploading')
          // @ts-ignore improve data provider ts
          deleteNote({ id: initialData.current.id })
        },
        style: 'destructive',
      },
      {
        text: i18n.t('CANCEL'),
        onPress: () => {
          return
        },
        style: 'cancel',
      },
    ])
  }

  const upload = () => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    if (mode === 'edit') {
      edit(state)
    } else {
      create({ ...state, fkId: id, type })
    }
  }

  const handleChangeText = (newVal: Partial<SMNote>) => {
    if (waitingUpload === 'uploading') return
    // @ts-ignore not important
    setState(prev => {
      return {
        ...prev,
        ...newVal,
      }
    })
  }

  const dateValue = useMemo(
    () =>
      state?.date
        ? convertDateToInputFormat(new Date(state.date), language)
        : convertDateToInputFormat(new Date(TimeUtils.resetTimeOfISOString(new Date().toISOString())), language),
    [state, language]
  )

  const commonProps: Pick<TextInputProps, 'spacing' | 'editable'> = {
    spacing: 'vertical',
    editable: !disabled,
  }

  return (
    <ScrollViewForm>
      <NoteGroupSelector
        id={state?.notegroupId}
        editable={!disabled}
        onChange={value => handleChangeText({ notegroup: value?.description, notegroupId: value?.id })}
        spacing="vertical"
      />
      <DateInput
        title={i18n.t('DATE')}
        value={dateValue}
        onChangeDate={date =>
          // Some weird issue in the comparison when we set {dateofbirth: undefined} in an object which does not have that key
          !(state?.date === undefined && date === undefined) && handleChangeText({ date: date ? date.toISOString() : undefined })
        }
        maximumDate={new Date()}
        {...commonProps}
      />
      <TextInput
        title={i18n.t('NOTE') + ' *'}
        value={state?.noteNoRtf}
        multiline
        fixMultilineHeight
        onChangeText={text => handleChangeText({ note: text === '' ? undefined : text, noteNoRtf: text })}
        {...commonProps}
      />
      {mode === 'edit' && !disabled && (
        <IM.View {...commonProps} spacing="vertical">
          <DeleteButton title={i18n.t('DELETE_NOTE')} onPress={handleDelete} />
        </IM.View>
      )}
    </ScrollViewForm>
  )
}

export default forwardRef(NoteEditOrCreateView)
