import { IM, IMLayout, useDimensions, useLanguage, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'
import TextWithIcon from './Infominds/TextWithIcon'
import NumberInput, { NumericInputProps } from './input/NumberInput'

interface Props extends NumericInputProps {
  pending?: boolean
  disableFixedWidth?: boolean
  containerStyle?: StyleProp<ViewStyle>
  controlled?: boolean
}

export default function SparePartQuantityInput({ controlled, pending, containerStyle, disableFixedWidth = false, ...others }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { isSmallDevice } = useDimensions()

  const minWidth = Platform.OS === 'web' ? 150 : isSmallDevice ? 170 : 190
  const maxWidth = Platform.OS === 'web' ? 190 : isSmallDevice ? 190 : 210

  return (
    <IM.View style={[IMLayout.flex.f1, { minWidth: disableFixedWidth ? undefined : minWidth, maxWidth: disableFixedWidth ? undefined : maxWidth }]}>
      <IM.View style={[styles.inputContainer, IMLayout.flex.f1, containerStyle]}>
        <NumberInput {...others} size={isSmallDevice ? 'small' : 'normal'} controlled={controlled} />
        {pending && others.value !== '' && (
          <TextWithIcon
            icon={['fas', 'triangle-exclamation']}
            color={theme.general.warn}
            style={[IMLayout.flex.f1, { color: theme.general.warn }]}
            spacing="top">
            {i18n.t('SPARE_PART_PENDING')}
          </TextWithIcon>
        )}
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    paddingLeft: 10,
  },
})
