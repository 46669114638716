import isEqual from 'lodash/isEqual'
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Platform } from 'react-native'

import api from '../../apis/apiCalls'
import useRequest from '../../components/Infominds/hooks/useRequest'
import EmailInputSelector from '../../components/inputSelector/EmailInputSelector'
import ScrollViewForm from '../../components/ScrollViewForm'
import { EditOrCreateViewProps, EditOrCreateViewRef, UploadStatus } from '../../types'

type Props = { activityId: string; init?: string } & Omit<EditOrCreateViewProps, 'disabled'>

const ActivitySendReportView = ({ activityId, init, onUploadStatus }: Props, ref: ForwardedRef<EditOrCreateViewRef>) => {
  useImperativeHandle(ref, () => ({
    handleUpload: upload,
  }))

  const { request: edit, loading: loadingEdit } = useRequest(api.sendActivityReport)

  const initialData = useRef<string[]>(init ? [init] : [])

  const [state, setState] = useState(initialData.current)
  const [waitingUpload, setWaitingUpload] = useState<UploadStatus>('done')

  useEffect(() => {
    updateUploadStatus(state.length === 0 ? 'done' : getStatus())
  }, [state])

  useEffect(() => {
    if (loadingEdit === 'catched') {
      updateUploadStatus(getStatus())

      return
    }

    const loadingDone = loadingEdit === false

    if (waitingUpload !== 'done' && loadingDone) {
      updateUploadStatus('done')
    }
  }, [loadingEdit])

  const getStatus = () => {
    return isEqual(state, initialData.current) ? 'waitingWithoutPending' : 'waiting'
  }

  const updateUploadStatus = (newStatus: UploadStatus) => {
    setWaitingUpload(newStatus)
    onUploadStatus(newStatus)
  }

  const upload = () => {
    if (state === undefined) return

    updateUploadStatus('uploading')

    edit({
      activityId: activityId,
      emails: state,
    })
  }

  const handleChangeText = (newVal: string[]) => {
    if (waitingUpload === 'uploading') return

    setState(newVal)
  }

  return (
    <ScrollViewForm scrollEnabled={false}>
      <EmailInputSelector
        activityId={activityId}
        value={init}
        editable={waitingUpload !== 'uploading'}
        onDataEntered={handleChangeText}
        spacing="top"
        popUpOffset={Platform.OS === 'web' ? 0 : 8}
      />
    </ScrollViewForm>
  )
}

export default forwardRef(ActivitySendReportView)
