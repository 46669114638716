import { IM, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { TextStyle } from 'react-native'

import PressableIcon from '../Infominds/PressableIcon'
import Text from '../Text'
import { BaseTextInput, BaseTextInputProps } from './baseTextInput/BaseTextInput'
import BaseTextInputTitle from './baseTextInput/BaseTextInputTitle'
import { BaseTextInputProvider, BaseTextInputProviderProps } from './baseTextInput/contexts/BaseTextInputContext'

type Props = BaseTextInputProps &
  BaseTextInputProviderProps & {
    title?: string
    titleFontWeight?: TextStyle['fontWeight']
    details?: string
    disableReset?: boolean
    showNoDataMessage: boolean
    noDataMessage?: string
    onReset: () => void
  }

const SelectTextInput = memo(function SelectTextInput({
  title,
  details,
  editable,
  error,
  loading,
  disableFocus,
  disableReset,
  titleFontWeight,
  onReset,
  showNoDataMessage,
  noDataMessage,
  ...textInputProps
}: Props) {
  const { theme } = useTheme()

  return (
    <>
      {showNoDataMessage ? (
        <IM.View {...textInputProps}>
          <BaseTextInputTitle title={title} details={details} fontWeight={titleFontWeight} />
          <Text secondary>{noDataMessage}</Text>
        </IM.View>
      ) : (
        <BaseTextInputProvider editable={editable} error={error} loading={loading} disableFocus={disableFocus}>
          <BaseTextInput {...textInputProps}>
            {title && <BaseTextInput.Title title={title} details={details} fontWeight={titleFontWeight} />}
            <BaseTextInput.RightIcon>
              {disableReset || textInputProps.value === '' || textInputProps.value === undefined ? (
                <PressableIcon
                  icon={['fal', 'chevron-right']}
                  size={18}
                  color={theme.textDetail}
                  disabled={loading === true || editable === false}
                  onPress={textInputProps.onPress}
                />
              ) : (
                <PressableIcon
                  icon={['fal', 'times']}
                  size={18}
                  color={theme.textDetail}
                  disabled={loading === true || editable === false}
                  onPress={onReset}
                />
              )}
            </BaseTextInput.RightIcon>
          </BaseTextInput>
        </BaseTextInputProvider>
      )}
    </>
  )
})

export default SelectTextInput
