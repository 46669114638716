import { IM, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'

import api from '../../apis/apiCalls'
import { Activity, TicketActivity } from '../../apis/types/apiResponseTypes'
import Button from '../../components/Button'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import useRequest from '../../components/Infominds/hooks/useRequest'
import TextInput from '../../components/input/TextInput'
import TextInputWithFlag from '../../components/input/TextInputWithFlag'
import useUserSettings from '../../hooks/useUserSettings'
import { ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import { utils } from '../../utils/utils'

type Props = {
  activity: TicketActivity
  spacing?: SpacingProps
  disabled?: boolean
  disableNextActivity?: boolean
  onEdited: (pending: boolean) => void
}

const ActivityDescriptionCard = memo(function ActivityDescriptionCard({ activity, spacing, disabled, disableNextActivity = false, onEdited }: Props) {
  const { i18n } = useLanguage()
  const { userSettings } = useUserSettings()
  const { theme } = useTheme<ThemeColorExpanded>()

  const initTech = useRef(activity.technicalDescription)
  const initCustomerNote = useRef(activity.customerDescription)
  const initNewActivity = useRef(activity.nextActivityDescription)

  const [id, setId] = useState(utils.generateUuid())
  const [tech, setTech] = useState(activity.technicalDescription)
  const [customerNote, setCustomerNote] = useState(activity.customerDescription)
  const [newActivity, setNewActivity] = useState(activity.nextActivityDescription)

  const { request, loading } = useRequest(api.editActivity)

  const code = useMemo(() => appUtils.customerLanguageToCountryCode(activity.customerLanguage), [])
  const edited = tech !== initTech.current || customerNote !== initCustomerNote.current || newActivity !== initNewActivity.current

  useEffect(() => {
    onEdited(edited)
  }, [tech, customerNote, newActivity])

  useEffect(() => {
    if (loading === false) {
      initTech.current = tech
      initCustomerNote.current = customerNote
      initNewActivity.current = newActivity

      onEdited(false)
      setId(utils.generateUuid())
    }
  }, [loading])

  return (
    <IM.Card key={id} spacing={spacing} head={<CardLeftTitle text={i18n.t('DESCRIPTION')} />}>
      <TextInput
        title={i18n.t('TECHNICAL_DESCRIPTION')}
        value={tech}
        onChangeText={text => setTech(text === '' ? undefined : text)}
        spacing="bottom"
        multiline
        editable={!disabled}
      />
      <TextInputWithFlag
        title={i18n.t('CUSTOMER_DESCRIPTION')}
        countryCode={code}
        value={customerNote}
        onChangeText={text => setCustomerNote(text === '' ? undefined : text)}
        spacing="vertical"
        multiline
        editable={!disabled && userSettings && userSettings.allowEditingCustomerDescriptionOfActivity}
      />
      <TextInput
        title={i18n.t('NEW_ACTIVITY_DESCRIPTION')}
        value={newActivity}
        onChangeText={text => setNewActivity(text === '' ? undefined : text)}
        spacing={edited ? 'vertical' : 'top'}
        multiline
        editable={!disableNextActivity && !disabled}
      />
      {edited && (
        <Button
          title={i18n.t('SAVE')}
          color={loading === 'catched' ? theme.general.error : theme.general.info}
          spacing="top"
          loading={!(loading === false || loading === 'catched')}
          onPress={() =>
            request({
              id: activity.id,
              technicalDescription: tech ?? '',
              customerDescription: customerNote ?? '',
              nextActivityDescription: newActivity ?? '',
            } as Activity)
          }
          disabled={disabled}
        />
      )}
    </IM.Card>
  )
})

export default ActivityDescriptionCard
