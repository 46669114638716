import { useContext } from 'react'

import AccessDataFilterContext from '../contexts/AccessDataFilterContext'

export default function useAccessDataFilter() {
  const context = useContext(AccessDataFilterContext)

  if (context === undefined) {
    throw new Error('useAccessDataFilter() must be called inside AccessDataFilterProvider')
  }

  return context
}
