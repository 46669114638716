import { Storage, useLanguage } from '@infominds/react-native-components'
import cloneDeep from 'lodash/cloneDeep'
import React, { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'

import { STORAGE_KEY_FILTER_CONTEXT_QUALITY_CHECK_GROUP, STORAGE_KEY_FILTER_CONTEXT_QUALITY_CHECK_ORDER } from '../constants/Keys'
import { Group, Order, StorageType } from '../types'
import ticketUtils from '../utils/TicketUtils'

export enum QualityCheckGroupType {
  SerialNumber = 'SerialNumberKey',
  Attribute = 'AttributeKey',
}

export enum QualityCheckOrderType {
  AlphabeticalDescending = 'OrderAlphabeticalDescendingKey',
  AlphabeticalAscending = 'OrderAlphabeticalAscendingKey',
}

interface ContextProps {
  groups: Group<QualityCheckGroupType>[]
  orders: Order<QualityCheckOrderType>[]
  setGroups: (groups: Group<QualityCheckGroupType>[]) => void
  setOrders: (orders: Order<QualityCheckOrderType>[]) => void
  changeFilterStatus: (id: QualityCheckGroupType | QualityCheckOrderType) => void
}

const QualityCheckFilterContext = createContext<ContextProps | undefined>(undefined)

const storageKeyUniqueId = 'QualityCheck'

export const QualityCheckFilterProvider = ({ children }: PropsWithChildren) => {
  const { i18n, language } = useLanguage()

  const order: Order<QualityCheckOrderType>[] = [
    {
      active: false,
      data: {
        id: QualityCheckOrderType.AlphabeticalDescending,
        name: ticketUtils.getNameByType(QualityCheckOrderType.AlphabeticalDescending, i18n),
        icon: ['fal', 'arrow-up-a-z'],
      },
    },
    {
      active: false,
      data: {
        id: QualityCheckOrderType.AlphabeticalAscending,
        name: ticketUtils.getNameByType(QualityCheckOrderType.AlphabeticalAscending, i18n),
        icon: ['fal', 'arrow-down-a-z'],
      },
    },
  ]

  const [groups, setGroups] = useState<Group<QualityCheckGroupType>[]>(
    [
      { active: true, data: { id: QualityCheckGroupType.SerialNumber, name: ticketUtils.getNameByType(QualityCheckGroupType.SerialNumber, i18n) } },
      { active: false, data: { id: QualityCheckGroupType.Attribute, name: ticketUtils.getNameByType(QualityCheckGroupType.Attribute, i18n) } },
    ].sort((a, b) => a.data.name.localeCompare(b.data.name))
  )
  const [orders, setOrders] = useState<Order<QualityCheckOrderType>[]>(order.sort((a, b) => a.data.name.localeCompare(b.data.name)))

  const groupsStorage = Storage<StorageType[]>(STORAGE_KEY_FILTER_CONTEXT_QUALITY_CHECK_GROUP + storageKeyUniqueId)
  const ordersStorage = Storage<StorageType[]>(STORAGE_KEY_FILTER_CONTEXT_QUALITY_CHECK_ORDER + storageKeyUniqueId)

  useEffect(() => {
    groupsStorage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const clone = cloneDeep(groups).map(elem => {
            const found = loaded.find(ld => ld.id === elem.data.id)

            if (found) {
              return { ...elem, active: found.value }
            } else {
              return elem
            }
          })

          setGroups(clone)
        }
      })
      .catch(err => console.error('Failed loading ticket group by', err))

    ordersStorage
      .load()
      .then(loaded => {
        if (loaded !== null) {
          const clone = cloneDeep(orders).map(elem => {
            const found = loaded.find(ld => ld.id === elem.data.id)

            if (found) {
              return { ...elem, active: found.value }
            } else {
              return elem
            }
          })

          setOrders(clone)
        }
      })
      .catch(err => console.error('Failed loading ticket sort by', err))
  }, [])

  useEffect(() => {
    setGroups(prv =>
      prv.map(el => {
        return { ...el, data: { ...el.data, name: ticketUtils.getNameByType(el.data.id, i18n) } }
      })
    )
    setOrders(prv =>
      prv.map(el => {
        return { ...el, data: { ...el.data, name: ticketUtils.getNameByType(el.data.id, i18n) } }
      })
    )
  }, [language])

  const changeFilterStatus = useCallback(
    (id: QualityCheckGroupType | QualityCheckOrderType) => {
      if (Object.values(QualityCheckGroupType).includes(id as QualityCheckGroupType)) {
        const clone = cloneDeep(groups)

        clone.forEach(filter => {
          if (filter.data.id === id) {
            filter.active = !filter.active
          }
        })

        handleGroupsUpdate(clone)
      } else if (Object.values(QualityCheckOrderType).includes(id as QualityCheckOrderType)) {
        const clone = cloneDeep(orders)

        clone.forEach(filter => {
          if (filter.data.id === id) {
            filter.active = !filter.active
          }
        })

        handleOrderUpdate(clone)
      }
    },
    [orders, groups]
  )

  const handleGroupsUpdate = (newGroups: Group<QualityCheckGroupType>[]) => {
    const toSave: StorageType[] = []
    newGroups.forEach(order => toSave.push({ id: order.data.id, value: order.active }))
    groupsStorage.save(toSave).catch(err => console.error('Failed saving quality group', err))
    setGroups(newGroups)
  }

  const handleOrderUpdate = (newOrders: Order<QualityCheckOrderType>[]) => {
    const toSave: StorageType[] = []
    newOrders.forEach(order => toSave.push({ id: order.data.id, value: order.active }))
    ordersStorage.save(toSave).catch(err => console.error('Failed saving quality order', err))
    setOrders(newOrders)
  }

  const props = useMemo<ContextProps>(
    () => ({
      groups,
      orders,
      setGroups: handleGroupsUpdate,
      setOrders: handleOrderUpdate,
      changeFilterStatus,
    }),
    [groups, orders]
  )

  return <QualityCheckFilterContext.Provider value={props}>{children}</QualityCheckFilterContext.Provider>
}

export default QualityCheckFilterContext
