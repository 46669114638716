import { IM, IMLayout, SpacingProps, useDimensions, useLanguage, useModalController } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, ReactNode, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import { TicketActivity } from '../../apis/types/apiResponseTypes'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import DynamicView from '../../components/Infominds/DynamicView'
import Pressable from '../../components/Infominds/Pressable'
import NavigationIcon from '../../components/NavigationIcon'
import OnlineView from '../../components/offline/OnlineView'
import TextWithHeader from '../../components/TextWithHeader'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useUserSettings from '../../hooks/useUserSettings'
import EditStatusModal from '../../modals/common/EditStatusModal'
import { ActivityStackParamList } from '../../navigation/types'
import { EditStateParams } from '../../types'
import ticketUtils from '../../utils/TicketUtils'
import TimeUtils from '../../utils/TimeUtils'

type Props = {
  activity: TicketActivity
  spacing?: SpacingProps
  sendReportButton: ReactNode | undefined
  navigateButton: ReactNode | undefined
  counterButton: ReactNode | undefined
  qualityCheckButton: ReactNode | undefined
  editable: boolean
}

const MARGIN = 6

const ActivityInfoCard = memo(function ActivityInfoCard({
  activity,
  spacing,
  sendReportButton,
  navigateButton,
  counterButton,
  qualityCheckButton,
  editable,
}: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useDimensions()
  const { userSettings } = useUserSettings()
  const isOnline = useIsOnline()
  const editStateController = useModalController<EditStateParams>()
  const navigation = useNavigation<NavigationProp<ActivityStackParamList>>()

  const alignItems = isSmallDevice ? 'center' : 'flex-start'
  const justifyContent = isSmallDevice ? 'center' : 'flex-start'

  const date = useMemo(() => TimeUtils.format(activity.date, language), [language])
  const disableState = !isOnline || !editable || userSettings?.allowModifyActivityAndTicketState === false

  return (
    <>
      <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('INFO')} />}>
        <DynamicView flexLimitSmallDevice={4} flexLimitLargeDevice={10}>
          <TextWithHeader style={[IMLayout.flex.f2, styles.marginRight]} header={i18n.t('DATE')} text={date} spacing="bottom" />
          <IM.View style={[IMLayout.flex.f2, styles.marginLeft, !isSmallDevice && styles.marginRight]} spacing="bottom">
            <Pressable
              style={styles.statePressable}
              disabled={disableState}
              onPress={() => {
                const data: EditStateParams = { id: activity.id, stateId: activity.stateId, type: 'Activity' }
                isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                  ? navigation.navigate('ActivityCommonStack', { screen: 'EditState', params: data })
                  : editStateController.show(data)
              }}>
              <TextWithHeader
                header={i18n.t('ACTIVITY_STATUS')}
                text={ticketUtils.composeStateString({ code: activity.stateCode, description: activity.stateDescription })}
                style={IMLayout.flex.f1}
              />
              {!disableState && <NavigationIcon />}
            </Pressable>
          </IM.View>

          <TextWithHeader
            style={[IMLayout.flex.f2, styles.marginRight, !isSmallDevice && styles.marginLeft]}
            header={i18n.t('RESPONSIBLE')}
            text={activity.employeeResponsible ?? '-'}
            spacing="bottom"
          />
          <TextWithHeader
            style={[IMLayout.flex.f2, styles.marginLeft, !isSmallDevice && styles.marginRight]}
            header={i18n.t('COLLABORATOR')}
            text={activity.employee ?? '-'}
            spacing="bottom"
          />
          <TextWithHeader
            style={[IMLayout.flex.f2, styles.marginRight, !isSmallDevice && styles.marginLeft]}
            header={i18n.t('INVOICE')}
            text={activity.invoiceType ?? '-'}
            spacing="bottom"
          />
          {isSmallDevice && navigateButton !== undefined && (
            <IM.View style={[IMLayout.flex.f2, styles.flexStart, styles.marginLeft, { justifyContent }]} spacing="bottom">
              {navigateButton}
            </IM.View>
          )}
          {editable && isSmallDevice && sendReportButton !== undefined && (
            <OnlineView>
              <IM.View style={[IMLayout.flex.f2, styles.flexStart, styles.marginLeft, { justifyContent }]} spacing="bottom">
                {sendReportButton}
              </IM.View>
            </OnlineView>
          )}
          {editable && isSmallDevice && counterButton !== undefined && (
            <IM.View style={[IMLayout.flex.f6, { alignItems }]} spacing="bottom">
              {counterButton}
            </IM.View>
          )}
          {editable && isSmallDevice && qualityCheckButton !== undefined && (
            <IM.View style={[IMLayout.flex.f6, { alignItems }]}>{qualityCheckButton}</IM.View>
          )}
        </DynamicView>
        {!isSmallDevice && (
          <IM.View style={[IMLayout.flex.f1, IMLayout.flex.row, styles.buttons]}>
            {navigateButton && <IM.View style={[{ alignItems, justifyContent }]}>{navigateButton}</IM.View>}
            {editable && (
              <OnlineView>
                <IM.View style={[{ alignItems, justifyContent }]}>{sendReportButton}</IM.View>
              </OnlineView>
            )}
            {editable && <IM.View style={[{ alignItems, justifyContent }]}>{counterButton}</IM.View>}
            {editable && <IM.View style={[{ alignItems, justifyContent }]}>{qualityCheckButton}</IM.View>}
          </IM.View>
        )}
      </IM.Card>
      <EditStatusModal controller={editStateController} />
    </>
  )
})

export default ActivityInfoCard

const styles = StyleSheet.create({
  marginRight: { marginRight: MARGIN },
  marginLeft: { marginLeft: MARGIN },
  flexStart: { alignItems: 'flex-start' },
  buttons: { justifyContent: 'space-between' },
  statePressable: { padding: 0, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 8 },
})
