import { IM, SpacingProps } from '@infominds/react-native-components'
import React from 'react'
import { StyleSheet } from 'react-native'

import useQualityCheck from '../../hooks/useQualityCheck'
import { QualityCharacteristicDetails, QualityCheckType } from '../../types'
import QualityCheckSelector from '../selectors/QualityCheckSelector'
import Switch from '../Switch'
import NumberInput from './NumberInput'
import TextInput from './TextInput'

interface Props {
  type: QualityCheckType
  selectorTitle: string
  qualityCharacteristicId: string
  qualityCharacteristicDetails?: QualityCharacteristicDetails[]
  qualityCharacteristicDecimal?: number
  serialNumberId: string
  spacing?: SpacingProps
}

export default function QualityInput({
  type,
  spacing,
  selectorTitle,
  qualityCharacteristicDecimal,
  qualityCharacteristicId,
  qualityCharacteristicDetails,
  serialNumberId,
}: Props) {
  const { getQualityValue, setQualityValue } = useQualityCheck()

  const render = () => {
    switch (type) {
      case 'Text': {
        return (
          <TextInput
            value={getQualityValue(qualityCharacteristicId, serialNumberId, 'value')}
            onChangeText={newVal =>
              setQualityValue({ characteristicsId: qualityCharacteristicId, serialNumberId, value: newVal === '' ? undefined : newVal })
            }
          />
        )
      }
      case 'Numeric': {
        return (
          <NumberInput
            value={getQualityValue(qualityCharacteristicId, serialNumberId, 'value')}
            decimals={qualityCharacteristicDecimal}
            onChangeText={newVal =>
              setQualityValue({ characteristicsId: qualityCharacteristicId, serialNumberId, value: newVal === '' ? undefined : newVal })
            }
          />
        )
      }
      case 'Boolean': {
        return (
          <Switch
            style={styles.switch}
            value={getQualityValue(qualityCharacteristicId, serialNumberId, 'value') === 'true' ? true : false}
            onValueChange={newVal =>
              setQualityValue({ characteristicsId: qualityCharacteristicId, serialNumberId, value: newVal ? 'true' : 'false' })
            }
          />
        )
      }
      case 'Selection': {
        return (
          qualityCharacteristicDetails && (
            <QualityCheckSelector
              id={getQualityValue(qualityCharacteristicId, serialNumberId, 'value')}
              screenTitle={selectorTitle}
              data={qualityCharacteristicDetails}
              onChange={newVal =>
                setQualityValue({ characteristicsId: qualityCharacteristicId, serialNumberId, value: newVal ? newVal.id : undefined })
              }
              alignRight
            />
          )
        )
      }
      case 'None': {
        return <></>
      }
    }
  }
  return <IM.View spacing={spacing}>{render()}</IM.View>
}

const styles = StyleSheet.create({
  switch: {
    alignItems: 'flex-end',
  },
})
