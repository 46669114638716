import { IM } from '@infominds/react-native-components'
import React, { ReactNode } from 'react'
import { StyleSheet } from 'react-native'

import { DetailProvider } from '../contexts/DetailContext'
import useMasterDetail from '../hooks/useMasterDetail'

export interface DetailProps {
  children: ReactNode
  flex?: number
  borderColor?: string
}

const Detail = ({ children, flex, borderColor }: DetailProps) => {
  const { detailParams, setDetailParams } = useMasterDetail()

  return (
    <DetailProvider detail={detailParams} setDetail={setDetailParams}>
      <IM.View style={[styles.detailView, { borderLeftColor: borderColor, flex }]}>{children}</IM.View>
    </DetailProvider>
  )
}

const styles = StyleSheet.create({
  detailView: {
    height: '100%',
    overflow: 'hidden',
    borderLeftWidth: 1,
  },
})

export default Detail
