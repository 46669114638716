import React from 'react'
import { useRecoilValue } from 'recoil'

import OnlineView from '../../components/offline/OnlineView'
import { SearchProvider } from '../../components/screen/contexts/SearchContext'
import FilterDetailHeaderContainer from '../../components/screen/headers/containers/FilterDetailHeaderContainer'
import SearchDetailHeaderContainer from '../../components/screen/headers/containers/SearchDetailHeaderContainer'
import FilterDetailHeader from '../../components/screen/headers/FilterDetailHeader'
import ServiceHeader from '../../components/screen/headers/ServiceHeader'
import { Screen } from '../../components/screen/Screen'
import FilterContext from '../../contexts/FilterContext'
import useUserSettings from '../../hooks/useUserSettings'
import { PlanningStackScreenProps } from '../../navigation/types'
import { activityPlanningFilterEnableAtom } from '../../utils/stateManager'
import PlanningView from '../../views/planning/PlanningView'

export default function PlanningScreen({ navigation }: PlanningStackScreenProps<'Planning'>) {
  const enabled = useRecoilValue(activityPlanningFilterEnableAtom)

  const { userSettings } = useUserSettings()

  const handleSDNavigation = () => {
    navigation.navigate('PlanningCommonStack', { screen: 'Filter' })
  }

  return (
    <SearchProvider>
      <FilterContext.Consumer>
        {context => {
          let activeFilters = false
          context?.filters.forEach(filter =>
            filter.elements.forEach(el => {
              if (el.active) activeFilters = true
            })
          )
          const activeGroups = context?.groups.filter(el => el.active)
          const activeOrder = context?.orders.filter(el => el.active)

          return (
            <Screen>
              <Screen.Header>
                <ServiceHeader
                  hideChangeViewIcon
                  tabletSearchComponent={<SearchDetailHeaderContainer dividers={['right']} enableQR />}
                  tabletFilterComponent={
                    <FilterDetailHeaderContainer dividers={['right']}>
                      <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                    </FilterDetailHeaderContainer>
                  }
                  highlightFilter={activeFilters || activeGroups?.length !== 0 || activeOrder?.length !== 0}
                  enabledOffline={userSettings?.offlineSynchronizationOfThePlanningList}
                />
              </Screen.Header>
              <Screen.DetailHeader>
                <SearchDetailHeaderContainer enableQR />
                <FilterDetailHeaderContainer>
                  <FilterDetailHeader enabled={enabled} onSDNavigation={handleSDNavigation} />
                </FilterDetailHeaderContainer>
              </Screen.DetailHeader>
              <Screen.Content>
                <OnlineView showOfflineIndicator showInOffline={userSettings?.offlineSynchronizationOfThePlanningList}>
                  <PlanningView />
                </OnlineView>
              </Screen.Content>
            </Screen>
          )
        }}
      </FilterContext.Consumer>
    </SearchProvider>
  )
}
