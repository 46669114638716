import { useCallback } from 'react'

import api from '../apis/apiCalls'
import useControlledLoader from '../components/Infominds/hooks/useControlledLoader'
import { REQUEST_ADDITIONAL_FIELDS } from '../constants/Keys'
import { AdditionalFieldType } from '../types'

export default function useAddFieldsLoader(type: AdditionalFieldType) {
  const {
    loadItem: loadAct,
    item: act,
    loading: loadingAct,
  } = useControlledLoader(api.getActivityAddFields, {
    id: REQUEST_ADDITIONAL_FIELDS,
  })
  const {
    loadItem: loadTck,
    item: tck,
    loading: loadingTck,
  } = useControlledLoader(api.getTicketAddFields, {
    id: REQUEST_ADDITIONAL_FIELDS,
  })
  const { loadItem, item, loading } = useControlledLoader(api.getAddFields, {
    id: REQUEST_ADDITIONAL_FIELDS,
  })

  const load = useCallback(
    (fkIds: string[]) => {
      switch (type) {
        case 'Activity': {
          loadAct({ fkIds })
          break
        }
        case 'Ticket': {
          loadTck({ fkIds })
          break
        }
        default: {
          if (fkIds.length !== 1) {
            console.error(`fkIds on ${type} must be of length 1`)
          }

          loadItem({ type, fkId: fkIds[0], onlyForApp: true })
          break
        }
      }
    },
    [loadAct, loadTck, loadItem]
  )

  const getLoading = useCallback(() => {
    switch (type) {
      case 'Activity': {
        return loadingAct
      }
      case 'Ticket': {
        return loadingTck
      }
      default: {
        return loading
      }
    }
  }, [loadingAct, loadingTck, loading])

  const getResult = useCallback(() => {
    switch (type) {
      case 'Activity': {
        return act
      }
      case 'Ticket': {
        return tck
      }
      default: {
        return item ? [item] : undefined
      }
    }
  }, [act, tck, item])

  return { loadAddFields: load, loading: getLoading(), item: getResult() }
}
