import { IM, IMLayout, SpacingProps, useLanguage, useModalController, useTheme } from '@infominds/react-native-components'
import { NavigationProp, useNavigation } from '@react-navigation/native'
import React, { memo, useEffect, useMemo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import api from '../../apis/apiCalls'
import { Ticket } from '../../apis/types/apiResponseTypes'
import Button from '../../components/Button'
import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import DynamicView from '../../components/Infominds/DynamicView'
import useControlledLoader from '../../components/Infominds/hooks/useControlledLoader'
import useRequest from '../../components/Infominds/hooks/useRequest'
import Pressable from '../../components/Infominds/Pressable'
import NavigationIcon from '../../components/NavigationIcon'
import Text from '../../components/Text'
import TextWithHeader from '../../components/TextWithHeader'
import CONSTANTS from '../../constants/Constants'
import { REQUEST_POST_REPORT, REQUEST_REPORTS } from '../../constants/Keys'
import useIsOnline from '../../dataProvider/hooks/useIsOnline'
import useLayout, { ForceLayoutType } from '../../hooks/useLayout'
import useUserSettings from '../../hooks/useUserSettings'
import EditStatusModal from '../../modals/common/EditStatusModal'
import TicketEditPriorityModal from '../../modals/tickets/TicketEditPriorityModal'
import TicketReportModal from '../../modals/tickets/TicketReportModal'
import { TicketStackParamList } from '../../navigation/types'
import { EditStateParams, ThemeColorExpanded, TicketEditPriorityParams, TicketReportParams } from '../../types'
import ticketUtils from '../../utils/TicketUtils'
import TimeUtils from '../../utils/TimeUtils'

type Props = {
  ticket: Ticket
  spacing?: SpacingProps
  onlyRead?: boolean
  forceLayout?: ForceLayoutType
}

const TicketDetailInfoCard = memo(function TicketDetailInfoCard({ ticket, spacing, onlyRead, forceLayout }: Props) {
  const { i18n, language } = useLanguage()
  const { isSmallDevice } = useLayout(false, forceLayout)
  const { theme } = useTheme<ThemeColorExpanded>()
  const isOnline = useIsOnline()
  const { userSettings } = useUserSettings()

  const { loadItem: loadReports, item: reports, loading } = useControlledLoader(api.getReports, { id: REQUEST_REPORTS })
  const { request, loading: loadingSend } = useRequest(api.sendTicketReport, { id: REQUEST_POST_REPORT })

  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()
  const sendReportController = useModalController<TicketReportParams>()
  const editPriorityController = useModalController<TicketEditPriorityParams>()
  const editStateController = useModalController<EditStateParams>()

  const documentDate = useMemo(() => TimeUtils.format(ticket.documentDate, language), [ticket, language])
  const planDate = useMemo(() => (ticket.planDate ? TimeUtils.format(ticket.planDate, language) : undefined), [ticket, language])
  const startTime = useMemo(() => !!ticket.planStartTime && TimeUtils.formatSeconds(ticket.planStartTime, undefined, true), [ticket])
  const endTime = useMemo(() => !!ticket.planEndTime && TimeUtils.formatSeconds(ticket.planEndTime, undefined, true), [ticket])
  const flexLayout = isSmallDevice ? IMLayout.flex.f2 : IMLayout.flex.f3

  useEffect(() => {
    if (isOnline) loadReports({})
  }, [isOnline])

  const disablePriority = !isOnline || onlyRead || userSettings?.allowModifyTicketPriority === false
  const disableState = !isOnline || onlyRead || userSettings?.allowModifyActivityAndTicketState === false

  return (
    <>
      <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('INFO')} />}>
        <DynamicView flexLimitSmallDevice={4} forceLayout={forceLayout}>
          <TextWithHeader
            style={[flexLayout, styles.marginRight]}
            header={i18n.t('DATE')}
            text={documentDate}
            spacing={isSmallDevice ? 'bottom' : 'none'}
          />
          <TextWithHeader
            style={[flexLayout, styles.marginLeft, !isSmallDevice && styles.marginRight]}
            header={i18n.t('PLAN_DATE')}
            text={planDate ? planDate + '\n' + (startTime ? startTime : '') + (endTime ? ` - ${endTime}` : '') : '-'}
            spacing={isSmallDevice ? 'bottom' : 'none'}
          />
          <TextWithHeader
            style={[flexLayout, styles.marginRight, !isSmallDevice && styles.marginLeft]}
            header={i18n.t('RESPONSIBLE')}
            text={ticket.employeeResponsible ?? '-'}
            spacing={isSmallDevice ? 'bottom' : 'none'}
          />
          <TextWithHeader
            style={[flexLayout, styles.marginLeft, !isSmallDevice && styles.marginRight]}
            header={i18n.t('COLLABORATOR')}
            text={ticket.employee ?? '-'}
            spacing={isSmallDevice ? 'bottom' : 'none'}
          />
          <TextWithHeader
            style={[flexLayout, styles.marginRight]}
            header={i18n.t('INVOICE')}
            text={ticket.invoicingType ?? '-'}
            spacing={isSmallDevice ? 'bottom' : 'top'}
          />
          <IM.View style={[flexLayout, styles.marginLeft, !isSmallDevice && styles.marginRight]} spacing={isSmallDevice ? 'bottom' : 'top'}>
            <Pressable
              style={styles.priorityPressable}
              disabled={disablePriority}
              onPress={() =>
                isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                  ? navigation.navigate('TicketEditPriority', { ticketId: ticket.id, priorityId: ticket.priorityId })
                  : editPriorityController.show({ ticketId: ticket.id, priorityId: ticket.priorityId })
              }>
              <TextWithHeader header={i18n.t('PRIORITY')} style={IMLayout.flex.f1}>
                <IM.View style={styles.priorityContainer}>
                  {ticket.priorityId && (
                    <IM.View style={[styles.priorityIndicator, { backgroundColor: ticket.priorityColorHex }]} spacing="right" spacingType="margin" />
                  )}
                  <Text numberOfLines={1} style={IMLayout.flex.f1}>
                    {ticket.priorityCode && ticket.priorityDescription
                      ? ticketUtils.composePriorityString({
                          code: ticket.priorityCode,
                          description: ticket.priorityDescription,
                        })
                      : '-'}
                  </Text>
                </IM.View>
              </TextWithHeader>
              {!disablePriority && <NavigationIcon />}
            </Pressable>
          </IM.View>
          <IM.View style={[flexLayout, styles.marginRight, !isSmallDevice && styles.marginLeft]} spacing={isSmallDevice ? 'none' : 'top'}>
            <Pressable
              style={styles.statePressable}
              disabled={disableState}
              onPress={() => {
                const data: EditStateParams = { id: ticket.id, stateId: ticket.stateId, type: 'Ticket' }

                isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                  ? navigation.navigate('TicketCommonStack', { screen: 'EditState', params: data })
                  : editStateController.show(data)
              }}>
              <TextWithHeader
                header={i18n.t('TICKET_STATE')}
                text={ticketUtils.composeStateString({ code: ticket.stateCode, description: ticket.stateDescription })}
                style={IMLayout.flex.f1}
              />
              {!disableState && <NavigationIcon />}
            </Pressable>
          </IM.View>
          <IM.View
            style={[flexLayout, styles.marginLeft, !isSmallDevice && styles.marginRight, styles.sendReportContainer]}
            spacing={isSmallDevice ? 'none' : 'top'}>
            <Button
              title={i18n.t('SEND_REPORT')}
              loading={!isOnline ? false : !(loading === false) || !(loadingSend === false)}
              onPress={() => {
                if (reports === undefined) return

                if (reports.length === 1) {
                  request({ ticketId: ticket.id, reportId: reports[0].id })
                } else {
                  isSmallDevice && (Platform.OS === 'android' || Platform.OS === 'ios')
                    ? navigation.navigate('TicketReport', { ticketId: ticket.id })
                    : sendReportController.show({ ticketId: ticket.id })
                }
              }}
              disabled={!isOnline || onlyRead || !(loading === false) || (loading === false && reports && reports.length === 0)}
              color={theme.general.info}
            />
          </IM.View>
        </DynamicView>
      </IM.Card>
      <TicketReportModal controller={sendReportController} />
      <TicketEditPriorityModal controller={editPriorityController} />
      <EditStatusModal controller={editStateController} />
    </>
  )
})

export default TicketDetailInfoCard

const styles = StyleSheet.create({
  marginRight: { marginRight: CONSTANTS.margin },
  marginLeft: { marginLeft: CONSTANTS.margin },
  sendReportContainer: { alignItems: 'flex-start', justifyContent: 'center' },
  priorityContainer: { flexDirection: 'row', alignItems: 'center' },
  priorityIndicator: { height: 13, aspectRatio: 1, borderRadius: 3 },
  priorityPressable: { padding: 0, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 8 },
  statePressable: { padding: 0, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingRight: 8 },
})
