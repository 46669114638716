import { CardProps, IM, IMLayout, useDimensions, useTheme } from '@infominds/react-native-components'
import React, { createRef, memo, useEffect, useRef, useState } from 'react'
import { StyleSheet } from 'react-native'

import { ArticleSparePart } from '../../apis/types/apiResponseTypes'
import ArticleDetailsAndGraphic from '../../components/ArticleDetailsAndGraphic'
import Pressable from '../../components/Infominds/Pressable'
import TextWithIcon from '../../components/Infominds/TextWithIcon'
import Text from '../../components/Text'
import TextExpandable from '../../components/TextExpandable'
import { TextExpandableRef, ThemeColorExpanded } from '../../types'
import ticketUtils from '../../utils/TicketUtils'

interface Props extends Omit<CardProps, 'onPress'> {
  selectedId?: string
  sparePart: ArticleSparePart
  onPress?: (identifier: string, articleId: string, graphicId?: string) => void
}

const SparePartsViewCard = memo(function SparePartsViewCard({ selected, sparePart, style, onPress, disabled, ...others }: Props) {
  const { isSmallDevice } = useDimensions()
  const { theme } = useTheme<ThemeColorExpanded>()

  const lastElem = useRef(sparePart)
  const textRef = createRef<TextExpandableRef>()

  const [shown, setShown] = useState(false)

  useEffect(() => {
    if (
      sparePart.serialnumberId !== lastElem.current.serialnumberId ||
      sparePart.articleId !== lastElem.current.articleId ||
      sparePart.depositId !== lastElem.current.depositId
    ) {
      lastElem.current = sparePart
      resetState()
    }
  }, [sparePart])

  const resetState = () => {
    setShown(false)
    textRef.current?.reset()
  }

  const handlePress = () => {
    setShown(prev => !prev)
    !isSmallDevice && onPress?.(ticketUtils.getPartIdentifier({ ...sparePart, date: new Date().toString() }), sparePart.articleId)
  }

  return (
    <IM.Card noContentSpacing {...others} style={[style, selected && { backgroundColor: theme.item.selected }]}>
      <Pressable onPress={handlePress} style={styles.pressable} disabled={disabled}>
        <IM.View style={IMLayout.flex.f1}>
          <Text>{sparePart.articleSearchtext}</Text>
          <Text>{sparePart.articleCode}</Text>
          {sparePart.serialnumber && (
            <TextWithIcon secondary icon={['fal', 'barcode']}>
              {sparePart.serialnumber}
            </TextWithIcon>
          )}
          {sparePart.stockQuantity !== undefined && (
            <TextWithIcon secondary icon={['fal', 'box']}>
              {sparePart.stockQuantity.toString() + (sparePart.measurementUnit ? ` ${sparePart.measurementUnit}` : '')}
            </TextWithIcon>
          )}
        </IM.View>

        {sparePart.articleDescription && (
          <IM.View spacing="top">
            <TextExpandable ref={textRef} secondary numberOfLines={2} inline>
              {sparePart.articleDescription}
            </TextExpandable>
          </IM.View>
        )}
        {isSmallDevice && shown && (
          <IM.View spacing="top">
            <ArticleDetailsAndGraphic articleId={sparePart.articleId} />
          </IM.View>
        )}
      </Pressable>
    </IM.Card>
  )
})

export default SparePartsViewCard

const styles = StyleSheet.create({
  pressable: {
    padding: 10,
  },
})
