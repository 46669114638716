import { useContext } from 'react'

import { CloseActivityContext } from '../contexts/CloseActivityContext'

export default function useCloseActivity() {
  const context = useContext(CloseActivityContext)

  if (context === undefined) {
    throw new Error('useCloseActivity() must be called inside CloseActivityProvider')
  }

  return context
}
