import { IM, IMLayout, ViewProps } from '@infominds/react-native-components'
import React, { useMemo } from 'react'
import { StyleSheet } from 'react-native'

import NotificationBadge from '../../../components/NotificationBadge'
import useLayout, { ForceLayoutType } from '../../../hooks/useLayout'
import { ActivityType } from '../../../types'
import ticketUtils from '../../../utils/TicketUtils'

type Props = {
  unReadMessages?: number
  activityType?: ActivityType
  priorityColorHex?: string
  enablePriorityIndicator?: boolean
  forceLayout?: ForceLayoutType
}

export default function TicketIcon({
  unReadMessages,
  activityType,
  priorityColorHex,
  enablePriorityIndicator,
  forceLayout,
  ...props
}: Props & ViewProps) {
  const { isSmallDevice } = useLayout(true, forceLayout)
  const icon = useMemo(() => ticketUtils.getTicketIconByActivity(activityType), [activityType])

  return (
    <IM.View style={[!isSmallDevice && styles.view, IMLayout.flex.row]} {...props}>
      {enablePriorityIndicator && (
        <IM.View
          style={[styles.priority, { backgroundColor: priorityColorHex, borderRadius: IMLayout.borderRadius }]}
          spacing="right"
          spacingType="margin"
        />
      )}
      <IM.Icon icon={icon} size={isSmallDevice ? 30 : 35} />
      {!!unReadMessages && <NotificationBadge number={unReadMessages} style={styles.badge} />}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  priority: {
    width: 8,
  },
  view: {
    justifyContent: 'center',
  },
  badge: { top: -9, right: 0 },
})
