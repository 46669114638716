import { IM, IMLayout } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, ViewStyle } from 'react-native'

import SkeletonText from './SkeletonText'

const SKELETON_ELEM = 35

interface Props {
  elementStyle?: StyleProp<ViewStyle>
}

export default function SkeletonScrollView({ elementStyle }: Props) {
  return (
    <>
      {Array(SKELETON_ELEM)
        .fill(0)
        .map((_, index) => {
          const isFirst = index === 0

          return (
            <IM.View
              key={`SkeletonScrollView-${index}`}
              style={[
                // eslint-disable-next-line react-native/no-inline-styles
                {
                  marginHorizontal: 2 * IMLayout.horizontalMargin,
                  marginTop: isFirst ? 2 * IMLayout.verticalMargin : 0,
                  marginBottom: 1.4 * IMLayout.verticalMargin,
                },
                elementStyle,
              ]}>
              <SkeletonText width="100%" />
            </IM.View>
          )
        })}
    </>
  )
}
