import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo, ReactNode } from 'react'
import { Pressable, StyleSheet, TextStyle } from 'react-native'

import { ThemeColorExpanded } from '../../types'
import PressableIcon from '../Infominds/PressableIcon'

type Props<T> = {
  values: T[]
  title?: string
  placeholder?: string
  editable?: boolean
  titleFontWeight?: TextStyle['fontWeight']
  spacing?: SpacingProps
  renderItem: (item: T) => ReactNode
  onPress?: () => void
}

const MultiSelectField = memo(function MultiSelectField<T extends { id: string }>({
  values,
  title,
  placeholder,
  titleFontWeight,
  editable = true,
  spacing,
  onPress,
  renderItem,
}: Props<T>) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View spacing={spacing}>
      {title && (
        <IM.View style={styles.title}>
          <IM.Text style={{ fontWeight: titleFontWeight ?? IMStyle.typography.fontWeightMedium }}>{title}</IM.Text>
        </IM.View>
      )}
      <Pressable
        onPress={onPress}
        disabled={editable === false}
        style={[
          styles.inputContainer,
          {
            backgroundColor: editable ? theme.inputBox.background.active : theme.inputBox.background.disabled,
            borderColor: editable ? theme.inputBox.border.active : theme.inputBox.border.disabled,
          },
        ]}>
        <IM.View style={[IMLayout.flex.f1, styles.content]}>
          {values.length === 0 ? (
            <IM.Text style={{ color: theme.textPlaceholder }}>{placeholder ?? i18n.t('NO_SELECTION')}</IM.Text>
          ) : (
            <IM.View style={styles.item}>
              {values.map(val => {
                return <IM.View key={val.id}>{renderItem(val)}</IM.View>
              })}
            </IM.View>
          )}
        </IM.View>
        <PressableIcon icon={['fal', 'chevron-right']} size={18} color={theme.textDetail} disabled={editable === false} onPress={onPress} />
      </Pressable>
    </IM.View>
  )
})

export default MultiSelectField

const styles = StyleSheet.create({
  content: {
    paddingHorizontal: 9,
    marginRight: 4,
  },
  inputContainer: {
    borderWidth: 1,
    paddingHorizontal: 1,
    paddingVertical: 3,
    paddingRight: 6,
    borderRadius: IMLayout.borderRadius,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  item: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  title: {
    paddingBottom: 4,
  },
})
