import { IM, IMLayout, IMStyle, useTheme } from '@infominds/react-native-components'
import React from 'react'
import { StyleProp, StyleSheet, ViewStyle } from 'react-native'

import { ThemeColorExpanded } from '../types'
import LoadingIcon from './LoadingIcon'

type Props = {
  loading?: boolean
  number?: number
  color?: string
  disabled?: boolean | null
  disabledColor?: string
  style?: StyleProp<ViewStyle>
}

export default function NotificationBadge({ loading, number, color, disabled, disabledColor, style }: Props) {
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.View
      style={[
        styles.container,
        IMLayout.shadow,
        style,
        { backgroundColor: color ?? theme.general.info },
        disabled && { backgroundColor: disabledColor ?? theme.pressableIcon.disabled },
      ]}
      pointerEvents="none">
      {loading ? <LoadingIcon size={10} color={IMStyle.palette.white} /> : <IM.Text style={styles.text}>{number}</IM.Text>}
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: {
    borderRadius: IMLayout.iconRadius,
    minWidth: 20,
    height: 20,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 11,
    fontWeight: '700',
    width: '100%',
    textAlign: 'center',
    paddingHorizontal: 1,
  },
})
