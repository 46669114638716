import { IM, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'

import CardLeftTitle from '../../components/card/left/CardLeftTitle'
import TextExpandable from '../../components/TextExpandable'
import { ThemeColorExpanded } from '../../types'

type Props = {
  tip: string
  spacing?: SpacingProps
}

const TipCard = memo(function TipCard({ tip, spacing }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()

  return (
    <IM.Card spacing={spacing} head={<CardLeftTitle text={i18n.t('INFO')} color={theme.general.error} />}>
      <TextExpandable numberOfLines={2}>{tip}</TextExpandable>
    </IM.Card>
  )
})

export default TipCard
