import { IM, IMLayout, IMStyle, SpacingProps, useLanguage, useTheme } from '@infominds/react-native-components'
import { useAuthentication } from '@infominds/react-native-license'
import React, { useEffect, useState } from 'react'
import { Platform, SectionListRenderItemInfo } from 'react-native'
import { useRecoilState } from 'recoil'

import api from '../../apis/apiCalls'
import { SerialNumber } from '../../apis/types/apiResponseTypes'
import SerialNumberCard from '../../cards/common/SerialnumberCard'
import { REQUEST_TICKET_SERIAL_NUMBER } from '../../constants/Keys'
import { ListSection, ThemeColorExpanded } from '../../types'
import { serialNumberCustomerFilterAtom } from '../../utils/stateManager'
import useInfiniteLoader from '../Infominds/hooks/useInfiniteLoader'
import PressableIcon from '../Infominds/PressableIcon'
import SelectInput from './selectInput/SelectInput'

interface Props {
  customerId: string
  value: SerialNumber | undefined
  multiSelect?: boolean
  editable?: boolean
  spacing?: SpacingProps
  onChange: (value: SerialNumber | undefined) => void
}

export default function SerialnumberSelector({ customerId, value, onChange, ...props }: Props) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { sessionKey } = useAuthentication()

  const [search, setSearch] = useState('')
  const [customerFilter, setCustomerFilter] = useRecoilState(serialNumberCustomerFilterAtom(sessionKey))
  const {
    item: serialNumbers,
    loadItem: loadItems,
    allDataLoaded,
    loadMore,
    loading,
  } = useInfiniteLoader(api.getSerialNumber, { chuckSize: 20, id: REQUEST_TICKET_SERIAL_NUMBER })

  useEffect(() => {
    refresh()
  }, [search, customerFilter])

  const refresh = () => loadItems({ customerId: customerFilter ? customerId : undefined, searchText: search, devicesOnly: true })

  const render = ({ item }: SectionListRenderItemInfo<SerialNumber, ListSection<SerialNumber>>, onPress?: () => void) => {
    const isSelected = value?.number === item.number
    const isLast = serialNumbers.length > 0 && item.id === serialNumbers[serialNumbers.length - 1].id

    return (
      <IM.View
        // eslint-disable-next-line react-native/no-inline-styles
        style={{ marginBottom: isLast ? (allDataLoaded ? 3 : 2) * IMLayout.verticalMargin : 0 }}>
        <SerialNumberCard item={item} onPress={onPress} selected={isSelected} spacing={['horizontal', 'top']} />
      </IM.View>
    )
  }

  const handleOnChange = (newValue: SerialNumber | undefined) => onChange(newValue)

  return (
    <SelectInput
      data={serialNumbers}
      value={value === undefined || value.id === undefined ? undefined : value}
      loading={loading}
      allDataLoaded={allDataLoaded}
      title={i18n.t('SERIAL_NUMBER')}
      screenTitle={i18n.t('SERIAL_NUMBERS')}
      noDataMessage={i18n.t('NO_SN_FOUND')}
      onLoadMore={loadMore}
      refresh={refresh}
      onSearchChange={setSearch}
      renderItem={render}
      onChange={handleOnChange}
      focusSearch={Platform.OS === 'android' ? false : true} // TODO: the space to dismiss keyboard is pretty limited
      renderSelectedString={item => item.number}
      disableLoadAfterMount
      disableFastInput
      extraIcon={
        <PressableIcon
          icon={['fal', 'user']}
          color={customerFilter ? theme.general.info : IMStyle.palette.white}
          onPress={() => setCustomerFilter(prev => !prev)}
          size={19}
        />
      }
      {...props}
    />
  )
}
