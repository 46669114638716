import { useAlert, useEvent, useLanguage, useTheme } from '@infominds/react-native-components'
import { useKeyboard } from '@react-native-community/hooks'
import { NavigationProp, RouteProp, useNavigation } from '@react-navigation/native'
import React, { useEffect, useState } from 'react'
import { Animated, Platform } from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

import api from '../../apis/apiCalls'
import { Activity } from '../../apis/types/apiResponseTypes'
import DoneButton from '../../components/DoneButton'
import HeaderWithSync from '../../components/screen/headers/HeaderWithSync'
import { Screen } from '../../components/screen/Screen'
import { TICKET_CLOSE_ACTIVITY_ID } from '../../constants/ButtonIds'
import { REFRESH_ACTIVITY_HISTORY_LIST_EVENT_KEY, REFRESH_TICKET_LIST_EVENT_KEY } from '../../constants/EmitterKeys'
import useCloseActivity from '../../hooks/useCloseActivity'
import useCloseActivityId from '../../hooks/useCloseActivityId'
import useEditOrCreateScreenBackManager from '../../hooks/useEditOrCreateScreenBackManager'
import usePopState from '../../hooks/usePopState'
import { ActivityStackParamList, TicketStackParamList } from '../../navigation/types'
import { CloseType, ThemeColorExpanded } from '../../types'
import appUtils from '../../utils/appUtils'
import ActivityCloseView from './ActivityCloseView'

const buttonAnimationValue = new Animated.Value(0)

interface Props {
  route: RouteProp<ActivityStackParamList, 'ActivityClose'>
}

export default function ActivityCloseWrapperView({ route }: Props) {
  const { alert } = useAlert()
  const { i18n } = useLanguage()
  const { bottom } = useSafeAreaInsets()
  const { activities } = useCloseActivityId()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { keyboardShown, coordinates } = useKeyboard()
  const { enableDone, pending, dto, closeType } = useCloseActivity()
  const navigation = useNavigation<NavigationProp<TicketStackParamList>>()

  const [error, setError] = useState(false)
  const [loading, setLoading] = useState<boolean | undefined>(undefined)

  const { emit: emitTickets } = useEvent({ key: REFRESH_TICKET_LIST_EVENT_KEY })
  const { emit: emitHistory } = useEvent({ key: REFRESH_ACTIVITY_HISTORY_LIST_EVENT_KEY })

  const { setStatus, handleGoBack } = useEditOrCreateScreenBackManager({
    title: i18n.t('UNSAVED_CHANGES_TITLE'),
    description: i18n.t('DISCARD_UNSAVED_CHANGES'),
  })
  usePopState(pending, i18n.t('UNSAVED_CHANGES_TITLE'), i18n.t('DISCARD_UNSAVED_CHANGES'), () => {
    return
  })

  useEffect(() => {
    if (pending) {
      setStatus('mandatoryMissing')
    } else {
      setStatus('done')
    }
  }, [pending])

  const handlePress = () => {
    setError(false)
    setLoading(true)
    const promises: Promise<string>[] = []

    for (const el of activities) {
      promises.push(
        api.editActivity({
          ...dto,
          id: el.id,
          state: closeType === CloseType.ok ? 'Closing' : closeType === CloseType.withCheck ? 'ClosingWithVerification' : 'ClosingWithAFollowupVisit',
        } as Activity)
      )
    }

    Promise.all(promises)
      .then(() => {
        emitTickets()
        emitHistory()
        navigation.navigate('Tickets')
      })
      .catch(err => {
        setError(true)
        alert(i18n.t('API_CATCH_TITLE'), appUtils.getBackendErrorMessage(err))
      })
      .finally(() => setLoading(false))
  }

  return (
    <Screen>
      <Screen.Header goBack={handleGoBack} backHandlerCallback={handleGoBack}>
        <HeaderWithSync title={i18n.t('CLOSING')} />
      </Screen.Header>
      <Screen.Content forceBackground={theme.closing.fourth}>
        <ActivityCloseView
          activityId={route.params.activityId}
          customerId={route.params.customerId}
          shippingAddressId={route.params.shippingAddressId}
          initialReportEmail={route.params.email}
          initialContactName={route.params.contact}
          addressId={route.params.addressId}
        />
        <DoneButton
          id={TICKET_CLOSE_ACTIVITY_ID}
          value={buttonAnimationValue}
          disabled={!enableDone || loading}
          forceShow={enableDone === true}
          loading={loading === true ? 'reloading' : false}
          onPress={handlePress}
          error={error}
          bottom={Platform.OS === 'ios' ? (keyboardShown ? (coordinates.start?.height ?? 0) + bottom : undefined) : undefined}
        />
      </Screen.Content>
    </Screen>
  )
}
