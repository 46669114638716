import { IM, IMStyle, useLanguage, useTheme } from '@infominds/react-native-components'
import React, { memo } from 'react'
import { Platform, StyleSheet } from 'react-native'

import TicketActivityCloseNextCard from '../../../cards/activity/ActivityCloseNextCard'
import TextWithIcon from '../../../components/Infominds/TextWithIcon'
import { ScreenCardsHeaderShadows } from '../../../components/screen/constants/constants'
import Text from '../../../components/Text'
import { closeActivityStyles } from '../../../constants/Constants'
import useCloseActivity from '../../../hooks/useCloseActivity'
import { CloseType, ThemeColorExpanded } from '../../../types'
import { CloseActivityViewProps } from '../../activity/ActivityCloseView'
import TicketCloseActivityStep4View from './TicketCloseActivityStep4View'

const TicketCloseActivityStep3View = memo(function TicketCloseActivityStep3View({ ...others }: CloseActivityViewProps) {
  const { i18n } = useLanguage()
  const { theme } = useTheme<ThemeColorExpanded>()
  const { closeType } = useCloseActivity()

  return (
    <>
      <IM.View spacing="top" />
      <IM.View
        style={[
          closeActivityStyles.header,
          ScreenCardsHeaderShadows,
          styles.container,
          {
            backgroundColor: theme.closing.third,
          },
        ]}>
        <Text style={{ fontSize: IMStyle.typography.fontSizeRegular }}>{i18n.t('STEP_3')}</Text>
        {closeType === undefined ? (
          <TextWithIcon icon={['fas', 'circle-exclamation']} color={theme.general.error} viewStyle={styles.error} spacing="bottom">
            {i18n.t('COMPLETE_STEP_1')}
          </TextWithIcon>
        ) : (
          <>
            {closeType === CloseType.withNextActivity ? (
              <Text secondary>{i18n.t('STEP_3_DESCRIPTION')}</Text>
            ) : (
              <Text secondary>{i18n.t('STEP_NOT_NEEDED')}</Text>
            )}
            {closeType === CloseType.withNextActivity ? <TicketActivityCloseNextCard spacing="vertical" /> : <IM.View spacing="top" />}
          </>
        )}
        <TicketCloseActivityStep4View {...others} />
      </IM.View>
    </>
  )
})

export default TicketCloseActivityStep3View

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      native: {
        height: '100%',
      },
      web: {
        flexGrow: 1,
      },
    }),
    zIndex: 3,
  },
  error: {
    marginTop: 3,
  },
})
